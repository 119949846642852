export const MAIN_PAGE_PATH = '/app/dashboard';

export interface MenuInfo {
  label: string;
  path: string;
  permissionKey?: string;
  sub?: MenuInfo[];
  activated?: boolean;
  icon?: React.ReactNode;
  testId?: string;
  newTab?: boolean;
}
