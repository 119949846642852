import { Theme, DividerProps, DividerClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<DividerProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof DividerClasses,
    'MuiDivider',
    Omit<Theme, 'components'>
  >
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
