/* eslint-disable react-hooks/rules-of-hooks */
import {
  useAssetBonds as useAssetBondsService,
  useBondRelated as useBondRelatedService,
  useBondComparables as useBondComparablesService,
  useBondLincDetail as useBondLincDetailService,
  useBondLincDocuments as useBondLincDocumentsService,
  useBondLincRequestResearch as useBondLincRequestResearchService,
  useBondLincResearch as useBondLincResearchService,
  useBondNews as useBondNewsService,
  useBondNewsInfinite as useBondNewsInfiniteService,
  useBondPriceMonitor as useBondPriceMonitorService,
  useBondPriceMonitorInfinite as useBondPriceMonitorInfiniteService,
  useBondPriceMonitorDetail as useBondPriceMonitorDetailService,
  useBondPriceMonitorDetailInfinite as useBondPriceMonitorDetailInfiniteService,
  useBondPriceMonitorDetailGraph as useBondPriceMonitorDetailGraphService,
  useBondPriceMonitorFirms as useBondPriceMonitorFirmsService,
  useBondYieldAnalysis as useBondYieldAnalysisService,
  useBondsYieldHistoryChart as useBondsYieldHistoryChartService,
  useGetBondShareLink as useGetBondShareLinkService,
  useIndex as useIndexService,
  useIndexInfinite as useIndexInfiniteService,
  useSearchCharts as useSearchChartsService,
  useSearchChartsInfinite as useSearchChartsInfiniteService,
  usePriceHistory as usePriceHistoryService,
  useSearch as useSearchService,
  useStatistics as useStatisticsService,
  useYieldCalculator as useYieldCalculatorService,
  useYieldHistory as useYieldHistoryService,
  useDeleteYieldCurveFilter as useDeleteYieldCurveFilterService,
  useSaveYieldCurveFilter as useSaveYieldCurveFilterService,
  useYieldCurveFilter as useYieldCurveFilterService,
  useYieldCurveGenerator as useYieldCurveGeneratorService,
  useYieldCurveGeneratorFilters as useYieldCurveGeneratorFiltersService,
  yieldCurveFilterQueryFn as yieldCurveFilterQueryFnService,
  usePatchPriceMover as usePatchPriceMoverService,
  useApproveOutlierPrice as useApproveOutlierPriceService,
  useShareLink as useShareLinkService,
  useSearchHistory as useSearchHistoryService,
  useValidation,
  ApiKey,
  ApiConfig,
} from 'services/bond';
import { apiAuthClient } from '../services/apiClient';
import type { Bond } from 'services/bond';

export type { Bond };
export { ApiKey, ApiConfig, useValidation };

export const useAssetBonds = useAssetBondsService(apiAuthClient());
export const useBondRelated = useBondRelatedService(apiAuthClient());
export const useBondComparables = useBondComparablesService(apiAuthClient());
export const useBondLincDetail = useBondLincDetailService(apiAuthClient());
export const useBondLincDocuments =
  useBondLincDocumentsService(apiAuthClient());
export const useBondLincRequestResearch =
  useBondLincRequestResearchService(apiAuthClient());
export const useBondLincResearch = useBondLincResearchService(apiAuthClient());
export const useBondNews = useBondNewsService(apiAuthClient());
export const useBondNewsInfinite = useBondNewsInfiniteService(apiAuthClient());
export const useBondPriceMonitor = useBondPriceMonitorService(apiAuthClient());
export const useBondPriceMonitorInfinite =
  useBondPriceMonitorInfiniteService(apiAuthClient());
export const useBondPriceMonitorDetail =
  useBondPriceMonitorDetailService(apiAuthClient());
export const useBondPriceMonitorDetailInfinite =
  useBondPriceMonitorDetailInfiniteService(apiAuthClient());
export const useBondPriceMonitorDetailGraph =
  useBondPriceMonitorDetailGraphService(apiAuthClient());
export const useBondPriceMonitorFirms =
  useBondPriceMonitorFirmsService(apiAuthClient());
export const useBondYieldAnalysis =
  useBondYieldAnalysisService(apiAuthClient());
export const useBondsYieldHistoryChart =
  useBondsYieldHistoryChartService(apiAuthClient());
export const useGetBondShareLink = useGetBondShareLinkService(apiAuthClient());
export const useIndex = useIndexService(apiAuthClient());
export const useIndexInfinite = useIndexInfiniteService(apiAuthClient());
export const useSearchCharts = useSearchChartsService(apiAuthClient());
export const useSearchChartsInfinite =
  useSearchChartsInfiniteService(apiAuthClient());
export const usePriceHistory = usePriceHistoryService(apiAuthClient());
export const useSearch = useSearchService(apiAuthClient());
export const useStatistics = useStatisticsService(apiAuthClient());
export const useYieldCalculator = useYieldCalculatorService(apiAuthClient());
export const useYieldHistory = useYieldHistoryService(apiAuthClient());
export const useDeleteYieldCurveFilter =
  useDeleteYieldCurveFilterService(apiAuthClient());
export const useSaveYieldCurveFilter =
  useSaveYieldCurveFilterService(apiAuthClient());
export const useYieldCurveFilter = useYieldCurveFilterService(apiAuthClient());
export const useYieldCurveGenerator =
  useYieldCurveGeneratorService(apiAuthClient());
export const useYieldCurveGeneratorFilters =
  useYieldCurveGeneratorFiltersService(apiAuthClient());
export const yieldCurveFilterQueryFn =
  yieldCurveFilterQueryFnService(apiAuthClient());
export const usePatchPriceMover = usePatchPriceMoverService(apiAuthClient());
export const useApproveOutlierPrice =
  useApproveOutlierPriceService(apiAuthClient());
export const useShareLink = useShareLinkService(apiAuthClient());
export const useSearchHistory = useSearchHistoryService(apiAuthClient());
