import { Theme, CheckboxProps, CheckboxClasses, SvgIcon } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import CheckboxUncheckedIcon from 'ui/icons/checkbox-unchecked.svg?react';
import CheckboxCheckedIcon from 'ui/icons/checkbox-checked.svg?react';
import CheckboxIndeterminateIcon from 'ui/icons/checkbox-indeterminate.svg?react';

export const defaultProps: Partial<CheckboxProps> = {
  icon: <CheckboxUncheckedIcon />,
  checkedIcon: <CheckboxCheckedIcon />,
  indeterminateIcon: <CheckboxIndeterminateIcon />,
};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof CheckboxClasses,
    'MuiCheckbox',
    Omit<Theme, 'components'>
  >
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
