import { FC, useMemo } from 'react';
import { Avatar, Box, ButtonBase, Stack } from '@mui/material';
import { formatInitialism } from 'ui/utils';
import TypographyEllipsisWithTooltip from '../TypographyEllipsisWithTooltip';

type User = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  company?: string | null;
  title?: string | null;
};

const ChatMemberSearchItem: FC<{
  user: User;
  avatar?: React.ReactNode;
  onClick?: (user: User) => void;
}> = ({ user, avatar, onClick }) => {
  const fullName = useMemo(() => {
    if (!user?.firstName && !user?.lastName) {
      return user.username;
    }

    return `${user.firstName} ${user.lastName}`;
  }, [user]);
  return (
    <ButtonBase
      sx={{
        display: 'block',
        width: '100%',
        p: 1,
        borderRadius: 2,
      }}
      onClick={() => onClick?.(user)}
    >
      <Stack
        direction={{
          xs: 'row',
          sm: 'column',
        }}
        alignItems={'center'}
        spacing={1}
      >
        <Box>
          {avatar ? (
            avatar
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                bgcolor: '#BBDEFB',
                color: (theme) => theme.palette.background.paper,
                fontSize: 12,
              }}
            >
              {formatInitialism(fullName)}
            </Avatar>
          )}
        </Box>
        <Stack
          spacing={{
            xs: 0,
            sm: 1,
          }}
          textAlign={{
            xs: 'left',
            sm: 'center',
          }}
          width={'100%'}
        >
          <TypographyEllipsisWithTooltip
            variant={'body2'}
            fontWeight={'bold'}
            width={'100%'}
          >
            {fullName}
          </TypographyEllipsisWithTooltip>
          {user.company && (
            <TypographyEllipsisWithTooltip variant={'body2'} width={'100%'}>
              {user.company}
            </TypographyEllipsisWithTooltip>
          )}
        </Stack>
      </Stack>
    </ButtonBase>
  );
};

export default ChatMemberSearchItem;
