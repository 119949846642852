import { storageHasItem } from '../../core/storage';
import { StorageKey } from '../../utils/storage-key';
import { redirect } from 'react-router-dom';

const nonAuthLoader = async ({ request }: { request: Request }) => {
  if (storageHasItem(StorageKey.token)) {
    return redirect('/app');
  }

  return null;
};

export default nonAuthLoader;
