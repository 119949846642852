import { useContext, useEffect } from 'react';
import { StompClientContext } from '../../../services/websocket';
import { ApiKey, Websocket } from 'services/broker';
import { useQueryClient } from '@tanstack/react-query';

export default function RfqWebsocketSubscriber() {
  const { client: wsClient, connected } = useContext(StompClientContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (connected && wsClient) {
      wsClient.subscribe(Websocket.RfqStatus.EVENT_KEY, (e) => {
        // const data = Websocket.RfqStatus.eventHandler(e);
        queryClient.invalidateQueries([ApiKey, 'summary']);
        queryClient.invalidateQueries([ApiKey, 'searchRfq']);
        queryClient.invalidateQueries([ApiKey, 'searchRfqInfinite']);
      });
    }

    return () => {
      if (wsClient) {
        wsClient.unsubscribe(Websocket.RfqStatus.EVENT_KEY);
      }
    };
  }, [connected]);

  return null;
}
