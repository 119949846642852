import { isNumber } from 'lodash';
import { RfqDetail } from 'services/broker';

const ColumnTaxRate: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (isNumber(rfq?.taxRate)) {
    return `${rfq?.taxRate * 100}%`;
  }

  if (isNumber(rfq?.bestQuoteExchangeRate)) {
    return `${rfq?.bestQuoteExchangeRate * 100}%`;
  }

  return '-';
};

export default ColumnTaxRate;
