export const ApiKey = 'chats';
export const ApiConfig = {
  index: 'chat',
  create: 'chat',
  update: (id: string) => `chat/${id}`,
  messages: (id: string) => `chat/${id}`,
  sendMessage: (id: string) => `chat/${id}`,
  readMessage: (id: string) => `chat/${id}/message/read-status`,
  summary: 'chat/summary',
  getSessionByUsername: (username: string) => `chat/username/${username}`,
};
