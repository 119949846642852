export const StorageKey = {
  token: 'token',
  expiredTime: 'expiredTime',
  permission: 'permission',
  language: 'language',
  theme: 'theme',
  userName: 'userName',
  userFullName: 'userFullName',
  acceptCookies: 'accept',
  versionCookies: 'version',
  timerOtp: 'timerOtp',
  cryptoPrice: 'cryptoPrice',
  aidanLevel: 'aidanLevel',
  authState: 'authState',
  bondSearchFilterColumns: 'bondSearchFilterCols',
  bondAnalysisPriceMonitorFilterColumns: 'bondAnalysisPriceMonitorFilterCols',
  traderToolMarketFilterColumns: 'traderToolMarketFilterCols',
  traderToolMyOrdersFilterColumns: 'traderToolMyOrdersFilterCols',
};
