export const PermissionForRole: any = {
  // roles
  ROLE_ADMIN: {
    permission: ['menu_editor'],
  },
  ROLE_RM: {
    permission: ['menu_clients'],
  },
  ROLE_TRADER: {
    permission: ['menu_blotter'],
  },
};

export const PermissionForMembership: any = {
  MEMBERSHIP_INVESTOR: {
    permission: [
      'portfolio_counts_1',
      'portfolio_assets_20',
      'watchlist_counts_1',
      'watchlist_assets_20',
    ],
  },
  MEMBERSHIP_PROFESSIONAL: {
    permission: [
      'portfolio_counts_3',
      'portfolio_assets_20',
      'watchlist_counts_3',
      'watchlist_assets_20',
      'bond_detail_related_comparables',
      'alerts',
    ],
  },
  MEMBERSHIP_ENTERPRISE: {
    permission: [
      'portfolio_counts_all',
      'portfolio_assets_all',
      'watchlist_counts_all',
      'watchlist_assets_all',
      'bond_detail_related_comparables',
      'bond_detail_features',
      'yield_cruve_generator',
      'alerts',
      'historical_price',
    ],
  },
};

export const INFINITY = 'all';
export type Permission = number | typeof INFINITY;

export enum Roles {
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_RM = 'ROLE_RM',
  ROLE_TRADER = 'ROLE_TRADER',
  // Staff only roles
  ROLE_STAFF = 'ROLE_STAFF',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export const BASE_ROLE_PERMISSIONS = {
  administrator: 0 as Permission,
  'page.client': 0 as Permission,
  'page.order': 0 as Permission,
  'page.blotter': 0 as Permission,
  'action.requestForQuote': 0 as Permission,
  'action.accountNotificationSetting': 0 as Permission,
  'aidan.summaryOfPortfolioAndWatchlist': 0 as Permission,
  'aidan.summaryOfInvestment': 0 as Permission,
};

export const RolePermissions = {
  [Roles.ROLE_CLIENT]: {
    'page.order': 1,
    'action.requestForQuote': 1,
  },
  [Roles.ROLE_RM]: {
    'page.client': 1,
  },
  [Roles.ROLE_TRADER]: {
    'page.blotter': 1,
    'action.accountNotificationSetting': 1,
  },
  [Roles.ROLE_STAFF]: {
    'aidan.summaryOfPortfolioAndWatchlist': 1,
    'aidan.summaryOfInvestment': 1,
  },
  [Roles.ROLE_ADMIN]: {
    administrator: 1,
  },
} as Record<Roles, Record<keyof typeof BASE_ROLE_PERMISSIONS, Permission>>;

const BASE_MEMBERSHIP_PERMISSIONS = {
  'dashboard.newIssuePerformers': 1 as Permission,
  'dashboard.sectorLiquidity': 1 as Permission,
  'dashboard.dailyPriceMovers': 1 as Permission,
  'dashboard.trendingScore': 1 as Permission,

  'bond.detail.chart.price': 1 as Permission,
  'bond.detail.chart.yield': 1 as Permission,
  'bond.detail.chart.relatedComparables': 0 as Permission,
  'bond.detail.chart.bondCurve': 0 as Permission,
  'bond.detail.features': 1 as Permission,
  'bond.detail.news': 1 as Permission,

  'tool.yieldCalculator': 1 as Permission,
  'tool.historicalPrice': 1 as Permission,
  'tool.historicalYield': 1 as Permission,
  'tool.yieldCurveGenerator': 0 as Permission,
  'tool.priceMonitor': 0 as Permission,
  'tool.priceMonitor.sourceType': 0 as Permission,
  'tool.yieldAnalysis': 1 as Permission,

  portfolio: 1 as Permission,
  'portfolio.assets': 20 as Permission,

  watchlist: 1 as Permission,
  'watchlist.assets': 20 as Permission,

  alert: 1 as Permission,

  // 'aidan.summaryOfPortfolioAndWatchlist': 1 as Permission,
  // 'aidan.summaryOfInvestment': 0 as Permission,
};

const MEMBERSHIP_INVESTOR = { ...BASE_MEMBERSHIP_PERMISSIONS };

const MEMBERSHIP_TRIAL = { ...BASE_MEMBERSHIP_PERMISSIONS };
MEMBERSHIP_TRIAL['bond.detail.chart.relatedComparables'] = 1;
MEMBERSHIP_TRIAL['bond.detail.chart.bondCurve'] = 1;
MEMBERSHIP_TRIAL['tool.yieldCurveGenerator'] = 1;

const MEMBERSHIP_PROFESSIONAL = { ...BASE_MEMBERSHIP_PERMISSIONS };
MEMBERSHIP_PROFESSIONAL['bond.detail.chart.relatedComparables'] = 1;
MEMBERSHIP_PROFESSIONAL['bond.detail.chart.bondCurve'] = 1;
MEMBERSHIP_PROFESSIONAL['tool.priceMonitor'] = 1;
MEMBERSHIP_PROFESSIONAL['portfolio'] = 3;

const MEMBERSHIP_ENTERPRISE = { ...BASE_MEMBERSHIP_PERMISSIONS };
MEMBERSHIP_ENTERPRISE['bond.detail.chart.relatedComparables'] = 1;
MEMBERSHIP_ENTERPRISE['bond.detail.chart.bondCurve'] = 1;
MEMBERSHIP_ENTERPRISE['tool.yieldCurveGenerator'] = 1;
MEMBERSHIP_ENTERPRISE['tool.priceMonitor'] = 1;
MEMBERSHIP_ENTERPRISE['tool.priceMonitor.sourceType'] = 1;
MEMBERSHIP_ENTERPRISE['portfolio'] = INFINITY;
MEMBERSHIP_ENTERPRISE['portfolio.assets'] = INFINITY;
MEMBERSHIP_ENTERPRISE['watchlist'] = INFINITY;
MEMBERSHIP_ENTERPRISE['watchlist.assets'] = INFINITY;
MEMBERSHIP_ENTERPRISE['aidan.summaryOfInvestment'] = 1;

export const MembershipPermissions = {
  MEMBERSHIP_INVESTOR,
  MEMBERSHIP_TRIAL,
  MEMBERSHIP_PROFESSIONAL,
  MEMBERSHIP_ENTERPRISE,
};

export const PERMISSIONS = {
  ...BASE_ROLE_PERMISSIONS,
  ...BASE_MEMBERSHIP_PERMISSIONS,
};
