import React, { useState } from 'react';
import { Stack, Tab, Tabs, tabsClasses } from '@mui/material';

const RfqDialogLayout: React.FC<{
  tabs?: { label: string; content?: React.ReactNode }[];
}> = ({ tabs }) => {
  // tabs
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Stack px={2} pt={4} pb={2} width={'100%'} alignItems={'center'}>
      {tabs && (
        <>
          {/* <Tabs
            value={tabValue}
            onChange={handleChange}
            sx={{
              [`& .${tabsClasses.indicator}`]: {
                display: 'none',
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs> */}
          {tabs[tabValue].content}
        </>
      )}
    </Stack>
  );
};

export default RfqDialogLayout;
