import { Container, ContainerProps } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Logo from 'ui/components/Logo';
import { SnackbarProvider } from 'ui/components/snackbar/SnackbarBox';

const recaptchaSiteKey = import.meta.env
  .VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY as string;

const ForgetPasswordLayout: React.FC<{
  children: React.ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  withLogo?: boolean;
}> = ({ children, maxWidth = 'sm', withLogo = true }) => {
  return (
    <div className="bg-white dark:bg-background">
      <Container
        className="h-auto min-h-screen md:h-screen py-4 flex flex-col justify-center"
        maxWidth={maxWidth}
      >
        {withLogo && (
          <div className="w-full flex flex-col items-center">
            <div className="w-[270px] relative">
              <Logo variant={'text-vertical'} />
            </div>
          </div>
        )}
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
          <SnackbarProvider>{children}</SnackbarProvider>
        </GoogleReCaptchaProvider>
      </Container>
    </div>
  );
};

export default ForgetPasswordLayout;
