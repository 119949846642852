import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
  pickersDayClasses,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  buttonBaseClasses,
  ClickAwayListener,
  inputAdornmentClasses,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import CalendarIcon from 'ui/icons/calendar.svg?react';
dayjs.extend(utc);
dayjs.extend(timezone);

const DatePickerOpenPickerIcon = ({ width = 22 }: { width?: number }) => (
  <SvgIcon
    sx={{
      color: 'text.secondary',
      fontSize: width,
    }}
  >
    <CalendarIcon width={width} />
  </SvgIcon>
);

const InputSettlementDate: React.FC<DatePickerProps<dayjs.Dayjs>> = ({
  sx,
  ...props
}) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
      mouseEvent="onMouseDown"
    >
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD MMM YYYY"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            minDate={
              dayjs()
                .tz('Asia/Hong_Kong')
                .isAfter(
                  dayjs().tz('Asia/Hong_Kong').startOf('day').add(18, 'hours'),
                )
                ? dayjs().tz('Asia/Hong_Kong').endOf('day').add(2, 'day')
                : dayjs().tz('Asia/Hong_Kong').endOf('day').add(1, 'day')
            }
            maxDate={dayjs().add(3, 'month').endOf('day')}
            shouldDisableDate={(date) =>
              // Disable weekends
              date.day() === 0 || date.day() === 6
            }
            {...props}
            sx={{
              width: '100%',
              ...sx,
            }}
            slots={{
              openPickerIcon: DatePickerOpenPickerIcon,
              ...props.slots,
            }}
            slotProps={{
              ...props.slotProps,
              day: {
                sx: {
                  [`&.${buttonBaseClasses.root}.${pickersDayClasses.root}.${pickersDayClasses.disabled}`]:
                    {
                      color: (theme) => theme.palette.text.secondary,
                    },
                },
              },
              textField: {
                InputProps: {
                  ...(onMobile
                    ? { endAdornment: <DatePickerOpenPickerIcon width={18} /> }
                    : {}),
                },
                ...props.slotProps?.textField,
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
};

export default InputSettlementDate;
