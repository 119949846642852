import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCookie, setCookie } from '../../../common/functions';
import { StorageKey } from '../../../utils/storage-key';
import { useTranslation } from 'react-i18next';

const CookiePermissionDialog: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('user');
  const [permissionGranted, setPermissionGranted] = useState(
    getCookie(StorageKey.acceptCookies) === 'yes',
  );

  useEffect(() => {
    if (getCookie(StorageKey.acceptCookies) === 'yes') {
      setCookie(StorageKey.acceptCookies, 'yes', 365);
    }
  }, []);

  return (
    !permissionGranted && (
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 24,
          left: 0,
          height: {
            xs: 'auto',
            sm: 64,
          },
          width: '100vw',
          zIndex: (theme) => theme.zIndex.modal,
        }}
      >
        <Card
          sx={{
            width: '80%',
            mx: 'auto',
            p: 2,
            boxShadow: (theme) =>
              theme.palette.mode === 'dark'
                ? 'none'
                : '0px 8px 10px 0px #0000001A,0px 6px 30px 0px #0000000F,0px 16px 24px 0px #00000005',
          }}
          elevation={1}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography whiteSpace={'pre-line'}>
              {t('title.cookiePermission')}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setCookie(StorageKey.acceptCookies, 'yes', 365);
                  setPermissionGranted(true);
                }}
                data-testid="accept-cookies"
              >
                {t('title.acceptCookies')}
              </Button>
              <Button
                onClick={() => {
                  navigate('/privacy');
                }}
                variant="outlined"
                data-testid="read-more"
              >
                {t('title.readMore')}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Box>
    )
  );
};

export default CookiePermissionDialog;
