import { Theme, LinkProps, LinkClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<LinkProps> = {
  // className: 'dark:text-white dark:decoration-white	',
};

export const styleOverrides: Partial<
  OverridesStyleRules<keyof LinkClasses, 'MuiLink', Omit<Theme, 'components'>>
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
