import { FC } from 'react';
import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  Link,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import AidanWelcomePeople from 'ui/icons/aidan/aidan-welcome-people.svg?react';
import { AidanLevels, useAidanLevels } from './aidan.config';
import { useTranslation } from 'react-i18next';

const AidanWelcome: FC<{
  onLevelChange?: (level: number) => void;
}> = ({ onLevelChange }) => {
  const { t } = useTranslation(['aidan', 'user']);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const levelOptions = useAidanLevels();

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1150,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack spacing={1.5} alignItems={'flex-start'} p={4}>
        <AidanWelcomePeople />

        <Stack direction={'row'} spacing={1}>
          <Typography
            color={'#F8F8F8B3'}
            fontSize={28}
            fontWeight={600}
            lineHeight={'33.6px'}
          >
            {/* Hi there */}
            {t('action.welcome')}
          </Typography>
          <Typography fontSize={28} fontWeight={600} lineHeight={'33.6px'}>
            👋
          </Typography>
        </Stack>
        <Typography
          color={'#F8F8F8'}
          fontSize={28}
          fontWeight={600}
          lineHeight={'33.6px'}
        >
          {/* Meet your one and only virtual analyst - Aidan */}
          {t('title.welcome')}
        </Typography>
        <Typography color={'#F8F8F8CC'} fontSize={14}>
          {/* What is Aidan, and what is it for? Aidan is an AI-empowered support
          analyst that helps you maintain your portfolio and gain insights into
          the market. */}
          {t('message.welcome')}
        </Typography>
        <ButtonBase
          disableRipple
          sx={{
            p: 0,
            display: 'inline-block',
          }}
          href="/terms"
        >
          <Link color={'#CBD2E0'}>
            {/* Terms and conditions */}
            {t('user:message.terms')}
          </Link>
        </ButtonBase>
      </Stack>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        mt={4}
        spacing={1}
        width={'100%'}
        flex={1}
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography
          color={'#CCCDCE'}
          fontSize={16}
          fontWeight={600}
          lineHeight={'22.4px'}
        >
          {t('tooltip.chooseLevel')}
        </Typography>
        <Stack direction={'row'} spacing={1.5}>
          {levelOptions.map((level) => (
            <Card
              key={level.level}
              elevation={isDark ? 0 : 2}
              sx={{
                background: (theme) => theme.palette.background.paper,
                border: (theme) =>
                  theme.palette.mode === 'dark'
                    ? `1px solid ${theme.palette.divider}`
                    : 'none',
                color: (theme) => theme.palette.text.primary,
              }}
            >
              <CardActionArea
                sx={{
                  py: '12px',
                  px: '14px',
                  textAlign: 'center',
                }}
                onClick={() => {
                  onLevelChange?.(level.level);
                }}
              >
                <Typography fontSize={24}>{level.emoji}</Typography>
                <Typography fontSize={14} fontWeight={600}>
                  {level.label}
                </Typography>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AidanWelcome;
