import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MailDialogCover from 'ui/icons/dialog/mail-dialog-cover.svg?react';
import MobileDialogCover from 'ui/icons/dialog/mail-mobile-dialog-cover.svg?react';
import {
  OrderFeedbackStatus,
  RfqDetail,
  useSubmitOrderFeedback,
} from '../../../../services/brokers';
import { useToast } from 'ui/components';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { formatApiErrorToast } from '../../../../services/apiUtils';

const ActionCancelOrder: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onCanceled?: () => void;
  rfq: RfqDetail;
}> = ({ children, onCanceled, rfq }) => {
  const { t } = useTranslation('rfq');
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        note: yup
          .string()
          .required()
          .label(t('fields.cancelReason').toLowerCase()),
      }),
    [t],
  );
  const { control, handleSubmit } = useForm({
    defaultValues: {
      note: '',
    },
    resolver: yupResolver(validationSchema),
  });

  //api call

  const { mutate: submitOrderFeedback, isLoading } = useSubmitOrderFeedback({
    onSuccess: () => {
      toast.success(t('message.submitOrderFeedbackSuccess'));
      onCanceled?.();
      setOpen(false);
    },
    onError: (err) => {
      const error = err?.response?.data;
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<{
    note: string;
  }> = async (data) => {
    rfq.id &&
      submitOrderFeedback({
        rfqId: String(rfq.id),
        status: OrderFeedbackStatus.CanceledByBroker,
        notes: data.note,
        executedAt: null,
        executedSize: null,
        executePrice: null,
      });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    if (isLoading) return;
    setOpen(false);
  };

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        cover={<MailDialogCover />}
      >
        <Stack
          spacing={2}
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 4,
            pt: 6,
            pb: 3,
            height: '100%',
            width: '100%',
          }}
        >
          {onTablet && (
            <Box textAlign={'center'}>
              <MobileDialogCover width={90} />
            </Box>
          )}
          <Box width={'100%'}>
            <Box>
              <Typography
                variant="h5"
                fontWeight={'bold'}
                textTransform={'uppercase'}
                gutterBottom
              >
                {t('title.blotterCancelOrder')}
              </Typography>
              <Typography
                fontSize={{
                  xs: 12,
                  sm: 14,
                  lg: 18,
                }}
                fontWeight={'bold'}
              >
                {t('message.blotterCancelOrder')}
              </Typography>
            </Box>
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={onMobile ? 6 : 8}
                  variant="outlined"
                  sx={{
                    mt: 2,
                  }}
                  inputProps={{ maxLength: 500 }}
                  error={!!fieldState.error}
                  helperText={`${fieldState.error?.message ?? ''} ${
                    field.value.length
                  } / 500`}
                  FormHelperTextProps={{
                    sx: {
                      textAlign: 'end',
                      mt: -3,
                      zIndex: 10,
                    },
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            />
          </Box>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
            justifyContent="stretch"
            mt={4}
            width={'100%'}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(false)}
              fullWidth
              size="large"
              disabled={isLoading}
            >
              {t('action.keepOrder')}
            </Button>
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t('action.cancelOrder')}
            </Button>
          </Stack>
        </Stack>
      </BaseActionDialog>
    </>
  );
};

export default ActionCancelOrder;
