import {
  onNumberInputKeyPress,
  onPhoneInputKeyPress,
} from './onNumberInputKeyPress';
import TextField from '@mui/material/TextField';

type TextFieldInputProps = Parameters<typeof TextField>[0]['inputProps'];

export const textFieldNumberInputProps = {
  inputProps: {
    onKeyPress: onNumberInputKeyPress,
    inputMode: 'numeric',
    pattern: '[0-9]*',
  } as TextFieldInputProps,
  type: 'tel',
} as {
  inputProps: TextFieldInputProps;
  type: 'tel';
};

export const textFieldPhoneInputProps = {
  inputProps: {
    onKeyPress: onPhoneInputKeyPress,
    inputMode: 'tel',
    pattern: '[0-9 ]*',
  } as TextFieldInputProps,
  type: 'tel',
} as {
  inputProps: TextFieldInputProps;
  type: 'tel';
};
