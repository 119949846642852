import { Box, paperClasses, Popover, PopoverProps } from '@mui/material';
import {
  createContext,
  forwardRef,
  RefObject,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import ParentSize from '../ParentSize';
import { ChatActionHandle } from './type';

const CHATROOM_HEIGHT = 560;
const CHATROOM_WIDTH = 460;

const ChatContext = createContext<{
  anchorEl: HTMLElement | null;
  openChat: (event: React.MouseEvent<HTMLElement>) => void;
  closeChat: () => void;
}>({
  anchorEl: null,
  openChat: () => {},
  closeChat: () => {},
});

const ChatProvider = forwardRef<
  ChatActionHandle,
  {
    children: (props: {
      openChat: (event: React.MouseEvent<HTMLElement>) => void;
      closeChat: () => void;
      open: boolean;
    }) => React.ReactNode;
    onClose?: () => void;
  }
>(({ children, onClose }, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        openChatOn: (element: HTMLElement) => setAnchorEl(element),
      };
    },
    [],
  );

  return (
    <ChatContext.Provider
      value={{
        anchorEl: anchorEl,
        openChat: handleOpen,
        closeChat: handleClose,
      }}
    >
      {children &&
        children({
          openChat: handleOpen,
          closeChat: handleClose,
          open: Boolean(anchorEl),
        })}
    </ChatContext.Provider>
  );
});

const ToggleButtonHOC: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return children;
};

const Content: React.FC<
  Omit<PopoverProps, 'anchorEl' | 'open' | 'onClose'> & {
    scrollContainerRef?: RefObject<HTMLDivElement>;
  }
> = ({ children, scrollContainerRef, ...props }) => {
  const { anchorEl, closeChat } = useContext(ChatContext);
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={closeChat}
      // disableScrollLock
      anchorOrigin={{
        vertical: -8,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      elevation={0}
      sx={{
        [`& .${paperClasses.root}`]: {
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#232525' : '#fff',
          // boxShadow: (theme) =>
          //   theme.palette.mode === 'dark'
          //     ? 'none'
          //     : '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        },
        pointerEvents: 'none',
      }}
      {...props}
    >
      <Box width={CHATROOM_WIDTH} height={CHATROOM_HEIGHT}>
        <ParentSize>
          {({ width, height }) => (
            <Box>
              <Box
                ref={scrollContainerRef}
                width={width}
                height={height}
                sx={{
                  overflowY: 'auto',
                  // hide scrollbar
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                  pointerEvents: 'auto',
                  overscrollBehavior: 'contain',
                }}
                onScroll={(e) => {
                  e.stopPropagation();
                }}
              >
                {children}
              </Box>
            </Box>
          )}
        </ParentSize>
      </Box>
    </Popover>
  );
};

export const Chat = Object.assign(ChatProvider, {
  Button: ToggleButtonHOC,
  Content,
});

export default Chat;
