import {
  Theme,
  outlinedInputClasses,
  CSSObject,
  AutocompleteClasses,
  AutocompleteProps,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<
  AutocompleteProps<any, any, any, any, 'div'>
> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof AutocompleteClasses,
    'MuiAutocomplete',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }): CSSObject => ({
    borderColor: theme.palette.divider,
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.primary.main,
    },
  }),
  paper: {
    boxShadow:
      '0px 8px 10px 0px #0000001A,0px 6px 30px 0px #0000000F,0px 16px 24px 0px #00000005;',
  },
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
