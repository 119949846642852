import { FC, forwardRef } from 'react';
import {
  AppBar,
  Box,
  Chip,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  appBarClasses,
  chipClasses,
  paperClasses,
  useTheme,
} from '@mui/material';
import AidanFace from './AidanFace';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AidanMail from 'ui/icons/aidan/aidan-mail.svg?react';
import ArrowBackIcon from 'ui/icons/chevron-left.svg?react';
import ActionSendInsight from './ActionSendInsight';

const AidanAppBar = forwardRef<
  HTMLDivElement,
  {
    onBack?: () => void;
  }
>(({ onBack }, ref) => {
  const theme = useTheme();

  return (
    <AppBar
      ref={ref}
      position="absolute"
      elevation={0}
      sx={{
        [`&.${paperClasses.root}`]: {
          bgcolor: theme.palette.primary.main,
          boxShadow:
            theme.palette.mode === 'dark'
              ? 'none'
              : '0px -4px 4px rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <Toolbar disableGutters>
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={2.5}
          py={2.5}
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} alignItems={'center'}>
            {onBack && (
              <IconButton
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
                size="small"
                onClick={onBack}
              >
                <ArrowBackIcon width={24} />
              </IconButton>
            )}
            <AidanFace
              backgroundColor={theme.palette.primary.main}
              color={theme.palette.common.white}
              elevation={0}
            />
            <Stack
              direction={'row'}
              spacing={1}
              fontSize={10}
              alignItems={'end'}
              fontWeight={'bold'}
            >
              <Typography
                color={'primary.contrastText'}
                fontSize={18}
                fontWeight={'bold'}
                lineHeight={1}
              >
                Aidan
              </Typography>
              <Chip
                label={'Beta'}
                sx={{
                  fontSize: 10,
                  bgcolor: theme.palette.common.white,
                  color: theme.palette.primary.main,
                  height: '20px',
                  borderRadius: '6px',
                  [`& .${chipClasses.label}`]: {
                    px: 1,
                  },
                }}
              />
              <Chip
                label={'AI Bot'}
                sx={{
                  fontSize: 10,
                  bgcolor: theme.palette.common.white,
                  color: theme.palette.primary.main,
                  height: '20px',
                  borderRadius: '6px',
                  [`& .${chipClasses.label}`]: {
                    px: 1,
                  },
                }}
              />
            </Stack>
          </Stack>
          <ActionSendInsight>
            {({ onClick }) => (
              <IconButton onClick={onClick}>
                <AidanMail />
              </IconButton>
            )}
          </ActionSendInsight>
        </Stack>
      </Toolbar>
    </AppBar>
  );
});

export const AidanAppBarSpacer: FC<{
  onBack?: () => void;
}> = ({ onBack }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        [`&.${paperClasses.root}`]: {
          bgcolor: theme.palette.primary.main,
          boxShadow:
            theme.palette.mode === 'dark'
              ? 'none'
              : '0px -4px 4px rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <Toolbar disableGutters>
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={3}
          py={2.5}
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {onBack && (
              <IconButton
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
                size="small"
                onClick={onBack}
              >
                <ArrowBackIosNewIcon fontSize="inherit" />
              </IconButton>
            )}
            <AidanFace
              backgroundColor="#fff"
              color={theme.palette.primary.main}
              elevation={0}
            />
            <Stack spacing={0.5}>
              <Typography
                color={'primary.contrastText'}
                fontSize={18}
                fontWeight={'bold'}
              >
                Aidan
              </Typography>
              <Stack
                direction={'row'}
                spacing={0.5}
                fontSize={10}
                fontWeight={'bold'}
              >
                <Box
                  sx={{
                    px: '6px',
                    py: '4px',
                    borderRadius: '6px',
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.primary.main,
                  }}
                >
                  Beta
                </Box>
                <Box
                  sx={{
                    px: '6px',
                    py: '4px',
                    borderRadius: '6px',
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.primary.main,
                  }}
                >
                  AI
                </Box>
                <Box
                  sx={{
                    px: '6px',
                    py: '4px',
                    borderRadius: '6px',
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  Bot
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <ActionSendInsight>
            {({ onClick }) => (
              <IconButton onClick={onClick}>
                <AidanMail />
              </IconButton>
            )}
          </ActionSendInsight>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AidanAppBar;
