import LogoLightTextNonsub from 'ui/icons/logo/logo-text-nonsub-light-1.1.svg?react';
import LogoLightTextVertical from 'ui/icons/logo/logo-text-vertical-light.svg?react';
import LogoLight from 'ui/icons/logo/logo-light.svg?react';
import LogoDarkTextNonSub from 'ui/icons/logo/logo-text-nonsub-dark-1.1.svg?react';
import LogoDarkTextVertical from 'ui/icons/logo/logo-text-vertical-dark.svg?react';
import LogoDark from 'ui/icons/logo/logo-dark.svg?react';
import { useTheme } from '@mui/material';
import { FC } from 'react';

const Variant = {
  'text-nonsub': {
    light: LogoLightTextNonsub,
    dark: LogoDarkTextNonSub,
  },
  'text-vertical': {
    light: LogoLightTextVertical,
    dark: LogoDarkTextVertical,
  },
  icon: {
    light: LogoLight,
    dark: LogoDark,
  },
};

const Logo: FC<{
  variant?: keyof typeof Variant;
  color?: 'auto' | 'light' | 'dark';
}> = ({ variant = 'text-nonsub', color = 'auto' }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const LightLogo = Variant[variant].light;
  const DarkLogo = Variant[variant].dark;

  if (color === 'light') {
    return <>{<LightLogo />}</>;
  }

  if (color === 'dark') {
    return <>{<DarkLogo />}</>;
  }

  return <>{isDark ? <LightLogo /> : <DarkLogo />}</>;
};

export default Logo;
