import { RfqDetail } from '../../../services/brokers';
import { dateFormat } from 'ui/utils';

const ColumnRequestTime: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (!rfq) {
    return null;
  }
  return dateFormat(
    rfq?.orderRequestTime ?? rfq?.createdAt ?? '',
    'DD MMM YYYY HH:mm',
  );
};

export default ColumnRequestTime;
