import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGetSessionByUsername, useUpdate } from '../../../../services/chat';
import { ChatRefContext } from './ChatRefContext';
import {
  serviceIsDisabled,
  Services,
} from '../../../../config/serviceIsDisabled';

import { RfqStatus, useRfqDetail } from '../../../../services/brokers';
import { AnnouncementType, MessageType } from 'ui/components/chat/type';
import { RedirectActions } from '../../../layout/RedirectLoader';

type RfqDetail = NonNullable<ReturnType<typeof useRfqDetail>['data']>;

const ActionOpenChatOfRfq: React.FC<{
  username: string;
  rfq?: RfqDetail;
  children: ({
    onClick,
  }: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    'data-ga-event': 'action_open_chat_of_rfq_init';
    'data-ga-category': 'chat';
  }) => JSX.Element;
  onSuccess?: () => void;
}> = ({ username, rfq, children, onSuccess }) => {
  const { t } = useTranslation(['chat', 'user', 'validation']);
  const theme = useTheme();
  const { chatRef } = useContext(ChatRefContext);

  const { data: chatSession } = useGetSessionByUsername(username, {
    enabled: username !== '',
  });
  const message = useMemo(() => {
    return t('message.operationChangeChatAnnouncementRfq', {
      rfq: rfq?.assetName,
    });
  }, [t, rfq]);

  const { mutate } = useUpdate({
    onSuccess: async (data, variables) => {
      onSuccess?.();
      await chatRef.current?.sendMessage(
        String(variables.id),
        message,
        MessageType.OPERATION,
      );
      chatSession && chatRef.current?.openChat(chatSession);
      onSuccess && onSuccess();
    },
  });

  const onClick = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      if (chatSession && rfq) {
        mutate({
          id: Number(chatSession.id),
          announcement: {
            announcementType: AnnouncementType.RFQ,
            parameters: {
              rfqId: rfq?.id,
            },
          },
        });
      }
    },
    [chatSession, onSuccess],
  );

  if (serviceIsDisabled(Services.CHAT)) return null;

  if (!chatSession) return null;
  if (!rfq) return null;
  if (!formatRfqActionLink(rfq, username)) return null;

  return (
    <>
      {children({
        onClick: onClick,
        // disabled: isLoadingPermission || isLoadingKyc,
        'data-ga-event': 'action_open_chat_of_rfq_init',
        'data-ga-category': 'chat',
      })}
    </>
  );
};

function formatRfqActionLink(rfq: RfqDetail, to: string) {
  const target = to === rfq?.traderName ? 'trader' : 'client';

  switch (true) {
    case rfq?.status === RfqStatus.Quoted && target === 'trader':
      return `app/rfq/blotter?action=${RedirectActions.RfqQuoted}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.Quoted && target === 'client':
      return `app/rfq/orders?action=${RedirectActions.RfqQuoted}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.OrderPlaced && target === 'trader':
      return `app/rfq/blotter?action=${RedirectActions.RfqOrderPlaced}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.OrderPlaced && target === 'client':
      return `app/rfq/orders?action=${RedirectActions.RfqOrderPlaced}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.OrderUpdated && target === 'trader':
      return `app/rfq/blotter?action=${RedirectActions.RfqOrderUpdated}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.OrderUpdated && target === 'client':
      return `app/rfq/orders?action=${RedirectActions.RfqOrderUpdated}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.OrderExecuted && target === 'trader':
      return `app/rfq/blotter?action=${RedirectActions.RfqOrderExecuted}&rfq-id=${rfq?.id}`;
    case rfq?.status === RfqStatus.OrderExecuted && target === 'client':
      return `app/rfq/orders?action=${RedirectActions.RfqOrderExecuted}&rfq-id=${rfq?.id}`;

    default:
      return '';
  }
}

export default ActionOpenChatOfRfq;
