import { useTranslation } from 'react-i18next';
import { CurrencyCode } from '../../utils/currency';
import CountryOptions from '../../utils/country-name';
import { TFunction } from 'i18next';
import { CircleFlag } from 'react-circle-flags';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

interface FilterOption {
  label: React.ReactNode;
  value: string | number;
}

export const useRatingOptions = (): FilterOption[] => {
  const { t } = useTranslation('bond');
  // comma seperated: NO_RATING(0), DEFAULT(1), HIGH_YIELD(2), INVESTMENT_GRADE(3)
  const options = useMemo(
    () => [
      {
        label: t('options.rating.investmentGrade'),
        value: 3,
      },
      {
        label: t('options.rating.highYield'),
        value: 2,
      },
      {
        label: t('options.rating.default'),
        value: 1,
      },
      {
        label: t('options.rating.noRating'),
        value: 0,
      },
    ],
    [t],
  );

  return options;
};

export const ratingValueLocale = (t: TFunction, rating: string): string => {
  switch (rating) {
    case 'INVESTMENT_GRADE':
      return t('options.rating.investmentGrade');
    case 'HIGH_YIELD':
      return t('options.rating.highYield');
    case 'DEFAULT':
      return t('options.rating.notRated');
    case 'NO_RATING':
      return t('options.rating.noRating');
    default:
      return '';
  }
};

export const useSectorOptions = (): FilterOption[] => {
  const { t } = useTranslation('bond');

  const options = useMemo(
    () => [
      { label: t('options.sector.basicMaterials'), value: 'Basic Materials' },
      { label: t('options.sector.technology'), value: 'Technology' },
      {
        label: t('options.sector.consumerNonCyclical'),
        value: 'Consumer, Non-cyclical',
      },
      { label: t('options.sector.financial'), value: 'Financial' },
      {
        label: t('options.sector.consumerCyclical'),
        value: 'Consumer, Cyclical',
      },
      { label: t('options.sector.government'), value: 'Government' },
      { label: t('options.sector.energy'), value: 'Energy' },
      { label: t('options.sector.utilities'), value: 'Utilities' },
      { label: t('options.sector.diversified'), value: 'Diversified' },
      { label: t('options.sector.communications'), value: 'Communications' },
      { label: t('options.sector.industrial'), value: 'Industrial' },
      {
        label: t('options.sector.assetBackedSecurities'),
        value: 'Asset Backed Securities',
      },
    ],
    [t],
  );

  return options;
};

export const sectorValueLocale = (t: TFunction, sector: string): string => {
  switch (sector) {
    case 'Basic Materials':
      return t('bond:options.sector.basicMaterials');
    case 'Technology':
      return t('bond:options.sector.technology');
    case 'Consumer, Non-cyclical':
      return t('bond:options.sector.consumerNonCyclical');
    case 'Financial':
      return t('bond:options.sector.financial');
    case 'Consumer, Cyclical':
      return t('bond:options.sector.consumerCyclical');
    case 'Government':
      return t('bond:options.sector.government');
    case 'Energy':
      return t('bond:options.sector.energy');
    case 'Utilities':
      return t('bond:options.sector.utilities');
    case 'Diversified':
      return t('bond:options.sector.diversified');
    case 'Communications':
      return t('bond:options.sector.communications');
    case 'Industrial':
      return t('bond:options.sector.industrial');
    case 'Asset Backed Securities':
      return t('bond:options.sector.assetBackedSecurities');
    default:
      return '';
  }
};

export const useCurrencyOptions = (): FilterOption[] => {
  const { t } = useTranslation('currency');
  const options = useMemo(
    () =>
      CurrencyCode.map((currency) => ({
        label: (
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <CircleFlag countryCode={currency.countryCode} height="24" />
            <Typography variant={'body2'}>
              {/* {t(`${currency.id}.fullName`)} */}
              {`${currency.name}`}
            </Typography>
          </Stack>
        ),
        value: currency.id,
      })),
    [t],
  );

  return options;
};

export const useCountryOptions = () => {
  const { t } = useTranslation('country');

  const options = useMemo(
    () =>
      CountryOptions.map((country) => ({
        label: t(`${country.key}.fullName`),
        // label: (
        //   <Stack direction={'row'} spacing={1} alignItems={'center'}>
        //     {country.key !== 'OT' && (
        //       <CircleFlag countryCode={country.key.toLowerCase()} height="24" />
        //     )}
        //     <Typography variant={'body2'}>
        //       {t(`${country.key}.fullName`)}
        //     </Typography>
        //   </Stack>
        // ),
        key: country.key,
        value: country.value,
      })),
    [t],
  );

  return options;
};

export const useIntervalOptions = (): FilterOption[] => {
  const { t } = useTranslation('bond');
  const options = useMemo(
    () => [
      {
        label: t('options.interval.weekly'),
        value: 'WEEKLY',
      },
      {
        label: t('options.interval.monthly'),
        value: 'MONTHLY',
      },
      {
        label: t('options.interval.quarterly'),
        value: 'QUARTERLY',
      },
      {
        label: t('options.interval.biyearly'),
        value: 'BIYEARLY',
      },
      {
        label: t('options.interval.yearly'),
        value: 'YEARLY',
      },
    ],
    [t],
  );

  return options;
};

export const useCouponFrequencyOptions = (): FilterOption[] => {
  const { t } = useTranslation('bond');
  const options = useMemo(
    () => [
      {
        label: t('options.couponFrequency.annual'),
        value: 'ANNUAL',
      },
      {
        label: t('options.couponFrequency.atMaturity'),
        value: 'AT_MATURITY',
      },
      {
        label: t('options.couponFrequency.monthly'),
        value: 'MONTHLY',
      },
      {
        label: t('options.couponFrequency.quarterly'),
        value: 'QUARTERLY',
      },
      {
        label: t('options.couponFrequency.semiAnnual'),
        value: 'SEMI_ANNUAL',
      },
    ],
    [t],
  );

  return options;
};
