import {
  TableContainerClasses,
  TableContainerProps,
  Theme,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<TableContainerProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof TableContainerClasses,
    'MuiTableContainer',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }) => ({
    backgroundColor: 'transparent',
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
