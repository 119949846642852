import Box from '@mui/material/Box';
import { useContext } from 'react';
import Favicon from 'react-favicon';
import { NotificationContext } from 'ui/components';
import { useNotificationSummary } from './useNotificationSummary';

export default function ChatLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { alertCount } = useContext(NotificationContext);
  useNotificationSummary();

  return (
    <>
      <Favicon
        url="/logo.webp"
        renderOverlay={
          alertCount > 0
            ? (
                canvas: HTMLCanvasElement,
                context: CanvasRenderingContext2D,
              ) => {
                const iconSize = 16;
                // const radius = iconSize / 4;
                const radius = 3.5;
                const centerX = radius;
                const centerY = radius;

                context.beginPath();
                context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
                context.fillStyle = 'red';
                context.fill();
                context.lineWidth = 1;
                context.strokeStyle = '#000';
                context.stroke();
              }
            : // empty overlay
              () => {}
        }
      />
      <Box className="bg-white dark:bg-background flex-1 overflow-y-auto snap-y snap-mandatory">
        {children}
      </Box>
    </>
  );
}
