import React from 'react';
import { Card, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useMeasure } from 'react-use';
import SummarySection from './SummarySection';

const SummaryLayout: React.FC<{
  header?: React.ReactNode;
  summary?: React.ReactNode;
  footer?: React.ReactNode;
  extraActions?: React.ReactNode;
}> = ({ header, summary, footer, extraActions }) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [headerContainerRef, { height: headerHeight }] =
    useMeasure<HTMLDivElement>();
  const [footerContainerRef, { height: footerHeight }] =
    useMeasure<HTMLDivElement>();

  return (
    <Stack spacing={1} width={'100%'}>
      <Stack ref={headerContainerRef} spacing={1} width={'100%'}>
        <SummarySection>
          <Stack spacing={1}>{header}</Stack>
        </SummarySection>
        {/* Extra actions */}
        {extraActions}
      </Stack>
      <SummarySection
        elevation={0}
        sx={{
          px: 2,
          py: 1,
          alignSelf: 'stretch',
          overflowY: 'auto',
          maxHeight: onMobile
            ? undefined
            : `calc(100vh - 64px - 48px - 48px - 48px - ${headerHeight}px - ${footerHeight ? footerHeight + 16 : 0}px)`,
        }}
      >
        {summary}
      </SummarySection>
      {footer && (
        <Stack ref={footerContainerRef} spacing={1} width={'100%'}>
          <SummarySection>
            <Stack spacing={1}>{footer}</Stack>
          </SummarySection>
        </Stack>
      )}
    </Stack>
  );
};

export default SummaryLayout;
