import { createTheme } from '@mui/material/styles';
import { componentOverrides } from './common/componentOverrides';
import { typography } from './common/typography';
import { shape } from './common/shape';
import { breakpoints } from './common/breakpoints';
import tailwindTheme from './tailwindTheme';
import resolveConfig from 'tailwindcss/resolveConfig';
import { KeyValuePair } from 'tailwindcss/types/config';

const tailwind = resolveConfig({
  content: [],
  theme: tailwindTheme,
});

type TailwindColor = KeyValuePair<number, string>;
const primaryColor = tailwind.theme?.colors?.primary as TailwindColor;
const errorColor = tailwind.theme?.colors?.error as TailwindColor;
const warningColor = tailwind.theme?.colors?.warning as TailwindColor;
const infoColor = tailwind.theme?.colors?.info as TailwindColor;
const successColor = tailwind.theme?.colors?.success as TailwindColor;

const text = {
  primary: '#252525',
  secondary: '#6A707B',
  disabled: '#C9CFDC',
};

const primary = {
  main: primaryColor[500],
  contrastText: tailwind.theme?.colors?.white as string,
};

const secondary = {
  main: '#D8E0F1',
  contrastText: text.primary,
};

const theme = createTheme({
  palette: {
    primary,
    secondary,
    error: {
      main: errorColor[500],
    },
    warning: {
      main: warningColor[500],
    },
    info: {
      main: infoColor[500],
    },
    success: {
      main: successColor[500],
    },
    text: text,
    divider: '#C9CFDC',
  },
  typography,
  shape,
  breakpoints,
  components: { ...componentOverrides },
});

export default theme;
