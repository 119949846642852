import { StorageKey } from '../../utils/storage-key';
// import package_json from '../../../package.json';

export const addDataIntoCache = (
  cacheName: string,
  url: string,
  response: string,
) => {
  // Converting our response into Actual Response form
  const data = new Response(JSON.stringify(response));

  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
      alert('Data Added into cache!');
    });
  }
};

export const getAllCacheData = async (cacheName: string, url: string) => {
  const cacheStorage = await caches.open(cacheName);
  // Fetching that particular cache data
  const cachedResponse = await cacheStorage.match(url);
  const data = await cachedResponse?.json();
  return data;
};

export const cleanCache = () => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};

export const getCookie = (cname: string): any => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname: string, cvalue: any, exdays?: number) => {
  const d = new Date();
  exdays && d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = exdays ? 'expires=' + d.toUTCString() : '';
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

// export const saveVersion = () => {
//   const version = getCookie(StorageKey.versionCookies);
//   const currentVersion = package_json.version;
//   if (version !== currentVersion) {
//     cleanCache();
//     setCookie(StorageKey.versionCookies, package_json.version);
//   }
// };
