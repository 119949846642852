import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  UseMutateFunction,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { apiAuthClient } from '../apiClient';

export const ApiKey = 'bonds';
export const ApiConfig = {
  contact: 'landing/contact-us',
};

type ContactRequestPayload = {
  email: string;
  message: string;
};

export const useContactForm = (
  options?: UseMutationOptions<
    any,
    AxiosError<any>,
    ContactRequestPayload,
    unknown
  >,
) =>
  useMutation({
    mutationFn: (param: ContactRequestPayload) =>
      apiAuthClient()
        .post(ApiConfig.contact, param)
        .then((res) => res.data),
    ...options,
  });
