import { Theme, AppBarProps, AppBarClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<AppBarProps> = { elevation: 0 };

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof AppBarClasses,
    'MuiAppBar',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme: { palette } }) => ({
    backgroundColor: palette.background.default,
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
