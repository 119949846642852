import {
  IconButton,
  Switch,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import lightTheme from './lightTheme';
import darkTheme from './darkTheme';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export const ColorModeContext = createContext({
  toggleColorMode: () => {
    return;
  },
});

export function ThemeContext({
  children,
  defaultTheme,
  onThemeChange,
}: {
  children: React.ReactNode;
  defaultTheme?: string;
  onThemeChange?: (theme: 'light' | 'dark') => void;
}) {
  const [mode, setMode] = useState<'light' | 'dark'>('dark');
  const root = document.getElementById('root');
  const modalRoot = document.getElementById('modal-root');

  useEffect(() => {
    if (defaultTheme) {
      setMode(defaultTheme === 'dark' ? 'dark' : 'light');
    }
    // else {
    //   if (
    //     window.matchMedia &&
    //     window.matchMedia('(prefers-color-scheme: dark)').matches
    //   ) {
    //     setMode('dark');
    //   } else {
    //     setMode('light');
    //   }
    // }
  }, [defaultTheme]);

  const toggleColorMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    onThemeChange?.(newMode);
  };

  const theme = useMemo(
    () => createTheme(mode === 'light' ? lightTheme : darkTheme),
    [mode],
  );

  useEffect(() => {
    if (mode === 'dark') {
      root?.classList.add('dark');
      modalRoot?.classList.add('dark');
    } else {
      root?.classList.remove('dark');
      modalRoot?.classList.remove('dark');
    }
  }, [mode]);

  return (
    <ColorModeContext.Provider
      value={{
        toggleColorMode,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export const ToggleColorModeButton = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <IconButton
      sx={{ ml: 1 }}
      onClick={colorMode.toggleColorMode}
      className=" text-inherit dark:text-white"
    >
      {theme.palette.mode === 'dark' ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
    </IconButton>
  );
};

export const ToggleColorModeSwitch = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Switch
      onClick={(e) => {
        e.stopPropagation();
      }}
      checked={theme.palette.mode === 'dark'}
      onChange={colorMode.toggleColorMode}
    />
  );
};
