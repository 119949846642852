import { Tooltip, Typography, PopperProps } from '@mui/material';
import { FC, useLayoutEffect, useRef, useState } from 'react';

const useIsOverflowX = (): [React.RefObject<HTMLDivElement>, boolean] => {
  const [isOverflow, setIsOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, [ref]);

  return [ref, isOverflow];
};

type TypographyProps = Parameters<typeof Typography>[0];

const TypographyEllipsisWithTooltip: FC<
  Omit<TypographyProps, 'children' | 'ref'> & {
    children: string;
    container?: PopperProps['container'];
  }
> = ({ children, container, ...props }) => {
  const [ref, isOverflow] = useIsOverflowX();
  return (
    <Tooltip
      title={isOverflow ? children : ''}
      placement="bottom-start"
      classes={{
        tooltip: 'max-w-[70vw]',
      }}
      PopperProps={{
        ...(container ? { container } : {}),
      }}
    >
      <Typography
        ref={ref}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={{
          sx: 'normal',
          lg: 'nowrap',
        }}
        maxWidth={{
          sx: 'auto',
          lg: 240 - 24 - 4,
        }}
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TypographyEllipsisWithTooltip;
