import { storageHasItem } from '../../core/storage';
import { StorageKey } from '../../utils/storage-key';
import { redirect } from 'react-router-dom';
import { MAIN_PAGE_PATH } from './types';
import routeBackAfterLoginLoader from './RouteBackAfterLoginLoader';

export enum RedirectActions {
  RfqCreated = 'rfq-created',
  RfqQuoted = 'rfq-quoted',
  RfqOrderPlaced = 'rfq-order-placed',
  RfqNew = 'rfq-new',
  RfqOrderUpdated = 'rfq-order-updated',
  RfqOrderExecuted = 'rfq-order-executed',
  AnalysisCurveGenerator = 'analysis-curve-generator',
  RfqOrderUpdateRejected = 'rfq-order-update-rejected',
  LimitOrderPlaced = 'limit-order-placed',
  LimitOrderFirmMatched = 'limit-order-firm-matched',
  LimitOrderFlexMatched = 'limit-order-flex-matched',
  LimitOrderPartialFilled = 'limit-order-partial-filled',
}

const redirectLoader = async ({ request }: { request: Request }) => {
  const searchParams = new URL(request.url).searchParams;
  const action = searchParams.get('action');

  if (!storageHasItem(StorageKey.token)) {
    return routeBackAfterLoginLoader({ request });
  }

  if (!action) {
    return redirect(MAIN_PAGE_PATH);
  }

  switch (action) {
    case RedirectActions.RfqCreated:
      return redirect('/app/rfq/blotter?' + searchParams.toString());
    case RedirectActions.RfqOrderPlaced:
      return redirect('/app/rfq/blotter?' + searchParams.toString());
    case RedirectActions.RfqNew:
      return redirect('/app/rfq/orders?' + searchParams.toString());
    case RedirectActions.AnalysisCurveGenerator:
      // 參數說明
      // tickers :  債卷 ticker ，多選，用 "," 分隔
      // currency: 貨幣，ex: USD/HKD/EUR...
      // view:  chart/table ，預設顯示圖表/列表
      // expanded: true/false 圖表/列表區塊展開與否

      // example: https://dev.latticefi.com/app/redirect?action=analysis-curve-generator&tickers=GOOGL%2CBABA&currency=USD&view=table&expanded=true
      return redirect(
        '/app/tools/yield-curve-generator?' + searchParams.toString(),
      );
    case RedirectActions.RfqOrderExecuted:
      return redirect('/app/rfq/orders?' + searchParams.toString());
    case RedirectActions.RfqOrderUpdateRejected:
      return redirect('/app/rfq/blotter?' + searchParams.toString());
    case RedirectActions.LimitOrderPlaced:
      return redirect('/app/trader-tool/market?' + searchParams.toString());
    case RedirectActions.LimitOrderFirmMatched:
    case RedirectActions.LimitOrderFlexMatched:
    case RedirectActions.LimitOrderPartialFilled:
      return redirect(
        '/app/trader-tool/order-matches?' + searchParams.toString(),
      );
  }

  return redirect(MAIN_PAGE_PATH);
};

export default redirectLoader;
