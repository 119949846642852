import { FC, forwardRef } from 'react';
import {
  AppBar,
  AppBarProps,
  IconButton,
  Stack,
  StackProps,
  Toolbar,
  ToolbarProps,
  Typography,
  paperClasses,
  useTheme,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface ChatroomBarProps {
  onBack?: () => void;
  avatar?: React.ReactNode;
  title: string;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
  announcement?: React.ReactNode;
  height?: number;
  slotProps?: {
    appBar?: Partial<AppBarProps>;
    toolbar?: Partial<ToolbarProps>;
    container?: Partial<StackProps>;
  };
}

const ChatroomBar = forwardRef<HTMLDivElement, ChatroomBarProps>(
  (
    {
      onBack,
      avatar,
      title,
      subtitle,
      action,
      announcement,
      height,
      slotProps,
    },
    ref,
  ) => {
    const theme = useTheme();

    return (
      <AppBar
        ref={ref}
        position="absolute"
        elevation={0}
        {...slotProps?.appBar}
        sx={{
          [`&.${paperClasses.root}`]: {
            bgcolor: theme.palette.primary.main,
            boxShadow:
              theme.palette.mode === 'dark'
                ? 'none'
                : '0px -4px 4px rgba(0, 0, 0, 0.4)',
          },
          ...slotProps?.appBar?.sx,
        }}
      >
        <Toolbar disableGutters {...slotProps?.toolbar}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={3}
            py={2.5}
            width={'100%'}
            justifyContent={'space-between'}
            {...slotProps?.container}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              {onBack && (
                <IconButton
                  sx={{
                    color: theme.palette.primary.contrastText,
                  }}
                  size="small"
                  onClick={onBack}
                >
                  <ArrowBackIosNewIcon fontSize="inherit" />
                </IconButton>
              )}
              {avatar && avatar}
              <Stack>
                <Typography
                  color={'primary.contrastText'}
                  fontSize={18}
                  fontWeight={'bold'}
                >
                  {title}
                </Typography>
                <Typography variant="subtitle1" color={'primary.contrastText'}>
                  {subtitle && subtitle}
                </Typography>
              </Stack>
            </Stack>
            {action}
          </Stack>
        </Toolbar>
        {announcement}
      </AppBar>
    );
  },
);

export const ChatroomBarSpacer: FC<{
  onBack?: () => void;
  avatar?: React.ReactNode;
  title: string;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;
  announcement?: React.ReactNode;
  slotProps?: {
    appBar?: Partial<AppBarProps>;
    toolbar?: Partial<ToolbarProps>;
    container?: Partial<StackProps>;
  };
}> = ({ onBack, avatar, title, subtitle, action, announcement, slotProps }) => {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      elevation={0}
      {...slotProps?.appBar}
      sx={{
        [`&.${paperClasses.root}`]: {
          bgcolor: theme.palette.primary.main,
          boxShadow:
            theme.palette.mode === 'dark'
              ? 'none'
              : '0px -4px 4px rgba(0, 0, 0, 0.4)',
        },
        ...slotProps?.appBar?.sx,
      }}
    >
      <Toolbar disableGutters {...slotProps?.toolbar}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={3}
          py={2.5}
          width={'100%'}
          justifyContent={'space-between'}
          {...slotProps?.container}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {onBack && (
              <IconButton
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
                size="small"
                onClick={onBack}
              >
                <ArrowBackIosNewIcon fontSize="inherit" />
              </IconButton>
            )}
            {avatar && avatar}
            <Stack>
              <Typography
                color={'primary.contrastText'}
                fontSize={18}
                fontWeight={'bold'}
              >
                {title}
              </Typography>
              <Typography variant="subtitle1" color={'primary.contrastText'}>
                {subtitle && subtitle}
              </Typography>
            </Stack>
          </Stack>
          {action}
        </Stack>
      </Toolbar>
      {announcement}
    </AppBar>
  );
};

export default ChatroomBar;
