import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  inputAdornmentClasses,
  inputBaseClasses,
  listItemClasses,
  listItemTextClasses,
  tabsClasses,
  typographyClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { useToast } from 'ui/components/snackbar/SnackbarBox';
import { isNil, upperCase } from 'lodash';
import { dateFormat, isDivisibleBy, thousandSeparated } from 'ui/utils';
import { LoadingCover } from 'ui/components';
import MobileDialogCover from 'ui/icons/dialog/rfq-mobile-dialog-cover.svg?react';
import {
  useAddRFQ,
  useIndex as useBrokerIndex,
} from '../../../../services/brokers';
import {
  Services,
  serviceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import ReactGA from 'react-ga4';
import { EventAction, Events } from 'analytics/ga4';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useJoinWaitList, useKyc } from '../../../../services/kyc';
import { useAssetBonds } from '../../../../services/bonds';
import { usePermittedV2 } from '../../../../libs/hooks/usePermissions';
import { RedirectActions } from '../../../../libs/layout/RedirectLoader';
import { NumericFormat } from 'react-number-format';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import getSettlementDate from '../../../../pages/rfq/components/getSettlementDate';
import { Dayjs } from 'dayjs';
import PenOnPanelIcon from 'ui/icons/pen-on-panel.svg?react';
import InformationIcon from 'ui/icons/information.svg?react';
import InformationMark from 'ui/components/InformationMark';
import InputSettlementDate from '../../../../pages/rfq/blotter/components/InputSettlementDate';

interface FormValues {
  direction: string;
  size: number;
  isPartialFill: boolean;
  settlementDate: Dayjs;
  // confirmTradeLegalAgreement: boolean;
  // confirmPriceChangeAnnouncement: boolean;
}

export const useActionDisabled = () => {
  const [permittedToRequestQuote] = usePermittedV2('action.requestForQuote');

  if (serviceIsDisabled(Services.TRADE)) {
    return true;
  }

  if (!permittedToRequestQuote) {
    // Blotter side could not pull up RFQ
    return true;
  }

  return false;
};

const ActionRequestForQuote: React.FC<{
  children: (props: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    'data-ga-event': 'action_request_for_quote_init';
    'data-ga-category': 'trade';
    'data-testid': 'action-request-for-quote';
  }) => JSX.Element;
  onCreated?: () => void;
  asset: {
    id: number;
    assetName: string;
    companyName: string;
  };
}> = ({ children, onCreated, asset }) => {
  const { t } = useTranslation(['rfq', 'validation', 'bond']);
  const [open, setOpen] = useState(false);
  const [openRequireBroker, setOpenRequireBroker] = useState(false);
  const [openRequireKyc, setOpenRequireKyc] = useState(false);
  const [manualToggledPartialFill, setManualToggledPartialFill] =
    useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const actionDisabled = useActionDisabled();

  const enabledFetchAsset = useMemo(() => {
    return !!asset?.id && open;
  }, [asset, open]);

  const { data: assetDetail, isLoading: isLoadingAssetDetail } = useAssetBonds(
    String(asset?.id),
    {
      enabled: enabledFetchAsset,
      keepPreviousData: true,
    },
  );
  const minIncrement = useMemo(() => {
    return assetDetail?.minIncrement ?? 0;
  }, [assetDetail]);
  const minSize = useMemo(() => {
    return assetDetail?.minSize ?? 0;
  }, [assetDetail]);

  const settlementDate = useMemo(() => {
    return getSettlementDate({
      assetIdentifier: assetDetail?.isin,
    });
  }, [assetDetail]);

  const defaultValues = useMemo(
    () => ({
      direction: 'BUY',
      size: minSize,
      isPartialFill: false,
      settlementDate: settlementDate,
      // confirmTradeLegalAgreement: false,
      // confirmPriceChangeAnnouncement: false,
    }),
    [minSize, settlementDate],
  );
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        direction: yup.string().required().label(t('field.direction')),
        size: yup
          .number()
          .min(
            minSize || 0,
            t('validation:number.min', {
              label: t('field.size').toLowerCase(),
              min: thousandSeparated(minSize || 0),
            }),
          )
          .required()
          .label(t('field.size').toLowerCase())
          .test(
            'multipleOfAssetMinIncrement',
            t('validation:custom.multipleOfAssetMinIncrement', {
              minIncrement: thousandSeparated(minIncrement || 0),
            }),
            isDivisibleBy(minIncrement),
          ),
        isPartialFill: yup.boolean().required(),
      }),
    [minIncrement, minSize, t],
  );
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { control, handleSubmit, reset, formState, setValue } = methods;
  const { isValid } = formState;
  const size = useWatch({
    control,
    name: 'size',
  });
  const isPartialFillAllowed = useMemo(() => {
    return size > minSize;
  }, [size, minSize]);

  const { data: kycResult, isLoading: isLoadingKyc } = useKyc();
  const isKycPassed = useMemo(() => {
    return kycResult?.isPassed;
  }, [kycResult]);
  const alreadyJoinWaitList = useMemo(() => {
    return kycResult?.isWaiting;
  }, [kycResult]);

  const { data: brokerIndex, isLoading: isLoadingBrokerIndex } = useBrokerIndex(
    {
      page: 1,
      size: 10000,
      status: 3,
    },
  );
  const hasVerifiedBroker = useMemo(() => {
    return brokerIndex?.totalItems && brokerIndex?.totalItems > 0;
  }, [brokerIndex]);

  const { mutate: addRFQ, isLoading } = useAddRFQ({
    onSuccess(res) {
      ReactGA.event({
        action: EventAction.RequestSuccess,
        category: 'trade',
        label: 'action_request_for_quote_success',
      });
      toast.success(t('title.rfqCreated'), t('message.rfqCreated'), {
        onClick: () => {
          navigate(
            `/app/rfq/orders?${qs.stringify({
              action: RedirectActions.RfqCreated,
            })}`,
          );
        },
      });
      onCreated?.();
      onClose();
    },
    onError(error) {
      ReactGA.event({
        action: EventAction.RequestError,
        category: 'trade',
        label: 'action_request_for_quote_error',
      });
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    !isNil(asset?.id) &&
      addRFQ({
        assetId: asset?.id,
        assetType: 'BOND',
        direction: data.direction,
        size: data.size,
        isPartialFill: data.isPartialFill,
      });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    Events.ButtonClick(e);

    if (!isKycPassed) {
      setOpenRequireKyc(true);
      return;
    }

    if (!serviceIsDisabled(Services.TRADE_BROKER) && !hasVerifiedBroker) {
      setOpenRequireBroker(true);
      return;
    }

    setOpen(true);
  };
  const onClose = () => {
    ReactGA.event({
      action: EventAction.ButtonClick,
      category: 'trade',
      label: 'action_request_for_quote_cancel',
    });
    reset();
    setManualToggledPartialFill(false);
    setOpen(false);
  };

  // join wait list
  const { mutate: joinWaitList, isLoading: isLoadingJoinWaitList } =
    useJoinWaitList({
      onSuccess() {
        setOpenRequireKyc(false);
        toast.success(
          t('title.joinWaitListSuccess'),
          t('message.joinWaitListSuccess'),
        );
      },
      onError(error) {
        toast.error(...formatApiErrorToast(t, error?.message));
      },
    });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!manualToggledPartialFill && isPartialFillAllowed) {
      setValue('isPartialFill', true);
    } else if (!isPartialFillAllowed) {
      setValue('isPartialFill', false);
    }
  }, [size]);

  if (actionDisabled) {
    // Blotter side could not pull up RFQ
    return null;
  }

  return (
    <>
      {children({
        onClick: handleClick,
        disabled: isLoadingKyc || isLoadingBrokerIndex,
        'data-ga-event': 'action_request_for_quote_init',
        'data-ga-category': 'trade',
        'data-testid': 'action-request-for-quote',
      })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        // cover={<DialogCover width={556} />}
        data-testid="dialog-request-for-quote"
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
      >
        <Stack justifyContent={'center'}>
          <Box pt={6} mb={2}>
            <Box textAlign={'center'}>
              <MobileDialogCover width={90} />
            </Box>
            <Typography className="uppercase py-2 font-bold text-center">
              {t('title.requestForQuote')}
            </Typography>
            {onMobile && asset && (
              <div className="text-center">
                <Typography variant={'body1'} fontWeight={'bold'} gutterBottom>
                  {asset.assetName}
                </Typography>
                {/* <Typography
                  variant="body2"
                  color={'textSecondary'}
                  gutterBottom
                >
                  {asset.companyName}
                </Typography> */}
              </div>
            )}
          </Box>
          <Stack
            width={'100%'}
            px={4}
            pb={2}
            flex={1}
            justifyContent={'space-between'}
            spacing={2}
          >
            <Stack spacing={2}>
              <Controller
                name="direction"
                control={control}
                render={({ field, fieldState }) => (
                  <Tabs
                    value={field.value}
                    onChange={(e, value) => {
                      field.onChange(value);
                    }}
                    variant="fullWidth"
                    sx={{
                      [`& .${tabsClasses.indicator}`]: {
                        backgroundColor:
                          field.value === 'SELL'
                            ? 'error.main'
                            : 'success.main',
                      },
                    }}
                  >
                    <Tab
                      label={upperCase(t('options.direction.buy'))}
                      value={'BUY'}
                      sx={{
                        [`&.Mui-selected`]: {
                          color: 'success.main',
                        },
                      }}
                    />
                    <Tab
                      label={upperCase(t('options.direction.sell'))}
                      value={'SELL'}
                      sx={{
                        [`&.Mui-selected`]: {
                          color: 'error.main',
                        },
                      }}
                    />
                  </Tabs>
                )}
              />
              <List
                sx={{
                  [`& .${listItemClasses.root}`]: {
                    px: 0,
                    ...(onMobile
                      ? {
                          // flexDirection: 'column',
                          // alignItems: 'flex-start',
                          [`& .${typographyClasses.root}`]: {
                            fontSize: '0.875rem',
                          },
                          [`& .${inputBaseClasses.root}`]: {
                            fontSize: '0.875rem',
                          },
                        }
                      : {}),
                  },
                  [`& .${listItemTextClasses.root}`]: {
                    color: 'text.secondary',
                    [`& .${typographyClasses.root}`]: {
                      fontSize: '0.75rem',
                      textTransform: 'uppercase',
                    },
                  },
                }}
              >
                {onMobile ? (
                  <>
                    <ListItem>
                      <ListItemText primary={t('bond:field.isin')} />
                      <Typography fontWeight={'bold'}>
                        {assetDetail?.isin ?? '-'}
                      </Typography>
                    </ListItem>
                    {/* <ListItem>
                      <ListItemText primary={`${t('bond:field.assetName')}`} />
                      <Typography fontWeight={'bold'}>
                        {asset.assetName}
                      </Typography>
                    </ListItem> */}
                  </>
                ) : (
                  <ListItem>
                    <ListItemText
                      primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                    />
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Chip
                        label={assetDetail?.isin}
                        sx={{
                          bgcolor: '#171A19',
                          color: 'text.secondary',
                        }}
                      />
                      <Typography fontWeight={'bold'}>
                        {asset.assetName}
                      </Typography>
                    </Stack>
                  </ListItem>
                )}
                <Controller
                  name="size"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <ListItem>
                        <ListItemText
                          primary={`${t('field.size')}
                        ${t('bond:tooltip.bondSize', {
                          minSize: thousandSeparated(minSize as number),
                          minIncrement: thousandSeparated(
                            minIncrement as number,
                          ),
                        })}`}
                        />
                        <Box>
                          <NumericFormat
                            thousandSeparator
                            customInput={TextField}
                            value={field.value}
                            variant="standard"
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              field.onChange(floatValue);
                            }}
                            error={!!fieldState.error}
                            placeholder={t('tooltip.size')}
                            disabled={isLoadingAssetDetail}
                            InputProps={{
                              endAdornment: (
                                <PenOnPanelIcon
                                  width={20}
                                  color={theme.palette.text.secondary}
                                />
                              ),
                            }}
                            sx={{
                              [`& .${inputBaseClasses.input}`]: {
                                textAlign: 'right',
                                mr: 1,
                              },
                              // hide underline
                              [`& .${inputBaseClasses.root}`]: {
                                '&:before': {
                                  display: 'none',
                                },
                                '&:after': {
                                  display: 'none',
                                },
                              },
                            }}
                          />
                        </Box>
                      </ListItem>
                      {fieldState.error?.message && (
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                          justifyContent={'end'}
                        >
                          <Typography
                            variant="body2"
                            color="error.main"
                            gutterBottom
                          >
                            {fieldState.error?.message}
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                />
                <Controller
                  name="isPartialFill"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ListItem>
                      <ListItemText
                        primary={
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                          >
                            <Typography fontSize={'inherit'}>
                              {t('field.isPartialFill')}
                            </Typography>
                            {onMobile && !isPartialFillAllowed && (
                              <InformationMark
                                title={t('tooltip.partialFillMinSize')}
                                width={20}
                                placement="top-start"
                                slots={{
                                  icon: <InformationIcon width={20} />,
                                }}
                              />
                            )}
                          </Stack>
                        }
                      />
                      <Tooltip
                        title={
                          isPartialFillAllowed
                            ? ''
                            : t('tooltip.partialFillMinSize')
                        }
                        placement="top-start"
                      >
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <Typography fontWeight={'bold'} variant="body2">
                            {!isPartialFillAllowed
                              ? t('common.disabled')
                              : field.value
                                ? t('common:action.accept')
                                : t('common:action.reject')}
                          </Typography>
                          <Switch
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              !manualToggledPartialFill &&
                                setManualToggledPartialFill(true);
                            }}
                            disabled={!isPartialFillAllowed}
                            color={'primary'}
                            size={'small'}
                          />
                        </Stack>
                      </Tooltip>
                    </ListItem>
                  )}
                />
                {/* <Controller
                  name="settlementDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ListItem>
                      <ListItemText
                        primary={t('field.estimatSettlementDate')}
                      />
                      <InputSettlementDate
                        {...field}
                        slotProps={{
                          textField: {
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                            variant: 'standard',
                            sx: {
                              [`& .${inputBaseClasses.input}`]: {
                                textAlign: 'right',
                                mr: onMobile ? 1 : 0,
                              },
                              [`& .${inputAdornmentClasses.root}`]: {
                                ml: 0,
                              },
                              // hide underline
                              [`& .${inputBaseClasses.root}`]: {
                                '&:before': {
                                  display: 'none',
                                },
                                '&:after': {
                                  display: 'none',
                                },
                              },
                            },
                          },
                        }}
                      />
                    </ListItem>
                  )}
                /> */}
              </List>
            </Stack>

            <Stack spacing={1} textAlign={'center'}>
              <Button
                variant={isValid ? 'contained' : 'text'}
                onClick={(e) => {
                  Events.ButtonClick(e);
                  handleSubmit(onSubmit)();
                }}
                disabled={!isValid}
                size="large"
                data-ga-event="action_request_for_quote_submit"
                data-ga-category="trade"
                data-testid="action-request-for-quote-submit"
              >
                {t('action.requestForQuote')}
              </Button>
              <Typography variant={'body2'} color={'textSecondary'}>
                {t('message.rfqDisclaimer')}
              </Typography>
            </Stack>
          </Stack>
          <LoadingCover loading={isLoading} />
        </Stack>
      </BaseActionDialog>
      {/* Suggestions for user to add broker */}
      <BaseActionDialog
        open={openRequireBroker}
        onClose={() => setOpenRequireBroker(false)}
      >
        <Box pt={8}>
          <div className="text-center">
            <Typography
              variant={onTablet ? 'body1' : 'h5'}
              className="uppercase py-2 font-bold"
            >
              {t('action.requireBroker')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('tooltip.requireBroker')}
            </Typography>
          </div>
        </Box>
        <Stack
          width={'100%'}
          p={2}
          flex={1}
          justifyContent={'space-between'}
          spacing={2}
          direction={'row'}
        >
          <Button
            variant="contained"
            onClick={() => {
              setOpenRequireBroker(false);
              navigate({
                pathname: '/app/settings/account/broker',
              });
            }}
            size={'large'}
            sx={{
              flex: 1,
            }}
            disabled={isLoading}
          >
            {t('action.addBroker')}
          </Button>
        </Stack>
      </BaseActionDialog>
      {/* By the mean time, the KYC function is not enabled,
      so if user's KYC hadn't been toggled from CMS, the user can only select to join wait list for future features */}
      <BaseActionDialog
        open={openRequireKyc}
        onClose={() => setOpenRequireKyc(false)}
      >
        <Box pt={8}>
          <div className="text-center">
            <Typography
              variant={onTablet ? 'body1' : 'h5'}
              className="uppercase py-2 font-bold"
            >
              {alreadyJoinWaitList
                ? t('action.alreadyJoinWaitList')
                : t('action.joinWaitList')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('tooltip.joinWaitList')}
            </Typography>
          </div>
        </Box>
        <Stack
          width={'100%'}
          p={2}
          flex={1}
          justifyContent={'space-between'}
          spacing={2}
          direction={'row'}
        >
          {alreadyJoinWaitList ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpenRequireKyc(false);
              }}
              size={'large'}
              fullWidth
            >
              {t('common:action.ok')}
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenRequireKyc(false);
                }}
                size={'large'}
                sx={{
                  flex: 1,
                }}
                disabled={isLoadingJoinWaitList}
              >
                {t('common:action.cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  joinWaitList();
                }}
                size={'large'}
                sx={{
                  flex: 1,
                }}
                disabled={isLoadingJoinWaitList}
              >
                {t('action.join')}
              </Button>
            </>
          )}
        </Stack>
      </BaseActionDialog>
    </>
  );
};

export default ActionRequestForQuote;
