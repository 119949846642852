import { forwardRef, KeyboardEvent, useMemo, useState } from 'react';
import {
  Menu,
  MenuItem,
  menuItemClasses,
  outlinedInputClasses,
  popoverClasses,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ChatRoomInput = forwardRef<
  HTMLDivElement,
  {
    onCommit?: (message: string) => void;
    commands?: string[];
    placeholder?: string;
    disabled?: boolean;
  }
>(({ onCommit, commands: commandOptions = [], placeholder, disabled }, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('chat');

  const [message, setMessage] = useState('');
  const [focusingCommand, setFocusingCommand] = useState(0);
  const [inputInComposition, setInputInComposition] = useState(false);

  const commands = useMemo(
    () =>
      message
        ? commandOptions
            .map((command) =>
              command.startsWith('/') ? command : `/${command}`,
            )
            .filter((command) =>
              command.startsWith(message.toLowerCase().trim()),
            )
            .sort((a, b) => {
              return b.toLowerCase() < a.toLowerCase() ? -1 : 1;
            })
        : [],
    [message],
  );

  const open = commands.length > 0;

  const onCommitMessage = (event: KeyboardEvent<HTMLDivElement>) => {
    if (inputInComposition) {
      return;
    }
    if (!message.trim()) {
      return;
    }
    onCommit?.(message);
    event.preventDefault();
    setMessage('');
    setFocusingCommand(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        ref={ref}
        value={message}
        onChange={(event) => {
          setMessage(event.target.value);

          if (event.target.value.startsWith('/')) {
            setAnchorEl(event.target);
            event.target.focus();
          } else {
            setAnchorEl(null);
          }
        }}
        placeholder={placeholder ?? t('placeholder.chatMessage')}
        fullWidth
        multiline
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Enter':
              if (open) {
                if (onCommit) {
                  onCommit(commands[focusingCommand]);
                  event.preventDefault();
                  setMessage('');
                  setAnchorEl(null);
                }
              } else {
                onCommitMessage(event);
              }
              break;
            case 'Tab':
              if (open) {
                if (onCommit) {
                  onCommit(commands[focusingCommand]);
                  setMessage('');
                  setAnchorEl(null);
                }
              }
              event.preventDefault();
              break;
            case 'ArrowUp':
              if (open) {
                event.preventDefault();
                setFocusingCommand((prev) =>
                  prev === 0 ? commands.length - 1 : prev - 1,
                );
              }
              break;
            case 'ArrowDown':
              if (open) {
                event.preventDefault();
                setFocusingCommand((prev) =>
                  prev === commands.length - 1 ? 0 : prev + 1,
                );
              }
              break;
            default:
              break;
          }
        }}
        onCompositionStart={(e) => {
          setInputInComposition(true);
        }}
        onCompositionEnd={(e) => {
          setInputInComposition(false);
        }}
        autoFocus
        disabled={disabled}
        sx={{
          position: 'absolute',
          bottom: 0,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#232525' : '#fff',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          [`& .${outlinedInputClasses.root}`]: {
            bgcolor: 'transparent',
          },
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: 'none',
          },
          [`& .${popoverClasses.root}`]: {
            bgcolor: 'pink',
          },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocus
        disableRestoreFocus
        autoFocus={false}
        elevation={1}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {commands.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === focusingCommand}
            sx={{
              [`&.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
                bgcolor: (theme) => theme.palette.action.selected,
              },
            }}
            onClick={() => {
              if (onCommit) {
                onCommit(option);
                setMessage('');
                setAnchorEl(null);
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});
