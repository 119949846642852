import { RfqDetail } from '../../../services/brokers';
import { dateFormat } from 'ui/utils';

const ColumnQuoteTime: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (!rfq) {
    return null;
  }

  return dateFormat(
    rfq?.quotedTime ?? rfq?.bestQuotedTime,
    'DD MMM YYYY HH:mm',
  );
};

export default ColumnQuoteTime;
