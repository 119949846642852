import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  RfqDetail,
  RfqStatus,
  useRfqDetail,
  useUpdateQuoteMeta,
  useUpdateQuote,
  useGetRfqNetPrice,
} from '../../../../services/brokers';
import { ModalLayout, SnackbarContext, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { dateFormat, thousandSeparated } from 'ui/utils';
import { isNil, isNumber } from 'lodash';
import InformationMark from 'ui/components/InformationMark';
import WarningTriangleIcon from 'ui/icons/warning_triangle.svg?react';
import RfqHeader from './RfqHeader';
import ActionCancelQuote from './ActionCancelQuote';
import InputPartialFillSize from './InputPartialFillSize';
import InputPrice from './InputPrice';
import DetailLabel from '../../components/DetailLabel';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnCreatedAt from '../../components/ColumnCreatedAt';
import ColumnStatus from './ColumnStatus';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useIndex } from '../../../../services/bonds';
import priceOutOfMidPriceRangeAlertText from '../../components/priceOutOfMidPriceRangeAlertText';
import {
  Services,
  useTraderServiceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import { useMeasure } from 'react-use';
import dayjs from 'dayjs';
import InputSettlementDate from './InputSettlementDate';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import SummaryLayout from '../../components/SummaryLayout';
import ColumnPrice from './ColumnPrice';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import SummaryList from '../../components/SummaryList';
import ColumnAsset from '../../components/ColumnAsset';
import {
  rfqDatePickerTextFieldProps,
  rfqInputBaseProps,
} from '../../components/utils';
import ColumnSize from '../../components/ColumnSize';
import { NumericFormat } from 'react-number-format';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import SummarySection from '../../components/SummarySection';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import ColumnSettlementFee from '../../orders/components/ColumnSettlementFee';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import ColumnTaxRate from '../../orders/components/ColumnTaxRate';
import ColumnPlatformFee from './ColumnPlatformFee';

interface FormValues {
  price: string | null;
  size: string | null;
  brokerSpread: string | null;
  settlementDate: dayjs.Dayjs | null;
  enablePlatformFee: boolean;
}

const ActionUpdateQuote: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation(['rfq', 'validation']);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const [priceConfirmed, setPriceConfirmed] = useState(false);

  const brokerSpreadDisabled = useTraderServiceIsDisabled(
    Services.TRADE_RFQ_BROKER_SPREAD,
  );

  const { data, refetch: refetchRfqDetail } = useRfqDetail(
    String(rfq?.id),
    {
      view: 1,
    },
    {
      enabled: open,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: rfq?.assetIdentifier,
    },
    {
      enabled: open && Boolean(rfq?.assetIdentifier),
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  const currentBestPrice = useMemo(() => {
    if (data?.direction === 'BUY') {
      return data?.bestQuote || Infinity;
    } else {
      return data?.bestQuote || 0;
    }
  }, [data?.bestQuote, data?.direction]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        price: yup
          .number()
          .required()
          .label(t('field.price').toLowerCase())
          .moreThan(0, t('validation:custom.greaterThanZero')),
        size: yup
          .number()
          .optional()
          .label(t('field.size').toLowerCase())
          .test(
            'requiredIfPartialFill',
            t('validation:custom.requiredIfPartialFill'),
            (value) => {
              if (!data?.isPartialFill) return true;
              return !isNil(value);
            },
          )
          .min(
            bondDetail?.minSize || 0,
            t('validation:custom.greaterThanBondMinSize', {
              minSize: thousandSeparated(bondDetail?.minSize || 0),
            }),
          )
          .test(
            'shouldNotExceed',
            t('validation:custom.shouldNotExceed', {
              size: data?.initSize,
            }),
            (value) => {
              if (!data?.initSize) return true;
              if (!value) return true;
              return value <= data?.initSize;
            },
          ),
        brokerSpread: yup
          .number()
          .optional()
          .nullable()
          .label(t('field.brokerSpread').toLowerCase()),
        settlementDate: yup.date().required(),
      }),
    [t, data?.direction, currentBestPrice, data?.isPartialFill, data?.initSize],
  );
  const defaultValues = useMemo(() => {
    return {
      price: data?.quote?.toString() || null,
      size: data?.quoteSize?.toString() || null,
      brokerSpread: data?.brokerSpread?.toString() || null,
      settlementDate: data?.settlementDate ? dayjs(data?.settlementDate) : null,
      enablePlatformFee: data?.enablePlatformFee || true,
    };
  }, [data]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const formValues = useWatch({
    control,
  });
  const isFormValuesValid = useMemo(() => {
    return validationSchema.isValidSync(formValues);
  }, [formValues]);

  const { data: estimatedPrice, isLoading: isLoadingEstimatedPrice } =
    useGetRfqNetPrice(
      {
        id: rfq?.id,
        basePrice: formValues.price ? Number(formValues.price) : 0,
        enablePlatformFee:
          formValues?.enablePlatformFee || rfq?.enablePlatformFee || true,
        size: formValues.size ? Number(formValues.size) : 0,
      },
      {
        enabled: isFormValuesValid,
      },
    );

  const ableToChangeQuote = useMemo(() => {
    return Boolean(data?.reQuoteChance) || data?.status === RfqStatus.Quoted;
  }, [data?.reQuoteChance, data?.status]);

  const sizeHelperText = useMemo(() => {
    if (
      data?.isPartialFill &&
      formValues.size &&
      Number(formValues.size) < data?.size
    ) {
      return t('tooltip.partialFillSizeWillBeLowPriority');
    }
    return '';
  }, [data?.isPartialFill, formValues.size, data?.size]);

  const priceHelperText = useMemo(() => {
    let helperText = '';
    const textColor = 'warning.main';
    // If offered price is out of mid price +- 40% range, show alert
    if (formValues.price && bondDetail?.midPriceClean) {
      const alert = priceOutOfMidPriceRangeAlertText(
        bondDetail,
        Number(formValues.price),
        t,
      );

      helperText = alert ?? '';
    } else if (
      data?.status === RfqStatus.WaitingQuote &&
      data?.bestQuote &&
      formValues.price
    ) {
      switch (true) {
        case data?.direction === 'BUY' &&
          Number(formValues.price) > data?.bestQuote:
          helperText = t('tooltip.theresABetterPrice');
          break;
        case data?.direction === 'SELL' &&
          Number(formValues.price) < data?.bestQuote:
          helperText = t('tooltip.theresABetterPrice');
          break;
      }
    }

    if (!helperText) return '';

    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <SvgIcon
          sx={{
            width: 16,
            height: 16,
            color: textColor,
          }}
        >
          <WarningTriangleIcon />
        </SvgIcon>
        <Typography variant="body2" color="warning.main" gutterBottom>
          {helperText}
        </Typography>
      </Stack>
    );
  }, [data, formValues.price, bondDetail?.midPriceClean]);

  const { mutate, isLoading: isLoadingUpdateQuote } = useUpdateQuote({
    onSuccess: () => {
      toast.success(
        t('title.quoteUpdatedSuccess'),
        t('message.quoteUpdatedSuccess'),
        {
          duration: 10,
        },
      );
      onClose();
      onSuccess?.();
    },
    onError: async (error) => {
      const resData = await refetchRfqDetail().then((res) => {
        return res.data;
      });
      if (resData?.status === RfqStatus.OrderPlaced) {
        toast.error(
          t('title.quoteOrderAlreadyPlaced'),
          t('message.quoteOrderAlreadyPlaced'),
        );

        setOpen(false);
        return;
      }

      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });
  const { mutate: updateQuoteMeta, isLoading: isLoadingUpdateBrokerSpread } =
    useUpdateQuoteMeta({
      onSuccess(data, variables, context) {
        reset({
          ...defaultValues,
          ...(variables.brokerSpread && {
            brokerSpread: variables.brokerSpread?.toString(),
          }),
          ...(variables.settlementDate && {
            settlementDate: dayjs(variables.settlementDate),
          }),
        });
        onClose();
        toast.success(t('title.brokerSpreadUpdated'));
      },
    });
  const isLoading = isLoadingUpdateQuote || isLoadingUpdateBrokerSpread;

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    reset(defaultValues);
    setOpen(false);
    setPriceConfirmed(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (!rfq) return;
    if (!formData.price) return;
    await Promise.all([
      (dirtyFields.price ||
        dirtyFields.size ||
        dirtyFields.enablePlatformFee) &&
        mutate({
          rfqId: String(rfq.id),
          quoteId: String(rfq.quoteId),
          price: Number(formData.price),
          ...(data?.isPartialFill && { size: Number(formData.size) }),
          enablePlatformFee: formData.enablePlatformFee,
        }),
      !isNil(data?.quoteId) &&
        ((dirtyFields.brokerSpread &&
          isNumber(formData.brokerSpread) &&
          !brokerSpreadDisabled) ||
          dirtyFields.settlementDate) &&
        updateQuoteMeta({
          rfqId: rfq.id,
          quoteId: data?.quoteId,
          ...(dirtyFields.brokerSpread && {
            brokerSpread: Number(formData.brokerSpread),
          }),
          ...(dirtyFields.settlementDate && {
            settlementDate: dayjs(formData.settlementDate).format('YYYY-MM-DD'),
          }),
        }),
    ]);
  };

  const renderActions = useCallback(
    (data: RfqDetail) =>
      data &&
      (!priceConfirmed ? (
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
        >
          <ActionCancelQuote rfq={data} onCanceled={onClose}>
            {({ onClick, disabled }) =>
              !disabled ? (
                <Button
                  variant="outlined"
                  onClick={onClick}
                  disabled={disabled}
                  size={onMobile ? 'large' : 'medium'}
                  fullWidth={onMobile}
                >
                  {t('action.cancelQuote')}
                </Button>
              ) : null
            }
          </ActionCancelQuote>
          <Box>
            <Button
              variant={isFormValuesValid ? 'contained' : 'text'}
              onClick={() => {
                if (isFormValuesValid) {
                  setPriceConfirmed(true);
                }
              }}
              disabled={
                !isFormValuesValid ||
                !isDirty ||
                !ableToChangeQuote ||
                isLoading
              }
              size={onMobile ? 'large' : 'medium'}
              fullWidth={onMobile}
            >
              {t('action.updateQuote')}
            </Button>
          </Box>
        </Stack>
      ) : (
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
        >
          <Button
            variant="outlined"
            onClick={() => setPriceConfirmed(false)}
            size={onMobile ? 'large' : 'medium'}
          >
            {t('action.reselectBroker')}
          </Button>
          <Box>
            <Button
              variant={isFormValuesValid ? 'contained' : 'text'}
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              disabled={
                !isFormValuesValid ||
                !isDirty ||
                !ableToChangeQuote ||
                isLoading
              }
              size={onMobile ? 'large' : 'medium'}
              fullWidth={onMobile}
            >
              {t('common:action.confirm')}
            </Button>
          </Box>
        </Stack>
      )),
    [
      priceConfirmed,
      isFormValuesValid,
      isDirty,
      ableToChangeQuote,
      isLoading,
      onMobile,
      t,
    ],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <SummaryLayout
              header={
                <Stack spacing={1}>
                  <TypographyWithLoading loading={isLoadingData}>
                    <RfqHeader
                      rfq={{
                        ...data,
                        netQuote: estimatedPrice?.netPrice,
                      }}
                    />
                  </TypographyWithLoading>
                  {!onMobile && renderActions(data)}
                </Stack>
              }
              summary={
                <SummaryList>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                  {/* <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={t('bond:field.isin')} />
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.isin ?? '-'}
                    </TypographyWithLoading>
                  </ListItem>
                  <Controller
                    name="size"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText
                            primary={`${t('field.size')}
                        ${t('bond:tooltip.bondSize', {
                          minSize: thousandSeparated(data?.bond?.minSize),
                          minIncrement: thousandSeparated(
                            data?.bond?.minIncrement,
                          ),
                        })}`}
                          />
                          {data?.isPartialFill && !priceConfirmed ? (
                            <InputPartialFillSize
                              rfq={data}
                              {...rfqInputBaseProps}
                              {...field}
                              error={!!fieldState.error}
                            />
                          ) : (
                            <TypographyWithLoading loading={isLoadingData}>
                              <ColumnSize
                                rfq={{
                                  ...data,
                                  size: formValues.size,
                                }}
                              />
                            </TypographyWithLoading>
                          )}
                        </ListItem>
                        {!priceConfirmed &&
                          (fieldState.error?.message ? (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                              justifyContent={'end'}
                            >
                              <Typography
                                variant="body2"
                                color="error.main"
                                gutterBottom
                              >
                                {fieldState.error?.message}
                              </Typography>
                            </Stack>
                          ) : (
                            sizeHelperText && (
                              <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'end'}
                              >
                                <SvgIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: 'warning.main',
                                  }}
                                >
                                  <WarningTriangleIcon />
                                </SvgIcon>
                                <Typography
                                  variant="body2"
                                  color="warning.main"
                                  gutterBottom
                                  maxWidth={320}
                                >
                                  {sizeHelperText}
                                </Typography>
                              </Stack>
                            )
                          ))}
                      </>
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText primary={`${t('field.price')}`} />
                          <TypographyWithLoading loading={isLoadingData}>
                            {!priceConfirmed ? (
                              <InputPrice
                                {...rfqInputBaseProps}
                                {...field}
                                placeholder={t('tooltip.layQuote')}
                                error={!!fieldState.error}
                                disabled={!ableToChangeQuote}
                              />
                            ) : formValues?.price ? (
                              <ColumnPrice
                                rfq={{
                                  ...data,
                                  quote: formValues.price,
                                }}
                              />
                            ) : (
                              '-'
                            )}
                          </TypographyWithLoading>
                        </ListItem>
                        {!priceConfirmed &&
                          (fieldState.error?.message ? (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                              justifyContent={'end'}
                            >
                              <Typography
                                variant="body2"
                                color="error.main"
                                gutterBottom
                              >
                                {fieldState.error?.message}
                              </Typography>
                            </Stack>
                          ) : (
                            priceHelperText && (
                              <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'end'}
                              >
                                <SvgIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: 'warning.main',
                                  }}
                                >
                                  <WarningTriangleIcon />
                                </SvgIcon>
                                <Typography
                                  variant="body2"
                                  color="warning.main"
                                  gutterBottom
                                  maxWidth={360}
                                >
                                  {priceHelperText}
                                </Typography>
                              </Stack>
                            )
                          ))}
                      </>
                    )}
                  />
                  <Controller
                    name="enablePlatformFee"
                    control={control}
                    render={({ field, fieldState }) => (
                      <ListItem>
                        <ListItemText
                          primary={
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                            >
                              <Typography fontSize={'inherit'}>
                                {t('field.platformFee')}
                              </Typography>
                              {!priceConfirmed && (
                                <Switch
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                  color={'primary'}
                                  size={'small'}
                                />
                              )}
                            </Stack>
                          }
                        />
                        <Box>
                          <TypographyWithLoading
                            loading={
                              isFormValuesValid && isLoadingEstimatedPrice
                            }
                          >
                            <ColumnPlatformFee
                              rfq={{
                                ...data,
                                quote: formValues.price,
                                enablePlatformFee: formValues.enablePlatformFee,
                                platformFee: estimatedPrice?.platformFee,
                              }}
                            />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                    )}
                  />
                  <ListItem>
                    <ListItemText primary={t('field.settlementFee')} />
                    <Box>
                      <TypographyWithLoading
                        loading={isFormValuesValid && isLoadingEstimatedPrice}
                      >
                        <ColumnSettlementFee
                          rfq={{
                            ...data,
                            settlementFee: estimatedPrice?.settlementFee,
                          }}
                        />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.quoteExchangeRate !== 1 && (
                    <ListItem>
                      <ListItemText primary={t('field.quoteExchangeRate')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnQuoteExchangeRate rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary={t('field.tax')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <ColumnTaxResidency rfq={data} />
                          <Box>
                            <ColumnTaxRate rfq={data} />
                          </Box>
                        </Stack>
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <Controller
                    name="settlementDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText primary={t('field.settlementDate')} />
                          <Box>
                            <TypographyWithLoading loading={isLoadingData}>
                              {priceConfirmed ? (
                                dateFormat(formValues.settlementDate)
                              ) : (
                                <InputSettlementDate
                                  {...field}
                                  slotProps={{
                                    textField: {
                                      error: !!fieldState.error,
                                      helperText: fieldState.error?.message,
                                      ...rfqDatePickerTextFieldProps,
                                    },
                                  }}
                                />
                              )}
                            </TypographyWithLoading>
                          </Box>
                        </ListItem>
                        {!priceConfirmed && fieldState.error?.message && (
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'end'}
                          >
                            <Typography
                              variant="body2"
                              color="error.main"
                              gutterBottom
                            >
                              {fieldState.error?.message}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                  <ListItem>
                    <ListItemText
                      primary={t('bond:field.tradingRestrictionType')}
                    />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.bond?.tradingRestrictionType || '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.quoteRequestedAt')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnCreatedAt rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                </SummaryList>
              }
            />

            {onMobile && data && (
              <>
                <Box height={132} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),
    [
      rfq?.id,
      open,
      estimatedPrice?.netPrice,
      estimatedPrice?.platformFee,
      estimatedPrice?.settlementFee,
      onMobile,
      renderActions,
      t,
      control,
      isFormValuesValid,
      isLoadingEstimatedPrice,
      formValues.price,
      formValues.enablePlatformFee,
      formValues.size,
      formValues.settlementDate,
      priceConfirmed,
      sizeHelperText,
      ableToChangeQuote,
      priceHelperText,
    ],
  );

  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content?: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
        })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick, disabled: !rfq.quote })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isLoading) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionUpdateQuote;
