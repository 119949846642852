import { createContext, useState } from 'react';

export const LimitOrderMarketContext = createContext<{
  hasNewOrder: boolean;
  setHasNewOrder: (hasNewOrder: boolean) => void;
}>({
  hasNewOrder: false,
  setHasNewOrder: () => {},
});

export default function LimitOrderMarketContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [hasNewOrder, setHasNewOrder] = useState(false);
  return (
    <LimitOrderMarketContext.Provider
      value={{
        hasNewOrder,
        setHasNewOrder,
      }}
    >
      {children}
    </LimitOrderMarketContext.Provider>
  );
}
