export enum Services {
  AUTH = 'auth',
  AUTH_GOOGLE = 'auth/google',
  AUTH_LINKEDIN = 'auth/linkedin',
  USER = 'user',
  USER_ACCOUNT_SETTINGS = 'user/account-settings',
  USER_MEMBERSHIP = 'user/membership',
  USER_KYC = 'user/kyc',
  ALERT = 'alert',
  BOND = 'bond',
  BOND_PRICE_MONITOR = 'bond/price-monitor',
  BOND_YIELD_CALCULATOR = 'bond/yield-calculator',
  BOND_YIELD_ANALYSIS = 'bond/yield-analysis',
  BOND_HISTORY = 'bond/history',
  BOND_YIELD_CURVE = 'bond/yield-curve',
  BOND_YIELD_CURVE_FILTER = 'bond/yield-curve/filter',
  BOND_DETAIL_SHARE = 'bond/detail/share',
  BOND_DETAIL_ANALYSIS = 'bond/detail/analysis',
  BOND_DOWNLOAD_REPORT = 'bond/download-report',
  BOND_DOWNLOAD_REPORT_GENERATED = 'bond/download-report/generated',
  BOND_DOWNLOAD_REPORT_SAMPLE = 'bond/download-report/sample',
  WATCHLIST = 'watchlist',
  PORTFOLIO = 'portfolio',
  TRADE = 'trade',
  TRADE_BROKER = 'trade/broker',
  TRADE_BROKER_ADD_BROKER = 'trade/broker/add-broker',
  TRADE_RFQ_BROKER_SPREAD = 'trade/rfq/broker-spread',
  TRADE_TRADER_TOOL = 'trade/limit-order',
  TRADE_TRADER_TOOL_ORDER_MATCHES = 'trade/limit-order/order-matches',
  TRADE_LIMIT_ORDER_BROKER_SPREAD = 'trade/limit-order/broker-spread',
  DASHBOARD = 'dashboard',
  AIDAN = 'aidan',
  AIDAN_SESSION = 'aidan/session',
  CHAT = 'chat',
  COMMUNITY = 'community',
  FAQ = 'faq',
}
