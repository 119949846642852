export const ApiKey = 'limit-order';
export const ApiConfig = {
  index: 'limited-order',
  create: 'limited-order',
  update: 'limited-order',
  cancel: 'limited-order/cancel',
  export: 'limited-order/export',
  import: 'limited-order/import',
  updateMatchSettlementDate: 'limited-order/match',
  searchMatch: 'limited-order/match',
  updateMatch: 'limited-order/match',
};
