import { TFunction } from 'i18next';

export const objectToFormData = (target: any) => {
  const formData = new FormData();
  Object.keys(target).forEach((key: string) => {
    const value = target[key];
    formData.append(key, value);
  });
  return formData;
};

export const formatApiErrorToast = (t: TFunction, errorKey?: string) => {
  return [
    errorKey
      ? t([`${errorKey}.title`, 'common:api.error.unknown.title'])
      : t('common:api.error.unknown.title'),
    errorKey
      ? t([`${errorKey}.message`], {
          defaultValue: '',
        })
      : undefined,
  ] as [string, string | undefined];
};
