import { storageClear, storageGet } from '../core/storage';
import { StorageKey } from '../utils/storage-key';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios';
import axios from 'axios';
import globalRouter from '../utils/router';
import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();

const baseURL =
  (import.meta.env.VITE_APP_URL || 'https://api-dev.latticefi.com') + '/api/v1';

const errorAlertHandler = (error: any) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
      case 440:
      case 419:
      case 400:
      case 403:
      case 404:
      case 429:
      case 500:
        return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

const authExpiredHandler = (error: AxiosError<{ message?: string }>) => {
  if (error.response?.status === 401) {
    window.location.href = '/login';
    queryClient.clear();
    storageClear();
  }
  return Promise.reject(error);
};

export const apiBaseClient = (headers?: AxiosRequestHeaders) => {
  // console.log('=== baseURL ===', baseURL)
  const client = axios.create({
    baseURL,
    responseType: 'json',
    withCredentials: true,
    withXSRFToken: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });

  client.interceptors.response.use((value) => value, errorAlertHandler);

  return client;
};

export const apiAuthClient = (headers?: AxiosRequestHeaders) => {
  const client = apiBaseClient(headers);

  client.interceptors.request.use((config) => {
    const token = storageGet(StorageKey.token);
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  client.interceptors.response.use(undefined, authExpiredHandler);

  return client;
};
