import { FC } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  toolbarClasses,
} from '@mui/material';

const ChatroomAnnouncement: FC<{
  title: React.ReactNode;
  action?: React.ReactNode;
  onClick?: () => void;
}> = ({ title, action, onClick }) => {
  return (
    <Toolbar
      disableGutters
      sx={{
        color: (theme) => theme.palette.text.primary,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark' ? '#232525' : '#fff',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        [`&.${toolbarClasses.root}`]: {
          minHeight: 0,
        },
      }}
    >
      {onClick ? (
        <ListItemButton onClick={onClick}>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 15,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </ListItemText>
          {action}
        </ListItemButton>
      ) : (
        <ListItem>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 15,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </ListItemText>
          {action}
        </ListItem>
      )}
    </Toolbar>
  );
};

export default ChatroomAnnouncement;
