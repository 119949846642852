import { TFunction } from 'i18next';
import { Bond } from 'services/bond';

const range = 40;

const priceOutOfMidPriceRangeAlertText = (
  bond: Bond | undefined,
  price: number,
  t: TFunction,
) => {
  if (!bond || !bond?.midPriceClean || !price) return;
  const midPrice = bond?.midPriceClean;
  const diff = Math.abs(price - midPrice);
  const diffPercentage = (diff / midPrice) * 100;
  if (diffPercentage > range) {
    return t('tooltip.priceOutOfMidPriceRange');
  }
  return '';
};

export default priceOutOfMidPriceRangeAlertText;
