import {
  Theme,
  OutlinedInputProps,
  OutlinedInputClasses,
  outlinedInputClasses,
  CSSObject,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<OutlinedInputProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof OutlinedInputClasses,
    'MuiOutlinedInput',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }): CSSObject => ({
    backgroundColor: theme.palette.background.paper,
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.primary.main,
    },
    [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
      {
        borderColor:
          theme.palette.mode === 'light'
            ? theme.palette.divider
            : theme.palette.background.paper,
      },
  }),
  input: ({ theme }): CSSObject => ({
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  }),
  notchedOutline: ({ theme }): CSSObject => ({
    borderColor: theme.palette.divider,
  }),
  // adornedStart: ({ theme }): CSSObject => ({
  //   '& svg': {
  //     color: theme.palette.text.secondary,
  //   },
  // }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
