import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CardHeader,
  Chip,
  chipClasses,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import DirectionChip from '../../components/DirectionChip';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import { dateFormat, thousandSeparated } from 'ui/utils';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import { RfqDetail } from '../../../../services/brokers';
import { ModalLayout } from 'ui/components';
import RfqHeader from './RfqHeader';
import StatusColumn from './StatusColumn';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import SummaryLayout from '../../components/SummaryLayout';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import SummaryList from '../../components/SummaryList';
import ColumnAsset from '../../components/ColumnAsset';
import ColumnTax from '../../orders/components/ColumnTax';
import ColumnPlatformFee from './ColumnPlatformFee';
import ColumnSettlementFee from '../../orders/components/ColumnSettlementFee';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import ColumnPrice from './ColumnPrice';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import InformationMark from 'ui/components/InformationMark';
import ColumnTaxRate from '../../orders/components/ColumnTaxRate';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';

const ActionCheckOrderUpdate: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const handleClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const renderActions = useCallback(
    (rfq: RfqDetail) => (
      <Stack direction={'row'} spacing={2}>
        {rfq?.username && (
          <ActionOpenChatOfRfq
            username={rfq?.username}
            rfq={rfq}
            onSuccess={onClose}
          >
            {(props) => (
              <Button
                variant={'outlined'}
                color={'primary'}
                size={onMobile ? 'large' : 'medium'}
                startIcon={
                  <SvgIcon>
                    <ChatIcon />
                  </SvgIcon>
                }
                fullWidth={onMobile}
                {...props}
              >
                {t('action.openChat')}
              </Button>
            )}
          </ActionOpenChatOfRfq>
        )}
      </Stack>
    ),
    [t],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <SummaryLayout
              header={
                <Stack spacing={1}>
                  <TypographyWithLoading loading={isLoadingData}>
                    <RfqHeader rfq={data} />
                  </TypographyWithLoading>
                  {!onMobile && renderActions(data)}
                </Stack>
              }
              summary={
                <SummaryList>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={t('bond:field.isin')} />
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.isin ?? '-'}
                    </TypographyWithLoading>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.clientAccount')}`} />
                    <Box textAlign={'right'}>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnClientAccount rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.clientContact')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.username ?? '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.clientName')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.fullName ?? '-'}
                    </TypographyWithLoading>
                  </Box>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={`${t('field.size')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSize rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.price')}`} />
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnPrice rfq={data} />
                    </TypographyWithLoading>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.platformFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnPlatformFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.quoteExchangeRate !== 1 && (
                    <ListItem>
                      <ListItemText primary={t('field.quoteExchangeRate')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnQuoteExchangeRate rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary={t('field.tax')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <ColumnTaxResidency rfq={data} />
                          <Box>
                            <ColumnTaxRate rfq={data} />
                          </Box>
                        </Stack>
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementDate')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementDate rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={t('bond:field.tradingRestrictionType')}
                    />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.bond?.tradingRestrictionType || '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.orderedAt')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnRequestTime rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.orderNote && (
                    <ListItem>
                      <ListItemText primary={t('field.cancelReason')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          {data?.orderNote ?? '-'}
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                </SummaryList>
              }
            />
            {onMobile && data && (
              <>
                <Box height={60} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),
    [rfq?.id, open, t, renderActions, onMobile],
  );

  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content?: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
        })}
        <ModalLayout open={open} onClose={onClose}>
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionCheckOrderUpdate;
