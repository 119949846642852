import React from 'react';
import {
  List,
  listItemClasses,
  listItemTextClasses,
  ListProps,
  typographyClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const SummaryList: React.FC<ListProps> = (props) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <List
      {...props}
      sx={{
        [`& .${listItemClasses.root}`]: {
          px: 0,
          // ...(onMobile
          //   ? {
          //       flexDirection: 'column',
          //       alignItems: 'flex-start',
          //     }
          //   : {}),
        },
        [`& .${listItemTextClasses.root}`]: {
          color: 'text.secondary',
          [`& .${typographyClasses.root}`]: {
            fontSize: '0.75rem',
            textTransform: 'uppercase',
          },
        },
        ...props.sx,
      }}
    />
  );
};

export default SummaryList;
