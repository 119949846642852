import { useEffect, useState } from 'react';

export const useIsScrolledToBottom = (ref: React.RefObject<HTMLDivElement>) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const margin = 20;
    const handleScroll = (e: HTMLElementEventMap['scroll']) => {
      if (
        ref.current &&
        ref.current?.scrollHeight - ref.current?.scrollTop <=
          ref.current?.clientHeight + margin
      ) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    };

    ref.current?.addEventListener('scroll', handleScroll);
    return () => {
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isScrolledToBottom;
};
