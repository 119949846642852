import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export const useCountDownTilDate = (date?: Date | dayjs.Dayjs | null) => {
  const [time, setTime] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  useEffect(() => {
    if (!date) return;
    const interval = setInterval(() => {
      // update countdown
      const now = dayjs();
      const diffYears = dayjs(date).diff(now, 'year');
      const diffMonths = dayjs(date).diff(now, 'month') % 12;
      const diffDays = dayjs(date).diff(now, 'day') % 30;
      const diffHours = dayjs(date).diff(now, 'hour') % 24;
      const diffMinutes = dayjs(date).diff(now, 'minute') % 60;
      const diffSeconds = dayjs(date).diff(now, 'second') % 60;

      setTime({
        years: diffYears ?? 0,
        months: diffMonths ?? 0,
        days: diffDays ?? 0,
        hours: diffHours ?? 0,
        minutes: diffMinutes ?? 0,
        seconds: diffSeconds ?? 0,
      });
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [date]);

  return { time };
};
