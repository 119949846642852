import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isNil } from 'lodash';
import DialogCover from 'ui/icons/dialog/chat-dialog-cover.svg?react';
import MobileDialogCover from 'ui/icons/dialog/chat-mobile-dialog-cover.svg?react';
import SearchIcon from 'ui/icons/search.svg?react';
import ReactGA from 'react-ga4';
import { EventAction, Events } from 'analytics/ga4';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { useToast } from 'ui/components';
import { useUserList } from '../../../../services/users';
import ChatMemberSearchItem from 'ui/components/chat/ChatMemberSearchItem';
import { useCreate } from '../../../../services/chat';
import { formatApiErrorToast } from '../../../../services/apiUtils';

interface FormFields {
  search: string;
}

type UseMutationRes = ReturnType<typeof useCreate>;

const ActionCreateChat: React.FC<{
  children: ({
    onClick,
  }: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled?: boolean;
    'data-ga-event': 'action_create_chat_init';
    'data-ga-category': 'chat';
  }) => JSX.Element;
  onCreated?: (
    data: UseMutationRes['data'],
    variables: UseMutationRes['variables'],
    context: UseMutationRes['context'],
  ) => void;
}> = ({ children, onCreated }) => {
  const { t } = useTranslation(['chat', 'user', 'validation']);
  const [open, setOpen] = useState(false);

  const toast = useToast();
  const theme = useTheme();
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const validationSchema = useMemo(() => yup.object().shape({}), [t]);
  const { control, reset, watch } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  });
  const search = watch('search');
  const { mutate } = useCreate({
    onSuccess: (...params) => {
      ReactGA.event({
        action: EventAction.RequestSuccess,
        category: 'chat',
        label: 'action_create_chat_success',
      });
      setOpen(false);
      onCreated?.(...params);
    },
    onError: (error) => {
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onCreateChat = async (id: number) => {
    mutate({
      invitedUserIds: [id],
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    Events.ButtonClick(e);

    setOpen(true);
  };
  const onClose = () => {
    ReactGA.event({
      action: EventAction.ButtonClick,
      category: 'chat',
      label: 'action_create_chat_cancel',
    });
    reset();
    setOpen(false);
  };

  const { data } = useUserList(
    {
      page: 1,
      size: 8,
      keyword: search,
      sortBy: 'firstName',
      order: 'asc',
    },
    {
      enabled: !isNil(search) && search.length > 0,
    },
  );

  const users = useMemo(() => {
    return data?.content || [];
  }, [data]);

  return (
    <>
      {children({
        onClick: handleClick,
        // disabled: isLoadingPermission || isLoadingKyc,
        'data-ga-event': 'action_create_chat_init',
        'data-ga-category': 'chat',
      })}
      <BaseActionDialog open={open} onClose={onClose} cover={<DialogCover />}>
        <Stack height={'100%'} justifyContent={'center'}>
          <Box pt={8} textAlign={'center'}>
            {onTablet && (
              <Box textAlign={'center'}>
                <MobileDialogCover width={90} />
              </Box>
            )}
            <Typography
              variant={onTablet ? 'body1' : 'h5'}
              className="uppercase font-bold"
            >
              {t('title.createChat')}
            </Typography>
            <Typography
              variant={onTablet ? 'body2' : 'body1'}
              className="uppercase font-bold"
              gutterBottom
            >
              {t('message.createChat')}
            </Typography>
          </Box>
          <Stack width={'100%'} p={4} flex={1} spacing={2}>
            <Stack spacing={2}>
              <Controller
                name="search"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    placeholder={t('user:placeholder.searchUser')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    InputProps={{
                      startAdornment: (
                        <SvgIcon
                          sx={{
                            color: 'text.secondary',
                            mr: 1,
                          }}
                        >
                          <SearchIcon width={24} />
                        </SvgIcon>
                      ),
                    }}
                  />
                )}
              />
            </Stack>
            <Box
              maxHeight={{
                xs: 'calc(100dvh - 420px)',
                sm: 'unset',
              }}
              overflow={'auto'}
            >
              <Grid container spacing={1}>
                {users.map((user) => (
                  <Grid item xs={12} sm={4} md={3}>
                    <ChatMemberSearchItem
                      user={user}
                      onClick={(user) => {
                        onCreateChat(user.id);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Stack>
      </BaseActionDialog>
    </>
  );
};

export default ActionCreateChat;
