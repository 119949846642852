import { Theme, SwitchProps, SwitchClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<SwitchProps> = {
  // sx: ({ theme }) => ({
  //   width: 42,
  //   height: 26,
  //   padding: 0,
  //   '& .MuiSwitch-switchBase': {
  //     padding: 0,
  //     margin: 2,
  //     transitionDuration: '300ms',
  //     '&.Mui-checked': {
  //       transform: 'translateX(16px)',
  //       color: '#fff',
  //       '& + .MuiSwitch-track': {
  //         backgroundColor:
  //           theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
  //         opacity: 1,
  //         border: 0,
  //       },
  //       '&.Mui-disabled + .MuiSwitch-track': {
  //         opacity: 0.5,
  //       },
  //     },
  //     '&.Mui-focusVisible .MuiSwitch-thumb': {
  //       color: '#33cf4d',
  //       border: '6px solid #fff',
  //     },
  //     '&.Mui-disabled .MuiSwitch-thumb': {
  //       color:
  //         theme.palette.mode === 'light'
  //           ? theme.palette.grey[100]
  //           : theme.palette.grey[600],
  //     },
  //     '&.Mui-disabled + .MuiSwitch-track': {
  //       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     boxSizing: 'border-box',
  //     width: 22,
  //     height: 22,
  //   },
  //   '& .MuiSwitch-track': {
  //     borderRadius: 26 / 2,
  //     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
  //     opacity: 1,
  //     transition: theme.transitions.create(['background-color'], {
  //       duration: 500,
  //     }),
  //   },
  // }),
};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof SwitchClasses,
    'MuiSwitch',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }) => ({
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.main
              : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }),
  sizeMedium: ({ theme }) => ({
    width: 42,
    height: 26,
    '& .MuiSwitch-thumb': {
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
    },
  }),
  sizeSmall: ({ theme }) => ({
    width: 40,
    height: 24,
    '& .MuiSwitch-thumb': {
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: 24 / 2,
    },
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
