import { FC } from 'react';
import { Box, Divider, MenuList } from '@mui/material';
import ChatroomBar, { ChatroomBarSpacer } from './ChatroomBar';
import { useTranslation } from 'react-i18next';
import ChatListItem from './ChatListItem';
import { ChatListItem as ChatListItemType } from './type';
import useInfiniteScroll from 'react-infinite-scroll-hook';

type ChatroomBarProps = Parameters<typeof ChatroomBar>[0];

export const ChatList: FC<{
  chats: ChatListItemType[];
  action?: React.ReactNode;
  onClickChatRoom?: (chat: ChatListItemType) => void;
  loading?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  slotProps?: {
    header?: Partial<ChatroomBarProps>;
  };
}> = ({
  chats = [],
  onClickChatRoom,
  action,
  loading = false,
  hasNextPage = false,
  onLoadMore,
  slotProps,
}) => {
  const { t } = useTranslation('chat');

  const [loadingNextRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: () => {
      onLoadMore?.();
    },
  });

  return (
    <>
      <ChatroomBar
        title={t('title.chats')}
        action={action}
        {...slotProps?.header}
      />
      <ChatroomBarSpacer
        title={t('title.chats')}
        action={action}
        {...slotProps?.header}
      />
      <MenuList
        sx={{
          py: 0,
        }}
      >
        {chats.map(({ id, ...chatRoom }, index) => (
          <Box key={index}>
            <ChatListItem
              {...chatRoom}
              onClick={() => {
                onClickChatRoom?.({
                  id,
                  ...chatRoom,
                });
              }}
            />
            <Box px={2}>
              <Divider />
            </Box>
          </Box>
        ))}
      </MenuList>
      {!loading && hasNextPage && (
        <Box ref={loadingNextRef} height={0} width={'100%'} />
      )}
    </>
  );
};
