import {
  Theme,
  ChipProps,
  ChipClasses,
  IconButton,
  lighten,
  chipClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import CrossIcon from 'ui/icons/cross.svg?react';

export const defaultProps: Partial<ChipProps> = {
  deleteIcon: (
    <IconButton>
      <CrossIcon width={10} color="inherit" />
    </IconButton>
  ),
};

export const styleOverrides: Partial<
  OverridesStyleRules<keyof ChipClasses, 'MuiChip', Omit<Theme, 'components'>>
> = {
  root: ({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#252525' : '#F2F6FF',
    color: theme.palette.text.primary,
    borderRadius: 8,
  }),
  filled: ({ theme }) => ({
    [`&.${chipClasses.colorSuccess}`]: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },

    [`&.${chipClasses.colorError}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },

    [`&.${chipClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    [`&.${chipClasses.colorSecondary}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  }),
  outlined: ({ theme }) => ({
    [`&.${chipClasses.colorSuccess}`]: {
      backgroundColor: lighten(theme.palette.success.main, 0.9),
      color: theme.palette.success.main,
    },

    [`&.${chipClasses.colorError}`]: {
      backgroundColor: lighten(theme.palette.error.main, 0.9),
      color: theme.palette.error.main,
    },

    [`&.${chipClasses.colorPrimary}`]: {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      color: theme.palette.primary.main,
    },

    [`&.${chipClasses.colorSecondary}`]: {
      backgroundColor: lighten(theme.palette.secondary.main, 0.9),
      color: theme.palette.secondary.main,
    },
  }),
  deleteIcon: ({ theme }) => ({
    color: theme.palette.text.primary,
  }),
  sizeSmall: ({ theme }) => ({
    borderRadius: 4,
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
