import React from 'react';
import { RfqDetail, useUpdateRfqReadStatus } from '../../../services/brokers';

const ActionReadRfq: React.FC<{
  children: (props: { read: () => void }) => JSX.Element;
  onRead?: () => void;
  rfq: RfqDetail;
}> = ({ children, onRead, rfq }) => {
  const { mutate } = useUpdateRfqReadStatus({
    onSuccess: () => {
      onRead?.();
    },
  });

  const readRfq = async () => {
    if (!rfq || rfq.isRead) return;

    await mutate({
      rfqId: String(rfq.id),
      isRead: true,
    });
  };

  return (
    <>
      {children({
        read: readRfq,
      })}
    </>
  );
};

export default ActionReadRfq;
