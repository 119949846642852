import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CardHeader, Divider, SvgIcon } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SearchInput from '../SearchInput';
import SearchIcon from 'ui/icons/search.svg?react';
import { useUpdate } from '../../../../services/chat';
import { Bond } from '../../../../services/bonds';
import { ChatRefContext } from './ChatRefContext';
import { MessageType } from 'ui/components/chat/type';
import { formatBondName } from 'ui/utils';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';

const ActionSearchBond: React.FC<{
  chatId: number | string;
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onCanceled?: () => void;
}> = ({ chatId, children, onSuccess, onCanceled }) => {
  const { t } = useTranslation(['chat', 'bond']);
  const { chatRef } = useContext(ChatRefContext);
  const [open, setOpen] = useState(false);
  const [selectedBondName, setSelectedBondName] = useState<string | null>(null);

  const handleClick = () => {
    setOpen(true);
  };

  const { control } = useForm({
    defaultValues: {
      keyword: '',
    },
  });

  const onClose = () => {
    setOpen(false);
  };

  const { mutate } = useUpdate({
    onSuccess: async (data, variables) => {
      onSuccess?.();
      onClose();
      await chatRef.current?.sendMessage(
        String(chatId),
        t('message.operationChangeChatAnnouncementBond', {
          bond: selectedBondName,
        }),
        MessageType.OPERATION,
      );
      setSelectedBondName(null);
    },
  });

  const selectBond = useCallback((bond: Bond) => {
    setSelectedBondName(formatBondName(bond));
    mutate({
      id: Number(chatId),
      isin: bond.isin,
    });
  }, []);

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          onClose();
          onCanceled?.();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
        disableRestoreFocus
      >
        <CardHeader
          title={t('title.searchBond')}
          subheader={t('bond:placeholder.searchBond')}
        />
        <Divider />
        <Box
          p={{
            xs: 2,
            lg: 4,
          }}
        >
          <Controller
            name="keyword"
            control={control}
            render={({ field }) => (
              <SearchInput
                value={field.value}
                freeSolo
                placeholder={t('bond:placeholder.searchBond')}
                startAdornment={
                  <SvgIcon
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    <SearchIcon width={24} />
                  </SvgIcon>
                }
                onChange={(newValue) => {
                  if (typeof newValue === 'string') {
                    return;
                  }
                  selectBond(newValue as Bond);
                }}
                autoFocus
              />
            )}
          />
        </Box>
      </BaseActionDialog>
    </>
  );
};

export default ActionSearchBond;
