/* eslint-disable react-hooks/rules-of-hooks */
import { apiBaseClient, apiAuthClient } from '../services/apiClient';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  useSingIn as useSingInService,
  useSingInWithGoogle as useSingInWithGoogleService,
  useSingInWithLinkedin as useSingInWithLinkedinService,
  useSignOut as useSignOutService,
  useSignUp as useSignUpService,
  useCheckLogin as useCheckLoginService,
  useForgetPassword as useForgetPasswordService,
  useResetPassword as useResetPasswordService,
  useActivateUser as useActivateUserService,
  useGetPhoneNumberVerifyCode as useGetPhoneNumberVerifyCodeService,
  useUserInfo as useUserInfoService,
  useUserList as useUserListService,
  fetchUserInfo as fetchUserInfoService,
  useUpdateUserInfo as useUpdateUserInfoService,
  useUpdateUserPhoneNumber as useUpdateUserPhoneNumberService,
  checkLoginQuery as checkLoginQueryService,
  useResendVerificationLink as useResendVerificationLinkService,
  UpdateUserInfoParam,
} from 'services/user';

export type { UpdateUserInfoParam };

export const checkLoginQuery = (
  options?: UseQueryOptions<
    AxiosResponse<unknown>,
    AxiosError,
    unknown,
    string[]
  >,
) => checkLoginQueryService(apiAuthClient(), options);
export const useSingIn = useSingInService(apiBaseClient());
export const useSingInWithGoogle = useSingInWithGoogleService(apiBaseClient());
export const useSingInWithLinkedin =
  useSingInWithLinkedinService(apiBaseClient());
export const useSignOut = useSignOutService(apiAuthClient());
export const useSignUp = useSignUpService(apiBaseClient());
export const useCheckLogin = useCheckLoginService(apiAuthClient());
export const useForgetPassword = useForgetPasswordService(apiBaseClient());
export const useResetPassword = useResetPasswordService(apiBaseClient());
export const useActivateUser = useActivateUserService(apiBaseClient());
export const useGetPhoneNumberVerifyCode =
  useGetPhoneNumberVerifyCodeService(apiAuthClient());
export const useUserInfo = useUserInfoService(apiAuthClient());
export const fetchUserInfo = fetchUserInfoService(apiAuthClient());
export const useUpdateUserInfo = useUpdateUserInfoService(apiAuthClient());
export const useUpdateUserPhoneNumber =
  useUpdateUserPhoneNumberService(apiAuthClient());
export const useUserList = useUserListService(apiAuthClient());
export const useResendVerificationLink =
  useResendVerificationLinkService(apiAuthClient());
