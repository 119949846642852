import { Chip, chipClasses, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RfqDetail, RfqStatus } from 'services/broker';
import { thousandSeparated } from 'ui/utils';

const ColumnNetQuote: React.FC<{
  rfq: RfqDetail | undefined;
  disableHighlight?: boolean;
}> = ({ rfq, disableHighlight = false }) => {
  const { t } = useTranslation('rfq');
  if (!rfq) {
    return null;
  }

  switch (true) {
    case rfq?.status === RfqStatus.OrderCancelledByBlotter ||
      rfq?.status === RfqStatus.ClientRejected:
      return (
        <Typography
          fontSize={'inherit'}
          color={disableHighlight ? 'auto' : '#FFB024'}
        >
          {thousandSeparated(rfq?.netQuote)}
        </Typography>
      );
    case rfq?.status === RfqStatus.OrderUpdated &&
      rfq?.netQuote &&
      rfq?.prevQuoteNetPrice &&
      rfq?.netQuote !== rfq?.prevQuoteNetPrice:
      return (
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={1}
        >
          <Stack direction={'row'} spacing={1}>
            <Chip
              label={t('common:option.old')}
              size="small"
              sx={{
                textTransform: 'uppercase',
                [`& .${chipClasses.root}`]: {
                  backgroundColor: '#232525',
                },
                [`& .${chipClasses.label}`]: {
                  color: 'text.secondary',
                },
              }}
            />
            <Typography
              color={'textSecondary'}
              component={'span'}
              sx={{
                textDecoration: 'line-through',
              }}
            >
              {thousandSeparated(rfq?.prevQuoteNetPrice)}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <Chip
              label={t('common:option.new')}
              color="primary"
              size="small"
              sx={{
                textTransform: 'uppercase',
              }}
            />
            <Typography
              component={'span'}
              color={disableHighlight ? 'auto' : '#FFB024'}
            >
              {thousandSeparated(rfq?.netQuote)}
            </Typography>
          </Stack>
        </Stack>
      );
    case Boolean(rfq?.netQuote):
      return (
        <Typography
          fontSize={'inherit'}
          color={disableHighlight ? 'auto' : '#FFB024'}
        >
          {thousandSeparated(rfq?.netQuote)}
        </Typography>
      );
    case Boolean(rfq?.bestNetQuote):
      return (
        <Typography
          fontSize={'inherit'}
          color={disableHighlight ? 'auto' : '#FFB024'}
        >
          {thousandSeparated(rfq?.bestNetQuote)}
        </Typography>
      );
    default:
      return '-';
  }
};

export default ColumnNetQuote;
