import { createTheme } from '@mui/material/styles';
import { componentOverrides } from './common/componentOverrides';
import { typography } from './common/typography';
import { shape } from './common/shape';
import { breakpoints } from './common/breakpoints';
import tailwindTheme from './tailwindTheme';
import resolveConfig from 'tailwindcss/resolveConfig';
import { KeyValuePair } from 'tailwindcss/types/config';

const tailwind = resolveConfig({
  content: [],
  theme: tailwindTheme,
});

type TailwindColor = KeyValuePair<number, string>;
const primaryColor = tailwind.theme?.colors?.primary as TailwindColor;
const errorColor = tailwind.theme?.colors?.error as TailwindColor;
const warningColor = tailwind.theme?.colors?.warning as TailwindColor;
const infoColor = tailwind.theme?.colors?.info as TailwindColor;
const successColor = tailwind.theme?.colors?.success as TailwindColor;
const backgroundColor = tailwind.theme?.colors?.background as string;
const surfaceColor = tailwind.theme?.colors?.surface as string;

const primary = {
  main: primaryColor[500],
  contrastText: '#fff',
};

const secondary = {
  main: '#393939',
  contrastText: '#fff',
};

const text = {
  primary: '#fff',
  secondary: '#858B97',
  disabled: '#393939',
};

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary,
    secondary,
    error: {
      main: errorColor[500],
    },
    warning: {
      main: warningColor[500],
    },
    info: {
      main: infoColor[500],
    },
    success: {
      main: successColor[500],
    },
    background: {
      default: backgroundColor,
      paper: surfaceColor,
    },
    text,
  },
  typography,
  shape,
  breakpoints,
  components: { ...componentOverrides },
});

export default theme;
