import ToggleButton from './ToggleButton';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { get, isNil, pick } from 'lodash';
import { Chat, ChatList, ChatRoom, ModalLayout } from 'ui/components';
import {
  ChatListItem as ChatListItemType,
  ChatActionHandle as ComponentChatActionHandle,
  MessageType,
} from 'ui/components/chat/type';
import AidanNoSession from './Aidan';
import AidanFace from './AidanFace';
import AidanLegacy from '../Aidan/Aidan';
import AidanWithSession from './Aidan_v1.1.2';
import {
  serviceIsDisabled,
  Services,
} from '../../../../config/serviceIsDisabled';
import ChatCommandHandler, { ChatCommand } from './ChatCommandHandler';
import ActionCreateChat from './ActionCreateChat';
import PersonAddIcon from 'ui/icons/person-add.svg?react';
import {
  useIndexInfinite,
  useMessagesInfinite,
  useSendMessage,
  useReadMessage,
  useSummary,
} from '../../../../services/chat';
import paginatorReducer from 'ui/components/table/pageReducer';
import { StompClientContext } from '../../../../services/websocket';
import { ApiKey, Websocket as ChatWS } from 'services/chat';
import { useUserInfo } from '../../../../services/users';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import OpenNewIcon from 'ui/icons/open-new.svg?react';
import CloseIcon from '@mui/icons-material/Close';
import TerminalChatroomAnnouncement from './TerminalChatroomAnnouncement';
import { usePermittedV2 } from '../../../hooks/usePermissions';

const Aidan = serviceIsDisabled(Services.AIDAN_SESSION)
  ? AidanNoSession
  : AidanWithSession;

export type ChatActionHandle = {
  openChat: (chat: ChatListItemType) => void;
  sendMessage: (
    chatId: string | number,
    message: string,
    type?: MessageType,
  ) => Promise<void>;
};

type ToggleButtonProps = (typeof ToggleButton)['defaultProps'];

const AIDAN_ID = 'aidan';

const LatticefiChat = forwardRef<
  ChatActionHandle,
  {
    slotProps?: {
      toggleButton?: ToggleButtonProps;
    };
    marginRight?: number;
  }
>(({ slotProps, marginRight }, ref) => {
  const { t } = useTranslation(['chat', 'bond', 'aidan', 'country']);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const chatScrollContainerRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<ComponentChatActionHandle>(null);
  const chatButtonRef = useRef<HTMLButtonElement>(null);
  const { client: wsClient, connected } = useContext(StompClientContext);
  const queryClient = useQueryClient();

  const { data: userInfo } = useUserInfo();
  const currentUsername = userInfo?.username;

  //temp
  const [activeChatRoom, setActiveChatRoom] = useState<ChatListItemType | null>(
    null,
  );
  const [activateCommand, setActivateCommand] = useState<string>('');

  // Chat list
  // pagination
  const [{ rowsPerPage }] = useReducer(paginatorReducer, {
    page: 1,
    rowsPerPage: 20,
  });

  // infinite load
  const { data, isFetching, fetchNextPage, hasNextPage } = useIndexInfinite(
    {
      size: rowsPerPage,
      sortBy: 'lastMessageTime',
      order: 'desc',
    },
    {
      refetchOnWindowFocus: true,
    },
  );
  const chats = useMemo(() => {
    return (
      data?.pages.map((page) => page.content).flat() ||
      ([] as ChatListItemType[])
    );
  }, [data]);
  const { data: summary } = useSummary();
  const totalUnreadNumber = useMemo(() => {
    return summary?.totalUnreadNumber || 0;
  }, [summary]);

  const [permittedAidan1_3] = usePermittedV2(
    'aidan.summaryOfPortfolioAndWatchlist',
  );

  const chatListWithAidan = useMemo(
    () =>
      [
        serviceIsDisabled(Services.AIDAN) || !permittedAidan1_3
          ? null
          : {
              id: AIDAN_ID,
              title: 'Aidan',
              lastMessage: t('message.aidanGreeting'),
              unreadCount: 0,
              isPinned: true,
              avatar: <AidanFace size={30} faceSize={24} elevation={0} />,
            },
        ...chats,
      ].filter(Boolean) as ChatListItemType[],
    [chats, t, permittedAidan1_3],
  );

  const onSelectChatRoom = (chat: ChatListItemType) => {
    setActiveChatRoom(chat);
  };

  // Chat room
  const {
    data: messageRes,
    isFetching: isFetchingMessage,
    fetchNextPage: fetchNextPageMessage,
    hasNextPage: hasNextPageMessage,
  } = useMessagesInfinite(
    {
      id: String(activeChatRoom?.id),
      size: 100,
    },
    {
      enabled: !isNil(activeChatRoom?.id) && activeChatRoom?.id !== AIDAN_ID,
      refetchOnWindowFocus: true,
    },
  );
  const { mutateAsync: sendAsync } = useSendMessage();

  const { mutate: read } = useReadMessage(activeChatRoom?.id as string);

  const messages = useMemo(() => {
    return (
      messageRes?.pages
        .map((page) => page.messages.content)
        .flat()
        .reverse() || []
    );
  }, [messageRes]);

  const chatInfo = useMemo(() => {
    const info = pick(messageRes?.pages[0], [
      'title',
      'subtitle',
      'announcement',
    ]);

    return info as Pick<
      NonNullable<typeof messageRes>['pages'][0],
      'title' | 'subtitle' | 'announcement'
    >;
  }, [messageRes]);

  const unreadMessages = useMemo(() => {
    return messages.filter(
      (message) => message?.senderEmail !== currentUsername && !message?.isRead,
    );
  }, [messages, currentUsername]);

  const onSendMessage = useCallback(
    async (
      chatId: string | number,
      message: string,
      type: MessageType = MessageType.TEXT,
    ) => {
      if (isNil(chatId)) return;

      // if it starts with /, it's a command
      if (message.startsWith('/')) {
        const command = message.slice(1);

        // check if it's a valid command
        if (Object.values(ChatCommand).includes(command as ChatCommand)) {
          setActivateCommand(command);
          return;
        }
      }

      await sendAsync({
        chatId: String(chatId),
        message,
        messageType: type,
      });
    },
    [currentUsername],
  );

  // Chat room ref
  const openChatHandle = (chat: ChatListItemType) => {
    if (chatButtonRef.current) {
      setActiveChatRoom(chat);

      chatRef?.current?.openChatOn(chatButtonRef.current);
    }
  };

  // commands
  const commands = Object.values(ChatCommand);

  useImperativeHandle(
    ref,
    () => {
      return {
        openChat: openChatHandle,
        sendMessage: onSendMessage,
      };
    },
    [],
  );

  useEffect(() => {
    // reset scroll position when back to chat list
    if (chatScrollContainerRef.current && isNil(activeChatRoom)) {
      chatScrollContainerRef.current.scrollTop = 0;
    }
  }, [activeChatRoom]);

  useEffect(() => {
    if (connected && wsClient) {
      wsClient.subscribe(ChatWS.NewMessage.EVENT_KEY, (e) => {
        const data = ChatWS.NewMessage.eventHandler(e);

        queryClient.invalidateQueries([ApiKey, 'messages']);
        queryClient.invalidateQueries([ApiKey, 'index']);
        queryClient.invalidateQueries([ApiKey, 'summary']);
      });
    }
    return () => {
      if (wsClient) {
        wsClient.unsubscribe(ChatWS.NewMessage.EVENT_KEY);
      }
    };
  }, [connected]);

  useEffect(() => {
    if (unreadMessages.length > 0) {
      const unreadMessageIds = unreadMessages
        .map((message) => message?.id)
        .filter(Boolean)
        .map(String);

      read({
        messageIds: unreadMessageIds,
        isRead: true,
      });
    }
  }, [unreadMessages]);

  if (serviceIsDisabled(Services.CHAT) && !serviceIsDisabled(Services.AIDAN))
    return <AidanLegacy />;

  if (serviceIsDisabled(Services.CHAT)) return null;

  if (onMobile) {
    return (
      <div>
        {currentUsername && (
          <Chat ref={chatRef}>
            {({ openChat, closeChat, open }) => (
              <>
                <Chat.Button>
                  <ToggleButton
                    ref={chatButtonRef}
                    onClick={open ? closeChat : openChat}
                    open={open}
                    unreadNumber={totalUnreadNumber}
                    {...slotProps?.toggleButton}
                    slotProps={{
                      container: {
                        sx: {
                          marginRight: 0,
                          transition: (theme) =>
                            theme.transitions.create('margin', {
                              easing: theme.transitions.easing.easeOut,
                              duration:
                                theme.transitions.duration.enteringScreen,
                            }),
                          ...(marginRight && {
                            transition: (theme) =>
                              theme.transitions.create('margin', {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                  theme.transitions.duration.leavingScreen,
                              }),
                            marginRight: `${marginRight}px`,
                          }),
                        },
                      },
                    }}
                  />
                </Chat.Button>
                <ModalLayout
                  height={'100dvh'}
                  open={open}
                  slotProps={{
                    card: {
                      sx: {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                      },
                    },
                  }}
                >
                  {activeChatRoom?.id === AIDAN_ID ? (
                    activeChatRoom && (
                      <Box height={'100dvh'}>
                        <Aidan
                          onBack={() => {
                            setActiveChatRoom(null);
                          }}
                        />
                      </Box>
                    )
                  ) : isNil(activeChatRoom?.id) ? (
                    <ChatList
                      onClickChatRoom={onSelectChatRoom}
                      chats={chatListWithAidan}
                      loading={isFetching}
                      hasNextPage={Boolean(hasNextPage)}
                      onLoadMore={fetchNextPage}
                      action={
                        <Stack direction={'row'} spacing={1}>
                          <ActionCreateChat
                            onCreated={(data) => {
                              const createdChatId = data?.data.id;

                              if (createdChatId) {
                                openChatHandle({
                                  id: String(createdChatId),
                                  title: '',
                                  isPinned: false,
                                  avatar: null,
                                  unreadNumber: 0,
                                });
                              }
                            }}
                          >
                            {({ onClick }) => (
                              <IconButton
                                sx={{
                                  color: 'primary.contrastText',
                                }}
                                onClick={onClick}
                              >
                                <Box width={24} height={24}>
                                  <PersonAddIcon />
                                </Box>
                              </IconButton>
                            )}
                          </ActionCreateChat>
                          <IconButton
                            sx={{
                              color: 'primary.contrastText',
                            }}
                            onClick={() => {
                              closeChat();
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      }
                    />
                  ) : (
                    activeChatRoom && (
                      <Box height={'100dvh'}>
                        <ChatRoom
                          chatId={activeChatRoom?.id}
                          title={chatInfo.title}
                          subtitle={chatInfo.subtitle}
                          messages={messages}
                          announcement={
                            chatInfo?.announcement && (
                              <TerminalChatroomAnnouncement
                                announcement={chatInfo.announcement}
                              />
                            )
                          }
                          currentUsername={currentUsername}
                          onBack={() => {
                            setActiveChatRoom(null);
                          }}
                          onSendMessage={onSendMessage}
                          loading={isFetchingMessage}
                          hasNextPage={Boolean(hasNextPageMessage)}
                          onLoadMore={fetchNextPageMessage}
                          commands={commands}
                          slotProps={{
                            header: {
                              action: (
                                <IconButton
                                  sx={{
                                    color: 'primary.contrastText',
                                  }}
                                  onClick={() => {
                                    closeChat();
                                    setTimeout(() => {
                                      setActiveChatRoom(null);
                                    }, theme.transitions.duration.standard);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              ),
                            },
                          }}
                        />
                        <ChatCommandHandler
                          chatId={activeChatRoom?.id}
                          command={activateCommand}
                          onOperated={() => setActivateCommand('')}
                        />
                      </Box>
                    )
                  )}
                </ModalLayout>
              </>
            )}
          </Chat>
        )}
      </div>
    );
  }

  return (
    <div>
      {currentUsername && (
        <Chat ref={chatRef}>
          {({ openChat, closeChat, open }) => (
            <>
              <Chat.Button>
                <ToggleButton
                  ref={chatButtonRef}
                  onClick={open ? closeChat : openChat}
                  open={open}
                  unreadNumber={totalUnreadNumber}
                  {...slotProps?.toggleButton}
                  slotProps={{
                    container: {
                      sx: {
                        marginRight: 0,
                        transition: (theme) =>
                          theme.transitions.create('margin', {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                          }),
                        ...(marginRight && {
                          transition: (theme) =>
                            theme.transitions.create('margin', {
                              easing: theme.transitions.easing.sharp,
                              duration:
                                theme.transitions.duration.leavingScreen,
                            }),
                          marginRight: `${marginRight}px`,
                        }),
                      },
                    },
                  }}
                />
              </Chat.Button>
              <Chat.Content
                scrollContainerRef={chatScrollContainerRef}
                TransitionProps={{
                  onExited: () => {
                    setActiveChatRoom(null);
                  },
                }}
              >
                {activeChatRoom?.id === AIDAN_ID ? (
                  activeChatRoom && (
                    <Aidan
                      onBack={() => {
                        setActiveChatRoom(null);
                      }}
                    />
                  )
                ) : isNil(activeChatRoom?.id) ? (
                  <ChatList
                    onClickChatRoom={onSelectChatRoom}
                    chats={chatListWithAidan}
                    loading={isFetching}
                    hasNextPage={Boolean(hasNextPage)}
                    onLoadMore={fetchNextPage}
                    action={
                      <Stack direction={'row'} spacing={1}>
                        <IconButton
                          sx={{
                            color: 'primary.contrastText',
                          }}
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/app/chats`,
                              '_blank',
                            );
                            closeChat();
                          }}
                        >
                          <Box width={24} height={24}>
                            <OpenNewIcon />
                          </Box>
                        </IconButton>
                        <ActionCreateChat
                          onCreated={(data) => {
                            const createdChatId = data?.data.id;

                            if (createdChatId) {
                              openChatHandle({
                                id: String(createdChatId),
                                title: '',
                                isPinned: false,
                                avatar: null,
                                unreadNumber: 0,
                              });
                            }
                          }}
                        >
                          {({ onClick }) => (
                            <IconButton
                              sx={{
                                color: 'primary.contrastText',
                              }}
                              onClick={onClick}
                            >
                              <Box width={24} height={24}>
                                <PersonAddIcon />
                              </Box>
                            </IconButton>
                          )}
                        </ActionCreateChat>
                      </Stack>
                    }
                  />
                ) : (
                  activeChatRoom && (
                    <>
                      <ChatRoom
                        chatId={activeChatRoom?.id}
                        title={chatInfo.title}
                        subtitle={chatInfo.subtitle}
                        messages={messages}
                        announcement={
                          chatInfo?.announcement && (
                            <TerminalChatroomAnnouncement
                              announcement={chatInfo.announcement}
                            />
                          )
                        }
                        currentUsername={currentUsername}
                        onBack={() => {
                          setActiveChatRoom(null);
                        }}
                        onSendMessage={onSendMessage}
                        loading={isFetchingMessage}
                        hasNextPage={Boolean(hasNextPageMessage)}
                        onLoadMore={fetchNextPageMessage}
                        commands={commands}
                        slotProps={{
                          header: {
                            action: (
                              <IconButton
                                sx={{
                                  color: 'primary.contrastText',
                                }}
                                onClick={() => {
                                  window.open(
                                    `${window.location.origin}/app/chats`,
                                    '_blank',
                                  );
                                  closeChat();
                                }}
                              >
                                <Box width={24} height={24}>
                                  <OpenNewIcon />
                                </Box>
                              </IconButton>
                            ),
                          },
                        }}
                      />
                      <ChatCommandHandler
                        chatId={activeChatRoom?.id}
                        command={activateCommand}
                        onOperated={() => setActivateCommand('')}
                      />
                    </>
                  )
                )}
              </Chat.Content>
            </>
          )}
        </Chat>
      )}
    </div>
  );
});

export default LatticefiChat;
