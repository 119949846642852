import { FC } from 'react';
import {
  Avatar,
  Badge,
  badgeClasses,
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  MenuItemProps,
  SvgIcon,
} from '@mui/material';
import PinIcon from 'ui/icons/pin.svg?react';
import { formatInitialism } from 'ui/utils';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

const ChatListItem: FC<
  MenuItemProps & {
    title: string;
    avatar?: React.ReactNode;
    lastMessage?: string;
    lastMessageTime?: string;
    unreadNumber?: number;
    isPinned: boolean;
  }
> = ({
  title,
  avatar,
  lastMessage,
  lastMessageTime,
  unreadNumber = 0,
  isPinned,
  ...menuItemProps
}) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;

  const formatLastMessageTime = (time: string) => {
    const messageTime = dayjs(time);

    if (isNil(time) || !messageTime.isValid()) return '-';

    const now = dayjs();
    const diff = now.diff(messageTime, 'hour');

    if (diff < 24) {
      return currentLang
        ? messageTime.locale(currentLang.toLowerCase()).fromNow()
        : messageTime.fromNow();
    } else {
      return currentLang
        ? messageTime.locale(currentLang.toLowerCase()).format('MMM D')
        : messageTime.format('MMM D');
    }
  };

  return (
    <MenuItem
      sx={{
        px: 2,
        py: 2,
      }}
      {...menuItemProps}
    >
      <Box mr={2} ml={1}>
        {avatar ? (
          avatar
        ) : (
          <Avatar
            sx={{
              width: 30,
              height: 30,
              bgcolor: '#BBDEFB',
              color: (theme) => theme.palette.background.paper,
              fontSize: 12,
            }}
          >
            {formatInitialism(title)}
          </Avatar>
        )}
      </Box>
      <ListItemText
        primary={lastMessage ?? title}
        secondary={
          lastMessage
            ? `${title}` +
              (lastMessageTime
                ? ` • ${formatLastMessageTime(lastMessageTime)}`
                : '')
            : ''
        }
        primaryTypographyProps={{
          fontSize: 15,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        secondaryTypographyProps={{
          fontSize: 15,
        }}
      />
      {/* Spacer */}
      <Box>
        <Box width={32} height={32} position={'relative'}>
          <Box
            position={'absolute'}
            top={0}
            right={0}
            bottom={0}
            left={0}
            zIndex={20}
          >
            {!(unreadNumber > 0) && isPinned && (
              <IconButton disabled>
                <SvgIcon
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                >
                  <PinIcon />
                </SvgIcon>
              </IconButton>
            )}
            {unreadNumber > 0 && (
              <Badge
                variant="dot"
                color={'primary'}
                invisible={false}
                sx={{
                  [`& .${badgeClasses.badge}`]: {
                    top: 0,
                    right: 0 + 16,
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: '#F75D5F',
                  },
                }}
              >
                <Box width={32} height={'100%'}></Box>
              </Badge>
            )}
          </Box>
        </Box>
      </Box>
    </MenuItem>
  );
};

export default ChatListItem;
