import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const AidanLevels = [
  {
    label: 'Elementary', // t('aidan:options.elementary')
    emoji: '😀',
    level: 1,
  },
  {
    label: 'Intermediate', // t('aidan:options.intermediate')
    emoji: '⭐️',
    level: 2,
  },
  {
    label: 'Professional', // t('aidan:options.professional')
    emoji: '🚀',
    level: 3,
  },
];

export const useAidanLevels = () => {
  const { t } = useTranslation('aidan');

  const options = useMemo(() => {
    return AidanLevels.map((level) => ({
      ...level,
      label: t(`options.level.${level.label.toLowerCase()}`),
    }));
  }, [t]);

  return options;
};
