import dayjs from 'dayjs';

const getSettlementDate = (
  bond:
    | {
        assetIdentifier: string | undefined;
      }
    | undefined,
) => {
  const isUsBond =
    bond?.assetIdentifier?.startsWith('US') ||
    bond?.assetIdentifier?.startsWith('CA') ||
    false;

  const defaultDateDiff = isUsBond ? 1 : 2;

  let date = dayjs()
    .tz('Asia/Hong_Kong')
    .isAfter(dayjs().tz('Asia/Hong_Kong').startOf('day').add(18, 'hours'))
    ? dayjs().tz('Asia/Hong_Kong').endOf('day').add(1, 'day')
    : dayjs().tz('Asia/Hong_Kong').endOf('day');

  for (let i = 0; i < defaultDateDiff; i++) {
    date = date.add(1, 'day');
    if (date.day() === 0) {
      date = date.add(1, 'day');
    } else if (date.day() === 6) {
      date = date.add(2, 'day');
    }
  }

  return date;
};

export default getSettlementDate;
