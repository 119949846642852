import { ChatActionHandle } from './Chat';
import { createContext } from 'react';

export const ChatRefContext = createContext<{
  chatRef: React.RefObject<ChatActionHandle>;
  marginRight?: number;
  setMarginRight?: (marginRight: number) => void;
}>({
  chatRef: { current: null },
  marginRight: 0,
});
