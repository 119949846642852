import {
  Alert,
  AlertColor,
  AlertTitle,
  Paper,
  Slide,
  Snackbar,
  Stack,
} from '@mui/material';
import { get, isUndefined } from 'lodash';
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useCountDown } from 'ui/hooks';

type ToastItem = {
  title: string;
  message?: string;
  severity?: AlertColor;
  duration?: number | null;
  onClick?: () => void;
  'data-testid'?: string;
};

const ANIMATION_DURATION = 300;

export const SnackbarContext = createContext<{
  toasts: ToastItem[];
  addToast: (toast: {
    title: string;
    message?: string;
    options?: {
      severity?: AlertColor;
      duration?: number | null;
      onClick?: () => void;
    };
  }) => void;
  removeToast: (key: string) => void;
}>({
  toasts: [],
  addToast: () => {
    return;
  },
  removeToast: () => {
    return;
  },
});

const maxToast = 3;

const getKey = (item: ToastItem, index: number) => {
  return item.title + item.message;
};

export const SnackbarProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const addToast = ({
    title,
    message,
    options,
  }: {
    title: string;
    message?: string;
    options?: {
      severity?: AlertColor;
      duration?: number | null;
      onClick?: () => void;
    };
  }) => {
    setToasts((prev) => [
      {
        title,
        message,
        severity: options?.severity || 'info',
        duration: isUndefined(options?.duration) ? 5 : options?.duration,
        onClick: options?.onClick,
        'data-testid':
          get(options, 'data-testid') ||
          `toast-item-${options?.severity || 'info'}`,
      },
      ...prev.slice(0, maxToast - 1),
    ]);
  };

  const removeToast = (key: string) => {
    setToasts((prev) =>
      prev.filter((item, index) => {
        return getKey(item, index) !== key;
      }),
    );
  };

  useEffect(() => {
    if (toasts.length > 0) {
      setSnackbarOpen(true);
    } else {
      if (snackbarOpen) {
        setTimeout(() => {
          setSnackbarOpen(false);
        }, ANIMATION_DURATION);
      }
    }
  }, [toasts]);

  return (
    <SnackbarContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        transitionDuration={0}
        autoHideDuration={null}
      >
        <Stack flexDirection="column" spacing={1}>
          <TransitionGroup>
            {toasts.map((item, index) => (
              <Slide
                direction="left"
                key={getKey(item, index)}
                mountOnEnter
                unmountOnExit
                timeout={ANIMATION_DURATION}
              >
                <Toast itemKey={getKey(item, index)} item={item} />
              </Slide>
            ))}
          </TransitionGroup>
        </Stack>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

const Toast = forwardRef<HTMLDivElement, { item: ToastItem; itemKey: string }>(
  ({ item, itemKey }, ref) => {
    const { removeToast } = useContext(SnackbarContext);
    const { time, start, stop } = useCountDown(item.duration || 0);

    useEffect(() => {
      if (item.duration) {
        start();
      }
    }, []);

    useEffect(() => {
      if (time === 0) {
        removeToast(itemKey);
      }
    }, [time]);

    const handleClose = (
      event: React.SyntheticEvent | Event,
      reason?: string,
    ) => {
      if (reason === 'clickaway') {
        return;
      }
      removeToast(itemKey);
    };

    return (
      <Paper
        ref={ref}
        onClick={(e) => {
          if (item.onClick) {
            e.stopPropagation();
            item.onClick();
          }
        }}
        onMouseEnter={() => {
          if (item.onClick) {
            stop();
          }
        }}
        onMouseLeave={() => {
          if (item.onClick) {
            start();
          }
        }}
        sx={{
          cursor: item.onClick ? 'pointer' : 'default',
        }}
        {...(get(item, 'data-testid') && {
          'data-testid': get(item, 'data-testid'),
        })}
      >
        <Alert
          severity={item.severity}
          sx={{
            minWidth: 280,
            width: { xs: 1, md: 'auto' },
            mb: 1,
          }}
          onClose={handleClose}
        >
          <AlertTitle>{item.title}</AlertTitle>
          {item.message}
        </Alert>
      </Paper>
    );
  },
);

export const useToast = () => {
  const { addToast } = useContext(SnackbarContext);

  const addCommonToast = ({
    title,
    message,
    options,
  }: {
    title: string;
    message?: string;
    options?: {
      severity?: AlertColor;
      duration?: number | null;
      onClick?: () => void;
    };
  }) => {
    addToast({
      title,
      message,
      options,
    });
  };

  const addInfoToast = (
    title: string,
    message?: string,
    options?: Omit<ToastItem, 'title' | 'message' | 'severity'>,
  ) => {
    addToast({
      title,
      message,
      options: {
        severity: 'info',
        ...options,
      },
    });
  };

  const addSuccessToast = (
    title: string,
    message?: string,
    options?: Omit<ToastItem, 'title' | 'message' | 'severity'>,
  ) => {
    addToast({
      title,
      message,
      options: {
        severity: 'success',
        ...options,
      },
    });
  };

  const addErrorToast = (
    title: string,
    message?: string,
    options?: Omit<ToastItem, 'title' | 'message' | 'severity'>,
  ) => {
    addToast({
      title,
      message,
      options: {
        severity: 'error',
        ...options,
      },
    });
  };

  return {
    info: addInfoToast,
    message: addCommonToast,
    success: addSuccessToast,
    error: addErrorToast,
  };
};
