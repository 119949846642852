import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useNavigate,
} from 'react-router-dom';
import LatticeFi from './pages';
import RootLayout from './libs/layout/RootLayout';
import SignInLayout from './libs/layout/SignInLayout';
import NotFoundPage from './libs/layout/NotFoundPage';
import globalRouter from './utils/router';
import AnalyticsLayout from './libs/layout/AnalyticsLayout';
import { initialize } from 'analytics/ga4';
import redirectLoader from './libs/layout/RedirectLoader';
import routeBackAfterLoginLoader from './libs/layout/RouteBackAfterLoginLoader';
import nonAuthLoader from './libs/layout/NonAuthLoader';
import { useEffect } from 'react';
import authedLoader from './libs/layout/AuthedLoader';
import ApprovePrice from './pages/approve-price/page';
import ForgetPasswordLayout from './libs/layout/ForgetPasswordLayout';
import DiscourseSsoLoader from './libs/layout/DiscourseSsoLoader';
import ErrorPage from './libs/layout/ErrorPage';

const NavigatorLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return <>{children}</>;
};

const gaTrackingId: string = import.meta.env.VITE_APP_MEASUREMENT_ID || '';
if (gaTrackingId) {
  const isDev = import.meta.env.VIET_APP_ENV === 'dev';

  initialize(gaTrackingId, {
    debugMode: isDev,
  });
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <RootLayout>
          <NavigatorLayout>
            <AnalyticsLayout>
              <Outlet />
            </AnalyticsLayout>
          </NavigatorLayout>
        </RootLayout>
      }
    >
      <Route element={<Outlet />} errorElement={<ErrorPage />}>
        <Route path="/app/redirect" loader={redirectLoader} element={<div />} />
        <Route
          path="/app/approve-price"
          loader={routeBackAfterLoginLoader}
          element={<ApprovePrice />}
        />
        <Route loader={authedLoader} path="/app/*">
          {LatticeFi()}
        </Route>
        <Route loader={nonAuthLoader}>
          <Route
            element={
              <SignInLayout>
                <Outlet />
              </SignInLayout>
            }
          >
            <Route path="/login" lazy={() => import('./pages/login/page')} />
            <Route path="/sign" lazy={() => import('./pages/sign/page')} />
            <Route
              path="/rfq-quote"
              lazy={() => import('./pages/rfq-quote/page')}
            />
            <Route
              path="/rfq-order-feedback"
              lazy={() => import('./pages/rfq-order-feedback/page')}
            />
            <Route
              path="/kyc-feedback"
              lazy={() => import('./pages/kyc-feedback/page')}
            />
            <Route
              path="/activate"
              lazy={() => import('./pages/activate/page')}
            />
            <Route
              path="/rm-client-verify"
              lazy={() => import('./pages/rm-client-verify/page')}
            />
            <Route
              path="/client-verify"
              lazy={() => import('./pages/client-verify/page')}
            />
          </Route>
          <Route
            element={
              <ForgetPasswordLayout>
                <Outlet />
              </ForgetPasswordLayout>
            }
          >
            <Route
              path="/sign-success"
              lazy={() => import('./pages/sign-up-success/page')}
            />
            <Route
              path="/forget-password"
              lazy={() => import('./pages/forget-password/page')}
            />
          </Route>
        </Route>
        <Route
          element={
            <ForgetPasswordLayout>
              <Outlet />
            </ForgetPasswordLayout>
          }
        >
          <Route
            path="/change_password"
            lazy={() => import('./pages/change-password/page')}
          />
        </Route>
        <Route path="/terms" lazy={() => import('./pages/terms/page')} />
        <Route path="/privacy" lazy={() => import('./pages/privacy/page')} />
        <Route
          path="/discourse/sso"
          element={
            <SignInLayout>
              <Outlet />
            </SignInLayout>
          }
          loader={DiscourseSsoLoader}
        >
          <Route path="" lazy={() => import('./pages/discourse/sso/page')} />
        </Route>
        <Route path="/" element={<Navigate to="app" />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>,
  ),
);

function App() {
  useEffect(() => {
    const onVitePreloadError = (event: Event) => {
      window.location.reload(); //refresh the page
    };

    window.addEventListener('vite:preloadError', onVitePreloadError);

    return () => {
      window.removeEventListener('vite:preloadError', onVitePreloadError);
    };
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
