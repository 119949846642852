import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  inputBaseClasses,
  ListItem,
  listItemClasses,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
  typographyClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import {
  RfqDetail,
  useIndex as useBrokerIndex,
  useRFQOrder,
} from '../../../../services/brokers';
import { FilterSelect, ModalLayout, useToast } from 'ui/components';
import PlusIcon from 'ui/icons/plus.svg?react';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import { serviceIsDisabled } from '../../../../config/serviceIsDisabled';
import { Services } from '../../../../config/type';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import ColumnQuoteTime from '../../components/ColumnQuoteTime';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import ColumnAsset from '../../components/ColumnAsset';
import SummaryList from '../../components/SummaryList';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import SummaryLayoutAccordions from '../../components/SummaryLayoutAccordions';
import { rfqInputBaseProps } from '../../components/utils';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import SummarySection from '../../components/SummarySection';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import ColumnTax from './ColumnTax';
import ColumnPlatformFee from './ColumnPlatformFee';
import ColumnSettlementFee from './ColumnSettlementFee';
import InformationMark from 'ui/components/InformationMark';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import ColumnTaxRate from './ColumnTaxRate';
import RfqHeader from './RfqHeader';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';
import ChevronDownIcon from 'ui/icons/chevron-down.svg?react';
import ActionCancelRfq from './ActionCancelRfq';

const analyticsMetaProps = {
  'data-ga-event': 'action_accept_quote_init',
  'data-ga-category': 'trade',
  'data-testid': 'action-accept-quote',
};
const ActionAcceptQuote: React.FC<{
  children: (
    props: {
      onClick: () => void;
      disabled?: boolean;
    } & typeof analyticsMetaProps,
  ) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [brokerConfirmed, setBrokerConfirmed] = useState<boolean>(false);
  const [headerContainerRef, { height: headerHeight }] =
    useMeasure<HTMLDivElement>();
  const [expanded, setExpanded] = useState<string | false>('importantNotes');

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const toast = useToast();
  const navigate = useNavigate();

  const { data: brokerIndex } = useBrokerIndex({
    page: 1,
    size: 10000,
    status: 3,
  });
  const brokerOptions = useMemo(() => {
    if (!brokerIndex) return [];
    return brokerIndex?.content.map((broker) => ({
      label: `${broker.fi} ${broker.accountCode}`,
      value: broker.id,
    }));
  }, [brokerIndex]);
  const defaultBroker = useMemo(() => {
    if (!brokerIndex) return null;
    return brokerIndex?.content.find((broker) => broker.isDefault)?.id || null;
  }, [brokerIndex]);

  const defaultValues = useMemo(() => {
    return {
      quoteId: rfq?.quoteId || rfq?.bestQuoteId,
      price: rfq?.quote || rfq?.bestQuote,
      fiAccountId: defaultBroker || undefined,
      defaultNextTime: Boolean(defaultBroker),
      confirmTradeLegalAgreement: false,
      confirmPartialFillAgreement: false,
      confirmQuoteCheckAgreement: false,
    };
  }, [rfq, defaultBroker]);

  const { control, handleSubmit, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        quoteId: yup.number().required(),
        fiAccountId: yup.number().required(),
        defaultNextTime: yup.boolean(),
        confirmTradeLegalAgreement: yup
          .boolean()
          .required()
          // must be true
          .oneOf([true]),
        confirmPartialFillAgreement: yup
          .boolean()
          .required()
          // must be true
          .oneOf([true]),
        confirmQuoteCheckAgreement: yup
          .boolean()
          .required()
          // must be true
          .oneOf([true]),
      }),
    ),
  });
  const { isValid } = formState;

  const fiAccountId = useWatch({
    control: control,
    name: 'fiAccountId',
  });

  const selectedAccountName = useMemo(() => {
    if (!brokerOptions) return null;
    return brokerOptions?.find((option) => option.value === fiAccountId)?.label;
  }, [brokerOptions, fiAccountId]);

  const defaultNextTime = useWatch({
    control: control,
    name: 'defaultNextTime',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const { mutate, isLoading: isOrderingRFQ } = useRFQOrder({
    onSuccess() {
      toast.success(t('title.rfqOrderPlaced'), t('message.rfqOrderPlaced'));
      onClose();
      onSuccess?.();
    },
    onError: async (error, variables) => {
      toast.error(
        ...formatApiErrorToast(t, error.response?.data?.error?.message),
      );
    },
  });

  const onSubmit: SubmitHandler<{
    quoteId: number;
    price: number;
    fiAccountId: number;
    defaultNextTime: boolean;
  }> = (data) => {
    mutate({
      rfqId: rfq.id,
      ...data,
    });
  };

  const onSubmitError: SubmitErrorHandler<{
    quoteId: number;
    fiAccountId: number;
    defaultNextTime: boolean;
  }> = (data) => {
    console.error(data);
    toast.error(
      t('common:api.error.unknown.title'),
      t('common:api.error.unknown.message'),
    );
  };

  const onClose = () => {
    reset();
    setBrokerConfirmed(false);
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const renderActions = useCallback(
    (rfq: RfqDetail) =>
      !brokerConfirmed ? (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          {rfq?.netQuote && (
            <Button
              variant={fiAccountId === null ? 'text' : 'contained'}
              onClick={() => {
                setBrokerConfirmed(true);
              }}
              disabled={!isValid}
              size={onMobile ? 'large' : 'medium'}
              fullWidth={onMobile}
            >
              {t('action.acceptQuote')}
            </Button>
          )}
          {onMobile && (
            <ActionCancelRfq rfq={rfq} onCanceled={onClose}>
              {({ onClick }) => (
                <Button
                  variant="outlined"
                  fullWidth
                  size={onMobile ? 'large' : 'medium'}
                  onClick={onClick}
                >
                  {t('action.cancelOrder')}
                </Button>
              )}
            </ActionCancelRfq>
          )}
        </Stack>
      ) : (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          <Button
            key={'confirm_quote'}
            variant="contained"
            onClick={handleSubmit((data) => {
              onSubmit({
                ...data,
                fiAccountId: fiAccountId!,
              });
            }, onSubmitError)}
            size={onMobile ? 'large' : 'medium'}
            disabled={!isValid || isOrderingRFQ}
            fullWidth={onMobile}
          >
            {t('common:action.confirm')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setBrokerConfirmed(false);
            }}
            size={onMobile ? 'large' : 'medium'}
            disabled={isOrderingRFQ}
            fullWidth={onMobile}
          >
            {t('action.reselectBroker')}
          </Button>
        </Stack>
      ),
    [
      brokerConfirmed,
      brokerOptions,
      defaultBroker,
      defaultNextTime,
      fiAccountId,
      isOrderingRFQ,
      isValid,
      t,
      onMobile,
      rfq?.netQuote,
    ],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <Stack spacing={1} width={'100%'}>
              <Stack ref={headerContainerRef} spacing={1} width={'100%'}>
                <SummarySection>
                  <Stack spacing={1}>
                    <Stack spacing={1}>
                      <TypographyWithLoading loading={isLoadingData}>
                        <RfqHeader rfq={data} />
                      </TypographyWithLoading>
                      {!onMobile && renderActions(data)}
                    </Stack>
                  </Stack>
                </SummarySection>
                {/* Extra actions */}
                {rfq?.netQuote && (
                  <>
                    {/* <SummarySection>
                  <Typography variant="body2" color="text.secondary">
                    {t('tooltip.acceptQuoteCheck')}
                  </Typography>
                </SummarySection>
                {data?.isPartialFill && data?.size !== data?.initSize && (
                  <SummarySection>
                    <Typography variant="body2" color="text.secondary">
                      {t('tooltip.partialFill')}
                    </Typography>
                  </SummarySection>
                )} */}
                    <SummarySection>
                      <SummaryList
                        sx={{
                          [`& .${listItemClasses.root}`]: {
                            p: 0,
                            ...(onMobile
                              ? {
                                  flexDirection: 'column',
                                  alignItems: 'flex-start',
                                }
                              : {}),
                          },
                        }}
                      >
                        <ListItem>
                          {brokerConfirmed ? (
                            <Box flex={1}>
                              <Typography
                                fontSize={'0.75rem'}
                                fontWeight={'bold'}
                                lineHeight={'40px'}
                              >
                                {selectedAccountName}
                              </Typography>
                            </Box>
                          ) : (
                            <Controller
                              name="fiAccountId"
                              control={control}
                              render={({ field, fieldState }) => (
                                <ListItemText
                                  sx={{
                                    [`& .${inputBaseClasses.input}`]: {
                                      fontSize: '0.75rem',
                                      fontWeight: 'bold',
                                      textTransform: 'none',
                                    },
                                  }}
                                  primary={
                                    <FilterSelect
                                      placeholder={t('tooltip.selectAccount')}
                                      options={brokerOptions}
                                      {...field}
                                      defaultValue={defaultBroker}
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                      size={onMobile ? 'large' : 'medium'}
                                      {...rfqInputBaseProps}
                                      renderValue={(selected) => {
                                        if (!selected) {
                                          return t('tooltip.selectAccount');
                                        }

                                        return brokerOptions?.find(
                                          (option) => option.value === selected,
                                        )?.label;
                                      }}
                                      SelectProps={{
                                        displayEmpty: true,
                                      }}
                                    >
                                      {!serviceIsDisabled(
                                        Services.TRADE_BROKER_ADD_BROKER,
                                      ) && (
                                        <>
                                          {brokerOptions.length > 0 && (
                                            <Divider />
                                          )}
                                          <MenuItem
                                            disableRipple
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate({
                                                pathname:
                                                  '/app/settings/account/broker',
                                              });
                                            }}
                                          >
                                            <ListItemIcon>
                                              <SvgIcon>
                                                <PlusIcon width={24} />
                                              </SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText>
                                              {t('action.addBroker')}
                                            </ListItemText>
                                          </MenuItem>
                                        </>
                                      )}
                                    </FilterSelect>
                                  }
                                />
                              )}
                            />
                          )}
                          <Controller
                            name="defaultNextTime"
                            control={control}
                            render={({ field, fieldState }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    defaultChecked={defaultNextTime}
                                    disabled={
                                      fiAccountId === null || brokerConfirmed
                                    }
                                  />
                                }
                                sx={{
                                  whiteSpace: 'nowrap',
                                }}
                                label={t('options.defaultNextTime')}
                              />
                            )}
                          />
                        </ListItem>
                      </SummaryList>
                    </SummarySection>
                  </>
                )}
              </Stack>
              {rfq?.netQuote && (
                <div>
                  <Accordion
                    elevation={0}
                    sx={{
                      bgcolor: '#232525',
                      [`& .${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]:
                        {
                          minHeight: 'auto',
                        },
                    }}
                    expanded={expanded === 'importantNotes'}
                    onChange={handleExpand('importantNotes')}
                  >
                    <AccordionSummary
                      expandIcon={<ChevronDownIcon width={20} />}
                      sx={{
                        [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]:
                          {
                            m: '12px 0',
                          },
                      }}
                    >
                      <Typography fontSize={'inherit'} fontWeight={'bold'}>
                        {t('title.importantNotes')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        [`& .${formControlLabelClasses.root} .${formControlLabelClasses.label}.${formControlLabelClasses.disabled}`]:
                          {
                            color: 'text.primary',
                          },
                      }}
                    >
                      <Controller
                        name="confirmTradeLegalAgreement"
                        control={control}
                        render={({ field }) => (
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                  color={'primary'}
                                  checked={field.value}
                                  sx={{
                                    mr: 1,
                                  }}
                                />
                              }
                              label={t('message.rfqPolicy')}
                              sx={{
                                textAlign: 'left',
                                alignItems: 'start',
                              }}
                              disabled={brokerConfirmed}
                            />
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="confirmPartialFillAgreement"
                        control={control}
                        render={({ field }) => (
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                  color={'primary'}
                                  checked={field.value}
                                  sx={{
                                    mr: 1,
                                  }}
                                />
                              }
                              label={t('message.confirmPartialFillAgreement')}
                              sx={{
                                textAlign: 'left',
                                alignItems: 'start',
                              }}
                              disabled={brokerConfirmed}
                            />
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="confirmQuoteCheckAgreement"
                        control={control}
                        render={({ field }) => (
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                  color={'primary'}
                                  checked={field.value}
                                  sx={{
                                    mr: 1,
                                  }}
                                />
                              }
                              label={t('tooltip.acceptQuoteCheck')}
                              sx={{
                                textAlign: 'left',
                                alignItems: 'start',
                              }}
                              disabled={brokerConfirmed}
                            />
                          </FormControl>
                        )}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              <div>
                <Accordion
                  elevation={0}
                  sx={{
                    bgcolor: '#232525',
                    [`& .${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]:
                      {
                        minHeight: 'auto',
                      },
                  }}
                  expanded={!rfq?.netQuote || expanded === 'orderDetail'}
                  onChange={handleExpand('orderDetail')}
                >
                  <AccordionSummary
                    expandIcon={rfq?.netQuote && <ChevronDownIcon width={20} />}
                    sx={{
                      [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]:
                        {
                          m: '12px 0',
                        },
                    }}
                  >
                    <Typography fontSize={'inherit'} fontWeight={'bold'}>
                      {t('title.orderDetail')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SummaryList>
                      {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                      {/* <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                      <ListItem>
                        <ListItemText primary={t('bond:field.isin')} />
                        <TypographyWithLoading loading={isLoadingData}>
                          {data?.bond?.isin ?? '-'}
                        </TypographyWithLoading>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={`${t('field.size')}`} />
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnSize rfq={data} />
                        </TypographyWithLoading>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={`${t('field.price')}`} />
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnPrice rfq={data} />
                        </TypographyWithLoading>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={t('field.platformFee')} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <ColumnPlatformFee rfq={data} />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={t('field.settlementFee')} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <ColumnSettlementFee rfq={data} />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                      {data?.quoteExchangeRate !== 1 && (
                        <ListItem>
                          <ListItemText
                            primary={t('field.quoteExchangeRate')}
                          />
                          <Box>
                            <TypographyWithLoading loading={isLoadingData}>
                              <ColumnQuoteExchangeRate rfq={data} />
                            </TypographyWithLoading>
                          </Box>
                        </ListItem>
                      )}
                      <ListItem>
                        <ListItemText primary={t('field.tax')} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                            >
                              <ColumnTaxResidency rfq={data} />
                              <Box>
                                <ColumnTaxRate rfq={data} />
                              </Box>
                            </Stack>
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={t('field.settlementDate')} />
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnSettlementDate rfq={data} />
                        </TypographyWithLoading>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={t('bond:field.tradingRestrictionType')}
                        />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            {data?.bond?.tradingRestrictionType || '-'}
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                      {onMobile ? (
                        <>
                          <ListItem>
                            <ListItemText primary={`${t('field.orderedAt')}`} />
                            <Box>
                              <TypographyWithLoading loading={isLoadingData}>
                                <ColumnRequestTime rfq={data} />
                              </TypographyWithLoading>
                            </Box>
                          </ListItem>
                          <ListItem>
                            <ListItemText primary={`${t('field.quotedAt')}`} />
                            <Box>
                              <TypographyWithLoading loading={isLoadingData}>
                                <ColumnQuoteTime rfq={data} />
                              </TypographyWithLoading>
                            </Box>
                          </ListItem>
                        </>
                      ) : (
                        <ListItem>
                          <ListItemText
                            primary={`${t('field.orderedAt')} / ${t('field.quotedAt')}`}
                          />
                          <Box>
                            <TypographyWithLoading loading={isLoadingData}>
                              <ColumnRequestTime rfq={data} /> /{' '}
                              <ColumnQuoteTime rfq={data} />
                            </TypographyWithLoading>
                          </Box>
                        </ListItem>
                      )}
                    </SummaryList>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Stack>

            {onMobile && data && (
              <>
                <Box height={132} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),
    [
      rfq?.id,
      open,
      headerContainerRef,
      onMobile,
      renderActions,
      brokerConfirmed,
      selectedAccountName,
      control,
      expanded,
      t,
      brokerOptions,
      defaultBroker,
      navigate,
      defaultNextTime,
      fiAccountId,
    ],
  );

  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content?: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (!onMobile && !rfq.quote && !rfq.bestQuote) return null;

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick, ...analyticsMetaProps })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isOrderingRFQ) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick, ...analyticsMetaProps })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isOrderingRFQ) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionAcceptQuote;
