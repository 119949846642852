import * as React from 'react';
import ReactGA from 'react-ga4';
import { EventAction } from './type';

export default function buttonClick(
  e: React.MouseEvent<HTMLElement, MouseEvent>,
): void {
  // check if the button has a data-ga-event attribute
  const event = e.currentTarget.getAttribute('data-ga-event');
  const category = e.currentTarget.getAttribute('data-ga-category');

  if (event) {
    // send the event to GA
    ReactGA.event({
      category: 'button',
      action: EventAction.ButtonClick,
      label: event,
      ...(category && { category }),
    });
  }
}
