import ReactGA from 'react-ga4';

export function initialize(
  gaTrackingId: string,
  {
    debugMode = false,
  }: {
    debugMode?: boolean;
  },
): void {
  ReactGA.initialize(gaTrackingId, {
    gaOptions: {
      debug_mode: debugMode,
    },
    gtagOptions: {
      debug_mode: debugMode,
    },
  });

  // override ReactGA.event to remove title case formatting
  ReactGA.event = (optionsOrName, params) => {
    if (typeof optionsOrName === 'string') {
      ReactGA._gtag('event', optionsOrName, ReactGA._toGtagOptions(params));
    } else {
      const { action, category, label, value, nonInteraction, transport } =
        optionsOrName;
      if (!category || !action) {
        console.warn('args.category AND args.action are required in event()');

        return;
      }

      // Required Fields
      const fieldObject = {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
      } as {
        hitType: 'event';
        eventAction: string;
        eventCategory?: string;
        eventLabel?: string;
        eventValue?: number;
        nonInteraction?: boolean;
        transport?: string;
      };

      // Optional Fields
      if (label) {
        fieldObject.eventLabel = label;
      }

      if (typeof value !== 'undefined') {
        if (typeof value !== 'number') {
          console.warn('Expected `args.value` arg to be a Number.');
        } else {
          fieldObject.eventValue = value;
        }
      }

      if (typeof nonInteraction !== 'undefined') {
        if (typeof nonInteraction !== 'boolean') {
          console.warn('`args.nonInteraction` must be a boolean.');
        } else {
          fieldObject.nonInteraction = nonInteraction;
        }
      }

      if (typeof transport !== 'undefined') {
        if (typeof transport !== 'string') {
          console.warn('`args.transport` must be a string.');
        } else {
          if (['beacon', 'xhr', 'image'].indexOf(transport) === -1) {
            console.warn(
              '`args.transport` must be either one of these values: `beacon`, `xhr` or `image`',
            );
          }

          fieldObject.transport = transport;
        }
      }

      ReactGA._gaCommand('send', fieldObject);
    }
  };
}
