import { Palette, createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import tailwindTheme from '../tailwindTheme';
import resolveConfig from 'tailwindcss/resolveConfig';

const tailwind = resolveConfig({
  content: [],
  theme: tailwindTheme,
});

const defaultTheme = createTheme();

export const typography:
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions) = (theme) => ({
  fontFamily: (
    (tailwind.theme?.fontFamily?.inter as string[]) ??
    (tailwind.theme?.fontFamily?.sans as string[]) ??
    defaultTheme.typography.fontFamily
  ).join(','),
});
