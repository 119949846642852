import ToggleButton from './ToggleButton';
import AidanBody from './AidanBody';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  Services,
  serviceIsDisabled,
} from '../../../../config/serviceIsDisabled';
const Aidan = () => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (serviceIsDisabled(Services.AIDAN)) return null;

  return (
    <>
      {!onMobile && (
        <AidanBody>
          {({ onClick, open }) => (
            <ToggleButton onClick={onClick} open={open} />
          )}
        </AidanBody>
      )}
    </>
  );
};

export default Aidan;
