import { storageHasItem } from '../../core/storage';
import { StorageKey } from '../../utils/storage-key';
import { redirect } from 'react-router-dom';

const DiscourseSsoLoader = async ({ request }: { request: Request }) => {
  const searchParams = new URL(request.url).searchParams;
  const sso = searchParams.get('sso');
  const sig = searchParams.get('sig');

  if (sso && sig && !storageHasItem(StorageKey.token)) {
    // redirect to login page with redirect url
    const redirectUrl = request.url;

    // replace the & with %26
    const encodedRedirectUrl = redirectUrl.replace(/&/g, '%26');

    return redirect(`/login?redirect-url=${encodedRedirectUrl}`);
  }

  return null;
};

export default DiscourseSsoLoader;
