import { Theme, PopoverProps, PopoverClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<PopoverProps> = {
  container: () => document.querySelector('#modal-root') || document.body,
};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof PopoverClasses,
    'MuiPopover',
    Omit<Theme, 'components'>
  >
> = {
  paper: {
    boxShadow:
      '0px 8px 10px 0px #0000001A,0px 6px 30px 0px #0000000F,0px 16px 24px 0px #00000005;',
  },
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
