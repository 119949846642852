import { storageHasItem } from '../../core/storage';
import { useUserInfo } from '../../services/users';
import { StorageKey } from '../../utils/storage-key';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { checkGa4Initialized } from 'analytics/ga4';

const GA_TRACKING_ID = import.meta.env.VITE_APP_MEASUREMENT_ID || '';

const AnalyticsLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  const hasToken = storageHasItem(StorageKey.token);

  // const {
  //   data: userInfo,
  //   refetch,
  //   remove,
  // } = useUserInfo({
  //   enabled: hasToken,
  //   keepPreviousData: false,
  // });

  // useEffect(() => {
  //   if (userInfo) {
  //     checkGa4Initialized(GA_TRACKING_ID);
  //     ReactGA.gtag('set', 'user_properties', {
  //       email: userInfo.email,
  //     });
  //   }
  // }, [userInfo]);

  // useEffect(() => {
  //   if (!hasToken) {
  //     checkGa4Initialized(GA_TRACKING_ID);
  //     ReactGA.gtag('set', 'user_properties', {
  //       email: null,
  //     });
  //     remove();
  //   } else {
  //     refetch();
  //   }
  // }, [hasToken]);

  useEffect(() => {
    if (!GA_TRACKING_ID) return;
    checkGa4Initialized(GA_TRACKING_ID);
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      query_string: location.search,
    });
  }, [location]);

  return <>{children}</>;
};

export default AnalyticsLayout;
