import { fetchUserInfo } from '../../services/users';
import { redirect } from 'react-router-dom';

const userFinishProfileLoader = async ({ request }: { request: Request }) => {
  // check use info
  const user = await fetchUserInfo();

  if (!user?.firstname || !user?.lastname || !user?.company || !user?.title) {
    return redirect('/app/finish-profile');
  }

  return null;
};

export default userFinishProfileLoader;
