import { focusManager } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import Stomp, { Client, Frame } from 'webstomp-client';

const websocketURI =
  (import.meta.env.VITE_APP_URL || 'https://api-dev.latticefi.com') + '/ws';
const isProduction = import.meta.env.MODE === 'production';

export const StompClientContext = createContext<{
  client: Client | null;
  connected: boolean;
  failed: boolean;
}>({
  client: null,
  connected: false,
  failed: false,
});

export const StompClientProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [client, setClient] = useState<Client | null>(null);
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [failed, setFailed] = useState(false);

  const successCallback = (client: Client, frame?: Frame) => {
    console.log('Connected:', frame);
    setClient(client);
    setConnected(true);
    setConnecting(false);
    setFailed(false);
  };

  const connect = async () => {
    if (connecting) return;
    setConnecting(true);
    try {
      const socket = new SockJS(websocketURI);
      const newClient = Stomp.over(socket, {
        debug: !isProduction,
      });
      newClient.connect(
        {},
        (frame) => {
          successCallback(newClient, frame);
        },
        (error) => {
          if (error instanceof CloseEvent && error?.code === 1006) {
            console.log('code 1006', error);
            setConnected(false);
            setConnecting(false);
            setClient(null);
            return;
          }
          setConnected(false);
          setClient(null);
          console.log('Closed unexpectedly', error);
          throw error;
        },
      );
    } catch (error) {
      setConnecting(false);
      setFailed(true);

      if (retryCount < 5) {
        setTimeout(() => {
          console.log('Retry connect', retryCount);
          setRetryCount(retryCount + 1);
          connect();
        }, 1000);
      } else {
        setRetryCount(0);
      }
    }
  };

  focusManager.onFocus = () => {
    if (!client) {
      connect();
    }
  };

  useEffect(() => {
    connect();
    return () => {
      if (client) {
        client.disconnect();
      }
    };
  }, []);

  return (
    <StompClientContext.Provider value={{ client, connected, failed }}>
      {children}
    </StompClientContext.Provider>
  );
};
