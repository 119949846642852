import { RfqDetail } from '../../../services/brokers';
import { dateFormat } from 'ui/utils';

const ColumnCreatedAt: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (!rfq) {
    return null;
  }

  return dateFormat(rfq?.createdAt, 'DD MMM YYYY HH:mm');
};

export default ColumnCreatedAt;
