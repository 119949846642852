import './styles/main.css';
import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './i18n';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

let environment = 'unknown';

// TODO: This is dumb, find a better way to determine the environment
switch (import.meta.env.VITE_APP_CLIENT_URL) {
  case 'https://dev.latticefi.com/':
    environment = 'development';
    break;
  case 'https://app.latticefi.com/':
    environment = 'production';
    break;
}

Sentry.init({
  environment,
  dsn: 'https://e4bf0ec76edf55dfeec97a1682ba2ceb@o4507371109416960.ingest.us.sentry.io/4507371110924288',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/dev\.latticefi\.com/,
    /^https:\/\/app\.latticefi\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: false,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
