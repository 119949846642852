import type { Config } from 'tailwindcss';
import colors from 'tailwindcss/colors';

export default {
  extend: {
    colors: {
      primary: {
        50: '#eef4ff',
        100: '#d9e6ff',
        200: '#bcd5ff',
        300: '#8ebaff',
        400: '#5994ff',
        500: '#336dff',
        600: '#1b4af5',
        700: '#1436e1',
        800: '#172db6',
        900: '#192c8f',
        950: '#141d57',
      },
      secondary: '#393939',
      error: colors.red,
      warning: colors.yellow,
      info: colors.blue,
      success: {
        50: '#ecfdf6',
        100: '#d1fae8',
        200: '#a8f2d5',
        300: '#6fe6be',
        400: '#35d2a3',
        500: '#13c998',
        600: '#069572',
        700: '#05775e',
        800: '#075e4b',
        900: '#074d3f',
        950: '#022c25',
      },
      background: '#171A19',
      surface: '#1F2121',
      surfaceSecondary: '#252525',
      surfaceThird: '#232525',
      outline: '#393939',
    },
    fontFamily: {
      inter: [
        'Inter',
        'ui-sans-serif',
        'system-ui',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
    },
  },
  screens: {
    sm: '576px',
    md: '678px',
    lg: '992px',
    xl: '1200px',
  },
} as Config['theme'];
