import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  ButtonBase,
  buttonBaseClasses,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import AidanFace from './AidanFace';
import AidanAppBar from './AidanAppBar';
import ChatMessage, { ChatMessageContainer } from './ChatMessage';
import {
  useAidanChat,
  useAidanSessions,
  useAidanSessionHistoryInfinite,
  AidanChatResponse,
  AidanSessionHistoryItem,
} from '../../../../services/aidan/aidan';
import { isNil, isString } from 'lodash';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import { usePermittedV2 } from '../../../../libs/hooks/usePermissions';
import { ChatRoomInput } from 'ui/components/chat/ChatRoomInput';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useIsScrolledToBottom } from 'ui/hooks';
import Markdown from 'react-markdown';

const useAidanSuggestions = () => {
  const { t } = useTranslation('aidan');

  const [permittedAidan1_3] = usePermittedV2(
    'aidan.summaryOfPortfolioAndWatchlist',
  );
  const [permittedAidan4] = usePermittedV2('aidan.summaryOfInvestment');

  const options = useMemo(() => {
    return [
      ...(permittedAidan1_3
        ? [
            // t('options.suggestion.updateOfPortfolio'),
            // t('options.suggestion.updateOfPortfolioAndWatchlist'),
            // t('options.suggestion.importantAlertsOnWatchlist'),
            // 'What is the top 3 most quoted bonds today?',
            'What is the highest traded bond today?',
            'Which bonds are leading in performance this month?',
          ]
        : []),
      // ...(permittedAidan4 ? [t('options.suggestion.investmentSummary')] : []),
    ];
  }, [t]);

  return options;
};

const ChatInformationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : '',
}));

const SuggestionMessage: FC<{
  onClickSuggestion?: (message: string) => void;
}> = ({ onClickSuggestion }) => {
  const { t } = useTranslation('aidan');
  const [selected, setSelected] = useState<number | null>(null);

  const suggestions = useAidanSuggestions();

  return (
    <Stack spacing={1} alignItems={'flex-start'} mt={1} width={'100%'}>
      <ChatInformationTypography variant="body1" fontWeight={'bold'}>
        {t('message.startInteracting')}
      </ChatInformationTypography>
      {suggestions.map((message, index) => (
        <ButtonBase
          key={index}
          sx={{
            display: 'inline-block',
            borderRadius: '10px',
            opacity: !isNil(selected) ? 0.5 : 1,
            textWrap: 'wrap',
            [`&.${buttonBaseClasses.root}`]: {
              textAlign: 'left',
            },
          }}
          onClick={() => {
            setSelected(index);
            onClickSuggestion?.(message);
          }}
          disabled={!isNil(selected)}
        >
          <ChatMessage align="left">{message}</ChatMessage>
        </ButtonBase>
      ))}
    </Stack>
  );
};

const AnswerMessage: FC<AidanSessionHistoryItem> = ({ content }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation('aidan');
  const currentLang = i18n.resolvedLanguage;
  const isCurrentLocaleZh = useMemo(() => {
    return currentLang && Boolean(/^zh-*/.test(currentLang));
  }, [t]);

  const reply = content;

  const markDollarsColorInMarkdown = (text: string) => {
    // match numbers with thousands separators and decimal points
    const numberFormat = /-?\$(\d{1,3}(,\d{3})*(\.\d+)?)/g;
    return text.replace(numberFormat, (match) => {
      return `**${match}**`;
    });
  };

  return (
    <ChatMessage align={'left'}>
      <ChatMessageContainer align="left">
        <Markdown
          components={{
            // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
            strong(props) {
              const { node, style, children, ...rest } = props;

              // check if title is minus number
              if (
                children &&
                isString(children) &&
                children.match(/-\$(\d{1,3}(,\d{3})*(\.\d+)?)/)
              ) {
                return (
                  <strong
                    style={{
                      ...style,
                      color: isCurrentLocaleZh
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                    {...rest}
                    children={children}
                  />
                );
              } else if (
                children &&
                isString(children) &&
                children.match(/\$(\d{1,3}(,\d{3})*(\.\d+)?)/)
              ) {
                return (
                  <strong
                    style={{
                      ...style,
                      color: isCurrentLocaleZh
                        ? theme.palette.error.main
                        : theme.palette.success.main,
                    }}
                    {...rest}
                    children={children}
                  />
                );
              }

              return <strong {...rest}>{children}</strong>;
            },
          }}
        >
          {markDollarsColorInMarkdown(reply)}
        </Markdown>
      </ChatMessageContainer>
    </ChatMessage>
  );
};

const AidanBody: FC<{
  onBack?: () => void;
}> = ({ onBack }) => {
  const { t } = useTranslation('aidan');
  const chatroomRef = useRef<HTMLDivElement>(null);
  const chatroomBottomRef = useRef<HTMLDivElement>(null);
  const [chatRoomBarRef, { height }] = useMeasure<HTMLDivElement>();
  const [chatInputRef, { height: inputHeight }] = useMeasure<HTMLDivElement>();
  const [initialized, setInitialized] = useState(false);
  const [loadingMoreScrollPosition, setLoadingMoreScrollPosition] = useState(0);
  const isScrolledToBottom = useIsScrolledToBottom(chatroomRef);

  const { data: sessions } = useAidanSessions({
    page: 1,
    size: 1,
  });
  const lastSessionId = useMemo(() => {
    return sessions?.content[0]?.id;
  }, [sessions]);
  const {
    data: sessionHistory,
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
  } = useAidanSessionHistoryInfinite(
    lastSessionId!,
    {
      size: 20,
    },
    {
      enabled: !!lastSessionId,
    },
  );
  const historyMessages = useMemo(() => {
    return (
      sessionHistory?.pages
        .map((page) => page.content)
        .flat()
        .reverse() ?? []
    );
  }, [sessionHistory]);

  const { mutate: ask, isLoading: isThinking } = useAidanChat({
    cacheTime: 0,
  });

  const handleSendMessage = (message: string) => {
    console.log('handleSendMessage', message);
    ask({
      question: message,
      sessionId: lastSessionId,
    });
  };

  const [loadingNextRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: Boolean(hasNextPage),
    onLoadMore: () => {
      if (chatroomRef.current) {
        const scrollPositionFromBottom =
          chatroomRef.current?.scrollHeight - chatroomRef.current?.scrollTop;
        setLoadingMoreScrollPosition(scrollPositionFromBottom);
      }
      fetchNextPage();
    },
    rootMargin: '32px 0px',
  });

  const scrollToEnd = (
    behavior: 'auto' | 'smooth' | 'instant' = 'smooth',
    timeout = 0,
  ) => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior });
    }, timeout);
  };

  useEffect(() => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior: 'instant' });
    }, 0);
  }, []);

  useEffect(() => {
    if (!initialized && historyMessages.length > 0) {
      setInitialized(true);
      scrollToEnd('instant');
    } else {
      if (!isScrolledToBottom) {
        if (loadingMoreScrollPosition > 0) {
          chatroomRef.current?.scrollTo({
            top: chatroomRef.current?.scrollHeight - loadingMoreScrollPosition,
          });
        }

        return;
      }
      scrollToEnd('smooth', 100);
    }
  }, [historyMessages]);

  useEffect(() => {
    isScrolledToBottom && scrollToEnd('instant');
  }, [height]);

  return (
    <Stack id="aidanChat" height={'100%'} justifyContent={'flex-end'}>
      <AidanAppBar
        ref={chatRoomBarRef}
        onBack={() => {
          onBack?.();
        }}
      />
      <Stack
        height={`calc(100% - ${height}px)`}
        overflow={'hidden'}
        justifyContent={'flex-end'}
      >
        <Box
          ref={chatroomRef}
          flex={1}
          position={'relative'}
          sx={{
            overflowY: 'auto',
            // hide scrollbar
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            overscrollBehavior: 'contain',
          }}
          onScroll={(e) => e.stopPropagation()}
        >
          <Stack height={'100%'}>
            {hasNextPage && (
              <Box>
                {!loading ? (
                  <Box ref={loadingNextRef} height={32} width={'100%'} />
                ) : (
                  <Box height={32} width={'100%'} />
                )}
              </Box>
            )}
            <Stack flex={1} spacing={2} p={2} justifyContent={'flex-end'}>
              {historyMessages.map((message, index) =>
                message.isUser ? (
                  <ChatMessage key={index} align={'right'}>
                    {message.content}
                  </ChatMessage>
                ) : (
                  <AnswerMessage key={index} {...message} />
                ),
              )}
              {isThinking && (
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <AidanFace elevation={0} />
                  <Typography color={'text.secondary'} fontSize={16}>
                    {t('message.thinking')} {<ThinkingDots />}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {historyMessages.length === 0 && !loading && (
              <Box p={2}>
                <SuggestionMessage
                  onClickSuggestion={(message) => handleSendMessage(message)}
                />
              </Box>
            )}
            <Box ref={chatroomBottomRef} height={0} width={'100%'} />
          </Stack>
        </Box>
        {/* Spacer for chat input */}
        <Box>
          <Box height={inputHeight} />
        </Box>
      </Stack>
      <ChatRoomInput
        ref={chatInputRef}
        placeholder={t('placeholder.askAidan')}
        onCommit={handleSendMessage}
        disabled={isThinking}
      />
    </Stack>
  );
};

const ThinkingDots: FC = () => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev === 3 ? 0 : prev + 1));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span>{Array.from({ length: dots }).map(() => '.')}</span>;
};

export default AidanBody;
