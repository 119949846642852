import { MenuProps } from '@mui/material';
import MuiMenu from '@mui/material/Menu';
import { createContext, useContext, useState } from 'react';

const MenuContext = createContext<{
  anchorEl: HTMLElement | null;
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
}>({
  anchorEl: null,
  openMenu: () => {},
  closeMenu: () => {},
});

export const Menu: React.FC<{
  children: (props: {
    openMenu: (event: React.MouseEvent<HTMLElement>) => void;
    closeMenu: () => void;
    open: boolean;
  }) => React.ReactNode;
}> & {
  Button: React.FC<{ children: React.ReactNode }>;
  Items: React.FC<Omit<MenuProps, 'anchorEl' | 'open'>>;
} = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <MenuContext.Provider
      value={{
        anchorEl: anchorEl,
        openMenu: handleOpenMenu,
        closeMenu: handleCloseMenu,
      }}
    >
      {children &&
        children({
          openMenu: handleOpenMenu,
          closeMenu: handleCloseMenu,
          open,
        })}
    </MenuContext.Provider>
  );
};

Menu.Button = ({ children }) => {
  return <>{children}</>;
};

Menu.Items = (({ children, ...props }) => {
  const { anchorEl, closeMenu } = useContext(MenuContext);

  return (
    <MuiMenu
      onClose={closeMenu}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...props}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      {children}
    </MuiMenu>
  );
}) as React.FC<Omit<MenuProps, 'anchorEl' | 'open'>>;

export default Menu;
