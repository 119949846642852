import {
  Theme,
  PaginationItemProps,
  PaginationItemClasses,
  paginationItemClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<PaginationItemProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof PaginationItemClasses,
    'MuiPaginationItem',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    [`&.${paginationItemClasses.selected}`]: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
    [`&.${paginationItemClasses.ellipsis}`]: {
      aspectRatio: '1/1',
    },
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
