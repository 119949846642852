import { storageHasItem } from '../../core/storage';
import { StorageKey } from '../../utils/storage-key';
import { redirect } from 'react-router-dom';

const authedLoader = async ({ request }: { request: Request }) => {
  if (!storageHasItem(StorageKey.token)) {
    return redirect('/login');
  }

  return null;
};

export default authedLoader;
