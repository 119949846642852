import { isNaN, isNil, isString } from 'lodash';
import { numericFormatter } from 'react-number-format';

export function formatPrice(price: number | string | null | undefined) {
  if (isNil(price)) return null;
  if (isNaN(parseFloat(price.toString()))) return null;

  return numericFormatter(String(price), {
    thousandSeparator: true,
    decimalScale: 2,
    fixedDecimalScale: true,
  });
}

export function formatYield(yieldValue: number | string | null | undefined) {
  if (isNil(yieldValue)) return null;
  if (isNaN(parseFloat(yieldValue.toString()))) return null;

  return numericFormatter(String(yieldValue), {
    thousandSeparator: true,
    decimalScale: 2,
    fixedDecimalScale: true,
  });
}

export function formatCoupon(coupon: number | string | null | undefined) {
  if (isNil(coupon)) return null;
  if (isNaN(parseFloat(coupon.toString()))) return null;

  return numericFormatter(String(coupon), {
    thousandSeparator: true,
  });
}

export function thousandSeparated(number: number | string | null | undefined) {
  if (isNil(number)) return null;
  if (isNaN(parseFloat(number.toString()))) return null;

  return numericFormatter(String(number), {
    thousandSeparator: true,
  });
}

export function decimalPoint(numb: string, indexPoint = 2): string {
  return numericFormatter(String(numb), {
    thousandSeparator: true,
    decimalScale: indexPoint,
  });
}

export function formatNumberUnit(number: number | string | null | undefined) {
  if (isNil(number)) return null;
  if (isNaN(parseFloat(number.toString()))) return null;

  const value = isString(number) ? parseFloat(number) : number;

  const scale = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
    { value: 1e21, symbol: 'Z' },
    { value: 1e24, symbol: 'Y' },
  ];
  const i = scale.findIndex((item) => value < item.value);

  if (i === 0) {
    return thousandSeparated(value);
  }

  if (i === -1) {
    return thousandSeparated(value);
  }

  return (
    thousandSeparated(Number((value / scale[i - 1].value).toFixed())) +
    scale[i - 1].symbol
  );
}
