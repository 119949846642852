import dayjs from 'dayjs';
import { isNil } from 'lodash';

export function dateTimeFormat(
  date: string | Date | number | undefined | null | dayjs.Dayjs,
) {
  // check if date is dayjs
  if (dayjs.isDayjs(date)) return date.format('DD MMM YYYY HH:mm:ss');

  // check if date is valid
  if (!dayjs(date).isValid()) return '-';

  return dayjs(date).format('DD MMM YYYY HH:mm:ss');
}

export function dateFormat(
  date: string | Date | number | undefined | null | dayjs.Dayjs,
  format = 'DD MMM YYYY',
) {
  // check if date is dayjs
  if (dayjs.isDayjs(date)) return date.format(format);

  // check if date is valid
  if (isNil(date) || !dayjs(date).isValid()) return '-';

  return dayjs(date).format(format);
}

export function formatDateRange(
  dateRange: [number | null, number | null] | null | undefined,
) {
  if (!dateRange) return '';
  const [start, end] = dateRange;

  if (start && end) {
    return `${dayjs(start).format('DD MMM YYYY')} ~ ${dayjs(end).format('DD MMM YYYY')}`;
  } else if (start) {
    return `${dayjs(start).format('DD MMM YYYY')} ~ `;
  } else if (end) {
    return ` ~ ${dayjs(end).format('DD MMM YYYY')}`;
  } else {
    return '';
  }
}
