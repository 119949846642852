import { MouseEvent, useEffect, useState } from 'react';
import { StorageKey } from '../../../../utils/storage-key';
import i18n from '../../../../i18n';
import { localeOptions, fallbackLng } from '../../../../i18n/settings';
import Menu from 'ui/components/Menu';
import { ListItem, ListItemText, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useYupLocalization from '../../../../libs/utils/configYup';

const ActionSelectLanguage: React.FC<{
  children?: (props: {
    onClick: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
    selectedLang?: string;
  }) => React.ReactElement;
}> = ({ children }) => {
  const curLang = localStorage.getItem(StorageKey.language);
  const [lang, setLang] = useState(fallbackLng);
  useYupLocalization();

  useEffect(() => {
    const lang = localeOptions.find((l) => l.value === curLang);
    if (lang) {
      setLang(lang.value);
      i18n.changeLanguage(lang.value);
    }
  }, [curLang]);

  const onSelect = (value: string) => {
    setLang(value);
    localStorage.setItem(StorageKey.language, value);
    i18n.changeLanguage(value);
  };

  const selected = localeOptions.find((l) => l.value === lang)?.label;

  return (
    <Menu>
      {({ openMenu, closeMenu }) => (
        <>
          <Menu.Button>
            {children &&
              children({ onClick: openMenu, selectedLang: selected })}
          </Menu.Button>
          <Menu.Items
            className="mt-1"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {localeOptions.map((locale, index) => (
              <MenuItem
                key={locale.value}
                selected={locale.value === lang}
                onClick={() => {
                  onSelect(locale.value);
                  closeMenu();
                }}
                className="min-w-[120px]"
              >
                <ListItemText primary={locale.label} />
                {locale.value === lang ? <CheckIcon /> : undefined}
              </MenuItem>
            ))}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default ActionSelectLanguage;
