import { apiAuthClient } from '../../../../../../services/apiClient';
import { ApiConfig } from '../../../../../../services/bonds';

const loader = async ({ request }: { request: Request }) => {
  const searchParams = new URL(request.url).searchParams;

  const isins = searchParams.get('isins');

  if (isins) {
    const isinList = isins.split(',');

    const data = await apiAuthClient().get(ApiConfig.index, {
      params: {
        page: 1,
        size: isinList.length,
        isinList: isinList.join(','),
      },
    });

    if (data?.data?.content?.length > 0) {
      return {
        assets: data?.data?.content,
      };
    }
  }

  const res = await fetch(request);

  return res;
};

export default loader;
