import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, ApiKey } from './config';

interface PaymentParam {
  membership: 'Investor' | 'Professional' | 'Enterprise' | string;
  interval: 'year' | 'month' | string;
}

interface PaymentResponse {
  checkoutUrl: string;
}

export const usePayment =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<PaymentResponse>,
      AxiosError,
      PaymentParam,
      unknown
    >,
  ) =>
    useMutation({
      mutationKey: [ApiKey, 'payment'],
      mutationFn: (params) => axiosClient.post(ApiConfig.payment, params),
      ...options,
    });

interface MembershipPaymentResponse {
  membership: string;
  interval: string;
  expires: string;
  roles: string[];
}

export const useMembershipPayment =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      AxiosResponse<MembershipPaymentResponse>,
      AxiosError,
      MembershipPaymentResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'membershipPayment'],
      queryFn: () =>
        axiosClient.get(ApiConfig.membershipPayment).then((res) => res.data),
      ...options,
    });
