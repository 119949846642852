import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { numericFormatter } from 'react-number-format';
import { RfqDetail } from 'services/broker';

const ColumnPlatformFee: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  const { t } = useTranslation();
  const fee = rfq?.platformFee ?? rfq?.bestPlatformFee;

  if (rfq?.quote && !rfq?.enablePlatformFee) {
    return t('message.platformFeeAbsorbed');
  }

  if (isNumber(fee)) {
    return `USD ${numericFormatter(String(fee), {
      thousandSeparator: ',',
      decimalScale: 2,
      fixedDecimalScale: true,
    })}`;
  }

  return '-';
};

export default ColumnPlatformFee;
