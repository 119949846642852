import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useApproveOutlierPrice } from '../../services/bonds';
import { useEffect, useState } from 'react';

export default function Page() {
  const { t } = useTranslation('bond');
  const [searchParams] = useSearchParams();
  const bondPriceId = searchParams.get('bond-price-id');
  const [error, setError] = useState<string | null>(null);

  const { mutate, isLoading } = useApproveOutlierPrice({
    onError: (error) => {
      const message = error?.response?.data?.error?.message
        ? t([
            `${error?.response?.data?.error?.message}.title`,
            'common:api.error.unknown.title',
          ])
        : t('common:api.error.unknown.title');

      setError(message);
    },
  });

  useEffect(() => {
    if (bondPriceId) {
      mutate({
        id: bondPriceId,
      });
    }
  }, []);

  if (!bondPriceId) {
    return (
      <Stack
        spacing={{
          xs: 2,
          xl: 4,
        }}
        className="items-center w-full h-screen text-center justify-center"
      >
        <Typography
          variant="h5"
          fontWeight={'bold'}
          textTransform={'uppercase'}
        >
          {t('message.noPriceMoverIdProvided')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      spacing={{
        xs: 2,
        xl: 4,
      }}
      className="items-center w-full h-screen text-center justify-center"
    >
      <Typography variant="h5" fontWeight={'bold'} textTransform={'uppercase'}>
        {t('action.confrimOutlierPrice')}
      </Typography>

      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <Typography variant="body1" fontWeight={'bold'} color="primary.main">
          {t('title.confrimOutlierPriceSuccess')}
        </Typography>
      )}
    </Stack>
  );
}

export const Component = Page;
