import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, BoxProps, Card, Paper, Stack, useTheme } from '@mui/material';

export const ChatMessageContainer: React.FC<
  BoxProps & {
    align?: 'left' | 'right' | 'none';
  }
> = ({ children, sx, align = 'none', ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        boxShadow:
          theme.palette.mode === 'dark' ? 'none' : '2px 2px 15px 0px #0000001A',
        border: (theme) =>
          theme.palette.mode === 'dark'
            ? `1px solid ${theme.palette.divider}`
            : '1px solid #00000024',
        px: '16px',
        py: '12px',
        borderRadius: '10px',
        fontSize: '14px',

        backgroundColor:
          align === 'left' || align === 'none'
            ? theme.palette.background.paper
            : theme.palette.primary.main,
        color:
          align === 'left' || align === 'none'
            ? theme.palette.text.primary
            : theme.palette.common.white,
        borderBottomLeftRadius:
          align === 'none' ? '10px' : align === 'left' ? '0px' : '10px',
        borderBottomRightRadius:
          align === 'none' ? '10px' : align === 'left' ? '10px' : '0px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const ChatMessage: React.FC<{
  align?: 'left' | 'right' | 'none';
  children: React.ReactNode;
}> = ({ children, align = 'none' }) => {
  const isChildrenString = typeof children === 'string';

  return (
    <Stack
      direction={'row'}
      justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
    >
      {isChildrenString ? (
        <ChatMessageContainer align={align}>
          <Typography fontSize={'inherit'} color={'inherit'}>
            {children}
          </Typography>
        </ChatMessageContainer>
      ) : (
        <>{children}</>
      )}
    </Stack>
  );
};

export default ChatMessage;
