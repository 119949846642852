import {
  MenuItem,
  IconButton,
  TextField,
  BaseTextFieldProps,
  SvgIcon,
  Checkbox,
  svgIconClasses,
  Stack,
  Avatar,
  selectClasses,
  SvgIconProps,
  InputAdornment,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowChevronDownIcon from 'ui/icons/chevron-down.svg?react';
import { ChangeEvent, forwardRef } from 'react';
import { every, isArray, isString } from 'lodash';

interface FilterOption {
  label: React.ReactNode;
  value: unknown;
}

interface Props extends BaseTextFieldProps {
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  options?: FilterOption[];
  label?: string;
  multiple?: boolean;
  clearable?: boolean;
  renderValue?: (selected: unknown) => React.ReactNode;
  elevation?: number;
  displayDropdownIcon?: boolean;
  withCount?: boolean;
}

const FilterSelect = forwardRef<
  HTMLDivElement,
  Props & { children?: React.ReactNode }
>(
  (
    {
      options = [],
      label,
      clearable = false,
      multiple,
      children,
      renderValue: renderItemValue,
      elevation = 1,
      defaultValue,
      displayDropdownIcon = true,
      withCount = false,
      ...props
    },
    ref,
  ) => {
    const optionSelected = (option: FilterOption) => {
      if (multiple) {
        return (props?.value as unknown[])?.includes(option.value);
      } else {
        return (props?.value as string | number) === option.value;
      }
    };

    const renderValue = (selected: unknown) => {
      if (multiple) {
        if (isArray(selected) && selected.length === 0) {
          return props.placeholder;
        }
        const labels = (selected as unknown[]).map((item) => {
          const option = options.find((option) => option.value === item);

          if (renderItemValue) {
            return renderItemValue(option);
          }

          return option?.label;
        });

        if (every(labels, (label) => isString(label))) {
          return `${props.placeholder && !label ? props.placeholder + ' ' : ''}${labels.join(', ')}`;
        } else {
          return (
            <Stack direction={'row'} spacing={1}>
              {props.placeholder && !label && <span>{props.placeholder}</span>}
              {labels}
            </Stack>
          );
        }
      } else {
        if (renderItemValue) {
          return renderItemValue(selected);
        }

        return (
          (props.placeholder && !label ? props.placeholder + ' ' : '') +
          (options.find((option) => {
            return String(option.value) === String(selected);
          })?.label || '')
        );
      }
    };

    const noSelected =
      (multiple && (props?.value as string[])?.length === 0) ||
      (!multiple && !(props?.value as string));

    return (
      <TextField
        ref={ref}
        label={label}
        defaultValue={defaultValue}
        {...props}
        select
        SelectProps={{
          multiple,
          ...(clearable
            ? {
                endAdornment: noSelected ? null : (
                  <InputAdornment position="end">
                    <Stack direction={'row'} alignItems={'center'}>
                      {withCount && (
                        <Avatar
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            color: (theme) =>
                              theme.palette.primary.contrastText,
                            width: 20,
                            height: 20,
                            fontSize: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {(props?.value as string[])?.length}
                        </Avatar>
                      )}
                      <IconButton
                        onClick={() => {
                          props.onChange?.({
                            target: {
                              value: multiple
                                ? defaultValue ?? []
                                : defaultValue ?? '',
                            },
                          });
                        }}
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Stack>
                  </InputAdornment>
                ),

                IconComponent:
                  !clearable || noSelected ? CustomDropDownIcon : null,
              }
            : {
                ...(displayDropdownIcon && {
                  IconComponent: CustomDropDownIcon,
                }),
              }),
          defaultValue: defaultValue,
          MenuProps: {
            elevation,
            sx: {
              '& .MuiMenu-paper': {
                maxHeight: 360,
              },
            },
          },
          renderValue,
          ...props.SelectProps,
          sx: {
            [`& .${svgIconClasses.root}`]: {
              color: 'text.secondary',
            },
            [`& .${selectClasses.select}.${inputBaseClasses.input}.${outlinedInputClasses.input}`]:
              {
                pr: !clearable || noSelected ? 5 : 2,
              },
            ...props.SelectProps?.sx,
          },
        }}
        sx={{
          [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]: {
            ...(!clearable || noSelected ? {} : { pr: 0 }),
          },
          ...props?.sx,
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {multiple && (
              <Checkbox
                checked={optionSelected(option)}
                sx={{
                  p: 0,
                  py: 1,
                  pr: 1,
                }}
              />
            )}
            {option.label}
          </MenuItem>
        ))}
        {children}
      </TextField>
    );
  },
);

function CustomDropDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <ArrowChevronDownIcon width={24} />
    </SvgIcon>
  );
}

export default FilterSelect;
