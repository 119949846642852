import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import AppBarLogo from './AppBarLogo';
import NavigationMenu from './NavigationMenu';
import {
  Slide,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import Favicon from 'react-favicon';
import { NotificationContext } from 'ui/components';
import { useNotificationSummary } from './useNotificationSummary';
import { ChatRefContext } from '../ui/components/Chat/ChatRefContext';
import Chat from '../ui/components/Chat/Chat';
import { useTranslation } from 'react-i18next';

export default function AppLayout({ children }: { children: React.ReactNode }) {
  useTranslation(['chat', 'validation']);
  const navigate = useNavigate();
  const theme = useTheme();
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { alertCount } = useContext(NotificationContext);
  useNotificationSummary();
  const { chatRef, marginRight } = useContext(ChatRefContext);

  const trigger = useScrollTrigger();
  return (
    <>
      <Favicon
        url="/logo.webp"
        renderOverlay={
          alertCount > 0
            ? (
                canvas: HTMLCanvasElement,
                context: CanvasRenderingContext2D,
              ) => {
                const iconSize = 16;
                // const radius = iconSize / 4;
                const radius = 3.5;
                const centerX = radius;
                const centerY = radius;

                context.beginPath();
                context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
                context.fillStyle = 'red';
                context.fill();
                context.lineWidth = 1;
                context.strokeStyle = '#000';
                context.stroke();
              }
            : // empty overlay
              () => {}
        }
      />
      <Slide appear={false} direction="down" in={onTablet || !trigger}>
        <AppBar
          position="fixed"
          data-testid="app-bar"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Stack
              direction="row"
              width={'100%'}
              justifyContent={'space-between'}
            >
              <AppBarLogo
                onClick={() => {
                  navigate('/');
                }}
              />
              <Stack justifyContent={'center'}>
                <NavigationMenu />
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar />
      <Box className="bg-white dark:bg-background flex-1 overflow-y-auto snap-y snap-mandatory">
        {children}
      </Box>
      <Chat ref={chatRef} marginRight={marginRight} />
    </>
  );
}
