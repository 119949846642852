import { RfqDetail } from 'services/broker';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const low = (text: string) => (
  <Typography
    component={'span'}
    fontSize={'inherit'}
    color={'text.secondary'}
    whiteSpace={'nowrap'}
  >
    {text}
  </Typography>
);

const ColumnPriceCalculation: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  const { t } = useTranslation(['rfq', 'bond']);

  // Net price = (base price +（(settlement fee + platform fee)/(size/issue price))) * (1+ tax rate）
  return (
    <Typography fontSize={'inherit'} whiteSpace={'nowrap'}>
      {t('field.netQuote')}
      {low(' = ( ')}
      {t('field.price')}
      {rfq?.quoteExchangeRate === 1 ? low(' + ( ') : low(' + (( ')}
      {t('field.platformFee')}
      {low(' + ')}
      {t('field.settlementFee')}
      {rfq?.quoteExchangeRate === 1 ? (
        low(' ) / ( ')
      ) : (
        <>
          {low(' ) * ')}
          {t('field.quoteExchangeRate')}
          {low(' ) / ( ')}
        </>
      )}
      {t('field.size')}
      {low(' / ')}
      {100}
      {low(' )) * ( ')}
      {1}
      {low(' + ')}
      {t('field.taxRate')}
      {low(' )')}
    </Typography>
  );
};

export default ColumnPriceCalculation;
