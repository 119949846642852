export const ApiKey = 'brokers';
export const ApiConfig = {
  index: 'user/fi-accounts',
  addBrokerAccount: () => 'user/fi-accounts',
  deleteBrokers: (id: string) => `user/fi-accounts/${id}`,
  uploadBrokerAccountStatement: (id?: string) =>
    `user/fi-accounts/${id}/statement`,
  searchRFQ: 'rfq',
  rfqDetail: (id: string) => `rfq/${id}`,
  summary: 'rfq/summary',
  brokers: 'fi',
  addRFQ: 'rfq',
  rfqQuotes: (id: string) => `rfq/${id}/quotes`,
  rfqOrder: (id: string) => `rfq/${id}/order`,
  getQuote: () => 'rfq/quote',
  submitQuote: () => 'rfq/quote',
  updateQuote: (rfqId: string, quoteId: string) =>
    `rfq/${rfqId}/quote/${quoteId}`,
  cancelQuote: (rfqId: string, quoteId: string) =>
    `rfq/${rfqId}/quote/${quoteId}/cancel`,
  getOrderFeedback: () => 'rfq/order-feedback',
  submitOrderFeedback: (id: string) => `rfq/${id}/order-feedback`,
  cancelRFQ: (id: string) => `rfq/${id}/cancel`,
  uncancelRFQ: (id: string) => `rfq/${id}/uncancel`,
  placeQuote: (id: string) => `rfq/${id}/quote`,
  updateRfqReadStatus: (id: string) => `/rfq/${id}/read-status`,
  updateOrder: (rfqId: string, orderId: string) =>
    `/rfq/${rfqId}/order/${orderId}`,
  updateQuoteMeta: (rfqId: string, quoteId: string) =>
    `/rfq/${rfqId}/quote/${quoteId}/additional-info`,
  rfqNetPrice: (id: string) => `rfq/${id}/net-price`,
};
