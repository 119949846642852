import { isNil } from 'lodash';

export const isDivisibleBy =
  (divisor: number | undefined) => (dividend: number | undefined) => {
    if (isNil(divisor) || divisor === 0) {
      return true;
    }
    if (isNil(dividend)) {
      return true;
    }
    return !!dividend && !!divisor && (dividend / divisor) % 1 === 0;
  };
