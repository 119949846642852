import {
  CSSObject,
  Theme,
  ToggleButtonClasses,
  ToggleButtonProps,
  toggleButtonClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import tailwindTheme from '../../tailwindTheme';
import resolveConfig from 'tailwindcss/resolveConfig';

const tailwind = resolveConfig({
  content: [],
  theme: tailwindTheme,
});

export const defaultProps: Partial<ToggleButtonProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof ToggleButtonClasses,
    'MuiToggleButton',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }): CSSObject => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? (tailwind.theme?.colors?.surfaceThird as string)
        : '#F2F6FF',
    borderColor:
      theme.palette.mode === 'dark'
        ? (tailwind.theme?.colors?.surfaceThird as string)
        : '#F2F6FF',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.text.secondary
        : theme.palette.primary.main,

    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? tailwind.theme?.colors?.surfaceThird
          : '#F2F6FF',
      borderColor:
        theme.palette.mode === 'dark'
          ? tailwind.theme?.colors?.surfaceThird
          : '#F2F6FF',
      opacity: 0.8,
    },

    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
