import {
  CSSInterpolation,
  CSSObject,
  Theme,
  darken,
  lighten,
} from '@mui/material';

export const styleOverrides:
  | string
  | CSSObject
  | ((theme: Omit<Theme, 'components'>) => CSSInterpolation) = (theme) => ({
  '*::-webkit-scrollbar': {
    backgroundColor: theme.palette.background.default,
    width: 16,
    height: 16,
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.default,
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.paper, 0.2)
        : darken(theme.palette.background.paper, 0.2),
    border: `4px solid ${theme.palette.background.default}`,
    borderRadius: 16,
  },
  '*::-webkit-scrollbar-corner': {
    backgroundColor: theme.palette.background.default,
  },
  'scrollbar:hover::-webkit-scrollbar-thumb': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.paper, 0.1)
        : darken(theme.palette.background.paper, 0.1),
    border: `4px solid ${theme.palette.background.default}`,
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.paper, 0.1)
        : darken(theme.palette.background.paper, 0.1),
    border: `4px solid ${theme.palette.background.default}`,
  },
  '*::-webkit-scrollbar-button': {
    display: 'none',
  },
});
