import Box from '@mui/material/Box';
import { FC } from 'react';
import { ButtonBase, useMediaQuery, useTheme } from '@mui/material';
import Logo from 'ui/components/Logo';

const AppBarLogo: FC<{
  onClick?: () => void;
}> = ({ onClick }) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ButtonBase disableRipple onClick={onClick}>
      <Box
        sx={{
          height: '100%',
        }}
        width={{
          xs: 48,
          md: 224,
        }}
      >
        <Logo variant={onMobile ? 'icon' : 'text-nonsub'} />
      </Box>
    </ButtonBase>
  );
};

export default AppBarLogo;
