export enum TablePageActionType {
  CHANGE_PAGE = 'CHANGE_PAGE',
  CHANGE_ROWS_PER_PAGE = 'CHANGE_ROWS_PER_PAGE',
}

export interface TablePageState {
  page: number;
  rowsPerPage: number;
}

type ChangePage = { type: TablePageActionType.CHANGE_PAGE; page: number };
type ChangeRowsPerPage = {
  type: TablePageActionType.CHANGE_ROWS_PER_PAGE;
  rowsPerPage: number;
};
export type TablePageAction = ChangePage | ChangeRowsPerPage;

function tablePageReducer(state: TablePageState, action: TablePageAction) {
  switch (action.type) {
    case TablePageActionType.CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case TablePageActionType.CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 1,
      };
    default:
      return {
        ...state,
        page: 1,
        rowsPerPage: 20,
      };
  }
}

export default tablePageReducer;
