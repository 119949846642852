import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  buttonBaseClasses,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import ActionCancelOrder from './ActionCancelOrder';
import {
  OrderFeedbackStatus,
  RfqDetail,
  useRfqDetail,
  useSubmitOrderFeedback,
  useUpdateQuoteMeta,
} from '../../../../services/brokers';
import { ModalLayout, useToast } from 'ui/components';
import dayjs from 'dayjs';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import { isDivisibleBy, thousandSeparated } from 'ui/utils';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import PenOnPanelIcon from 'ui/icons/pen-on-panel.svg?react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import RfqHeader from './RfqHeader';
import InputPartialFillSize from './InputPartialFillSize';
import InputPrice from './InputPrice';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useIndex } from '../../../../services/bonds';
import priceOutOfMidPriceRangeAlertText from '../../components/priceOutOfMidPriceRangeAlertText';
import { isNil, isNumber } from 'lodash';
import WarningTriangleIcon from 'ui/icons/warning_triangle.svg?react';
import {
  Services,
  useTraderServiceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import { useMeasure } from 'react-use';
import InputSettlementDate from './InputSettlementDate';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import SummaryLayout from '../../components/SummaryLayout';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import SummaryList from '../../components/SummaryList';
import ColumnAsset from '../../components/ColumnAsset';
import ColumnTax from '../../orders/components/ColumnTax';
import ColumnPlatformFee from './ColumnPlatformFee';
import ColumnSettlementFee from '../../orders/components/ColumnSettlementFee';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import {
  rfqDatePickerTextFieldProps,
  rfqInputBaseProps,
} from '../../components/utils';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import InformationMark from 'ui/components/InformationMark';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import ColumnTaxRate from '../../orders/components/ColumnTaxRate';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';

type UpdateOrderFormValues = {
  size: string;
  price: string;
  brokerSpread?: string;
  settlementDate: dayjs.Dayjs | null;
};

const ActionResponseOrder: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onCanceled?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({
  children,
  onSuccess,
  onCanceled,
  onOpen,
  onClose: onCloseProp,
  rfq,
}) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const toast = useToast();
  const [headerContainerRef, { height: headerHeight }] = useMeasure();

  const brokerSpreadDisabled = useTraderServiceIsDisabled(
    Services.TRADE_RFQ_BROKER_SPREAD,
  );

  const { data } = useRfqDetail(
    String(rfq?.id),
    {
      view: 1,
    },
    {
      enabled: open,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: rfq?.assetIdentifier,
    },
    {
      enabled: open && Boolean(rfq?.assetIdentifier),
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  const defaultValues = useMemo(() => {
    return {
      size: data?.size.toString(),
      price: data?.quote?.toString(),
      brokerSpread: (data?.brokerSpread ?? 0).toString(),
      settlementDate: data?.settlementDate ? dayjs(data?.settlementDate) : null,
    };
  }, [data]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        size: yup
          .number()
          .optional()
          .label(t('field.size').toLowerCase())
          .test(
            'requiredIfPartialFill',
            t('validation:custom.requiredIfPartialFill'),
            (value) => {
              if (!data?.isPartialFill) return true;
              return !isNil(value);
            },
          )
          .min(
            bondDetail?.minSize || 0,
            t('validation:custom.greaterThanBondMinSize', {
              minSize: thousandSeparated(bondDetail?.minSize || 0),
            }),
          )
          .test(
            'shouldNotExceed',
            t('validation:custom.shouldNotExceed', {
              size: data?.initSize,
            }),
            (value) => {
              if (!data?.initSize) return true;
              if (!value) return true;
              return value <= data?.initSize;
            },
          )
          .test(
            'multipleOfAssetMinIncrement',
            t('validation:custom.multipleOfAssetMinIncrement', {
              minIncrement: thousandSeparated(bondDetail?.minIncrement || 0),
            }),
            isDivisibleBy(bondDetail?.minIncrement ?? 0),
          ),
        price: yup
          .number()
          .required()
          .label(t('field.price').toLowerCase())
          .test(
            'greaterThanZero',
            t('validation:custom.greaterThanZero'),
            (value) => !!value && value > 0,
          ),
        brokerSpread: yup
          .number()
          .optional()
          .nullable()
          .label(t('field.brokerSpread').toLowerCase()),
        settlementDate: yup.date().required(),
      }),
    [t, data?.initSize, data?.isPartialFill, bondDetail?.minIncrement],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<UpdateOrderFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const price = useWatch({
    control,
    name: 'price',
  });

  const {
    mutate: submitOrderFeedback,
    isLoading: isLoadingSubmitOrderFeedback,
  } = useSubmitOrderFeedback({
    onSuccess: (data, variables) => {
      switch (variables?.status) {
        case OrderFeedbackStatus.Updated:
          toast.success(t('message.submitOrderUpdateSuccess'));
          break;
        case OrderFeedbackStatus.Executed:
        case OrderFeedbackStatus.CanceledByBroker:
          toast.success(t('message.submitOrderFeedbackSuccess'));
      }
      onClose();
      onSuccess?.();
    },
    onError: (err) => {
      const error = err?.response?.data?.error;
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });
  const { mutate: updateQuoteMeta, isLoading: isLoadingUpdateBrokerSpread } =
    useUpdateQuoteMeta({
      onSuccess(data, variables, context) {
        reset({
          ...defaultValues,
          ...(variables.brokerSpread && {
            brokerSpread: variables.brokerSpread?.toString(),
          }),
          ...(variables.settlementDate && {
            settlementDate: dayjs(variables.settlementDate),
          }),
        });
        toast.success(t('title.brokerSpreadUpdated'));
      },
    });

  const isLoading = isLoadingSubmitOrderFeedback || isLoadingUpdateBrokerSpread;

  const onSubmitOrderExecuted = async () => {
    if (!data?.size || !data?.price) {
      toast.error(
        t('common:api.error.unknown.title'),
        t('common:api.error.unknown.message'),
      );
      return;
    }
    submitOrderFeedback({
      rfqId: String(rfq.id),
      status: OrderFeedbackStatus.Executed,
      executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      size: data?.size.toString(),
      price: data?.price.toString(),
    });
  };

  const onSubmitOrderUpdated: SubmitHandler<UpdateOrderFormValues> = async (
    formData,
  ) => {
    await Promise.all([
      (dirtyFields.size || dirtyFields.price) &&
        submitOrderFeedback({
          rfqId: String(rfq.id),
          status: OrderFeedbackStatus.Updated,
          executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
          size: formData.size,
          price: formData.price,
        }),
      !isNil(data?.quoteId) &&
        ((dirtyFields.brokerSpread &&
          isNumber(formData.brokerSpread) &&
          !brokerSpreadDisabled) ||
          dirtyFields.settlementDate) &&
        updateQuoteMeta({
          rfqId: rfq.id,
          quoteId: data?.quoteId,
          ...(dirtyFields.brokerSpread && {
            brokerSpread: Number(formData.brokerSpread),
          }),
          ...(dirtyFields.settlementDate && {
            settlementDate: dayjs(formData.settlementDate).format('YYYY-MM-DD'),
          }),
        }),
    ]);
  };

  const onOrderCanceled = () => {
    if (isLoading) return;
    onClose();
    onCanceled?.();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setOpenEdit(false);
    reset(defaultValues);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const priceHelperText = useMemo(() => {
    // // If offered price is out of mid price +- 40% range, show alert
    // if (price && isNumber(Number(price)) && bondDetail?.midPriceClean) {
    //   const alert = priceOutOfMidPriceRangeAlertText(
    //     bondDetail,
    //     Number(price),
    //     t,
    //   );

    //   if (alert) {
    //     return alert;
    //   }
    // }

    return '';
  }, [price, bondDetail, t]);

  const renderActions = useCallback(
    (data: RfqDetail) =>
      openEdit ? (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          <Button
            variant={'outlined'}
            color={'primary'}
            size={onMobile ? 'large' : 'medium'}
            disabled={isLoading}
            onClick={() => {
              setOpenEdit(false);
              reset(defaultValues);
            }}
          >
            {t('common:action.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit(onSubmitOrderUpdated)();
            }}
            size={onMobile ? 'large' : 'medium'}
            disabled={isLoading || !isDirty}
          >
            {t('action.updateOrderConfirm')}
          </Button>
        </Stack>
      ) : onMobile ? (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={() => {
                onSubmitOrderExecuted();
              }}
              size={'large'}
              disabled={isLoading}
              fullWidth
            >
              {t('action.finishOrder')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ActionCancelOrder rfq={rfq} onCanceled={onOrderCanceled}>
              {({ onClick }) => (
                <Button
                  variant="outlined"
                  onClick={onClick}
                  size={'large'}
                  disabled={isLoading}
                  fullWidth
                >
                  {t('action.reportOrderCancelled')}
                </Button>
              )}
            </ActionCancelOrder>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={'outlined'}
              color={'primary'}
              size={'large'}
              fullWidth
              startIcon={
                <SvgIcon>
                  <PenOnPanelIcon />
                </SvgIcon>
              }
              onClick={() => {
                setOpenEdit(true);
              }}
            >
              {t('action.updateOrder')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            {data?.username && (
              <ActionOpenChatOfRfq
                username={data?.username}
                rfq={data}
                onSuccess={() => {
                  onClose();
                }}
              >
                {(props) => (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    size={'large'}
                    fullWidth
                    startIcon={
                      <SvgIcon>
                        <ChatIcon />
                      </SvgIcon>
                    }
                    {...props}
                  >
                    {t('action.openChat')}
                  </Button>
                )}
              </ActionOpenChatOfRfq>
            )}
          </Grid>
        </Grid>
      ) : (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          <Button
            variant="contained"
            onClick={() => {
              onSubmitOrderExecuted();
            }}
            size={onMobile ? 'large' : 'medium'}
            disabled={isLoading}
          >
            {t('action.finishOrder')}
          </Button>
          <ActionCancelOrder rfq={rfq} onCanceled={onOrderCanceled}>
            {({ onClick }) => (
              <Button
                variant="outlined"
                onClick={onClick}
                size={onMobile ? 'large' : 'medium'}
                disabled={isLoading}
              >
                {t('action.reportOrderCancelled')}
              </Button>
            )}
          </ActionCancelOrder>
          <Button
            variant={'outlined'}
            color={'primary'}
            size={onMobile ? 'large' : 'medium'}
            startIcon={
              <SvgIcon>
                <PenOnPanelIcon />
              </SvgIcon>
            }
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            {t('action.updateOrder')}
          </Button>
          {data?.username && (
            <ActionOpenChatOfRfq
              username={data?.username}
              rfq={data}
              onSuccess={() => {
                onClose();
              }}
            >
              {(props) => (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  size={onMobile ? 'large' : 'medium'}
                  startIcon={
                    <SvgIcon>
                      <ChatIcon />
                    </SvgIcon>
                  }
                  {...props}
                >
                  {t('action.openChat')}
                </Button>
              )}
            </ActionOpenChatOfRfq>
          )}
        </Stack>
      ),
    [
      onTablet,
      onMobile,
      t,
      rfq,
      onOrderCanceled,
      isLoading,
      onClose,
      onSubmitOrderExecuted,
    ],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <SummaryLayout
              header={
                <Stack spacing={1}>
                  <TypographyWithLoading loading={isLoadingData}>
                    <RfqHeader rfq={data} />
                  </TypographyWithLoading>

                  {!onMobile && renderActions(data)}
                </Stack>
              }
              summary={
                <SummaryList>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={t('bond:field.isin')} />
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.isin ?? '-'}
                    </TypographyWithLoading>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.clientAccount')}`} />
                    <Box textAlign={'right'}>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnClientAccount rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.clientContact')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.username ?? '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.clientName')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.fullName ?? '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {/* <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                  <Controller
                    name="size"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText
                            primary={`${t('field.size')}
                        ${t('bond:tooltip.bondSize', {
                          minSize: thousandSeparated(data?.bond?.minSize),
                          minIncrement: thousandSeparated(
                            data?.bond?.minIncrement,
                          ),
                        })}`}
                          />
                          {data?.isPartialFill && openEdit ? (
                            <InputPartialFillSize
                              rfq={data}
                              {...rfqInputBaseProps}
                              {...field}
                              error={!!fieldState.error}
                            />
                          ) : (
                            <TypographyWithLoading loading={isLoadingData}>
                              <ColumnSize
                                rfq={{
                                  ...data,
                                  size: field.value,
                                }}
                              />
                            </TypographyWithLoading>
                          )}
                        </ListItem>
                        {openEdit && fieldState.error?.message && (
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'end'}
                          >
                            <Typography
                              variant="body2"
                              color="error.main"
                              gutterBottom
                            >
                              {fieldState.error?.message}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText primary={`${t('field.price')}`} />
                          <TypographyWithLoading loading={isLoadingData}>
                            {openEdit ? (
                              <InputPrice
                                {...rfqInputBaseProps}
                                {...field}
                                placeholder={t('tooltip.layQuote')}
                                error={!!fieldState.error}
                              />
                            ) : field?.value ? (
                              <ColumnPrice
                                rfq={{
                                  ...data,
                                  quote: field.value,
                                }}
                              />
                            ) : (
                              '-'
                            )}
                          </TypographyWithLoading>
                        </ListItem>
                        {openEdit &&
                          (fieldState.error?.message ? (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                              justifyContent={'end'}
                            >
                              <Typography
                                variant="body2"
                                color="error.main"
                                gutterBottom
                              >
                                {fieldState.error?.message}
                              </Typography>
                            </Stack>
                          ) : (
                            priceHelperText && (
                              <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'end'}
                              >
                                <SvgIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: 'warning.main',
                                  }}
                                >
                                  <WarningTriangleIcon />
                                </SvgIcon>
                                <Typography
                                  variant="body2"
                                  color="warning.main"
                                  gutterBottom
                                  maxWidth={360}
                                >
                                  {priceHelperText}
                                </Typography>
                              </Stack>
                            )
                          ))}
                      </>
                    )}
                  />
                  <ListItem>
                    <ListItemText primary={t('field.platformFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnPlatformFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.quoteExchangeRate !== 1 && (
                    <ListItem>
                      <ListItemText primary={t('field.quoteExchangeRate')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnQuoteExchangeRate rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary={t('field.tax')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <ColumnTaxResidency rfq={data} />
                          <Box>
                            <ColumnTaxRate rfq={data} />
                          </Box>
                        </Stack>
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <Controller
                    name="settlementDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText primary={t('field.settlementDate')} />
                          <Box>
                            <TypographyWithLoading loading={isLoadingData}>
                              {!openEdit ? (
                                <ColumnSettlementDate rfq={data} />
                              ) : (
                                <InputSettlementDate
                                  {...field}
                                  slotProps={{
                                    textField: {
                                      error: !!fieldState.error,
                                      helperText: fieldState.error?.message,
                                      ...rfqDatePickerTextFieldProps,
                                    },
                                  }}
                                />
                              )}
                            </TypographyWithLoading>
                          </Box>
                        </ListItem>
                        {openEdit && fieldState.error?.message && (
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'end'}
                          >
                            <Typography
                              variant="body2"
                              color="error.main"
                              gutterBottom
                            >
                              {fieldState.error?.message}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                  <ListItem>
                    <ListItemText
                      primary={t('bond:field.tradingRestrictionType')}
                    />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.bond?.tradingRestrictionType || '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.orderedAt')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnRequestTime rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.orderNote && (
                    <ListItem>
                      <ListItemText primary={t('field.cancelReason')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          {data?.orderNote ?? '-'}
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                </SummaryList>
              }
            />
            {onMobile && data && (
              <>
                <Box height={132} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),
    [rfq?.id, open, renderActions, t, openEdit, priceHelperText],
  );

  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content?: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
        })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
        {/* <ModalLayout
          open={openEdit}
          onClose={() => {
            if (isLoading) return;
            setOpenEdit(false);
            setOpen(true);
          }}
        >
          {renderEditContent()}
        </ModalLayout> */}
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isLoading) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 720,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
      {/* <BaseActionDialog
        open={openEdit}
        onClose={() => {
          if (isLoading) return;
          setOpenEdit(false);
          setOpen(true);
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 976,
        }}
      >
        {renderEditContent()}
      </BaseActionDialog> */}
    </>
  );
};

export default ActionResponseOrder;
