import {
  Theme,
  MenuItemProps,
  Fade,
  MenuItemClasses,
  menuItemClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<MenuItemProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof MenuItemClasses,
    'MuiMenuItem',
    Omit<Theme, 'components'>
  >
> = {
  root: {
    [`&.${menuItemClasses.selected}`]: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
