import { Theme, ToolbarProps, ToolbarClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<ToolbarProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof ToolbarClasses,
    'MuiToolbar',
    Omit<Theme, 'components'>
  >
> = {
  root: ({
    theme: {
      palette,
      breakpoints: { up },
    },
  }) => ({
    color: palette.text.secondary,
    [up('sm')]: {
      minHeight: 74,
    },
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
