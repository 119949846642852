export const fallbackLng = 'en';
export const fallbackLngLabel = 'English';
export const toggleTargetLng = 'zh-TW';
export const toggleTargetLngLabel = '中文';
export const localeOptions = [
  { value: fallbackLng, label: fallbackLngLabel },
  { value: 'zh-CN', label: '简体中文' },
  { value: 'zh-TW', label: '繁體中文' },
];

export const locales = localeOptions.map(({ value }) => value);
export const defaultNS = 'common';
export const cookieName = 'i18next';
// import { DetectorOptions } from 'i18next-browser-languagedetector';

export function getOptions(
  lng = fallbackLng,
  ns = defaultNS as string | string[],
) {
  return {
    // debug: true,
    supportedLngs: locales,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}

// export const languageDetectorOptions: DetectorOptions = {
//   order: ['path', 'cookie', 'htmlTag', 'navigator'],
//   lookupCookie: cookieName,
//   lookupFromPathIndex: 0,
// };
