import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  AppBar,
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  InputBase,
  Link,
  List,
  ListItem,
  Paper,
  Stack,
  Toolbar,
  styled,
  useTheme,
} from '@mui/material';
import { Input } from '@mui/base/Input';
import AidanFace from './AidanFace';
import AidanAppBar from './AidanAppBar';
import AidanWelcome from './AidanWelcome';
import ChatMessage, { ChatMessageContainer } from './ChatMessage';
import {
  AidanChatResponse,
  useAidanChat,
} from '../../../../services/aidan/aidan';
import { isNil, lowerCase, set } from 'lodash';
import { useLocalStorage } from 'react-use';
import { StorageKey } from '../../../../utils/storage-key';
import { useAidanLevels } from './aidan.config';
import { useTranslation } from 'react-i18next';
import { usePermittedV2 } from '../../../../libs/hooks/usePermissions';

const useAidanSuggestions = () => {
  const { t } = useTranslation('aidan');

  const [permittedAidan1_3] = usePermittedV2(
    'aidan.summaryOfPortfolioAndWatchlist',
  );
  const [permittedAidan4] = usePermittedV2('aidan.summaryOfInvestment');

  const options = useMemo(() => {
    return [
      ...(permittedAidan1_3
        ? [
            t('options.suggestion.updateOfPortfolio'),
            t('options.suggestion.updateOfPortfolioAndWatchlist'),
            t('options.suggestion.importantAlertsOnWatchlist'),
          ]
        : []),
      ...(permittedAidan4 ? [t('options.suggestion.investmentSummary')] : []),
    ];
  }, [t]);

  return options;
};

const AIDAN_HEIHGT = 560;

const ChatInformationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : '',
}));

const SuggestionMessage: FC<{
  onClickSuggestion?: (message: string) => void;
}> = ({ onClickSuggestion }) => {
  const { t } = useTranslation('aidan');
  const [selected, setSelected] = useState<number | null>(null);

  const suggestions = useAidanSuggestions();

  return (
    <Stack spacing={1} alignItems={'flex-start'} mt={1}>
      <ChatInformationTypography variant="body1" fontWeight={'bold'}>
        {t('message.startInteracting')}
      </ChatInformationTypography>
      {suggestions.map((message, index) => (
        <ButtonBase
          key={index}
          sx={{
            display: 'inline-block',
            borderRadius: '10px',
            opacity: !isNil(selected) ? 0.5 : 1,
          }}
          onClick={() => {
            setSelected(index);
            onClickSuggestion?.(message);
          }}
          disabled={!isNil(selected)}
        >
          <ChatMessage>{message}</ChatMessage>
        </ButtonBase>
      ))}
    </Stack>
  );
};

const AnswerMessage: FC<AidanChatResponse> = ({
  title,
  description,
  items,
}) => {
  const formatLinkWithParams = (
    link: string,
    params: {
      [key: string]: string;
    },
  ) => {
    // params format [[token]]
    const paramsFormat = /\[\[.*?\]\]/g;

    const paramsInLink = link.match(paramsFormat);

    if (paramsInLink) {
      paramsInLink.forEach((param) => {
        const key = param.replace('[[', '').replace(']]', '');
        link = link.replace(param, params[key]);
      });
    }

    return link;
  };

  const token = useLocalStorage(StorageKey.token)[0] as string | undefined;

  return (
    <ChatMessage align={'left'}>
      <ChatMessageContainer align="left">
        {title && (
          <Typography fontSize={'inherit'} fontWeight={'bold'} gutterBottom>
            {title}
          </Typography>
        )}
        {description && (
          <Typography fontSize={'inherit'} gutterBottom>
            {description}
          </Typography>
        )}

        <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
          {items &&
            items.map((item, index) => (
              <ListItem key={index} sx={{ display: 'list-item', p: 0 }}>
                {item.link ? (
                  <Link
                    href={formatLinkWithParams(item.link, {
                      token: token || '',
                    })}
                  >
                    {item.text}
                  </Link>
                ) : (
                  <Typography fontSize={'inherit'}>{item.text}</Typography>
                )}
              </ListItem>
            ))}
        </List>
      </ChatMessageContainer>
    </ChatMessage>
  );
};

const AidanLevelSelect: FC<{}> = ({}) => {
  const { t } = useTranslation('aidan');
  const [aidanStoredLevel] = useLocalStorage(StorageKey.aidanLevel, '');
  const [askLevel, setAskLevel] = useState(
    aidanStoredLevel ? Number(aidanStoredLevel) : 1,
  );
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const levelOptions = useAidanLevels();
  const chosenLevel = () => {
    if (!askLevel) {
      return null;
    }
    return levelOptions.find((level) => level.level === Number(askLevel));
  };

  useEffect(() => {
    const handleStorage: EventListenerOrEventListenerObject = (e) => {
      const newLevel = localStorage.getItem(StorageKey.aidanLevel);
      if (newLevel) {
        setAskLevel(JSON.parse(newLevel));
      }
      return;
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  return (
    <Stack alignItems={'center'} mt={4} spacing={1} width={'100%'}>
      <ChatInformationTypography
        fontSize={16}
        fontWeight={600}
        lineHeight={'22.4px'}
      >
        {t('message.chooseLevel', {
          level: lowerCase(chosenLevel()?.label),
        })}
      </ChatInformationTypography>
      <Stack direction={'row'} spacing={1.5}>
        {levelOptions.map((level) => (
          <Card
            key={level.level}
            elevation={isDark ? 0 : 2}
            sx={{
              py: '12px',
              px: '14px',
              textAlign: 'center',
              opacity: chosenLevel()?.level === level.level ? 1 : 0.5,
              background: (theme) => theme.palette.background.paper,
              border: (theme) =>
                theme.palette.mode === 'dark'
                  ? `1px solid ${theme.palette.divider}`
                  : 'none',
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <Typography fontSize={24}>{level.emoji}</Typography>
            <Typography fontSize={14} fontWeight={600}>
              {level.label}
            </Typography>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};

const AidanBody: FC<{
  children: (props: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    open: boolean;
  }) => React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation('aidan');
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const chatroomBottomRef = useRef<HTMLDivElement>(null);
  const [aidanStoredLevel, setStoredLevel] = useLocalStorage(
    StorageKey.aidanLevel,
    '',
  );
  const [askLevel, setAskLevel] = useState(
    aidanStoredLevel ? Number(aidanStoredLevel) : 1,
  );
  const [showWelcome, setShowWelcome] = useState(aidanStoredLevel === '');

  const { mutate: ask, isLoading: isThinking } = useAidanChat({
    onSuccess: async (data) => {
      for (const response of data) {
        handleAidanSendMessage(<AnswerMessage {...response} />);
      }

      setTimeout(() => {
        handleAidanSendMessage(
          <SuggestionMessage onClickSuggestion={onClickSuggestion} />,
        );
      }, 1000);
    },
    cacheTime: 0,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [messages, setMessages] = useState<
    {
      message: React.ReactNode;
      from: 'aidan' | 'user';
    }[]
  >([]);

  const handleSendMessage = (message: string) => {
    setMessages((prev) => [...prev, { message, from: 'user' }]);
    ask({
      q: message,
      level: askLevel,
    });
  };

  const handleAidanSendMessage = (message: React.ReactNode) => {
    setMessages((prev) => [...prev, { message, from: 'aidan' }]);
  };
  const onClickSuggestion = (message: string) => {
    handleSendMessage(message);
  };

  const onLevelChange = (level: number) => {
    setStoredLevel(String(level));
    window.dispatchEvent(new Event('storage'));
    setAskLevel(level);
    setShowWelcome(false);
  };

  useEffect(() => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [messages]);

  useEffect(() => {
    if (open) {
      if (messages.length === 0) {
        handleAidanSendMessage(<AidanLevelSelect />);

        handleAidanSendMessage(
          <SuggestionMessage onClickSuggestion={onClickSuggestion} />,
        );
      } else {
        setTimeout(() => {
          chatroomBottomRef.current?.scrollIntoView({ behavior: 'instant' });
        }, 0);
      }
    }
  }, [open, messages]);

  return (
    <div>
      {children({
        onClick: handleClick,
        open,
      })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={isDark ? 0 : 3}
      >
        <Box
          width={424}
          height={AIDAN_HEIHGT - 64}
          sx={{
            overflowY: 'auto',
            // hide scrollbar
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <AidanAppBar
            onBack={() => {
              setShowWelcome(true);
              setMessages([]);
            }}
          />
          <CardContent>
            <Stack
              spacing={1}
              justifyContent={'end'}
              sx={{
                minHeight: AIDAN_HEIHGT - 64 - 64 - 16 * 2 - 8,
              }}
            >
              {messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  align={message.from === 'aidan' ? 'left' : 'right'}
                >
                  {message.message}
                </ChatMessage>
              ))}
            </Stack>
            {isThinking && (
              <Stack py={2} direction={'row'} spacing={2} alignItems={'center'}>
                <AidanFace elevation={0} />
                <Typography color={'text.secondary'} fontSize={16}>
                  {t('message.thinking')}
                </Typography>
              </Stack>
            )}
            <Box ref={chatroomBottomRef} height={0} width={'100%'} />
          </CardContent>
        </Box>
        <InputBase
          placeholder={t('placeholder.askAidan')}
          fullWidth
          sx={{
            p: 2,
            height: 64,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (e.currentTarget.value.trim() === '') {
                return;
              }

              handleSendMessage(e.currentTarget.value);
              e.currentTarget.value = '';
            }
          }}
        />
        {showWelcome && <AidanWelcome onLevelChange={onLevelChange} />}
      </Popover>
    </div>
  );
};

export default AidanBody;
