import {
  CSSObject,
  Theme,
  ButtonBaseProps,
  ButtonBaseClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<ButtonBaseProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof ButtonBaseClasses,
    'MuiButtonBase',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }): CSSObject => ({
    whiteSpace: 'nowrap',
    '&:hover': {
      // color: theme.palette.primary.light,
    },
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
