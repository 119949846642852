import { Theme, BreadcrumbsProps, BreadcrumbsClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<BreadcrumbsProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof BreadcrumbsClasses,
    'MuiBreadcrumbs',
    Omit<Theme, 'components'>
  >
> = {
  root: {
    fontSize: '0.875rem',
  },
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
