import { useContext, useEffect } from 'react';
import { StompClientContext } from '../../../services/websocket';
import { ApiKey, ApiConfig, Websocket } from 'services/limit-order';
import { useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'ui/components';
import { get } from 'lodash';
import { LimitOrderMarketContext } from '../../layout/LimitOrderMarketContext';
import { useUserInfo } from '../../../services/users';

export default function LimitOrderWebsocketSubscriber() {
  const { client: wsClient, connected } = useContext(StompClientContext);
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { setHasNewOrder } = useContext(LimitOrderMarketContext);
  const { data: user } = useUserInfo();

  useEffect(() => {
    if (connected && wsClient) {
      wsClient.subscribe(Websocket.Events.EVENT_KEY, (e) => {
        const data = Websocket.Events.eventHandler(e);

        // if data is LimitOrderCreatedEvent, set hasNewOrder to true
        if (
          get(data, 'orderId') &&
          get(data, 'username') &&
          user?.username !== get(data, 'username')
        ) {
          setHasNewOrder(true);
        }

        queryClient.invalidateQueries([ApiKey, ApiConfig.index]);
      });
      wsClient.subscribe(Websocket.Events.USER_EVENT_KEY, (e) => {
        const data = Websocket.Events.eventHandler(e);
        queryClient.invalidateQueries([ApiKey, ApiConfig.index]);
        if (get(data, 'matchId')) {
          alert.success('New matched order! Please check your limit order.');
        }
      });
    }

    return () => {
      if (wsClient) {
        wsClient.unsubscribe(Websocket.Events.EVENT_KEY);
      }
    };
  }, [connected]);

  return null;
}
