import { Message } from 'webstomp-client';
import { LimitOrderCreatedEvent, LimitOrderMatchedEvent } from './type';

export const EVENT_KEY = '/limited-order';
export const USER_EVENT_KEY = '/user/limited-order';

type NewMessageBody = LimitOrderCreatedEvent | LimitOrderMatchedEvent;

export const eventHandler = (message: Message) => {
  const data = JSON.parse(message.body) as NewMessageBody;
  console.log(EVENT_KEY, data);

  return data;
};
