import {
  Theme,
  PaginationProps,
  PaginationClasses,
  paginationItemClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<PaginationProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof PaginationClasses,
    'MuiPagination',
    Omit<Theme, 'components'>
  >
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
