import { Theme, PaperProps, PaperClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<PaperProps> = {
  elevation: 2,
};

export const styleOverrides: Partial<
  OverridesStyleRules<keyof PaperClasses, 'MuiPaper', Omit<Theme, 'components'>>
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
