import { useSummary as useRfqSummary } from '../../services/brokers';
import { useSummary as useChatSummary } from '../../services/chat';
import { useContext, useEffect, useMemo } from 'react';
import { NotificationContext } from 'ui/components';

export const useNotificationSummary = () => {
  const { alertCount, setAlertCount } = useContext(NotificationContext);
  const { data: chatSummary } = useChatSummary();
  const totalUnreadChat = useMemo(() => {
    return chatSummary?.totalUnreadNumber || 0;
  }, [chatSummary]);

  const { data: rfqSummary } = useRfqSummary(
    {},
    {
      keepPreviousData: true,
    },
  );

  const totalRfqNotification = useMemo(() => {
    return rfqSummary?.total || 0;
  }, [rfqSummary]);

  useEffect(() => {
    setAlertCount?.(totalRfqNotification + totalUnreadChat);
  }, [totalRfqNotification, totalUnreadChat]);

  return;
};
