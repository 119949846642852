import { RfqDetail } from '../../../services/brokers';

const ColumnClientAccount: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (!rfq) {
    return null;
  }

  return (
    <>
      {rfq?.tradeAccountFi ?? '-'} {rfq?.tradeAccountCode ?? ''}
    </>
  );
};

export default ColumnClientAccount;
