import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DialogCover from 'ui/icons/dialog/mail-dialog-cover.svg?react';
import MobileDialogCover from 'ui/icons/dialog/mail-mobile-dialog-cover.svg?react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContactForm } from '../../../../services/landing-page-services/service';
import { useLocalStorage } from 'react-use';
import { StorageKey } from '../../../../utils/storage-key';
import { useToast } from 'ui/components';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { formatApiErrorToast } from '../../../../services/apiUtils';

const ActionSendInsight: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onCreated?: () => void;
}> = ({ children, onCreated }) => {
  const { t } = useTranslation(['aidan', 'user', 'validation']);
  const theme = useTheme();
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const [open, setOpen] = useState(false);
  const [userEmail] = useLocalStorage(StorageKey.userName) as string[];

  const toast = useToast();

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .required()
          .label(t('user:fields.email').toLowerCase()),
        message: yup
          .string()
          .required()
          .label(t('user:fields.contactMessage').toLowerCase())
          .min(5),
      }),
    [t],
  );
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: userEmail ?? '',
      firstname: '',
      lastname: '',
      phone: '',
      message: '',
    },
    resolver: yupResolver(validationSchema),
  });

  // count down
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countDown]);

  //functions

  const { mutate: contact, isLoading } = useContactForm({
    onSuccess: () => {
      setCountDown(60);
      setValue('message', '');

      toast.success(
        t('user:title.contactSuccess'),
        t('user:message.contactSuccess'),
      );
    },
    onError: (error) => {
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<{
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    message: string;
  }> = (data) => {
    contact({ ...data });
  };

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog open={open} onClose={onClose} cover={<DialogCover />}>
        <Stack pt={8} height={'100%'}>
          {onTablet && (
            <Box textAlign={'center'}>
              <MobileDialogCover width={90} />
            </Box>
          )}
          <Box>
            <div className="text-center">
              <Typography
                variant={onTablet ? 'body1' : 'h5'}
                className="uppercase py-2 font-bold text-center"
              >
                {t('action.sendInsight')}
              </Typography>
              <Typography
                variant={onTablet ? 'body2' : 'body1'}
                fontWeight={'bold'}
              >
                {t('message.sendInsight')}
              </Typography>
            </div>
          </Box>
          <Stack p={4} flex={1} justifyContent={'space-between'} spacing={2}>
            <Controller
              name="message"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={6}
                  variant="outlined"
                  sx={{
                    mt: 2,
                  }}
                  inputProps={{ maxLength: 500 }}
                  error={!!fieldState.error}
                  helperText={`${fieldState.error?.message ?? ''} ${
                    field.value.length
                  } / 500`}
                  FormHelperTextProps={{
                    sx: {
                      textAlign: 'end',
                      mt: -3,
                    },
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || countDown > 0}
            >
              {t('action.sendInsightToUs')}
              {countDown > 0 && `(${countDown})`}
            </Button>
          </Stack>
        </Stack>
      </BaseActionDialog>
    </>
  );
};

export default ActionSendInsight;
