import { Message } from 'webstomp-client';

export const EVENT_KEY = '/user/chat';

interface NewMessageBody {
  messageId: number;
  senderName: string;
  chatSessionId: number;
  message: string;
  createdAt: string;
}

export const eventHandler = (message: Message) => {
  const data = JSON.parse(message.body) as NewMessageBody;
  console.log(EVENT_KEY, data);

  return data;
};
