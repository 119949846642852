import { Chip, chipClasses, Stack, Typography } from '@mui/material';
import { RfqDetail, RfqStatus } from '../../../services/brokers';
import { thousandSeparated } from 'ui/utils';
import { useTranslation } from 'react-i18next';
import React from 'react';

const ColumnSize: React.FC<{
  rfq: RfqDetail | undefined;
  hidePartialFill?: boolean;
  disableHighlight?: boolean;
}> = ({ rfq, hidePartialFill = false, disableHighlight = false }) => {
  const { t } = useTranslation('rfq');
  if (!rfq) {
    return null;
  }

  let displaySize = thousandSeparated(rfq?.size || 0) as React.ReactNode;

  if ([RfqStatus.OrderUpdated].includes(rfq.status)) {
    switch (true) {
      case rfq?.size && rfq?.prevQuoteSize && rfq?.size !== rfq?.prevQuoteSize:
        displaySize = (
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={1}
          >
            <Stack direction={'row'} spacing={1}>
              <Chip
                label={t('common:option.old')}
                size="small"
                sx={{
                  textTransform: 'uppercase',
                  [`& .${chipClasses.root}`]: {
                    backgroundColor: '#232525',
                  },
                  [`& .${chipClasses.label}`]: {
                    color: 'text.secondary',
                  },
                }}
              />
              <Typography
                color={'textSecondary'}
                component={'span'}
                sx={{
                  textDecoration: 'line-through',
                }}
              >
                {thousandSeparated(rfq?.size)}
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Chip
                label={t('common:option.new')}
                color="primary"
                size="small"
                sx={{
                  textTransform: 'uppercase',
                }}
              />
              <Typography component={'span'}>
                {thousandSeparated(rfq?.prevQuoteSize)}
              </Typography>
            </Stack>
          </Stack>
        );
        break;
      case rfq?.size && rfq?.quoteSize && rfq?.size !== rfq?.quoteSize:
        displaySize = (
          <Stack direction="row" spacing={1}>
            <Chip
              label={t('common:option.old')}
              size="small"
              sx={{
                textTransform: 'uppercase',
                [`& .${chipClasses.root}`]: {
                  backgroundColor: '#232525',
                },
                [`& .${chipClasses.label}`]: {
                  color: 'text.secondary',
                },
              }}
            />
            <Typography
              color={'textSecondary'}
              component={'span'}
              sx={{
                textDecoration: 'line-through',
              }}
            >
              {thousandSeparated(rfq?.size)}
            </Typography>
            <Chip
              label={t('common:option.new')}
              color="primary"
              size="small"
              sx={{
                textTransform: 'uppercase',
              }}
            />
            <Typography component={'span'}>
              {thousandSeparated(rfq?.quoteSize)}
            </Typography>
          </Stack>
        );
        break;
    }
  }

  return (
    <Stack
      fontSize={'inherit'}
      direction={'row'}
      color={disableHighlight ? 'auto' : '#FFB024'}
    >
      {displaySize}
      {rfq?.isPartialFill && !hidePartialFill && (
        <Typography
          color={'text.secondary'}
          fontSize={'inherit'}
          whiteSpace={'nowrap'}
        >
          {` / ${thousandSeparated(rfq?.initSize || 0)}`}
        </Typography>
      )}
    </Stack>
  );
};

export default ColumnSize;
