import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RfqDetail, useCancelRFQ } from '../../../../services/brokers';
import { useToast } from 'ui/components';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { formatApiErrorToast } from '../../../../services/apiUtils';

const ActionCancelRfq: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onCanceled?: () => void;
  onClosed?: () => void;
  rfq: RfqDetail;
}> = ({ children, onCanceled, onClosed, rfq }) => {
  const { t } = useTranslation('rfq');
  const [open, setOpen] = useState(false);
  const toast = useToast();

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    onClosed?.();
  };

  //api call
  const { mutate: cancel } = useCancelRFQ({
    onSuccess: () => {
      onCanceled?.();
      onClose();
    },
    onError: (err) => {
      const error = err?.response?.data;
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const handleCancel = () => {
    rfq.id &&
      cancel({
        id: String(rfq.id),
      });
  };

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: 448,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            px: 4,
            pt: 6,
            pb: 3,
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            textTransform={'uppercase'}
            fontWeight={'bold'}
          >
            {t('title.cancelOrder')}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={'bold'}
            whiteSpace={'pre-line'}
          >
            {t('message.cancelOrder')}
          </Typography>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={2}
            justifyContent="stretch"
            mt={4}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              fullWidth
              size="large"
            >
              {t('action.keepOrder')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleCancel();
              }}
              fullWidth
              size="large"
            >
              {t('common:action.cancel')}
            </Button>
          </Stack>
        </Box>
      </BaseActionDialog>
    </>
  );
};

export default ActionCancelRfq;
