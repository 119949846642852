import { Theme, MenuProps, Fade, MenuClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<MenuProps> = {
  TransitionComponent: Fade,
};

export const styleOverrides: Partial<
  OverridesStyleRules<keyof MenuClasses, 'MuiMenu', Omit<Theme, 'components'>>
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
