import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ButtonBase,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIndex, useSearchHistory } from '../../../services/bonds';
import { formatBondName } from 'ui/utils';
import type { Bond } from '../../../services/bonds';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useCountryOptions } from '../../../libs/model/bondFilterOptions';
import { useMeasure } from 'react-use';
import ClockIcon from 'ui/icons/clock.svg?react';

type BondIndexResponseItem = NonNullable<
  ReturnType<typeof useIndex>['data']
>['content'][0];

type SearchHistoryResponse = NonNullable<
  ReturnType<typeof useSearchHistory>['data']
>['content'][0];

export enum SearchOptionType {
  Asset = 'asset',
  History = 'history',
}
export type SearchResult =
  | (BondIndexResponseItem & {
      searchOptionType: SearchOptionType.Asset;
      key: string;
    })
  | (SearchHistoryResponse & {
      searchOptionType: SearchOptionType.History;
      key: string;
    });

interface Props {
  value?: string;
  onChange?: (...event: any) => void;
  label?: string;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  freeSolo?: boolean;
  onClickOption?: (option: any) => void;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  autoHighlight?: boolean;
  clearOnBlur?: boolean;
  autoFocus?: boolean;
  searchFilters?: {
    ratingList?: string[];
    currencyCodeList?: string[];
    sectorList?: string[];
    countryCodeList?: string[];
    yearsToMaturity?: [number, number];
    yield?: [number, number];
    bondPrice?: [number, number];
    couponRate?: [number, number];
  };
  withSearchHistory?: boolean;
}

const SearchInput: React.FC<Props> = ({
  value = '',
  onChange,
  label,
  placeholder,
  startAdornment,
  endAdornment,
  freeSolo = false,
  onClickOption,
  helperText,
  error,
  disabled = false,
  autoHighlight = false,
  clearOnBlur = false,
  autoFocus = false,
  searchFilters,
  withSearchHistory = true,
}) => {
  const { t } = useTranslation(['bond', 'country']);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const [keyword, setKeyword] = React.useState<string>(value);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const countryOptions = useCountryOptions();
  const convertFiltersToParams = useCallback(
    (filters: any) => {
      const params: any = {};
      if (filters?.ratingList?.length > 0) {
        params.ratingList = filters?.ratingList.join(',');
      }
      if (filters?.currencyCodeList?.length > 0) {
        params.currencyCodeList = filters?.currencyCodeList.join(',');
      }
      if (filters?.sectorList?.length > 0) {
        params.sectorList = filters?.sectorList.join(',');
      }
      if (filters?.countryCodeList?.length > 0) {
        params.countryCodeList = filters?.countryCodeList
          .map(
            (key: string) =>
              countryOptions.find((item) => item.key === key) || {
                value: '',
              },
          )
          .map(
            (item: { label: string; value: string; key: string }) => item.value,
          )
          .flat()
          .join(',');
      }
      if (filters?.yearsToMaturity?.length > 0) {
        params.minYearsToMaturity = filters?.yearsToMaturity[0];
        params.maxYearsToMaturity = filters?.yearsToMaturity[1];
      }
      if (filters?.yield?.length > 0) {
        params.minYTW = filters?.yield[0];
        params.maxYTW = filters?.yield[1];
      }
      if (filters?.bondPrice?.length > 0) {
        params.minPrice = filters?.bondPrice[0];
        params.maxPrice = filters?.bondPrice[1];
      }
      if (filters?.couponRate?.length > 0) {
        params.minCoupon = filters?.couponRate[0];
        params.maxCoupon = filters?.couponRate[1];
      }
      return params;
    },
    [countryOptions],
  );
  const [ref, { width }] = useMeasure();

  const { data, isFetching } = useIndex(
    {
      page: 1,
      size: 5,
      keyword,
      ...convertFiltersToParams(searchFilters),
      sortBy: 'trendingScore',
      order: 'desc',
    },
    {
      enabled: !!keyword && keyword.length >= 3,
    },
  );

  const { data: searchHistory, isFetching: isFetchingSearchHistory } =
    useSearchHistory(
      {
        keyword,
        page: 1,
        size: 8,
      },
      {
        enabled: withSearchHistory,
      },
    );
  const options = useMemo(() => {
    if (isFetching) return [];
    return [
      ...(data?.content
        ? data.content.map((asset) => ({
            ...asset,
            key: asset.id,
            searchOptionType: SearchOptionType.Asset,
          }))
        : []),
      ...(withSearchHistory && searchHistory?.content
        ? searchHistory.content.map((history, index) => ({
            ...history,
            key: index + history.keyword,
            searchOptionType: SearchOptionType.History,
          }))
        : []),
    ] as SearchResult[];
  }, [data, isFetching, searchHistory]);

  return (
    <Autocomplete
      ref={ref}
      loading={isFetching}
      options={options ?? []}
      filterOptions={(x) => x}
      autoHighlight={autoHighlight}
      clearOnBlur={clearOnBlur}
      defaultValue={value}
      getOptionLabel={(option: SearchResult) => {
        // if option is string, return option
        if (typeof option === 'string') {
          return option;
        }

        if (option.searchOptionType === SearchOptionType.Asset) {
          return formatBondName(option as Bond);
        } else {
          return option.keyword;
        }
      }}
      isOptionEqualToValue={(option, value) => {
        switch (option.searchOptionType) {
          case SearchOptionType.Asset:
            return option.id === value.id;
          case SearchOptionType.History:
            return option.keyword === value;
          default:
            return false;
        }
      }}
      onChange={(event, newValue) => {
        console.log('newValue', newValue);
        if (isNil(newValue)) {
          return;
        }

        if (typeof newValue === 'string') {
          freeSolo && onChange?.(newValue);
          return;
        }

        if (newValue.searchOptionType === SearchOptionType.Asset) {
          onChange?.(newValue);
        }
      }}
      onFocus={(e) => {
        // select all text when focus
        (e.target as HTMLInputElement).select();
      }}
      onInputChange={(event, newInputValue) => {
        setKeyword(newInputValue);
      }}
      noOptionsText={t('message.noResult')}
      freeSolo
      disableClearable
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={inputRef}
          label={label}
          placeholder={placeholder}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment,
            endAdornment,
          }}
          error={error}
          helperText={helperText}
          autoComplete="one-time-code"
          autoFocus={autoFocus}
        />
      )}
      renderOption={(props, option: SearchResult) => (
        <li {...props} key={option.key}>
          {option.searchOptionType === SearchOptionType.Asset ? (
            <ButtonBase
              onClick={() => {
                onClickOption?.(option);
              }}
              sx={{ width: '100%', justifyContent: 'flex-start', py: 2 }}
            >
              {width > 600 ? (
                <Stack direction={'row'} spacing={1}>
                  <HighLightSearchText
                    noWrap
                    text={option.isin}
                    keyword={keyword}
                  />
                  <HighLightSearchText
                    noWrap
                    text={option.longObligorName}
                    keyword={keyword}
                  />
                  <HighLightSearchText
                    noWrap
                    text={formatBondName(option as Bond)}
                    keyword={keyword}
                  />
                </Stack>
              ) : (
                <Stack textAlign={'left'}>
                  <HighLightSearchText
                    noWrap
                    text={option.isin}
                    keyword={keyword}
                  />
                  <HighLightSearchText
                    noWrap
                    text={`${option.longObligorName} ${formatBondName(option as Bond)}`}
                    keyword={keyword}
                    color={'text.secondary'}
                    width={width - 32}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                  />
                </Stack>
              )}
            </ButtonBase>
          ) : (
            <ButtonBase
              onClick={() => {
                setKeyword(option.keyword);
                onChange?.(option.keyword);
              }}
              sx={{ width: '100%', justifyContent: 'flex-start', py: 2 }}
            >
              <SvgIcon
                fontSize="small"
                sx={{
                  mr: 1,
                }}
              >
                <ClockIcon />
              </SvgIcon>
              {option.keyword}
            </ButtonBase>
          )}
        </li>
      )}
      slotProps={{
        paper: {
          elevation: isDark ? 0 : 1,
        },
      }}
      ListboxProps={{
        sx: {
          maxHeight: `70dvh`,
        },
      }}
    />
  );
};

type TypographyProps = Parameters<typeof Typography>[0];
const HighLightSearchText = ({
  text,
  keyword,
  ...props
}: TypographyProps & {
  text: string;
  keyword: string;
}) => {
  const theme = useTheme();
  const keywords = keyword
    .split(' ')
    .filter((item) => item.length > 0)
    .map((item) => item.trim().toLowerCase());
  const parts = text.split(new RegExp(`(${keywords.join('|')})`, 'gi'));
  return (
    <Typography {...props}>
      {parts.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: keywords.includes(part.toLowerCase())
              ? 'bold'
              : 'normal',
            color: keywords.includes(part.toLowerCase())
              ? theme.palette.primary.main
              : 'inherit',
          }}
        >
          {part}
        </span>
      ))}
    </Typography>
  );
};

export default SearchInput;
