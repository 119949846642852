import {
  inputAdornmentClasses,
  inputBaseClasses,
  TextFieldProps,
} from '@mui/material';
import PenOnPanelIcon from 'ui/icons/pen-on-panel.svg?react';

export const rfqInputBaseProps: Partial<TextFieldProps> = {
  variant: 'standard',
  fullWidth: false,
  sx: {
    [`& .${inputBaseClasses.input}`]: {
      textAlign: 'right',
      mr: 1,
    },
    // hide underline
    [`& .${inputBaseClasses.root}`]: {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  // InputProps: {
  //   endAdornment: <PenOnPanelIcon width={20} color={'#6A707B'} />,
  // },
};

export const rfqDatePickerTextFieldProps: Partial<TextFieldProps> = {
  variant: 'standard',
  sx: {
    [`& .${inputBaseClasses.input}`]: {
      textAlign: 'right',
      mr: 1,
    },
    [`& .${inputAdornmentClasses.root}`]: {
      ml: 0,
    },
    // hide underline
    [`& .${inputBaseClasses.root}`]: {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
};
