import {
  CSSInterpolation,
  CSSObject,
  Theme,
  AlertProps,
  AlertClasses,
  SvgIcon,
  Box,
} from '@mui/material';
import CheckCircleIcon from 'ui/icons/check-circle.svg?react';
import CancelIcon from 'ui/icons/cancel.svg?react';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<AlertProps> = {
  iconMapping: {
    success: (
      <Box
        sx={{
          width: 24,
          height: 24,
          boxShadow: `0px 0px 6px 0px #1BD17A33,
                      0px 0px 22px 0px #1BD17A66,
                      0px 0px 68px 0px #1BD17AB2`,
          borderRadius: '50%',
        }}
      >
        <SvgIcon
          sx={{
            color: (theme) => theme.palette.success.light,
          }}
        >
          <CheckCircleIcon />
        </SvgIcon>
      </Box>
    ),
    error: (
      <Box
        sx={{
          width: 24,
          height: 24,
          boxShadow: `0px 0px 68px 0px #F75D5FB2,
                  0px 0px 22px 0px #F75D5F66,
                  0px 0px 6px 0px #F75D5F33`,
          borderRadius: '50%',
        }}
      >
        <SvgIcon
          sx={{
            color: (theme) => theme.palette.error.light,
          }}
        >
          <CancelIcon />
        </SvgIcon>
      </Box>
    ),
  },
};

export const styleOverrides: Partial<
  OverridesStyleRules<keyof AlertClasses, 'MuiAlert', Omit<Theme, 'components'>>
> = {
  standard: ({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.background.paper),
    backgroundColor: theme.palette.background.paper,
  }),
  icon: {
    alignItems: 'center',
  },
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
