import { Outlet, useNavigate } from 'react-router-dom';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import {
  Box,
  Card,
  Modal,
  Paper,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const MobileModalLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  return onMobile ? (
    <Modal open={true}>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          }}
          height={'90dvh'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Card
            sx={{
              height: '100%',
              borderTopRightRadius: '24px',
              borderTopLeftRadius: '24px',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: (theme) => theme.palette.background.default,
            }}
            elevation={0}
          >
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
              }}
            >
              <Box flex={1}>{children}</Box>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  ) : (
    <div>{children}</div>
  );
};

export default MobileModalLayout;
