import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ListItem,
  ListItemText,
  Stack,
  SvgIcon,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  RfqDetail,
  RfqStatus,
  useGetRfqNetPrice,
  usePlaceQuote,
  useRfqDetail,
} from '../../../../services/brokers';
import { ModalLayout, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import { dateFormat, isDivisibleBy, thousandSeparated } from 'ui/utils';
import { isNil } from 'lodash';
import WarningTriangleIcon from 'ui/icons/warning_triangle.svg?react';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnCreatedAt from '../../components/ColumnCreatedAt';
import ColumnDirection from '../../components/ColumnDirection';
import InputPartialFillSize from './InputPartialFillSize';
import InputPrice from './InputPrice';
import { useIndex } from '../../../../services/bonds';
import priceOutOfMidPriceRangeAlertText from '../../components/priceOutOfMidPriceRangeAlertText';
import {
  Services,
  useTraderServiceIsDisabled,
} from '../../../../config/serviceIsDisabled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import InputSettlementDate from './InputSettlementDate';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import getSettlementDate from '../../components/getSettlementDate';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import SummaryLayout from '../../components/SummaryLayout';
import ColumnPrice from './ColumnPrice';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import SummaryList from '../../components/SummaryList';
import ColumnAsset from '../../components/ColumnAsset';
import {
  rfqDatePickerTextFieldProps,
  rfqInputBaseProps,
} from '../../components/utils';
import { NumericFormat } from 'react-number-format';
import SummarySection from '../../components/SummarySection';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import RfqHeader from './RfqHeader';
import ColumnPlatformFee from './ColumnPlatformFee';
import ColumnSettlementFee from '../../orders/components/ColumnSettlementFee';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import ColumnTaxRate from '../../orders/components/ColumnTaxRate';
import { isValid } from 'i18n-iso-countries';
dayjs.extend(utc);
dayjs.extend(timezone);

interface FormValues {
  price: number | undefined;
  size: number | undefined;
  brokerSpread: number | undefined;
  settlementDate: dayjs.Dayjs | undefined;
  enablePlatformFee: boolean;
}

const analyticsMetaProps = {
  'data-ga-event': 'action_lay_quote_init',
  'data-ga-category': 'trade',
  'data-testid': 'action-lay-quote',
};
const ActionLayQuote: React.FC<{
  children: (
    props: {
      onClick: () => void;
      disabled?: boolean;
    } & typeof analyticsMetaProps,
  ) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation(['rfq', 'validation']);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [priceConfirmed, setPriceConfirmed] = useState(false);
  const toast = useToast();

  const brokerSpreadDisabled = useTraderServiceIsDisabled(
    Services.TRADE_RFQ_BROKER_SPREAD,
  );

  const { data, refetch: refetchRfqDetail } = useRfqDetail(
    String(rfq?.id),
    {
      view: 1,
    },
    {
      enabled: open,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      cacheTime: 0,
    },
  );
  const { data: bondIndex } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: rfq?.assetIdentifier,
    },
    {
      enabled: open && Boolean(rfq?.assetIdentifier),
    },
  );
  const bondDetail = useMemo(() => bondIndex?.content[0], [bondIndex]);

  const ableToQuote = useMemo(() => {
    return Boolean(!data?.quoteId) || data?.reQuoteChance;
  }, [data?.quoteId, data?.reQuoteChance]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        price: yup
          .number()
          .required()
          .label(t('field.price').toLowerCase())
          .moreThan(0, t('validation:custom.greaterThanZero')),
        size: yup
          .number()
          .optional()
          .label(t('field.size').toLowerCase())
          .test(
            'requiredIfPartialFill',
            t('validation:custom.requiredIfPartialFill'),
            (value) => {
              if (!data?.isPartialFill) return true;
              return !isNil(value);
            },
          )
          .min(
            bondDetail?.minSize || 0,
            t('validation:custom.greaterThanBondMinSize', {
              minSize: thousandSeparated(bondDetail?.minSize || 0),
            }),
          )
          .test(
            'shouldNotExceed',
            t('validation:custom.shouldNotExceed', {
              size: data?.initSize,
            }),
            (value) => {
              if (!data?.initSize) return true;
              if (!value) return true;
              return value <= data?.initSize;
            },
          )
          .test(
            'multipleOfAssetMinIncrement',
            t('validation:custom.multipleOfAssetMinIncrement', {
              minIncrement: thousandSeparated(bondDetail?.minIncrement || 0),
            }),
            isDivisibleBy(bondDetail?.minIncrement ?? 0),
          ),
        brokerSpread: yup
          .number()
          .optional()
          .nullable()
          .label(t('field.brokerSpread').toLowerCase()),
        settlementDate: yup.date().required(),
        enablePlatformFee: yup.boolean().required(),
      }),
    [t, data?.initSize, data?.isPartialFill, bondDetail?.minIncrement],
  );

  const settlementDate = useMemo(() => {
    return getSettlementDate(data);
  }, [data]);

  const defaultValues: FormValues = useMemo(() => {
    return {
      price: undefined,
      brokerSpread: 0,
      size: data?.size,
      settlementDate: settlementDate,
      enablePlatformFee: true,
    };
  }, [data?.size, settlementDate]);

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const formValues = watch();
  const isFormValuesValid = useMemo(() => {
    return validationSchema.isValidSync(formValues);
  }, [formValues]);

  const { data: estimatedPrice, isLoading: isLoadingEstimatedPrice } =
    useGetRfqNetPrice(
      {
        id: rfq?.id,
        basePrice: formValues.price ?? 0,
        enablePlatformFee: formValues.enablePlatformFee,
        size: formValues.size ?? 0,
      },
      {
        enabled: isFormValuesValid,
      },
    );

  const sizeHelperText = useMemo(() => {
    if (
      data?.isPartialFill &&
      formValues.size &&
      formValues.size < data?.size
    ) {
      return t('tooltip.partialFillSizeWillBeLowPriority');
    }
    return '';
  }, [data?.isPartialFill, data?.size, formValues.size, t]);

  const priceHelperText = useMemo(() => {
    // If offered price is out of mid price +- 40% range, show alert
    if (formValues.price && bondDetail?.midPriceClean) {
      const alert = priceOutOfMidPriceRangeAlertText(
        bondDetail,
        formValues.price,
        t,
      );

      if (alert) {
        return alert;
      }
    }

    if (data?.bestQuote && formValues.price) {
      switch (true) {
        case data?.direction === 'BUY' &&
          Number(formValues.price) >= data?.bestQuote:
          return t('tooltip.theresABetterPrice');

        case data?.direction === 'SELL' &&
          Number(formValues.price) <= data?.bestQuote:
          return t('tooltip.theresABetterPrice');
      }
    }

    return '';
  }, [
    data?.bestQuote,
    formValues.price,
    data?.direction,
    bondDetail?.midPriceClean,
  ]);

  const handleClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    reset();
    setOpen(false);
    setPriceConfirmed(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const { mutate, isLoading: isLoadingPlaceQuote } = usePlaceQuote({
    onSuccess: () => {
      toast.success(
        t('message.quotePlacedSuccess'),
        t('title.quotePlacedSuccess'),
      );
      onClose();
      onSuccess?.();
    },
    onError: async (error, variables) => {
      const resData = await refetchRfqDetail().then((res) => {
        return res.data;
      });
      if (resData?.status === RfqStatus.Quoted) {
        toast.error(t('message.quotedAlready'), t('title.quotedAlready'));

        setOpen(false);
        return;
      }

      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });
  const isLoading = isLoadingPlaceQuote;

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    if (!rfq) return;
    if (!formData.price) return;

    await mutate({
      id: String(rfq.id),
      price: formData.price,
      ...(data?.isPartialFill && { size: formData.size }),
      ...(!brokerSpreadDisabled && {
        brokerSpread: formData.brokerSpread ?? 0,
      }),
      ...(formData.settlementDate && {
        settlementDate: dayjs(formData.settlementDate).format('YYYY-MM-DD'),
      }),
      enablePlatformFee: formData.enablePlatformFee,
    });
  };

  const renderActions = useCallback(
    (data: RfqDetail) =>
      !priceConfirmed ? (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          <Box>
            <Button
              variant={isFormValuesValid ? 'contained' : 'text'}
              onClick={() => {
                if (isFormValuesValid) {
                  setPriceConfirmed(true);
                }
              }}
              disabled={!isFormValuesValid || !ableToQuote}
              size={onMobile ? 'large' : 'medium'}
              fullWidth={onMobile}
            >
              {t('action.layQuote')}
            </Button>
          </Box>
        </Stack>
      ) : (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          <Button
            variant="outlined"
            onClick={() => setPriceConfirmed(false)}
            size={onMobile ? 'large' : 'medium'}
            fullWidth={onMobile}
          >
            {t('action.reselectBroker')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            size={onMobile ? 'large' : 'medium'}
            data-ga-event="action_lay_quote_submit"
            data-ga-category="trade"
            data-testid="action-lay-quote-submit"
            disabled={isLoading}
            fullWidth={onMobile}
          >
            {t('common:action.confirm')}
          </Button>
        </Stack>
      ),
    [priceConfirmed, isFormValuesValid, ableToQuote, t, isLoading, onMobile],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={1} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <SummaryLayout
              header={
                <Stack spacing={1}>
                  <TypographyWithLoading loading={isLoadingData}>
                    <RfqHeader
                      rfq={{
                        ...data,
                        netQuote: estimatedPrice?.netPrice,
                      }}
                    />
                  </TypographyWithLoading>
                  {!onMobile && renderActions(data)}
                </Stack>
              }
              summary={
                <SummaryList>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                  {/* <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={t('bond:field.isin')} />
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.isin ?? '-'}
                    </TypographyWithLoading>
                  </ListItem>
                  <Controller
                    name="size"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText
                            primary={`${t('field.size')}
                        ${t('bond:tooltip.bondSize', {
                          minSize: thousandSeparated(data?.bond?.minSize),
                          minIncrement: thousandSeparated(
                            data?.bond?.minIncrement,
                          ),
                        })}`}
                          />
                          {data?.isPartialFill &&
                          !priceConfirmed &&
                          ableToQuote ? (
                            <InputPartialFillSize
                              rfq={data}
                              {...rfqInputBaseProps}
                              {...field}
                              error={!!fieldState.error}
                              disabled={!ableToQuote}
                            />
                          ) : (
                            <TypographyWithLoading loading={isLoadingData}>
                              <ColumnSize
                                rfq={{
                                  ...data,
                                  size: formValues?.size,
                                }}
                              />
                            </TypographyWithLoading>
                          )}
                        </ListItem>
                        {!priceConfirmed &&
                          (fieldState.error?.message ? (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                              justifyContent={'end'}
                            >
                              <Typography
                                variant="body2"
                                color="error.main"
                                gutterBottom
                              >
                                {fieldState.error?.message}
                              </Typography>
                            </Stack>
                          ) : (
                            sizeHelperText && (
                              <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'end'}
                              >
                                <SvgIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: 'warning.main',
                                  }}
                                >
                                  <WarningTriangleIcon />
                                </SvgIcon>
                                <Typography
                                  variant="body2"
                                  color="warning.main"
                                  gutterBottom
                                  maxWidth={320}
                                >
                                  {sizeHelperText}
                                </Typography>
                              </Stack>
                            )
                          ))}
                      </>
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText primary={`${t('field.price')}`} />
                          <TypographyWithLoading loading={isLoadingData}>
                            {!priceConfirmed ? (
                              <InputPrice
                                {...rfqInputBaseProps}
                                {...field}
                                placeholder={t('tooltip.layQuote')}
                                error={!!fieldState.error}
                                disabled={!data?.reQuoteChance}
                              />
                            ) : formValues?.price ? (
                              <ColumnPrice
                                rfq={{
                                  ...data,
                                  quote: formValues.price,
                                }}
                              />
                            ) : (
                              '-'
                            )}
                          </TypographyWithLoading>
                        </ListItem>
                        {!priceConfirmed &&
                          (fieldState.error?.message ? (
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                              justifyContent={'end'}
                            >
                              <Typography
                                variant="body2"
                                color="error.main"
                                gutterBottom
                              >
                                {fieldState.error?.message}
                              </Typography>
                            </Stack>
                          ) : (
                            priceHelperText && (
                              <Stack
                                direction={'row'}
                                spacing={1}
                                alignItems={'center'}
                                justifyContent={'end'}
                              >
                                <SvgIcon
                                  sx={{
                                    width: 16,
                                    height: 16,
                                    color: 'warning.main',
                                  }}
                                >
                                  <WarningTriangleIcon />
                                </SvgIcon>
                                <Typography
                                  variant="body2"
                                  color="warning.main"
                                  gutterBottom
                                  maxWidth={360}
                                >
                                  {priceHelperText}
                                </Typography>
                              </Stack>
                            )
                          ))}
                      </>
                    )}
                  />
                  <Controller
                    name="enablePlatformFee"
                    control={control}
                    render={({ field, fieldState }) => (
                      <ListItem>
                        <ListItemText
                          primary={
                            <Stack
                              direction={'row'}
                              spacing={1}
                              alignItems={'center'}
                            >
                              <Typography fontSize={'inherit'}>
                                {t('field.platformFee')}
                              </Typography>
                              {!priceConfirmed && (
                                <Switch
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                  color={'primary'}
                                  size={'small'}
                                />
                              )}
                            </Stack>
                          }
                        />
                        <Box>
                          <TypographyWithLoading
                            loading={
                              isFormValuesValid && isLoadingEstimatedPrice
                            }
                          >
                            <ColumnPlatformFee
                              rfq={{
                                ...data,
                                quote: formValues.price,
                                enablePlatformFee: formValues.enablePlatformFee,
                                platformFee: estimatedPrice?.platformFee,
                              }}
                            />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                    )}
                  />
                  <ListItem>
                    <ListItemText primary={t('field.settlementFee')} />
                    <Box>
                      <TypographyWithLoading
                        loading={isFormValuesValid && isLoadingEstimatedPrice}
                      >
                        <ColumnSettlementFee
                          rfq={{
                            ...data,
                            settlementFee: estimatedPrice?.settlementFee,
                          }}
                        />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {((data?.quoteExchangeRate &&
                    data?.quoteExchangeRate !== 1) ||
                    (data?.bestQuoteExchangeRate &&
                      data?.bestQuoteExchangeRate !== 1)) && (
                    <ListItem>
                      <ListItemText primary={t('field.quoteExchangeRate')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnQuoteExchangeRate rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary={t('field.tax')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <ColumnTaxResidency rfq={data} />
                          <Box>
                            <ColumnTaxRate rfq={data} />
                          </Box>
                        </Stack>
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <Controller
                    name="settlementDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <ListItem>
                          <ListItemText primary={t('field.settlementDate')} />
                          <Box>
                            <TypographyWithLoading loading={isLoadingData}>
                              {priceConfirmed ? (
                                dateFormat(formValues.settlementDate)
                              ) : (
                                <InputSettlementDate
                                  {...field}
                                  slotProps={{
                                    textField: {
                                      error: !!fieldState.error,
                                      helperText: fieldState.error?.message,
                                      ...rfqDatePickerTextFieldProps,
                                    },
                                  }}
                                />
                              )}
                            </TypographyWithLoading>
                          </Box>
                        </ListItem>
                        {!priceConfirmed && fieldState.error?.message && (
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'end'}
                          >
                            <Typography
                              variant="body2"
                              color="error.main"
                              gutterBottom
                            >
                              {fieldState.error?.message}
                            </Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  />
                  <ListItem>
                    <ListItemText
                      primary={t('bond:field.tradingRestrictionType')}
                    />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.bond?.tradingRestrictionType || '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.quoteRequestedAt')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnCreatedAt rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                </SummaryList>
              }
              // extraActions={
              //   <SummarySection>
              //     <Typography variant="body2" color="text.secondary">
              //       {t('tooltip.layQuoteCheck')}
              //     </Typography>
              //   </SummarySection>
              // }
            />
            {onMobile && data && (
              <>
                <Box height={60} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),
    [
      rfq?.id,
      open,
      estimatedPrice?.netPrice,
      estimatedPrice?.settlementFee,
      estimatedPrice?.platformFee,
      onMobile,
      renderActions,
      t,
      control,
      isFormValuesValid,
      isLoadingEstimatedPrice,
      formValues.price,
      formValues.enablePlatformFee,
      formValues?.size,
      formValues.settlementDate,
      priceConfirmed,
      ableToQuote,
      sizeHelperText,
      priceHelperText,
    ],
  );

  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content?: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  useEffect(() => {
    defaultValues && reset(defaultValues);
  }, [defaultValues]);

  if (onMobile) {
    return (
      <>
        {children({
          onClick: handleClick,
          ...analyticsMetaProps,
        })}
        <ModalLayout
          open={open}
          onClose={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({
        onClick: handleClick,
        ...analyticsMetaProps,
      })}
      <BaseActionDialog
        open={open}
        onClose={() => {
          if (isLoading) return;
          onClose();
        }}
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
        data-testid="dialog-lay-quote"
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionLayQuote;
