import { TableCellClasses, TableCellProps, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<TableCellProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof TableCellClasses,
    'MuiTableCell',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.divider,
    [theme.breakpoints.down('lg')]: {
      padding: 4,
    },
  }),
  head: ({ theme }) => ({
    borderBottomWidth: 2,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.text.secondary,
    },
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
