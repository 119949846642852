import { Components, Theme } from '@mui/material';
import { styleOverrides as MuiCssBaselineStyleOverrides } from './MuiCssBaseline';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiButton from './MuiButton';
import MuiButtonBase from './MuiButtonBase';
import MuiMenu from './MuiMenu';
import MuiAlert from './MuiAlert';
import MuiToolbar from './MuiToolbar';
import MuiSlider from './MuiSlider';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiFormLabel from './MuiFormLabel';
import MuiLink from './MuiLink';
import MuiAppBar from './MuiAppBar';
import MuiSelect from './MuiSelect';
import MuiAutocomplete from './MuiAutocomplete';
import MuiPagination from './MuiPagination';
import MuiPaginationItem from './MuiPaginationItem';
import MuiToggleButton from './MuiToggleButton';
import MuiAvatar from './MuiAvatar';
import MuiCheckbox from './MuiCheckbox';
import MuiMenuItem from './MuiMenuItem';
import MuiChip from './MuiChip';
import MuiDivider from './MuiDivider';
import MuiSwitch from './MuiSwitch';
import MuiTooltip from './MuiTooltip';
import MuiTableContainer from './MuiTableContainer';
import MuiCardHeader from './MuiCardHeader';
import MuiModal from './MuiModal';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiPopover from './MuiPopover';

export const componentOverrides: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: MuiCssBaselineStyleOverrides,
  },
  MuiPaper,
  MuiTableCell,
  MuiButtonBase,
  MuiButton,
  MuiMenu,
  MuiMenuItem,
  MuiAlert,
  MuiToolbar,
  MuiSlider,
  MuiOutlinedInput,
  MuiFormLabel,
  MuiLink,
  MuiAppBar,
  MuiSelect,
  MuiAutocomplete,
  MuiPagination,
  MuiPaginationItem,
  MuiToggleButton,
  MuiAvatar,
  MuiCheckbox,
  MuiChip,
  MuiDivider,
  MuiSwitch,
  MuiTooltip,
  MuiTableContainer,
  MuiCardHeader,
  MuiModal,
  MuiBreadcrumbs,
  MuiPopover,
};
