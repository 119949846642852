import { useUserInfo } from '../services/users';
import { Services as ServicesEnum } from './type';

const disabledServices =
  import.meta.env.VITE_APP_DISABLED_SERVICES?.split(',').map(
    (service: string) => service.trim(),
  ) || [];

export const serviceIsDisabled = (service: ServicesEnum) => {
  for (const disabledService of disabledServices) {
    // if user is disabled, then user/account-settings is also disabled

    if (disabledService === service) {
      return true;
    }

    const regex = new RegExp(`^${disabledService}/`);
    if (regex.test(service)) {
      return true;
    }
  }

  return false;
};

export const useTraderServiceIsDisabled = (service: ServicesEnum) => {
  const { data } = useUserInfo();

  switch (service) {
    case ServicesEnum.TRADE_RFQ_BROKER_SPREAD:
    case ServicesEnum.TRADE_LIMIT_ORDER_BROKER_SPREAD:
      return !data?.traderFi || data?.traderFi !== 'Amstel';
    default:
      return false;
  }
};

export const Services = ServicesEnum;
