import { Theme, TooltipProps, TooltipClasses, alpha } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<TooltipProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof TooltipClasses,
    'MuiTooltip',
    Omit<Theme, 'components'>
  >
> = {
  tooltip: ({
    theme: {
      palette,
      breakpoints: { up },
    },
  }) => ({
    fontSize: 14,
    [up('sm')]: {
      fontSize: 16,
    },
    backgroundColor:
      palette.mode === 'dark'
        ? alpha(palette.background.paper, 0.5)
        : palette.background.paper,
    color: palette.text.primary,
    border: palette.mode === 'dark' ? 'none' : `1px solid ${palette.divider}`,
  }),
  arrow: ({
    theme: {
      palette,
      breakpoints: { up },
    },
  }) => ({
    color:
      palette.mode === 'dark'
        ? alpha(palette.background.paper, 0.5)
        : palette.background.paper,
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
