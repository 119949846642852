import {
  Box,
  ButtonBase,
  ButtonProps,
  Paper,
  colors,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import AidanIcon from 'ui/icons/aidan/aidan.svg?react';

const AidanFace: FC<{
  color?: string;
  backgroundColor?: string;
  size?: number;
  faceSize?: number;
  elevation?: number;
}> = ({ backgroundColor, size = 40, faceSize = 20, elevation, color }) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        color: color ?? theme.palette.primary.contrastText,
        backgroundColor: backgroundColor ?? theme.palette.primary.main,
        width: size,
        aspectRatio: '1/1',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={elevation ?? 3}
    >
      <Box width={faceSize}>
        <AidanIcon />
      </Box>
    </Paper>
  );
};

export default AidanFace;
