import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseMutateFunction,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { apiAuthClient } from '../apiClient';

export const ApiKey = 'aidan';
export const ApiConfig = {
  ask: '/aidan/chat',
  sessionHistory: (sessionId: string) => `/aidan/chat/${sessionId}`,
  sessions: 'aidan/chat/session',
};

export interface AidanChatRequest {
  question: string;
  // level?: number;
  sessionId?: string;
}

export interface AidanChatResponseItem {
  answer: string;
  sessionId: string;
}

export interface AidanChatResponse {
  answer: string;
  sessionId: string;
}

export const useAidanChat = (
  options?: UseMutationOptions<
    AidanChatResponse,
    AxiosError,
    AidanChatRequest,
    unknown
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: AidanChatRequest) =>
      apiAuthClient()
        .post(ApiConfig.ask, request)
        .then((response) => response.data),
    ...options,
    onMutate(variables) {
      queryClient.setQueryData(
        [ApiKey, 'history', variables.sessionId, 'infinite'],
        (oldData: InfiniteData<AidanSessionHistoryResponse> | undefined) => {
          console.log('oldData', oldData, variables);
          if (!oldData) {
            return {
              pages: [
                {
                  content: [
                    {
                      userId: 'user',
                      content: variables.question,
                      createdAt: new Date().toISOString(),
                      isUser: true,
                    },
                  ],
                  totalPages: 1,
                  totalElements: 1,
                },
              ],
              pageParams: [1],
            };
          }
          return {
            ...oldData,
            pages: [
              {
                ...oldData.pages[0],
                content: [
                  {
                    userId: 'user',
                    content: variables.question,
                    createdAt: new Date().toISOString(),
                    isUser: true,
                  },
                  ...oldData.pages[0].content,
                ],
              },
              ...oldData.pages.slice(1),
            ],
          };
        },
      );
    },
    onSuccess: (data, variables, context) => {
      const sessionId = data.sessionId;
      queryClient.invalidateQueries([ApiKey, 'history', sessionId]);
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
export interface AidanSessionHistoryItem {
  userId: string;
  content: string;
  createdAt: string;
  isUser: boolean;
}

interface AidanSessionHistoryResponse {
  content: AidanSessionHistoryItem[];
  totalPages: number;
  totalElements: number;
}

interface AidanSessionHistoryParams {
  page?: number;
  size?: number;
}

export const useAidanSessionHistory = (
  sessionId: string,
  params: AidanSessionHistoryParams,
  options?: UseQueryOptions<AidanSessionHistoryResponse, AxiosError>,
) =>
  useQuery({
    queryKey: [ApiKey, 'history', sessionId, params],
    queryFn: () =>
      apiAuthClient()
        .get(ApiConfig.sessionHistory(sessionId), { params })
        .then((response) => response.data),
    ...options,
  });

export const useAidanSessionHistoryInfinite = (
  sessionId: string,
  params: AidanSessionHistoryParams,
  options?: UseInfiniteQueryOptions<AidanSessionHistoryResponse, AxiosError>,
) =>
  useInfiniteQuery({
    queryKey: [ApiKey, 'history', sessionId, 'infinite'],
    queryFn: ({ pageParam = 1 }) =>
      apiAuthClient()
        .get(ApiConfig.sessionHistory(sessionId), {
          params: { ...params, page: pageParam },
        })
        .then((response) => response.data),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) return;

      const nextPage = pages.length + 1;
      if (nextPage > lastPage.totalPages) return;

      return nextPage;
    },
    ...options,
  });

interface AidanSessionItem {
  id: string;
  title: string;
  createdAt: string;
}

interface AidanSessionResponse {
  content: AidanSessionItem[];
  totalPages: number;
  totalElements: number;
}

interface AidanSessionParams {
  page: number;
  size: number;
}

export const useAidanSessions = (
  params: AidanSessionParams,
  options?: UseQueryOptions<AidanSessionResponse, AxiosError>,
) =>
  useQuery({
    queryKey: [ApiKey, 'sessions', params],
    queryFn: () =>
      apiAuthClient()
        .get(ApiConfig.sessions, { params })
        .then((response) => response.data),
    ...options,
  });

export const useAidanSessionsInfinite = (
  params: AidanSessionParams,
  options?: UseInfiniteQueryOptions<AidanSessionResponse, AxiosError>,
) =>
  useInfiniteQuery({
    queryKey: [ApiKey, 'sessions', params],
    queryFn: ({ pageParam = 1 }) =>
      apiAuthClient()
        .get(ApiConfig.sessions, {
          params: { ...params, page: pageParam },
        })
        .then((response) => response.data),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage) return;

      const nextPage = pages.length + 1;
      if (nextPage > lastPage.totalPages) return;

      return nextPage;
    },
    ...options,
  });
