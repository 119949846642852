import ReactGA from 'react-ga4';

export const checkGa4Initialized = (gaTrackingId: string) => {
  if (!ReactGA.isInitialized) {
    if (!gaTrackingId) {
      throw new Error('GA4 tracking id is not provided');
    }
    ReactGA.initialize(gaTrackingId);
  }
};
