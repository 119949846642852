import {
  Badge,
  badgeClasses,
  Box,
  BoxProps,
  ButtonBase,
  ButtonProps,
  Paper,
  useTheme,
} from '@mui/material';
import { FC, forwardRef } from 'react';
import ExpandLessIcon from 'ui/icons/expand-less.svg?react';
import ChatIcon from 'ui/icons/chat.svg?react';

const ToggleButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'ref'> & {
    open?: boolean;
    unreadNumber?: number;
    slotProps?: {
      container?: BoxProps;
    };
  }
>(({ sx, open, unreadNumber = 0, slotProps, ...props }, ref) => {
  const theme = useTheme();
  const onMobile = theme.breakpoints.down('md');
  const buttonWidth = onMobile ? 52 : 60;
  return (
    <Box
      {...slotProps?.container}
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        marginRight: 0,
        zIndex: (theme) => theme.zIndex.drawer - 1,
        ...slotProps?.container?.sx,
      }}
    >
      <ButtonBase
        ref={ref}
        {...props}
        sx={{
          borderRadius: '50%',
          ...sx,
        }}
      >
        {open ? (
          <Paper
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              backgroundColor: (theme) => theme.palette.primary.main,
              width: buttonWidth,
              aspectRatio: '1/1',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ExpandLessIcon />
          </Paper>
        ) : (
          <Badge
            variant="dot"
            color={'error'}
            invisible={unreadNumber <= 0}
            sx={{
              [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: 8,
                width: 16,
                height: 16,
                borderRadius: '50%',
                border: (theme) => `1px solid ${theme.palette.common.white}`,
                backgroundColor: (theme) => theme.palette.error.main,
              },
            }}
          >
            <Paper
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                backgroundColor: (theme) => theme.palette.primary.main,
                width: buttonWidth,
                aspectRatio: '1/1',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box width={buttonWidth / 2} height={buttonWidth / 2}>
                <ChatIcon />
              </Box>
            </Paper>
          </Badge>
        )}
      </ButtonBase>
    </Box>
  );
});

export default ToggleButton;
