export const ApiKey = 'bonds';
export const ApiConfig = {
  index: 'asset/bonds',
  yieldAnalysis: 'bond-data-monitor/yield-analysis',
  assetSearch: 'asset/search',
  assetBonds: (id: string) => `asset/bonds/${id}`,
  yieldCalculator: 'tools/bond/yield-calculator',
  statistics: 'bond-data-monitor/statistics',
  priceHistory: (id: string) => `asset/bonds/${id}/archived/price`,
  yieldHistory: (id: string) => `asset/bonds/${id}/archived/yield`,
  bondsYieldHistoryChart: 'asset/bonds/price-yield-history',
  bondLincDetail: (id: string) => `asset/bonds/${id}/bondlinc-detail`,
  bondLincDocuments: (id: string) => `asset/bonds/${id}/bondlinc-documents`,
  research: (id: string) => `asset/bonds/${id}/research`,
  requestResearch: (id: string) => `asset/bonds/${id}/request-research`,
  relatedBonds: (id: string) => `asset/bonds/${id}/related`,
  comparables: (id: string) => `asset/bonds/${id}/comparables`,
  news: 'news',
  priceMonitor: 'bond-data-monitor/bonds',
  priceMonitorDetail: (id: string) => `bond-data-monitor/bonds/${id}`,
  priceMonitorDetailGraph: (id: string) =>
    `bond-data-monitor/bonds/${id}/graph`,
  priceMonitorFirms: 'bond-data-monitor/firms',
  shareLink: (id: string) => `asset/share/bond/${id}`,
  yieldCurveGenerator: '/tools/bond/yield-curve-generator',
  yieldCurveGeneratorFilters: 'tools/bond/yield-curve-generator/filters',
  getYieldCurveFilter: (id: string) =>
    `tools/bond/yield-curve-generator/filters/${id}`,
  deleteYieldCurveFilter: (id: string) =>
    `tools/bond/yield-curve-generator/filters/${id}`,
  saveYieldCurveFilter: () => 'tools/bond/yield-curve-generator/filters',
  patchPriceMover: `price-mover`,
  approveOutlierPrice: (id: string) => `bond-data-monitor/bond-price/${id}`,
  share: (id: string) => `asset/share/bond/${id}`,
  searchHistory: 'asset/bonds/search-history',
  searchCharts: 'asset/bonds/graph',
};
