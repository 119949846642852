import { Chip, chipClasses, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

enum Direction {
  BUY = 'BUY',
  SELL = 'SELL',
}

const ColumnDirection: React.FC<{
  rfq: { direction: string } | undefined;
  variant?: 'text' | 'chip';
}> = ({ rfq, variant = 'text' }) => {
  const { t } = useTranslation('rfq');
  if (!rfq) {
    return null;
  }

  switch (true) {
    case rfq.direction === Direction.BUY && variant === 'text':
      return (
        <Typography
          color={'success.main'}
          textTransform={'uppercase'}
          fontWeight={'bold'}
        >
          {t('options.direction.buy')}
        </Typography>
      );
    case rfq.direction === Direction.BUY && variant === 'chip':
      return (
        <Chip
          label={t('options.direction.buy')}
          color="success"
          size="small"
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            [`& .${chipClasses.label}`]: {
              fontWeight: 'bold',
            },
          }}
        />
      );
    case rfq.direction === Direction.SELL && variant === 'text':
      return (
        <Typography
          color={'error.main'}
          textTransform={'uppercase'}
          fontWeight={'bold'}
        >
          {t('options.direction.sell')}
        </Typography>
      );
    case rfq.direction === Direction.SELL && variant === 'chip':
      return (
        <Chip
          label={t('options.direction.sell')}
          color="error"
          size="small"
          sx={{
            textTransform: 'uppercase',
            [`& .${chipClasses.label}`]: {
              fontWeight: 'bold',
            },
          }}
        />
      );
    default:
      return null;
  }
};

export default ColumnDirection;
