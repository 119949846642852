import i18next from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { getOptions, locales } from './settings';
import {
  withTolgee,
  Tolgee,
  I18nextPlugin,
  DevTools,
  FormatSimple,
  BackendFetch,
} from '@tolgee/i18next';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import { setYupLocale } from '../libs/utils/configYup';

const runsOnServerSide = typeof window === 'undefined';

const tolgee = Tolgee()
  .use(DevTools())
  .use(I18nextPlugin())
  .use(FormatSimple())
  .use(
    BackendFetch({
      prefix: import.meta.env.VITE_APP_TOLGEE_CONTENT_DELIVERY_PATH || '',
    }),
  )
  .init({
    // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
  });

withTolgee(i18next, tolgee)
  // .use(ICU)
  .use(initReactI18next)
  .init({
    ...getOptions(),
    preload: runsOnServerSide ? locales : [],
  });

const { t } = i18next;

setYupLocale(t);

export default i18next;
