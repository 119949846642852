import { numericFormatter } from 'react-number-format';
import { RfqDetail } from '../../../services/brokers';
import { Stack, Typography } from '@mui/material';
import InformationMark from 'ui/components/InformationMark';
import { dateTimeFormat, thousandSeparated } from 'ui/utils';

const ColumnQuoteExchangeRate: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (!rfq) {
    return null;
  }

  const rate = rfq?.quoteExchangeRate || rfq?.bestQuoteExchangeRate || 1;
  const rateTime = rfq?.quoteExchangeRateTime || rfq?.bestQuoteExchangeRateTime;

  if (rate === 1) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {rate !== 1 && (
        <InformationMark
          title={dateTimeFormat(rateTime)}
          width={16}
          placement="left"
        />
      )}
      <Typography fontSize={'inherit'}>
        {`USD / ${rfq.currency} ${thousandSeparated(rate)}`}
      </Typography>
    </Stack>
  );
};

export default ColumnQuoteExchangeRate;
