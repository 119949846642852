import { KeyboardEventHandler } from 'react';

export const onNumberInputKeyPress: KeyboardEventHandler<
  HTMLInputElement | HTMLTextAreaElement
> = (e) => {
  const regex =
    /(^\d*$)|(\.)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Enter)/;

  if (!e.key.match(regex)) {
    e.preventDefault();
    return;
  }
};
export const onPhoneInputKeyPress: KeyboardEventHandler<
  HTMLInputElement | HTMLTextAreaElement
> = (e) => {
  const regex =
    /(^[\d]*$)|(\.)|( )|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Enter)/;

  if (!e.key.match(regex)) {
    e.preventDefault();
    return;
  }
};
