import { Box, CircularProgress, alpha, useTheme } from '@mui/material';
import { FC } from 'react';

const LoadingCover: FC<{
  loading: boolean;
  zIndex?: number;
}> = ({ loading = false, zIndex = 10 }) => {
  const theme = useTheme();

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            bgcolor: alpha(theme.palette.primary.main, 0.3),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex,
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
    </>
  );
};

export default LoadingCover;
