import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SortIcon from 'ui/icons/sort-arrow-up.svg?react';
import { Box } from '@mui/material';

const SortIconButton: React.FC<{
  onClick?: () => void;
  order?: 'asc' | 'desc';
}> = ({ onClick, order }) => {
  return (
    <IconButton onClick={onClick} className="relative w-6 h-6">
      <Box
        className="absolute -top-2.5"
        sx={{
          color: (theme) =>
            order === 'asc'
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
        }}
      >
        <SortIcon />
      </Box>
      <Box
        className="absolute -bottom-2.5"
        sx={{
          transform: 'rotate(180deg)',
          color: (theme) =>
            order === 'desc'
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
        }}
      >
        <SortIcon />
      </Box>
    </IconButton>
  );
};

export default SortIconButton;
