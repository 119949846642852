import { Theme, SliderProps, SliderClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<SliderProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof SliderClasses,
    'MuiSlider',
    Omit<Theme, 'components'>
  >
> = {
  thumb: ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    border: '6px solid currentColor',
    width: 16,
    height: 16,
  }),
  rail: ({ theme }) => ({
    backgroundColor: theme.palette.divider,
    opacity: 1,
    height: 2,
  }),
  track: {
    height: 1,
    border: '0.5px solid currentColor',
  },
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
