import { createContext, useState } from 'react';

export const NotificationContext = createContext<{
  alertCount: number;
  setAlertCount?: (count: number) => void;
}>({
  alertCount: 0,
  setAlertCount: () => {
    return;
  },
});

export const NotificationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [alertCount, setAlertCount] = useState<number>(0);

  return (
    <NotificationContext.Provider value={{ alertCount, setAlertCount }}>
      {children}
    </NotificationContext.Provider>
  );
};
