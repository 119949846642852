import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, ApiKey } from './config';

export enum AnnouncementType {
  BOND = 'BOND',
  RFQ = 'RFQ',
}

type Announcement = {
  title: string;
  announcementType: AnnouncementType; // BOND | RFQ
  parameters: {
    [key: string]: string | number | boolean | null;
  };
};

type IndexItem = {
  id: number;
  title: string;
  lastMessage?: string;
  lastMessageTime?: string;
  unreadNumber: number;
  isPinned: boolean;
  avatar: React.ReactNode;
  announcement?: Announcement;
};

type IndexResponse = {
  content: IndexItem[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  numberOfElements: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  number: number;
  size: number;
  first: boolean;
  empty: boolean;
};

interface IndexParam {
  page?: number;
  size?: number;
  sortBy?: string;
  order?: 'asc' | 'desc';
}

export const useIndex =
  (axiosClient: AxiosInstance) =>
  (
    params: IndexParam,
    options?: UseQueryOptions<
      IndexResponse,
      unknown,
      IndexResponse,
      (string | IndexParam)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'index', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.index, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useIndexInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: IndexParam,
    options?: UseInfiniteQueryOptions<
      IndexResponse,
      unknown,
      IndexResponse,
      IndexResponse,
      (string | IndexParam)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'index', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.index, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const pageCount = pages.length;
        const nextPage = pageCount + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

type CreateParams = {
  invitedUserIds: number[];
  isin?: string;
};

type CreateResponse = {
  id: number;
};

export const useCreate =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<CreateResponse>,
      AxiosError,
      CreateParams,
      unknown[]
    >,
  ) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (params) => axiosClient.post(ApiConfig.create, params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'index']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };
type UpdateParams = {
  id: number;
  addUserIds?: number[];
  deleteUserIds?: number[];
  isin?: string;
  title?: string;
  isPinned?: boolean;
  announcement?: {
    announcementType: string; // BOND | RFQ
    parameters: {
      [key: string]: string | number | boolean | null;
    };
  };
};

export const useUpdate =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      UpdateParams,
      unknown[]
    >,
  ) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: ({ id, ...params }) =>
        axiosClient.patch(ApiConfig.update(String(id)), params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'messages', variables.id]);
        queryClient.invalidateQueries([ApiKey, 'index']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

type MessagesParam = {
  id: string;
  page?: number;
  size?: number;
};

export type MessageItem = {
  id: number;
  senderId: number;
  sender: string;
  senderEmail: string;
  message: string;
  messageType: string;
  isRead: boolean;
  createdAt: string;
};

type MessageResponse = {
  title: string;
  subtitle?: string;
  announcement?: Announcement;
  messages: {
    content: MessageItem[];
    pageable: {
      sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
      };
      pageNumber: number;
      pageSize: number;
      offset: number;
      paged: boolean;
      unpaged: boolean;
    };
    totalElements: number;
    totalPages: number;
    last: boolean;
    numberOfElements: number;
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    number: number;
    size: number;
    first: boolean;
    empty: boolean;
  };
};

export const useMessages =
  (axiosClient: AxiosInstance) =>
  (
    { id, ...params }: MessagesParam,
    options?: UseQueryOptions<
      MessageResponse,
      unknown,
      MessageResponse,
      (string | number | Omit<MessagesParam, 'id'>)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'messages', id, params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.messages(id), {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useMessagesInfinite =
  (axiosClient: AxiosInstance) =>
  (
    { id, ...params }: Omit<MessagesParam, 'page'>,

    options?: UseInfiniteQueryOptions<
      MessageResponse,
      unknown,
      MessageResponse,
      MessageResponse,
      (string | number | Omit<MessagesParam, 'id' | 'page'>)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'messages', String(id)],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.messages(id), {
            params: {
              page: pageParam,
              ...params,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const pageCount = pages.length;
        const nextPage = pageCount + 1;
        if (nextPage > lastPage.messages.totalPages) return;

        return nextPage;
      },

      ...options,
    });

type SendMessageParams = {
  chatId: string;
  message: string;
  messageType?: string;
};

export const useSendMessage =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      SendMessageParams,
      unknown[]
    >,
  ) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: ({ chatId, ...params }) =>
        axiosClient.post(ApiConfig.sendMessage(chatId), params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'messages', variables.chatId]);
        queryClient.invalidateQueries([ApiKey, 'index']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };
type ReadMessageParams = {
  messageIds: string[];
  isRead: boolean;
};

export const useReadMessage =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      unknown,
      unknown[]
    >,
  ) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (params: ReadMessageParams) =>
        axiosClient.patch(ApiConfig.readMessage(id), params),
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([ApiKey, 'messages', id]);
        queryClient.invalidateQueries([ApiKey, 'index']);
        queryClient.invalidateQueries([ApiKey, 'summary']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    });
  };

type SummaryResponse = {
  totalUnreadNumber: number;
};

export const useSummary =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      SummaryResponse,
      unknown,
      SummaryResponse,
      (string | number)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'summary'],
      queryFn: () => axiosClient.get(ApiConfig.summary).then((res) => res.data),
      ...options,
    });

export const useGetSessionByUsername =
  (axiosClient: AxiosInstance) =>
  (
    username: string,
    options?: UseQueryOptions<IndexItem, unknown, IndexItem, string[]>,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'getSessionByUsername', username],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.getSessionByUsername(username))
          .then((res) => res.data),
      ...options,
    });
