import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, ApiKey } from './config';

type BondIndexResponseItem = {
  id: number;
  isin: string;
  figi: string;
  cusip: string;
  ticker: string;
  bondStatus: string;
  activeStatus: boolean;
  maturityDate: string;
  firstSettlementDate: string;
  amountIssued: number;
  amountOutstanding: number;
  maturityType: string;
  putable: string;
  perpetual: boolean;
  interestAccrualDate: string;
  bondFeature: string;
  convertible: boolean;
  exchangeable: string;
  resetCoupon: string;
  basicSpread: string;
  industryGroup: string;
  industrySubGroup: string;
  industrySector: string;
  minIncrement: number;
  minSize: number;
  parAmount: number;
  exchangeCode: string;
  snpRating: string;
  moodyRating: string;
  fitchRating: string;
  rating: number;
  nextCallDate: string;
  nextCallPrice: string;
  coupon: number;
  firstCouponDate: string;
  nextCouponDate: string;
  couponFrequency: string;
  couponSummary: string;
  couponType: number;
  currencyCode: string;
  dayCountType: number;
  dayCountDescription: string;
  bidPriceClean: number;
  askPriceClean: number;
  midPriceClean: number;
  bidYieldClean: number;
  askYieldClean: number;
  midYieldClean: number;
  ytc: string;
  ytm: number;
  ytw: number;
  issuerName: string;
  longObligorName: string;
  issuerCountryCode: string;
  issuerCountryName: string;
  countryOfRiskCode: string;
  launchDate: string;
  updatedAt: number;
  priceChangedAt: number;
  bidScoreTotal: number;
  askScoreTotal: number;
  lastTradedDateTime: number;
  frequency: number;
  contributors: number;
  trendingScore: number;
  coverageScore: number;
  callable: boolean;
  guarantor: string;
  senior: boolean;
  couponDescription: string;
  issueDate: string;
  subordinated: boolean;
  isHkChapter37: boolean;
  isComplexProduct: boolean;
  capitalContingentSecurity: string;
  ratingName: string;
  priceStatus: string;
  coverageStatus: string;
  securityName: string;
  avgSpread: number;
  latestCompositeAskPrice: number;
  latestCompositeBidPrice: number;
  latestCompositeMidPrice: number;
  daysSinceLastPriceUpdate: number;
  tradingRestrictionType?: string;
};
type BondIndexResponse = {
  content: BondIndexResponseItem[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
  };
  size: number;
  sort: { empty: boolean; sorted: boolean; unsorted: boolean };
  totalElements: number;
  totalPages: number;
};

interface BondListParams {
  page?: number;
  size?: number;
  word?: string;
  sortBy?: string;
  order?: 'asc' | 'desc';
  countryCodeList?: string;
  sectorList?: string;
  currencyCodeList?: string;
  ratingList?: string;
  minYearsToMaturity?: number;
  maxYearsToMaturity?: number;
  minPrice?: number;
  maxPrice?: number;
  minCoupon?: number;
  maxCoupon?: number;
  minYTW?: number;
  maxYTW?: number;
  watchlistIdList?: string;
  isin?: string;

  [key: string]: any;
}

export const useIndex =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseQueryOptions<
      BondIndexResponse,
      unknown,
      BondIndexResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'index', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.index, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useIndexInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseInfiniteQueryOptions<
      BondIndexResponse,
      unknown,
      BondIndexResponse,
      BondIndexResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'index', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.index, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

export const useMutationIndex =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<BondIndexResponseItem>,
      AxiosError,
      BondIndexResponseItem,
      unknown
    >,
  ) =>
    useMutation({
      mutationFn: (params: BondListParams) =>
        axiosClient
          .get(ApiConfig.index, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type BondSearchChartResponseItem = {
  bond: BondIndexResponseItem;
  priceYieldHistory: {
    id: number;
    dateId: string;
    bidPriceClean: number;
    midPriceClean: number;
    askPriceClean: number;
    bidYieldClean: number;
    midYieldClean: number;
    askYieldClean: number;
    lastModifiedDateTime: string;
    dateTime: string;
  }[];
  relatedBonds: BondIndexResponseItem[];
};
type BondSearchChartResponse = {
  content: BondSearchChartResponseItem[];
  totalElements: number;
  totalPages: number;
};

export const useSearchCharts =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseQueryOptions<
      BondSearchChartResponse,
      unknown,
      BondSearchChartResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'searchCharts', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.searchCharts, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useSearchChartsInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseInfiniteQueryOptions<
      BondSearchChartResponse,
      unknown,
      BondSearchChartResponse,
      BondSearchChartResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'searchCharts', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.searchCharts, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

type BondYieldAnalysisResponse = {
  content: {
    id: number;
    [key: string]: any;
  }[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};

export const useBondYieldAnalysis =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseQueryOptions<
      BondYieldAnalysisResponse,
      unknown,
      BondYieldAnalysisResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'yieldAnalysis', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.yieldAnalysis, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type BondSearchResponse = {
  content: {
    id: number;
    [key: string]: any;
  }[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};

export const useSearch =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseQueryOptions<
      BondSearchResponse,
      unknown,
      BondSearchResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'search', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.assetSearch, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export type GetBondResponse = BondIndexResponseItem & {
  tradingRestrictionType: string;
};

export const useAssetBonds =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      GetBondResponse,
      unknown,
      GetBondResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'assetBonds', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.assetBonds(id)).then((res) => res.data),
      ...options,
    });

type BondYieldCalculatorResponse = {
  price: number;
  yield: number;
  accruedInterest: number;
  accrualDays: number;
  totalPurchaseAmount: number;
  principalAmount: number;
};

export const useYieldCalculator =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      BondYieldCalculatorResponse,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      {
        isin: string;
        settlementDate: string;
        size: number;
        yieldType: string;
        price?: number;
        yield?: number;
      },
      BondYieldCalculatorResponse
    >,
  ) =>
    useMutation({
      mutationFn: (data) =>
        axiosClient
          .post(ApiConfig.yieldCalculator, data)
          .then((res: AxiosResponse) => res.data),
      ...options,
    });

type BondStatisticsResponse = {
  quotedBonds: number;
  quotesToday: number;
  quotingFirms: number;
};

export const useStatistics =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      BondStatisticsResponse,
      unknown,
      BondStatisticsResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'statistics'],
      queryFn: () =>
        axiosClient.get(ApiConfig.statistics).then((res) => res.data),
      ...options,
    });

type BondPriceHistoryParams = {
  interval?: string;
};

type BondPriceHistoryResponse = {
  id: number;
  askPriceClean: number;
  askYieldClean: number;
  bidPriceClean: number;
  bidYieldClean: number;
  dateId: number;
  dateTime: string;
  lastModifiedDateTime: string;
  midPriceClean: number;
  midYieldClean: number;
}[];

export const usePriceHistory =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    params: BondPriceHistoryParams = {
      interval: 'QUARTERLY',
    },
    options?: UseQueryOptions<
      BondPriceHistoryResponse,
      unknown,
      BondPriceHistoryResponse,
      (string | BondPriceHistoryParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'priceHistory', id, params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.priceHistory(id), {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type BondYieldHistoryParams = {
  interval?: string;
};

type BondYieldHistoryResponse = {
  id: number;
  askPriceClean: number;
  askYieldClean: number;
  bidPriceClean: number;
  bidYieldClean: number;
  dateId: number;
  dateTime: string;
  lastModifiedDateTime: string;
  midPriceClean: number;
  midYieldClean: number;
}[];

export const useYieldHistory =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    params: BondYieldHistoryParams = {
      interval: 'QUARTERLY',
    },
    options?: UseQueryOptions<
      BondYieldHistoryResponse,
      unknown,
      BondYieldHistoryResponse,
      (string | BondYieldHistoryParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'yieldHistory', id, params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.yieldHistory(id), {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type BondLincDetailResponse = {
  id: number;
  [key: string]: any;
};

export const useBondLincDetail =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      BondLincDetailResponse,
      unknown,
      BondLincDetailResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'bondLincDetail', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.bondLincDetail(id)).then((res) => res.data),
      ...options,
    });

type BondLincDocumentsResponse = {
  masterDocumentType: string;
  subType: string;
  prospectusURL: string;
  documentStage: string;
}[];

export const useBondLincDocuments =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      BondLincDocumentsResponse,
      unknown,
      BondLincDocumentsResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'bondLincDocuments', id],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.bondLincDocuments(id))
          .then((res) => res.data),
      ...options,
    });

type BondLincResearchResponse = {
  id: number;
  companyName: string;
  hasResearch: boolean;
  fileUrl: string;
  rating: string;
  isRequested: boolean;
  updatedAt: string;
};

export const useBondLincResearch =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      BondLincResearchResponse,
      unknown,
      BondLincResearchResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'research', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.research(id)).then((res) => res.data),
      ...options,
    });

export const useBondLincRequestResearch =
  (axiosClient: AxiosInstance) =>
  (options?: UseMutationOptions<unknown, AxiosError<any>, number, unknown>) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (id: number) =>
        axiosClient
          .post(ApiConfig.requestResearch(String(id)))
          .then((res: AxiosResponse) => res.data),
      ...options,
      onSuccess: (_, id) => {
        queryClient.invalidateQueries([ApiKey, 'research', id]);
      },
    });
  };

type BondRelatedResponse = {
  id: number;
  [key: string]: any;
};

export const useBondRelated =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      BondRelatedResponse,
      unknown,
      BondRelatedResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'related', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.relatedBonds(id)).then((res) => res.data),
      ...options,
    });

type BondComparablesResponse = {
  id: number;
  [key: string]: any;
};

export const useBondComparables =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      BondComparablesResponse,
      unknown,
      BondComparablesResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'comparables', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.comparables(id)).then((res) => res.data),
      ...options,
    });

type BondNewsParams = {
  companyName?: string;
  page?: number;
  size?: number;
  tagIds?: string;
};

type BondNewsResponse = {
  links: {
    [key: string]: any;
  }[];
  news: {
    title: string;
    url: string;
    date: string;
    source: string;
    sourceUrl: string;
    eventTags: {
      id: number;
      tag: string;
      score: number;
      count: number;
    }[];
  }[];
  nodes: {
    [key: string]: any;
  }[];
  totalItems: number;
  totalPages: number;
  todayEventTags: {
    id: number;
    tag: string;
    score: number;
    count: number;
  }[];
};

export const useBondNews =
  (axiosClient: AxiosInstance) =>
  (
    params: BondNewsParams,
    options?: UseQueryOptions<
      BondNewsResponse,
      unknown,
      BondNewsResponse,
      (string | BondNewsParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'news', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.news, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useBondNewsInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: BondNewsParams,
    options?: UseInfiniteQueryOptions<
      BondNewsResponse,
      unknown,
      BondNewsResponse,
      BondNewsResponse,
      (string | BondNewsParams)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'news', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.news, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

type BondPriceMonitorResponse = {
  content: {
    id: number;
    [key: string]: any;
  }[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};

export const useBondPriceMonitor =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseQueryOptions<
      BondPriceMonitorResponse,
      unknown,
      BondPriceMonitorResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'priceMonitor', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.priceMonitor, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useBondPriceMonitorInfinite =
  (axiosClient: AxiosInstance) =>
  (
    params: BondListParams,
    options?: UseInfiniteQueryOptions<
      BondPriceMonitorResponse,
      unknown,
      BondPriceMonitorResponse,
      BondPriceMonitorResponse,
      (string | BondListParams)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'priceMonitor', params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.priceMonitor, {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

type BondPriceMonitorDetailResponse = {
  isin: string;
  issuer: string;
  pageNumber: number;
  pageSize: number;
  securityName: string;
  totalItems: number;
  totalPages: number;
  compositePrice: string;
  content: {
    id: number;
    time: string;
    sender: string;
    firm: string;
    recipient: string;
    sourceType: number;
    bidPrice: string;
    askPrice: string;
    bidYield: string;
    askYield: string;
    bidSpread: string;
    askSpread: string;
    benchmark: string;
    benchmarkISIN: string;
    benchmarkPrice: string;
    midPrice: string;
    bidOfferSpread: string;
    emailSubject: string;
    computed: string[];
  }[];
};

type BondPriceMonitorDetailParams = {
  page?: number;
  size?: number;
  sortBy?: string;
  order?: string;
};

export const useBondPriceMonitorDetail =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    params: BondPriceMonitorDetailParams,
    options?: UseQueryOptions<
      BondPriceMonitorDetailResponse,
      unknown,
      BondPriceMonitorDetailResponse,
      (string | BondPriceMonitorDetailParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'priceMonitorDetail', id, params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.priceMonitorDetail(id), {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

export const useBondPriceMonitorDetailInfinite =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    params: BondPriceMonitorDetailParams,
    options?: UseInfiniteQueryOptions<
      BondPriceMonitorDetailResponse,
      unknown,
      BondPriceMonitorDetailResponse,
      BondPriceMonitorDetailResponse,
      (string | BondPriceMonitorDetailParams)[]
    >,
  ) =>
    useInfiniteQuery({
      queryKey: [ApiKey, 'priceMonitorDetail', id, params],
      queryFn: ({ pageParam = 1 }) =>
        axiosClient
          .get(ApiConfig.priceMonitorDetail(id), {
            params: {
              ...params,
              page: pageParam,
            },
          })
          .then((res) => res.data),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) return;

        const nextPage = pages.length + 1;
        if (nextPage > lastPage.totalPages) return;

        return nextPage;
      },
      ...options,
    });

type BondPriceMonitorDetailGraphResponse = {
  askPrice: string;
  bidPrice: string;
  firm: string;
  sender: string;
  time: string;
}[];
type BondPriceMonitorDetailGraphParams = {
  page?: number;
  size?: number;
  sortBy?: string;
  order?: string;
};

export const useBondPriceMonitorDetailGraph =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    params: BondPriceMonitorDetailGraphParams,
    options?: UseQueryOptions<
      BondPriceMonitorDetailGraphResponse,
      unknown,
      BondPriceMonitorDetailGraphResponse,
      (string | BondPriceMonitorDetailGraphParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'priceMonitorDetailGraph', id, params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.priceMonitorDetailGraph(id), {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type BondPriceMonitorFirmsResponse = string[];
type BondPriceMonitorFirmsParams = {
  bondId: string;
  page?: number;
  order?: string;
};

export const useBondPriceMonitorFirms =
  (axiosClient: AxiosInstance) =>
  (
    params: BondPriceMonitorFirmsParams,
    options?: UseQueryOptions<
      BondPriceMonitorFirmsResponse,
      unknown,
      BondPriceMonitorFirmsResponse,
      (string | BondPriceMonitorFirmsParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'priceMonitorFirms', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.priceMonitorFirms, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type BondsYieldHistoryChartParams = {
  isins: string[];
  interval?: string;
};

type BondsYieldHistoryChartResponse = {
  isin: string;
  priceYields: {
    askPriceClean: number;
    askYieldClean: number;
    bidPriceClean: number;
    bidYieldClean: number;
    dateId: string;
    dateTime: string;
    lastModifiedDateTime: string;
    midPriceClean: number;
    midYieldClean: number;
  }[];
}[];

export const useBondsYieldHistoryChart =
  (axiosClient: AxiosInstance) =>
  (
    params: BondsYieldHistoryChartParams,
    options?: UseQueryOptions<
      BondsYieldHistoryChartResponse,
      unknown,
      BondsYieldHistoryChartResponse,
      (string | BondsYieldHistoryChartParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'bondsYieldHistoryChart', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.bondsYieldHistoryChart, {
            params: {
              isins: params?.isins?.join(',') ?? '',
              interval: params?.interval ?? 'QUARTERLY',
            },
          })
          .then((res) => res.data),
      ...options,
    });

type BondShareLinkResponse = {
  id: number;
  assetType: string;
  url: string;
};

export const useGetBondShareLink =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      BondShareLinkResponse,
      AxiosError,
      string,
      unknown
    >,
  ) =>
    useMutation({
      mutationFn: (id: string) =>
        axiosClient
          .get(ApiConfig.shareLink(id))
          .then((res: AxiosResponse<BondShareLinkResponse>) => res.data),
      ...options,
    });

interface YieldCurveGeneratorParams {
  ticker: string[];
  currency: string;
  curve?: string;
}

type YieldCurveGeneratorResponse = {
  action: string;
  code: number;
  data: {
    datapoints: {
      ticker: string;
      points: {
        id: number;
        [key: string]: any;
      }[];
    }[];
    curves: {
      ticker: string;
      x_points: number[];
      y_points: number[];
    }[];
  };
  msg: string;
  status: string;
};

export const useYieldCurveGenerator =
  (axiosClient: AxiosInstance) =>
  (
    params: YieldCurveGeneratorParams,
    options?: UseQueryOptions<
      YieldCurveGeneratorResponse,
      unknown,
      YieldCurveGeneratorResponse,
      (string | YieldCurveGeneratorParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'yieldCurveGenerator', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.yieldCurveGenerator, {
            params: {
              ticker: params.ticker.join(','),
              currency: params.currency,
              curve: params.curve ?? 0,
            },
          })
          .then((res) => res.data),
      ...options,
    });

type YieldCurveGeneratorFiltersResponse = {
  id: number;
  name: string;
}[];

type YieldCurveGeneratorFiltersParams = {
  recommendedOnly?: boolean;
};

export const useYieldCurveGeneratorFilters =
  (axiosClient: AxiosInstance) =>
  (
    params?: YieldCurveGeneratorFiltersParams,
    options?: UseQueryOptions<
      YieldCurveGeneratorFiltersResponse,
      AxiosError,
      YieldCurveGeneratorFiltersResponse,
      (string | YieldCurveGeneratorFiltersParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [
        ApiKey,
        'yieldCurveGeneratorFilters',
        ...(params ? [params] : []),
      ],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.yieldCurveGeneratorFilters, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });

type YieldCurveFilterResponse = {
  id: number;
  name: string;
  tickers: string[];
  deleted: string[];
};

export const yieldCurveFilterQueryFn =
  (axiosClient: AxiosInstance) => (id: number) => {
    return () =>
      axiosClient
        .get(ApiConfig.getYieldCurveFilter(String(id)))
        .then((res) => res.data);
  };

export const useYieldCurveFilter =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      YieldCurveFilterResponse,
      AxiosError,
      YieldCurveFilterResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'yieldCurveFilter', id],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.getYieldCurveFilter(id))
          .then((res) => res.data),
      ...options,
    });

interface SaveCurveFilterParams {
  id: string | null;
  name?: string;
  tickers?: string[];
  deleted?: string[];
}

export const useSaveYieldCurveFilter =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      SaveCurveFilterParams,
      unknown
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: (params) =>
        axiosClient
          .post(ApiConfig.saveYieldCurveFilter(), params)
          .then((res: AxiosResponse) => res.data),
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([ApiKey, 'yieldCurveGeneratorFilters']);
        options?.onSuccess?.(...params);
      },
    });
  };
interface DeleteCurveFilterParams {
  id: string;
}

export const useDeleteYieldCurveFilter =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      DeleteCurveFilterParams,
      unknown
    >,
  ) =>
    useMutation({
      mutationFn: ({ id }) =>
        axiosClient.delete(ApiConfig.deleteYieldCurveFilter(id)),
      ...options,
    });

interface PatchPriceMoverParams {
  id: number;
  isOutlier: boolean;
}

export const usePatchPriceMover =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      PatchPriceMoverParams,
      unknown
    >,
  ) =>
    useMutation({
      mutationFn: ({ id, isOutlier }) =>
        axiosClient.patch(ApiConfig.patchPriceMover, {
          id,
          isOutlier,
        }),
      ...options,
    });

interface ApproveOutlierPriceParams {
  id: string;
}

export const useApproveOutlierPrice =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      ApproveOutlierPriceParams,
      unknown
    >,
  ) =>
    useMutation({
      mutationFn: ({ id }) =>
        axiosClient.patch(ApiConfig.approveOutlierPrice(id)),
      ...options,
    });

interface GetShareLinkResponse {
  id: string;
  assetType: string;
  url: string;
}

export const useShareLink =
  (axiosClient: AxiosInstance) =>
  (
    id: string,
    options?: UseQueryOptions<
      GetShareLinkResponse,
      AxiosError,
      GetShareLinkResponse,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'shareLink', id],
      queryFn: () =>
        axiosClient.get(ApiConfig.share(id)).then((res) => res.data),
      ...options,
    });

type SearchHistoryParams = {
  keyword: string;
  page?: number;
  size?: number;
};

type SearchHistoryItem = {
  keyword: string;
  createdAt: string;
};

type SearchHistoryResponse = {
  content: SearchHistoryItem[];
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    sort: { empty: boolean; sorted: boolean; unsorted: boolean };
    paged: boolean;
    unpaged: boolean;
  };
  size: number;
  sort: { empty: boolean; sorted: boolean; unsorted: boolean };
  totalElements: number;
  totalPages: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  number: number;
  empty: boolean;
};

export const useSearchHistory =
  (axiosClient: AxiosInstance) =>
  (
    params: SearchHistoryParams,
    options?: UseQueryOptions<
      SearchHistoryResponse,
      AxiosError,
      SearchHistoryResponse,
      (string | SearchHistoryParams)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'searchHistory', params],
      queryFn: () =>
        axiosClient
          .get(ApiConfig.searchHistory, {
            params,
          })
          .then((res) => res.data),
      ...options,
    });
