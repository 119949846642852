import React from 'react';
import { Card, CardProps } from '@mui/material';

const SummarySection: React.FC<CardProps> = ({ children, ...props }) => {
  return (
    <Card
      elevation={0}
      {...props}
      sx={{
        bgcolor: '#232525',
        p: 2,
        alignSelf: 'stretch',
        ...props.sx,
      }}
    >
      {children}
    </Card>
  );
};

export default SummarySection;
