import {
  formControlClasses,
  inputBaseClasses,
  outlinedInputClasses,
  TextField,
  textFieldClasses,
  TextFieldProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { isNil } from 'lodash';
import { inputClasses } from '@mui/base';
import PenOnPanelIcon from 'ui/icons/pen-on-panel.svg?react';

const InputPrice: React.FC<
  Omit<TextFieldProps, 'onChange'> & {
    onChange?: (value: number) => void;
  }
> = ({ defaultValue, type, value, onChange, ...props }) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <NumericFormat
      thousandSeparator
      allowNegative={false}
      customInput={TextField}
      size={onMobile ? 'small' : 'medium'}
      variant="outlined"
      fullWidth
      decimalScale={2}
      value={value as number}
      onValueChange={({ floatValue }, { event }) => {
        if (!event) return;
        if (isNil(floatValue)) return;
        onChange?.(floatValue);
      }}
      sx={{
        [`& .${inputBaseClasses.input}.${outlinedInputClasses.input}.${inputClasses.disabled}`]:
          {
            color: 'text.secondary',
            '-webkit-text-fill-color': (theme) => theme.palette.text.secondary,
          },
      }}
      {...props}
      InputProps={{
        endAdornment: (
          <PenOnPanelIcon width={20} color={theme.palette.text.secondary} />
        ),
        sx: {
          input: {
            color: '#FFB024',
            '&::placeholder': {
              color: (theme) => theme.palette.text.secondary,
              opacity: 1 /* Firefox */,
            },
          },
        },
        ...props.InputProps,
      }}
    />
  );
};

export default InputPrice;
