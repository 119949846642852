import {
  PermissionForMembership,
  PermissionForRole,
  PERMISSIONS,
  RolePermissions,
  MembershipPermissions,
  Roles,
  Permission,
} from '../../common/functions/model';
import { useMembershipPayment } from '../../services/memberships';
import { get } from 'lodash';
import { useMemo } from 'react';

const usePermissions = () => {
  const { data, isLoading } = useMembershipPayment({
    // 1 hour
    cacheTime: 3600000,
  });

  const permissions = useMemo(() => {
    if (!data) {
      return [];
    }

    const rolesPermission = data.roles.reduce((acc, role) => {
      const rolePermission = PermissionForRole[role]?.permission || [];

      return acc.concat(rolePermission);
    }, [] as string[]);

    const membershipPermission =
      PermissionForMembership[`MEMBERSHIP_${data.membership.toUpperCase()}`]
        ?.permission || [];

    return membershipPermission.concat(rolesPermission);
  }, [data]);

  return [permissions, isLoading];
};

export const usePermitted = (requiredPermission: string) => {
  const [permissions, isLoading] = usePermissions();

  const permitted = useMemo(() => {
    if (!requiredPermission) {
      return false;
    }

    return permissions.includes(requiredPermission);
  }, [permissions, requiredPermission]);

  return [permitted, isLoading];
};

export const usePermittedV2 = (
  requiredPermission: keyof typeof PERMISSIONS,
) => {
  const { data, isLoading } = useMembershipPayment({
    // 1 hour
    cacheTime: 3600000,
    keepPreviousData: true,
  });

  const permissions = useMemo(() => {
    if (!data) {
      return {} as { [key: string]: Permission };
    }

    const rolesPermission = data.roles.reduce(
      (acc, role) => {
        const rolePermission = RolePermissions[role as Roles] || [];

        return {
          ...acc,
          ...rolePermission,
        };
      },
      {} as {
        [key: string]: Permission;
      },
    );

    const membershipPermission =
      get(
        MembershipPermissions,
        `MEMBERSHIP_${data.membership.toUpperCase()}`,
      ) ||
      ({} as {
        [key: string]: Permission;
      });

    return {
      ...rolesPermission,
      ...membershipPermission,
    };
  }, [data]);

  const permitted = useMemo(() => {
    if (!requiredPermission) {
      return 0;
    }

    return permissions[requiredPermission] || 0;
  }, [permissions, requiredPermission]);

  return [permitted, isLoading] as [Permission, boolean];
};

export default usePermissions;
