import { RfqDetail } from '../../../services/brokers';
import { dateFormat } from 'ui/utils';

const ColumnSettlementDate: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  if (!rfq) {
    return null;
  }

  return dateFormat(rfq?.settlementDate ?? rfq?.bestSettlementDate);
};

export default ColumnSettlementDate;
