import { Theme, ModalProps, ModalClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<ModalProps> = {
  container: () => document.querySelector('#modal-root') || document.body,
};

export const styleOverrides: Partial<
  OverridesStyleRules<keyof ModalClasses, 'MuiModal', Omit<Theme, 'components'>>
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
