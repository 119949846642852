import { Chip, chipClasses, Stack, Typography } from '@mui/material';
import { RfqDetail, RfqStatus } from 'services/broker';
import { thousandSeparated } from 'ui/utils';
import { useTranslation } from 'react-i18next';
import { numericFormatter } from 'react-number-format';

const ColumnPrice: React.FC<{
  rfq: RfqDetail | undefined;
  disableHighlight?: boolean;
}> = ({ rfq, disableHighlight = false }) => {
  const { t } = useTranslation('rfq');
  if (!rfq) {
    return null;
  }

  switch (true) {
    case rfq?.status === RfqStatus.WaitingQuote && Boolean(rfq?.bestQuote):
      return (
        <Typography
          fontSize={'inherit'}
          color={disableHighlight ? 'auto' : '#FFB024'}
        >
          {thousandSeparated(rfq?.bestQuote)}
        </Typography>
      );
    case rfq?.status === RfqStatus.OrderUpdated &&
      rfq?.quote &&
      rfq?.prevQuotePrice &&
      rfq?.quote !== rfq?.prevQuotePrice:
      return (
        <Stack direction="row" spacing={1}>
          <Chip
            label={t('common:option.old')}
            size="small"
            sx={{
              textTransform: 'uppercase',
              [`& .${chipClasses.root}`]: {
                backgroundColor: '#232525',
              },
              [`& .${chipClasses.label}`]: {
                color: 'text.secondary',
              },
            }}
          />
          <Typography
            color={'textSecondary'}
            component={'span'}
            sx={{
              textDecoration: 'line-through',
            }}
          >
            {thousandSeparated(rfq?.prevQuotePrice)}
          </Typography>
          <Chip
            label={t('common:option.new')}
            color="primary"
            size="small"
            sx={{
              textTransform: 'uppercase',
            }}
          />
          <Typography
            component={'span'}
            color={disableHighlight ? 'auto' : '#FFB024'}
          >
            {thousandSeparated(rfq?.quote)}
          </Typography>
        </Stack>
      );
    case Boolean(rfq?.quote):
      return (
        <Typography
          fontSize={'inherit'}
          color={disableHighlight ? 'auto' : '#FFB024'}
        >
          {numericFormatter(String(rfq?.quote), {
            thousandSeparator: ',',
            decimalScale: 2,
            fixedDecimalScale: true,
          })}
        </Typography>
      );
    default:
      return '-';
  }
};

export default ColumnPrice;
