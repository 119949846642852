import { ChatActionHandle } from '../ui/components/Chat/Chat';
import { useRef, useState } from 'react';
import { ChatRefContext } from '../ui/components/Chat/ChatRefContext';

export default function ChatContext({
  children,
}: {
  children: React.ReactNode;
}) {
  const chatRef = useRef<ChatActionHandle>(null);
  const [marginRight, setMarginRight] = useState(0);
  return (
    <ChatRefContext.Provider
      value={{
        chatRef,
        marginRight,
        setMarginRight,
      }}
    >
      {children}
    </ChatRefContext.Provider>
  );
}
