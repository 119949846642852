import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
// import SearchGlobalInput from "./search-global/components";
// import BreathingLight from '../common/breathing-light';
import { SnackbarProvider } from 'ui/components/snackbar/SnackbarBox';
import NotFoundPage from '../libs/layout/NotFoundPage';
import ProtectedRoute from '../libs/layout/ProtectedRoute';
import MobileModalLayout from '../libs/layout/MobileModalLayout';
import AppLayout from '../libs/layout/AppLayout';
import AuctionLayout from '../libs/layout/AuctionLayout';
import { serviceIsDisabled, Services } from '../config/serviceIsDisabled';
import { MAIN_PAGE_PATH } from '../libs/layout/types';
import UserFinishProfileLoader from '../libs/layout/UserFinishProfileLoader';
import BondDetailByIsinLoader from '../libs/layout/RedirectBondDetailByIsinLoader';
import { StompClientProvider } from '../services/websocket';
import ForgetPasswordLayout from '../libs/layout/ForgetPasswordLayout';
import {
  AlertNotificationProvider,
  ModalLayout,
  NotificationProvider,
} from 'ui/components';
import RfqWebsocketSubscriber from '../libs/ui/components/RfqWebsocketSubscriber';
import LimitOrderWebsocketSubscriber from '../libs/ui/components/LimitOrderWebsocketSubscriber';
import ChatContext from '../libs/layout/ChatContext';
import ChatLayout from '../libs/layout/ChatLayout';
import LimitOrderMarketContextProvider from '../libs/layout/LimitOrderMarketContext';
import BondDetailAnalysisHistoricLoader from '../pages/bonds/[id]/analysis/historic/_components/PageLoader';
import BondDetailAnalysisCurveLoader from '../pages/bonds/[id]/analysis/curve/_components/PageLoader';

export const RootLayout: React.FC = () => {
  return (
    <div>
      <StompClientProvider>
        <AuctionLayout>
          <NotificationProvider>
            <Outlet />
            <RfqWebsocketSubscriber />
          </NotificationProvider>
        </AuctionLayout>
      </StompClientProvider>
    </div>
  );
};

const LatticeFi = () => {
  return (
    <Route element={<RootLayout />}>
      <Route
        element={
          <ChatContext>
            <LimitOrderMarketContextProvider>
              <AppLayout>
                <AlertNotificationProvider>
                  <SnackbarProvider>
                    <Outlet />
                  </SnackbarProvider>
                  <LimitOrderWebsocketSubscriber />
                </AlertNotificationProvider>
              </AppLayout>
            </LimitOrderMarketContextProvider>
          </ChatContext>
        }
      >
        <Route loader={UserFinishProfileLoader}>
          {!serviceIsDisabled(Services.BOND) && (
            <>
              <Route
                element={
                  <MobileModalLayout>
                    <Outlet />
                  </MobileModalLayout>
                }
              >
                <Route
                  path="bonds/bond-detail/:id"
                  lazy={() => import('../pages/bonds/[id]/page')}
                >
                  <Route path="" element={<Navigate to="summary" replace />} />
                  <Route
                    path="summary"
                    lazy={() => import('../pages/bonds/[id]/summary/page')}
                  />
                  <Route
                    path="analysis"
                    lazy={() => import('../pages/bonds/[id]/analysis/page')}
                  >
                    <Route
                      path=""
                      element={<Navigate to="calculator" replace />}
                    />
                    <Route
                      path="calculator"
                      lazy={() =>
                        import('../pages/bonds/[id]/analysis/calculator/page')
                      }
                    />
                    <Route
                      path="historic"
                      loader={BondDetailAnalysisHistoricLoader}
                      lazy={() =>
                        import('../pages/bonds/[id]/analysis/historic/page')
                      }
                    />
                    <Route
                      path="curve"
                      loader={BondDetailAnalysisCurveLoader}
                      lazy={() =>
                        import('../pages/bonds/[id]/analysis/curve/page')
                      }
                    />
                    <Route
                      path="quote"
                      lazy={() => import('./bonds/[id]/analysis/quote/page')}
                    />
                  </Route>
                  <Route
                    path="news"
                    lazy={() => import('../pages/bonds/[id]/news/page')}
                  />
                </Route>
                <Route
                  path="bonds/bond-detail/isin/:isin"
                  loader={BondDetailByIsinLoader}
                />
              </Route>
              <Route path="bonds" lazy={() => import('./bonds/page')} />
              <Route
                path="bonds/bond-detail"
                lazy={() => import('../pages/bonds/[id]/page')}
              />
            </>
          )}
          {!serviceIsDisabled(Services.PORTFOLIO) && (
            <Route
              path="clients"
              element={<ProtectedRoute requiredPermission="menu_clients" />}
            >
              <Route
                path=""
                lazy={() => import('../pages/portfolio-rm/page')}
              />
            </Route>
          )}
          {!serviceIsDisabled(Services.DASHBOARD) && (
            <Route
              path="dashboard"
              lazy={() => import('../pages/dashboard/page')}
            />
          )}
          <Route path="tools" lazy={() => import('../pages/tools/page')}>
            <Route path="" element={<Navigate to="yield-calculator" />} />
            {/* {!serviceIsDisabled(Services.BOND_YIELD_CALCULATOR) && (
              <Route
                path="yield-calculator"
                lazy={() =>
                  import('../pages/tools/components/BondYieldCalculator')
                }
              />
            )}
            {!serviceIsDisabled(Services.BOND_HISTORY) && (
              <Route
                path="price-history"
                loader={BondToolsPriceHistoryLoader}
                lazy={() => import('../pages/tools/components/BondHistory')}
              />
            )}
            {!serviceIsDisabled(Services.BOND_YIELD_CURVE) && (
              <Route
                path="yield-curve-generator"
                loader={BondToolsYieldCurveGeneratorLoader}
                lazy={() =>
                  import('../pages/tools/components/BondYieldCurveGenerator')
                }
              />
            )} */}
            {!serviceIsDisabled(Services.BOND_PRICE_MONITOR) && (
              <Route
                path="price-monitor"
                lazy={() =>
                  import('../pages/tools/components/BondPriceMonitor')
                }
              />
            )}
            {/* {!serviceIsDisabled(Services.BOND_YIELD_ANALYSIS) && (
              <Route
                path="yield-analysis"
                lazy={() => import('../pages/bond-yield-analysis/page')}
              />
            )} */}
          </Route>
          <Route
            path="monitoring"
            lazy={() => import('../pages/monitoring/page')}
          >
            <Route path="" element={<Navigate to="watchlist" replace />} />
            {!serviceIsDisabled(Services.WATCHLIST) && (
              <Route
                path="watchlist"
                lazy={() => import('../pages/watchlist/page')}
              />
            )}
            {!serviceIsDisabled(Services.PORTFOLIO) && (
              <Route
                path="portfolio"
                lazy={() => import('../pages/portfolio/page')}
              />
            )}
            {!serviceIsDisabled(Services.ALERT) && (
              <Route path="alert" lazy={() => import('../pages/alerts/page')} />
            )}
            {!serviceIsDisabled(Services.PORTFOLIO) && (
              <Route
                path="alert"
                lazy={() => import('../pages/portfolio-rm/page')}
              />
            )}
          </Route>
          <Route path="settings">
            <Route
              path="account"
              lazy={() => import('../pages/settings/account/page')}
            >
              <Route path="" element={<Navigate to="detail" replace />} />
              <Route
                path="detail"
                lazy={() =>
                  import('../pages/settings/account/components/AccountDetail')
                }
              />
              {!serviceIsDisabled(Services.TRADE_BROKER) && (
                <Route
                  path="broker"
                  lazy={() => import('../pages/brokers/page')}
                />
              )}
              <Route path="kyc" element={<div></div>} />
            </Route>
            <Route
              element={
                <ModalLayout height={'100vh'}>
                  <Outlet />
                </ModalLayout>
              }
            >
              <Route
                element={
                  <ForgetPasswordLayout>
                    <Outlet />
                  </ForgetPasswordLayout>
                }
              >
                <Route
                  path="account/forget-password"
                  lazy={() => import('../pages/forget-password/page')}
                />
                <Route
                  path="account/verify-phone-number"
                  lazy={() => import('../pages/verify-phone-number/page')}
                />
              </Route>
            </Route>
          </Route>
          {!serviceIsDisabled(Services.TRADE) && (
            <>
              <Route
                element={
                  <MobileModalLayout>
                    <Outlet />
                  </MobileModalLayout>
                }
              >
                <Route
                  path="rfq/orders/:id"
                  lazy={() => import('../pages/rfq/orders/[id]/page')}
                />
              </Route>
              <Route
                path="rfq/orders"
                lazy={() => import('../pages/rfq/orders/page')}
              />
              <Route
                path="rfq/blotter"
                lazy={() => import('../pages/rfq/blotter/page')}
              />
              {!serviceIsDisabled(Services.TRADE_TRADER_TOOL) && (
                <Route
                  path="trader-tool"
                  lazy={() => import('../pages/trader-tool/page')}
                >
                  <Route path="" element={<Navigate to="market" replace />} />
                  <Route
                    path="market"
                    lazy={() => import('../pages/trader-tool/market/page')}
                  />
                  <Route
                    path="create-order"
                    lazy={() =>
                      import('../pages/trader-tool/create-order/page')
                    }
                  />
                  <Route
                    path="my-orders"
                    lazy={() => import('../pages/trader-tool/my-orders/page')}
                  />
                  {/* order-matches */}
                  {!serviceIsDisabled(
                    Services.TRADE_TRADER_TOOL_ORDER_MATCHES,
                  ) && (
                    <Route
                      path="order-matches"
                      lazy={() =>
                        import('../pages/trader-tool/order-matches/page')
                      }
                    />
                  )}
                </Route>
              )}
            </>
          )}
          <Route
            path="membership"
            element={<Navigate to={'/app/settings/account/detail'} replace />}
          />
          <Route path="faq" lazy={() => import('../pages/faq/page')}></Route>
          <Route
            path="faq/:category"
            lazy={() => import('../pages/faq/[category]/page')}
          />
          <Route
            path="faq/video/:id"
            lazy={() => import('../pages/faq/video/[id]/page')}
          />
        </Route>
        <Route
          path="finish-profile"
          lazy={() => import('../pages/finish-profile/page')}
        />
        <Route path="" element={<Navigate to={MAIN_PAGE_PATH} replace />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route
        element={
          <ChatLayout>
            <SnackbarProvider>
              <Outlet />
            </SnackbarProvider>
          </ChatLayout>
        }
      >
        <Route loader={UserFinishProfileLoader}>
          <Route path="chats" lazy={() => import('../pages/chats/page')} />

          {!serviceIsDisabled(Services.AIDAN) && (
            <Route
              path="chats/aidan"
              lazy={() => import('../pages/chats/aidan/page')}
            />
          )}
          <Route
            path="chats/:id"
            lazy={() => import('../pages/chats/[id]/page')}
          />
        </Route>
      </Route>
    </Route>
  );
};

export default LatticeFi;
