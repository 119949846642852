import { RfqDetail } from 'services/broker';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesZh from 'i18n-iso-countries/langs/zh.json';
import { lowerCase } from 'lodash';
import * as OpenCC from 'opencc-js';
import { useMemo } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';
import InformationMark from 'ui/components/InformationMark';

countries.registerLocale(countriesEn);
countries.registerLocale(countriesZh);
const twConverter = OpenCC.Converter({
  from: 'cn',
  to: 'tw',
});

const ColumnTaxResidency = ({
  rfq,
  variant = 'default',
  lang,
}: {
  rfq: RfqDetail | undefined;
  variant?: 'default' | 'small';
  lang?: string;
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = lang ?? i18n.resolvedLanguage;
  const currentMainLanguage = currentLanguage?.split('-')[0];
  const isZhTw = currentLanguage === 'zh-TW';

  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isValidCountryCode = useMemo(() => {
    if (!rfq?.taxResidency) {
      return false;
    }
    return countries.isValid(rfq.taxResidency.toLowerCase());
  }, [rfq?.taxResidency]);

  if (
    !rfq?.taxResidency ||
    !isValidCountryCode ||
    !currentLanguage ||
    !currentMainLanguage
  ) {
    return '-';
  }

  if (variant === 'small') {
    const code = rfq.taxResidency.toLowerCase();
    return isZhTw
      ? twConverter(countries.getName(code, 'zh') ?? '')
      : countries.getName(code, currentMainLanguage);
  }

  return (
    // <Stack direction={'row'} spacing={1} alignItems={'center'}>
    //   <CircleFlag
    //     countryCode={lowerCase(rfq.taxResidency)}
    //     height={onMobile ? 16 : 24}
    //   />
    //   <Typography fontSize={'inherit'} fontWeight={'inherit'}>
    //     {isZhTw
    //       ? twConverter(
    //           countries.getName(rfq.taxResidency.toLowerCase(), 'zh') ?? '',
    //         )
    //       : countries.getName(
    //           rfq.taxResidency.toLowerCase(),
    //           currentMainLanguage,
    //         )}
    //   </Typography>
    // </Stack>

    <InformationMark
      title={
        <Typography fontSize={'inherit'} fontWeight={'inherit'}>
          {isZhTw
            ? twConverter(
                countries.getName(rfq.taxResidency.toLowerCase(), 'zh') ?? '',
              )
            : countries.getName(
                rfq.taxResidency.toLowerCase(),
                currentMainLanguage,
              )}
        </Typography>
      }
      width={14}
      placement="left"
      slots={{
        icon: (
          <CircleFlag countryCode={lowerCase(rfq.taxResidency)} height={14} />
        ),
      }}
    />
  );
};

export default ColumnTaxResidency;
