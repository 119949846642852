import { FC } from 'react';
import { useMeasure } from 'react-use';

const ParentSize: FC<{
  children: (props: { width: number; height: number }) => React.ReactNode;
}> = ({ children }) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      {children({
        width,
        height,
      })}
    </div>
  );
};

export default ParentSize;
