import {
  Theme,
  outlinedInputClasses,
  CSSObject,
  SelectProps,
  SelectClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<SelectProps> = {
  IconComponent: () => null,
};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof SelectClasses,
    'MuiSelect',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }): CSSObject => ({}),
  select: ({ theme }): CSSObject => ({
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.primary.main,
    },
  }),
  outlined: ({ theme }): CSSObject => ({
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
