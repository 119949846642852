export const ApiKey = 'user';
export const ApiConfig = {
  signIn: 'auth/login',
  singInWithGoogle: 'auth/login/google',
  singInWithLinkedin: 'auth/login/linkedin',
  singOut: 'auth/logout',
  signUp: 'user/v2',
  list: 'user/list',
  userInfo: 'user',
  update: 'user',
  updatePhoneNumber: 'user/phone',
  activateUser: (token: string) => `user/activate/${token}`,
  // createUser: 'user',
  // activateUser: (token: string) => `user/activate/${token}`,
  forgetPasswordUser: 'user/forget',
  resetPassword: 'user/reset_password',
  getPhoneNumberVerifyCode: 'user/verify',
  resendVerificationLink: 'user/resend-activate',
  checkLogin: 'auth/login',
};
