import { BreakpointsOptions } from '@mui/material';
import { get } from 'lodash';
import tailwindTheme from '../tailwindTheme';
import resolveConfig from 'tailwindcss/resolveConfig';

const tailwind = resolveConfig({
  content: [],
  theme: tailwindTheme,
});

const twBreakpoints = tailwind.theme?.screens;

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: Number(get(twBreakpoints, 'sm', '').replace('px', '') ?? 576),
    md: Number(get(twBreakpoints, 'md', '').replace('px', '') ?? 768),
    lg: Number(get(twBreakpoints, 'lg', '').replace('px', '') ?? 992),
    xl: Number(get(twBreakpoints, 'xl', '').replace('px', '') ?? 1200),
  },
};
