import React from 'react';
import {
  Box,
  BoxProps,
  Card,
  IconButton,
  Modal,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from 'ui/icons/cross.svg?react';

const BaseActionDialog: React.FC<{
  width?: BoxProps['width'];
  open: boolean;
  onClose?: () => void;
  cover?: React.ReactNode;
  children: React.ReactNode;
  closeOnEscape?: boolean;
  disableRestoreFocus?: boolean;
  'data-testid'?: string;
}> = ({
  children,
  width,
  open = false,
  onClose,
  cover,
  closeOnEscape = true,
  disableRestoreFocus = false,
  'data-testid': dataTestId = 'dialog-action',
}) => {
  const theme = useTheme();
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const showCover = !onTablet && Boolean(cover);

  return (
    <Modal
      open={open}
      onClose={closeOnEscape ? onClose : undefined}
      disableRestoreFocus={disableRestoreFocus}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width={
          width ?? {
            xs: '90%',
            md: '80%',
            lg: showCover ? 928 : 600,
          }
        }
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-testid={dataTestId}
      >
        <Card
          sx={{
            position: 'relative',
            maxHeight: 'calc(100vh - 64px)',
            overflow: 'auto',
            display: 'flex',

            borderRadius: '24px',
          }}
          elevation={0}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {showCover && cover}
          <Box
            flex={1}
            maxWidth={{
              xs: '100%',
              md: '100%',
              lg: showCover ? '50%' : '100%',
            }}
          >
            {children}
          </Box>
          {onClose && (
            <Box
              sx={{
                position: 'absolute',
                top: 4,
                right: 4,
              }}
            >
              <IconButton
                onClick={() => {
                  onClose();
                }}
                sx={{
                  p: 1,
                }}
              >
                <CloseIcon width={14} color="#6A707B" />
              </IconButton>
            </Box>
          )}
        </Card>
      </Box>
    </Modal>
  );
};

export default BaseActionDialog;
