import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import ChatroomBar from './ChatroomBar';
import ChatMessage from './ChatMessage';
import { Message, MessageType } from './type';
import { isNil } from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMeasure } from 'react-use';
import { ChatRoomInput } from './ChatRoomInput';
import { useIsScrolledToBottom } from 'ui/hooks';

type ChatroomBarProps = Parameters<typeof ChatroomBar>[0];

export const ChatRoom: FC<{
  chatId: number | string;
  title: string;
  subtitle?: string;
  messages: Message[];
  announcement?: React.ReactNode;
  currentUsername: string;
  onBack?: () => void;
  onSendMessage?: (chatId: number | string, message: string) => void;
  loading?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  commands?: string[];
  slotProps?: {
    header?: Partial<ChatroomBarProps>;
  };
}> = ({
  chatId,
  title,
  subtitle,
  messages = [],
  onBack,
  currentUsername,
  onSendMessage,
  announcement,
  loading = false,
  hasNextPage = false,
  onLoadMore,
  commands = [],
  slotProps,
}) => {
  const chatroomRef = useRef<HTMLDivElement>(null);
  const chatroomBottomRef = useRef<HTMLDivElement>(null);
  const [chatRoomBarRef, { height }] = useMeasure<HTMLDivElement>();
  const [chatInputRef, { height: inputHeight }] = useMeasure<HTMLDivElement>();
  const [initialized, setInitialized] = useState(false);
  const isScrolledToBottom = useIsScrolledToBottom(chatroomRef);

  const [loadingMoreScrollPosition, setLoadingMoreScrollPosition] = useState(0);

  const messagesFormatWithAvatar = useMemo(() => {
    const formatted = messages.reduce(
      (acc, message) => {
        if (
          isNil(acc.lastMessage?.senderId) ||
          acc.lastMessage.senderId !== message.senderId ||
          acc.lastMessage.messageType === MessageType.OPERATION
        ) {
          acc.messages.push({
            ...message,
            showAvatar: true,
          });
        } else {
          acc.messages.push({
            ...message,
            showAvatar: false,
          });
        }
        acc.lastMessage = message;
        return acc;
      },
      {
        messages: [],
        lastMessage: null,
      } as {
        messages: (Message & { showAvatar: boolean })[];
        lastMessage: Message | null;
      },
    );

    return formatted;
  }, [messages]);

  const handleSendMessage = (message: string) => {
    onSendMessage?.(chatId, message);
  };

  const [loadingNextRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: () => {
      if (chatroomRef.current) {
        const scrollPositionFromBottom =
          chatroomRef.current?.scrollHeight - chatroomRef.current?.scrollTop;
        setLoadingMoreScrollPosition(scrollPositionFromBottom);
      }
      onLoadMore?.();
    },
    rootMargin: '32px 0px',
  });

  const scrollToEnd = (
    behavior: 'auto' | 'smooth' | 'instant' = 'smooth',
    timeout = 0,
  ) => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior });
    }, timeout);
  };

  useEffect(() => {
    if (!initialized && messages.length > 0) {
      setInitialized(true);
      scrollToEnd('instant');
    } else {
      if (!isScrolledToBottom) {
        if (loadingMoreScrollPosition > 0) {
          chatroomRef.current?.scrollTo({
            top: chatroomRef.current?.scrollHeight - loadingMoreScrollPosition,
          });
        }

        return;
      }
      scrollToEnd('smooth', 100);
    }
  }, [messages]);

  useEffect(() => {
    isScrolledToBottom && scrollToEnd('instant');
  }, [height]);

  return (
    <Stack id="chatRoom" height={'100%'} justifyContent={'flex-end'}>
      <ChatroomBar
        ref={chatRoomBarRef}
        title={title}
        subtitle={subtitle}
        onBack={onBack}
        // announcement={announcement}
        {...slotProps?.header}
      />
      <Stack
        height={`calc(100% - ${height}px)`}
        overflow={'hidden'}
        justifyContent={'flex-end'}
      >
        {announcement}
        <Box
          ref={chatroomRef}
          flex={1}
          position={'relative'}
          sx={{
            overflowY: 'auto',
            // hide scrollbar
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            overscrollBehavior: 'contain',
          }}
          onScroll={(e) => e.stopPropagation()}
        >
          <Stack height={'100%'}>
            {hasNextPage && (
              <Box>
                {!loading ? (
                  <Box ref={loadingNextRef} height={32} width={'100%'} />
                ) : (
                  <Box height={32} width={'100%'} />
                )}
              </Box>
            )}
            <Stack flex={1} spacing={1} p={2} justifyContent={'flex-end'}>
              {messagesFormatWithAvatar.messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  align={
                    message?.senderEmail !== currentUsername ? 'left' : 'right'
                  }
                  message={message}
                />
              ))}
            </Stack>
            <Box ref={chatroomBottomRef} height={0} width={'100%'} />
          </Stack>
        </Box>
        {/* Spacer for chat input */}
        <Box>
          <Box height={inputHeight} />
        </Box>
      </Stack>
      <ChatRoomInput
        ref={chatInputRef}
        onCommit={handleSendMessage}
        commands={commands}
      />
    </Stack>
  );
};
