import React from 'react';
import { Skeleton, SkeletonProps, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material';

const TypographyWithLoading: React.FC<
  TypographyProps & {
    loading?: boolean;
    children?: React.ReactNode;
    SkeletonProps?: SkeletonProps;
  }
> = ({ loading, children, SkeletonProps, ...props }) => {
  const isChildrenText =
    typeof children === 'string' || typeof children === 'number';

  return loading ? (
    <Typography {...props}>
      <Skeleton variant="text" width={100} {...SkeletonProps} />
    </Typography>
  ) : isChildrenText ? (
    <Typography {...props}>{children}</Typography>
  ) : (
    children
  );
};

export default TypographyWithLoading;
