import { FC, useMemo } from 'react';
import ChatroomAnnouncement from 'ui/components/chat/ChatroomAnnouncement';
import { Announcement, AnnouncementType } from 'ui/components/chat/type';
import { Bond, useIndex } from '../../../../services/bonds';
import { get } from 'lodash';
import { formatBondName } from 'ui/utils';
import ChatroomAnnouncementAction from './ChatroomAnnouncementAction';
import { useNavigate } from 'react-router-dom';
import { useRfqDetail } from '../../../../services/brokers';
import { usePermittedV2 } from '../../../hooks/usePermissions';

const TerminalChatroomAnnouncement: FC<{
  announcement?: Announcement;
}> = ({ announcement }) => {
  const navigate = useNavigate();
  // AnnouncementType.BOND
  const { data: bondData } = useIndex(
    {
      page: 1,
      size: 1,
      isinList: announcement?.parameters?.isin as string,
    },
    {
      enabled:
        announcement?.announcementType === AnnouncementType.BOND &&
        !!announcement?.parameters?.isin,
    },
  );
  const bond = useMemo(
    () => get(bondData, 'content.0') as Bond | undefined,
    [bondData],
  );

  // AnnouncementType.RFQ
  const [canViewOrders] = usePermittedV2('page.order');
  const { data: rfqDetail } = useRfqDetail(
    String(announcement?.parameters?.rfqId),
    { view: canViewOrders ? 0 : 1 },
    {
      enabled:
        announcement?.announcementType === AnnouncementType.RFQ &&
        !!announcement?.parameters?.rfqId,
    },
  );

  const title = useMemo(() => {
    switch (announcement?.announcementType) {
      case AnnouncementType.BOND:
        return bond ? formatBondName(bond) : '';
      case AnnouncementType.RFQ:
        return rfqDetail?.assetName || '';
      default:
        return '';
    }
  }, [announcement, bond, rfqDetail]);

  const onClick = useMemo(() => {
    switch (announcement?.announcementType) {
      case AnnouncementType.BOND:
        return () => {
          bond && navigate(`/app/bonds/bond-detail/${bond?.id}`);
        };
      default:
        return undefined;
    }
  }, [announcement, bond]);

  if (!title) return null;

  return (
    <ChatroomAnnouncement
      title={title}
      action={<ChatroomAnnouncementAction announcement={announcement} />}
      onClick={onClick}
    />
  );
};

export default TerminalChatroomAnnouncement;
