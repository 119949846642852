import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Link, useLocation, useNavigate, LinkProps } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  Avatar,
  Badge,
  ButtonBase,
  Collapse,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  Portal,
  SvgIcon,
  Tab,
  Tabs,
  alpha,
  badgeClasses,
  listItemIconClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Menu from 'ui/components/Menu';
import { useTranslation } from 'react-i18next';
import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { MenuInfo } from './types';
import MenuIcon from 'ui/icons/menu.svg?react';
import DefaultUserAvatar from 'ui/icons/user-avatar.svg?react';
import GearIcon from 'ui/icons/gear.svg?react';
import EarthIcon from 'ui/icons/earth.svg?react';
import FileClockIcon from 'ui/icons/file-clock.svg?react';
import ArrowChevronDownIcon from 'ui/icons/chevron-down.svg?react';
import ActionSelectLanguage from '../../libs/ui/components/actions/ActionSelectLanguage';
import { ToggleColorModeSwitch } from 'ui/themes';
import CloseIcon from '@mui/icons-material/Close';
import { serviceIsDisabled, Services } from '../../config/serviceIsDisabled';
import { useUserInfo } from '../../services/users';
import { useSummary } from '../../services/brokers';
import ActionSignOut from './ActionSignOut';
import { usePermittedV2 } from '../../libs/hooks/usePermissions';
import { StompClientContext } from '../../services/websocket';

const SettingMenuItem = (props: MenuItemProps) => (
  <MenuItem {...props} className="py-4" />
);

const SettingListItem = (props: ListItemProps) => (
  <ListItem {...props} className="py-4" />
);

const OrderCount = () => {
  const { connected: websocketConnected } = useContext(StompClientContext);
  const { data: summaryData } = useSummary(
    {},
    {
      keepPreviousData: true,
      refetchInterval: websocketConnected ? false : 1000 * 5,
    },
  );

  const count = useMemo(() => {
    return summaryData?.total || 0;
  }, [summaryData]);

  if (count === 0) {
    return null;
  }

  return (
    <Avatar
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.primary.contrastText,
        width: 20,
        height: 20,
        fontSize: 10,
        fontWeight: 'bold',
      }}
    >
      {count > 99 ? '99+' : count}
    </Avatar>
  );
};

const HasNewNotificationBadge = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [invisible, setInvisible] = useState(true);
  const { data: summaryData } = useSummary(
    {},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      cacheTime: 0,
      refetchInterval: 1000 * 15,
    },
  );

  const count = useMemo(() => {
    return summaryData?.total || 0;
  }, [summaryData]);

  useEffect(() => {
    setInvisible(count === 0);
  }, [count]);

  return (
    <Badge
      variant="dot"
      color={'primary'}
      invisible={invisible}
      sx={{
        [`& .${badgeClasses.badge}`]: {
          top: onMobile ? 4 : 6,
          right: onMobile ? 4 : 6,
          width: onMobile ? 12 : 14,
          height: onMobile ? 12 : 14,
          borderRadius: '50%',
          border: (theme) => `2px solid ${theme.palette.common.white}`,
          backgroundColor: (theme) => theme.palette.error.main,
        },
      }}
    >
      {children}
    </Badge>
  );
};

const useSettings = () => {
  const { t } = useTranslation([
    'rfq',
    'user',
    'membership',
    'client',
    'trade',
  ]);

  const [clientsPagePermitted] = usePermittedV2('page.client');
  const [canViewOrders] = usePermittedV2('page.order');
  const [canViewBlotterPage] = usePermittedV2('page.blotter');

  const menuItems = useMemo(
    () =>
      [
        !serviceIsDisabled(Services.USER_ACCOUNT_SETTINGS) && {
          label: t('user:title.account'),
          path: '/app/settings/account',
          icon: (
            <SvgIcon fontSize="small">
              <GearIcon />
            </SvgIcon>
          ),
          testId: 'menu-account',
        },
        !serviceIsDisabled(Services.TRADE) &&
          canViewOrders && {
            label: (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography>{t('rfq:title.orders')}</Typography>
                <OrderCount />
              </Stack>
            ),
            path: '/app/rfq/orders',
            icon: (
              <SvgIcon fontSize="small">
                <FileClockIcon />
              </SvgIcon>
            ),
            testId: 'menu-orders',
          },
        !serviceIsDisabled(Services.TRADE) &&
          canViewBlotterPage && {
            label: (
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography>{t('rfq:title.blotter')}</Typography>
                <OrderCount />
              </Stack>
            ),
            path: '/app/rfq/blotter',
            icon: (
              <SvgIcon fontSize="small">
                <FileClockIcon />
              </SvgIcon>
            ),
            testId: 'menu-blotter',
          },
        !serviceIsDisabled(Services.TRADE_TRADER_TOOL) && {
          label: t('trade:title.traderTools'),
          path: '/app/trader-tool',
          // icon: (
          //   <SvgIcon fontSize="small">
          //     <FileClockIcon />
          //   </SvgIcon>
          // ),
          testId: 'menu-trader-tool',
        },
      ].filter(Boolean) as MenuInfo[],
    [t, clientsPagePermitted, canViewOrders, canViewBlotterPage],
  );

  return menuItems;
};

const useMenu = (): MenuInfo[] => {
  const { t } = useTranslation([
    'bond',
    'watchlist',
    'portfolio',
    'alert',
    'dashboard',
    'community',
    'kyc',
    'faq',
  ]);

  const menuItems = useMemo(
    () =>
      [
        !serviceIsDisabled(Services.DASHBOARD) && {
          label: t('dashboard:title.dashboard'),
          path: '/app/dashboard',
          testId: 'menu-dashboard',
        },
        !serviceIsDisabled(Services.BOND) && {
          label: t('bond:title.bondSearch'),
          path: '/app/bonds',
          testId: 'menu-bonds',
        },
        // {
        //   label: t('bond:title.bondTools'),
        //   path: '/app/tools',
        //   testId: 'menu-tools',
        // },
        {
          label: t('bond:title.monitoring'),
          path: '/app/monitoring',
          testId: 'menu-monitoring',
        },
        !serviceIsDisabled(Services.COMMUNITY) && {
          label: t('community:title.community'),
          path: 'https://latticefi.discourse.group/',
          newTab: true,
        },
        !serviceIsDisabled(Services.FAQ) && {
          label: t('faq:title.faq'),
          path: '/app/faq',
        },
      ].filter(Boolean) as MenuInfo[],
    [t],
  );

  return menuItems;
};

const MobileMenuItem = ({
  item,
  open = false,
  onToggleOpen,
  onClick,
}: {
  item: MenuInfo;
  open?: boolean;
  onToggleOpen?: () => void;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  if (item.sub) {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onToggleOpen?.();
          }}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              minWidth: 28,
            },
          }}
        >
          <ListItemText>{item.label}</ListItemText>
          <SvgIcon fontSize="small">
            <ArrowChevronDownIcon />
          </SvgIcon>
        </MenuItem>
        <Collapse in={open}>
          {item.sub?.map((subItem, index) => (
            <MenuItem
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                if (subItem.newTab) {
                  // is full url
                  if (subItem.path.startsWith('http')) {
                    window.open(subItem.path, '_blank');
                  } else {
                    window.open(
                      `${window.location.origin}${subItem.path}`,
                      '_blank',
                    );
                  }
                } else {
                  navigate(subItem.path);
                }
                onClick?.();
              }}
            >
              {subItem.label}
            </MenuItem>
          ))}
        </Collapse>
      </>
    );
  }

  return (
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
        if (item.newTab) {
          // is full url
          if (item.path.startsWith('http')) {
            window.open(item.path, '_blank');
          } else {
            window.open(`${window.location.origin}${item.path}`, '_blank');
          }
        } else {
          navigate(item.path);
        }
        onClick?.();
      }}
    >
      {item.label}
    </MenuItem>
  );
};

export default function NavigationMenu() {
  const { t } = useTranslation('user');
  const userSettings = useSettings();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [activeMenu, setActiveMenu] = useState(0);
  const [open, setOpen] = useState(false);
  const [collapsedOpen, setCollapsedOpen] = useState(false);
  const [openSubIndex, setOpenSubIndex] = useState<number>(-1);
  const menuItems = useMenu();

  const { data: userInfo } = useUserInfo();
  const userFullName = useMemo(() => {
    if (!userInfo) {
      return '';
    }

    return `${userInfo.firstname} ${userInfo.lastname}`;
  }, [userInfo]);

  const userName = useMemo(() => {
    if (!userInfo) {
      return '';
    }

    return userInfo.username;
  }, [userInfo]);

  const isActivatedMenu = (menu: {
    path: string;
    sub?: MenuInfo[];
    label: string;
  }) => {
    if (menu.sub) {
      return menu.sub.some((item) => item.path === location.pathname);
    }

    return location.pathname.includes(menu.path);
  };

  useEffect(() => {
    const index = menuItems.findIndex((item) => isActivatedMenu(item));
    setActiveMenu(index);
  }, [location.pathname]);

  useEffect(() => {
    if (onTablet) {
      if (open) {
        setTimeout(() => {
          setCollapsedOpen(true);
        }, 0);
      } else {
        setCollapsedOpen(false);
      }
    }
  }, [open]);

  if (onTablet) {
    return (
      <>
        <IconButton
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? (
            <CloseIcon />
          ) : (
            <HasNewNotificationBadge>
              <MenuIcon width={24} />
            </HasNewNotificationBadge>
          )}
        </IconButton>

        {open && (
          <Portal>
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                color: (theme) => theme.palette.secondary.contrastText,
                display: 'flex',
                flexDirection: 'column',
                zIndex: 100,
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <Toolbar />
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.background.default, 0.8),
                  position: 'relative',
                }}
              >
                <Collapse
                  in={collapsedOpen}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Divider />
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                      px: 2,
                    }}
                  >
                    <MenuList>
                      {menuItems?.map((item, index) => (
                        <MobileMenuItem
                          item={item}
                          data-testid={item.testId}
                          open={openSubIndex === index}
                          onToggleOpen={() => {
                            setOpenSubIndex(
                              index === openSubIndex ? -1 : index,
                            );
                          }}
                          onClick={() => {
                            setOpen(false);
                          }}
                        />
                      ))}
                      <Divider
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.text.disabled,
                          opacity: 0.2,
                        }}
                      />
                      {userSettings?.map((setting, index) => (
                        <MenuItem
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                            if (setting.newTab) {
                              // is full url
                              if (setting.path.startsWith('http')) {
                                window.open(setting.path, '_blank');
                              } else {
                                window.open(
                                  `${window.location.origin}${setting.path}`,
                                  '_blank',
                                );
                              }
                            } else {
                              navigate(setting.path);
                            }
                          }}
                        >
                          <ListItemText>{setting.label}</ListItemText>
                        </MenuItem>
                      ))}
                      <ActionSelectLanguage>
                        {({ onClick }) => (
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              onClick(e);
                            }}
                            sx={{
                              [`& .${listItemIconClasses.root}`]: {
                                minWidth: 28,
                              },
                            }}
                          >
                            <ListItemText>
                              {t('common:action.locale')}
                            </ListItemText>
                            <SvgIcon fontSize="small">
                              <ArrowChevronDownIcon />
                            </SvgIcon>
                          </MenuItem>
                        )}
                      </ActionSelectLanguage>

                      <ListItem>
                        <ListItemText>
                          {t('common:action.darkMode')}
                        </ListItemText>
                        <ToggleColorModeSwitch />
                      </ListItem>
                      <ActionSignOut>
                        {({ onClick }) => (
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              onClick();
                            }}
                          >
                            <ListItemText>{t('action.logOut')}</ListItemText>
                          </MenuItem>
                        )}
                      </ActionSignOut>
                    </MenuList>
                  </Box>
                </Collapse>
              </Box>
            </Box>
          </Portal>
        )}
      </>
    );
  }

  return (
    <Stack direction="row" spacing={2} alignItems={'center'}>
      <Tabs value={activeMenu}>
        {menuItems.map((item, tabIndex) => {
          if (item.sub) {
            return (
              <Menu key={item.path}>
                {({ openMenu, closeMenu }) => (
                  <>
                    <Menu.Button>
                      <Tab
                        data-testid={item.testId}
                        disableRipple
                        onClick={openMenu}
                        className={[
                          'rounded-none p-0 mx-2 min-w-0 align-bottom opacity-100',
                          (tabIndex === activeMenu && 'text-primary-500') || '',
                        ].join(' ')}
                        label={
                          <Typography fontWeight={'bold'}>
                            {item.label}
                          </Typography>
                        }
                      />
                    </Menu.Button>
                    <Menu.Items
                      className="mt-1"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {item.sub?.map((setting, index) => (
                        <MenuItem
                          key={index}
                          {...(setting.path && {
                            onClick: () => {
                              if (setting.newTab) {
                                // is full url
                                if (setting.path.startsWith('http')) {
                                  window.open(setting.path, '_blank');
                                } else {
                                  window.open(
                                    `${window.location.origin}${setting.path}`,
                                    '_blank',
                                  );
                                }
                              } else {
                                navigate(setting.path);
                              }
                              closeMenu();
                            },
                          })}
                        >
                          {setting.label}
                        </MenuItem>
                      ))}
                    </Menu.Items>
                  </>
                )}
              </Menu>
            );
          }

          return (
            // <NavLink
            //   data-testid={item.testId}
            //   to={item.path}
            //   key={item.path}
            //   className="text-inherit mx-2 no-underline"
            //   target={item.newTab ? '_blank' : '_self'}
            // >
            //   <Tab
            //     disableRipple
            //     className={[
            //       'rounded-none p-0 min-w-0 align-bottom opacity-100',
            //       (tabIndex === activeMenu && 'text-primary-500') || '',
            //     ].join(' ')}
            //     label={
            //       <Typography fontWeight={'bold'}>{item.label}</Typography>
            //     }

            //   to={item.path}
            //   key={item.path}
            //     LinkComponent={NavLink}
            //   />
            // </NavLink>

            <Tab
              disableRipple
              className={[
                'rounded-none p-0 min-w-0 align-bottom opacity-100',
                (tabIndex === activeMenu && 'text-primary-500') || '',
              ].join(' ')}
              label={<Typography fontWeight={'bold'}>{item.label}</Typography>}
              href={item.path}
              key={item.path}
              LinkComponent={TabLink}
              target={item.newTab ? '_blank' : '_self'}
            />
          );
        })}
      </Tabs>
      <Menu>
        {({ openMenu, closeMenu }) => (
          <>
            <Menu.Button>
              <ButtonBase
                onClick={openMenu}
                disableRipple
                data-testid="user-menu"
              >
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  {userName && (
                    <HasNewNotificationBadge>
                      <Avatar>
                        <SvgIcon>
                          <DefaultUserAvatar />
                        </SvgIcon>
                      </Avatar>
                    </HasNewNotificationBadge>
                  )}
                  <Typography color={'text.primary'}>{userFullName}</Typography>
                </Stack>
              </ButtonBase>
            </Menu.Button>
            <Menu.Items
              className="mt-1"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              elevation={1}
              data-testid="user-menu-items"
            >
              <SettingListItem>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  {userName && (
                    <Avatar>
                      <SvgIcon>
                        <DefaultUserAvatar />
                      </SvgIcon>
                    </Avatar>
                  )}
                  <Stack>
                    {userFullName && (
                      <Typography variant="h6">{userFullName}</Typography>
                    )}
                    {userName && (
                      <Typography variant="body1">{userName}</Typography>
                    )}
                  </Stack>
                </Stack>
              </SettingListItem>
              <Divider />
              {userSettings.map((setting, index) => (
                <SettingMenuItem
                  key={index}
                  {...(setting.path && {
                    onClick: () => {
                      closeMenu();
                      if (setting.newTab) {
                        // is full url
                        if (setting.path.startsWith('http')) {
                          window.open(setting.path, '_blank');
                        } else {
                          window.open(
                            `${window.location.origin}${setting.path}`,
                            '_blank',
                          );
                        }
                      } else {
                        navigate(setting.path);
                      }
                    },
                  })}
                  sx={{
                    [`& .${listItemIconClasses.root}`]: {
                      minWidth: 28,
                    },
                  }}
                  data-testid={setting.testId}
                >
                  {setting.icon && <ListItemIcon>{setting.icon}</ListItemIcon>}
                  <ListItemText>{setting.label}</ListItemText>
                </SettingMenuItem>
              ))}
              <ActionSelectLanguage>
                {({ onClick }) => (
                  <SettingMenuItem
                    onClick={onClick}
                    sx={{
                      [`& .${listItemIconClasses.root}`]: {
                        minWidth: 28,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <SvgIcon fontSize="small">
                        <EarthIcon />
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText>{t('common:action.locale')}</ListItemText>
                    <SvgIcon fontSize="small">
                      <ArrowChevronDownIcon />
                    </SvgIcon>
                  </SettingMenuItem>
                )}
              </ActionSelectLanguage>

              <SettingListItem>
                <ListItemText>{t('common:action.darkMode')}</ListItemText>
                <ToggleColorModeSwitch />
              </SettingListItem>
              <ActionSignOut
                children={({ onClick }) => (
                  <SettingMenuItem onClick={onClick}>
                    <Typography textAlign="center">
                      {t('action.logOut')}
                    </Typography>
                  </SettingMenuItem>
                )}
              />
            </Menu.Items>
          </>
        )}
      </Menu>
    </Stack>
  );
}

const TabLink = forwardRef<
  HTMLAnchorElement,
  LinkProps & {
    href: string;
  }
>(function TabLink({ href, ...props }, ref) {
  return (
    <Box sx={{ px: 1 }}>
      <Link ref={ref} {...props} to={href} />
    </Box>
  );
});
