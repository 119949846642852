/* eslint-disable react-hooks/rules-of-hooks */
import {
  useKyc as useKycService,
  useUploadBrokerStatement as useUploadBrokerStatementService,
  useFeedback as useFeedbackService,
  useJoinWaitList as useJoinWaitListService,
} from 'services/kyc';
import { apiAuthClient } from '../services/apiClient';

export const useKyc = useKycService(apiAuthClient());
export const useUploadBrokerStatement =
  useUploadBrokerStatementService(apiAuthClient());
export const useFeedback = useFeedbackService(apiAuthClient());
export const useJoinWaitList = useJoinWaitListService(apiAuthClient());
