import React, { Suspense, useEffect, useState } from 'react';
import { StorageKey } from '../../utils/storage-key';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeContext } from 'ui/themes';
import CookiePermissionDialog from '../../libs/ui/components/CookiePermissionDialog';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ScrollRestoration } from 'react-router-dom';

const RootLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openDialogue, setOpenDialogue] = useState(false);

  const currentTheme = localStorage.getItem(StorageKey.theme);

  // useEffect(() => {
  //   const version = getCookie(StorageKey.versionCookies);
  //   const currentVersion = package_json.version;
  //   if (version !== currentVersion) {
  //     // TODO It should be "true" if you want to use this pupop!
  //     setOpenDialogue(false);
  //     cleanCache();
  //   }
  // }, []);

  const onThemeChange = (theme: 'light' | 'dark') => {
    localStorage.setItem(StorageKey.theme, theme);
  };

  return (
    <Suspense fallback={<div />}>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_APP_GOOGLE_OAUTH_CLIENT_ID as string}
      >
        <ThemeContext
          defaultTheme={currentTheme as string}
          onThemeChange={onThemeChange}
        >
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            {children}
            <CookiePermissionDialog />
          </StyledEngineProvider>
        </ThemeContext>
      </GoogleOAuthProvider>

      <ScrollRestoration />
    </Suspense>
  );
};

export default RootLayout;
