import {
  CSSObject,
  Theme,
  ButtonProps,
  ButtonClasses,
  buttonClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<ButtonProps> = {
  disableElevation: true,
};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof ButtonClasses,
    'MuiButton',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }): CSSObject => ({
    fontWeight: 'bold',
  }),
  sizeLarge: {
    padding: '12px 20px',
  },
  outlined: ({ theme }): CSSObject => ({
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
