import { Box, ButtonBase, ButtonProps, Paper, useTheme } from '@mui/material';
import { FC } from 'react';
import AidanFace from './AidanFace';
import AidanCloseIcon from 'ui/icons/aidan/aidan-expand-less.svg?react';

const ToggleButton: FC<
  ButtonProps & {
    open?: boolean;
  }
> = ({ sx, open, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 999,
      }}
    >
      <ButtonBase
        {...props}
        sx={{
          borderRadius: '50%',
          ...sx,
        }}
      >
        {open ? (
          <Paper
            sx={{
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.main,
              width: 60,
              aspectRatio: '1/1',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AidanCloseIcon />
          </Paper>
        ) : (
          <AidanFace size={60} faceSize={28} />
        )}
      </ButtonBase>
    </Box>
  );
};

export default ToggleButton;
