import {
  Theme,
  AvatarProps,
  AvatarClasses,
  avatarClasses,
} from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<AvatarProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof AvatarClasses,
    'MuiAvatar',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#44444E' : '#F2F6FF',
    color: theme.palette.mode === 'dark' ? '#6A707B' : '#C9CFDC',
    fontSize: 14,
    fontWeight: 'bold',
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
