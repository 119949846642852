import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Chip,
  chipClasses,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  RfqStatus,
  RfqDetail,
  useResponseUpdateOrder,
  OrderFeedbackConfirmStatus,
} from '../../../../services/brokers';
import { ModalLayout, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import { thousandSeparated } from 'ui/utils';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import dayjs from 'dayjs';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import RfqHeader from './RfqHeader';
import DetailLabel from '../../components/DetailLabel';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnQuoteTime from '../../components/ColumnQuoteTime';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import SummaryLayout from '../../components/SummaryLayout';
import ColumnPrice from './ColumnPrice';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import SummaryList from '../../components/SummaryList';
import ColumnAsset from '../../components/ColumnAsset';
import ColumnIsPartialFill from '../../components/ColumnIsPartialFill';
import ColumnTax from './ColumnTax';
import ColumnPlatformFee from './ColumnPlatformFee';
import ColumnSettlementFee from './ColumnSettlementFee';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import InformationMark from 'ui/components/InformationMark';
import ColumnTaxRate from './ColumnTaxRate';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';

const ActionResponseOrderUpdate: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSuccess?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({ children, onSuccess, onOpen, onClose: onCloseProp, rfq }) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(false);
  const toast = useToast();

  const { mutate, isLoading } = useResponseUpdateOrder({
    onSuccess: (data, variables) => {
      switch (variables?.status) {
        case OrderFeedbackConfirmStatus.Confirmed:
          toast.success(t('title.confirmOrderUpdateSuccess'));
          break;
        case OrderFeedbackConfirmStatus.Rejected:
          toast.success(
            t('title.rejectOrderUpdateSuccess'),
            t('message.rejectOrderUpdateSuccess'),
          );
          break;
      }

      setOpen(false);
      onSuccess?.();
    },
    onError: (err) => {
      const error = err?.response?.data?.error;
      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onConfirm = () => {
    mutate({
      rfqId: rfq.id.toString(),
      orderId: rfq.orderId.toString(),
      status: OrderFeedbackConfirmStatus.Confirmed,
      executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
    });
  };

  const onReject = () => {
    mutate({
      rfqId: rfq.id.toString(),
      orderId: rfq.orderId.toString(),
      status: OrderFeedbackConfirmStatus.Rejected,
      executedAt: dayjs().format('YYYY-MM-DD hh:mm:ss'),
    });
  };

  const handleClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    if (isLoading) return;
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const renderActions = useCallback(
    (rfq: RfqDetail) =>
      onMobile ? (
        <Grid container spacing={1}>
          {rfq?.traderName && (
            <Grid item xs={6}>
              <ActionOpenChatOfRfq
                username={rfq?.traderName}
                rfq={rfq}
                onSuccess={() => {
                  onClose();
                }}
              >
                {(props) => (
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    size={onMobile ? 'large' : 'medium'}
                    startIcon={
                      <SvgIcon>
                        <ChatIcon />
                      </SvgIcon>
                    }
                    fullWidth
                    {...props}
                  >
                    {t('action.openChat')}
                  </Button>
                )}
              </ActionOpenChatOfRfq>
            </Grid>
          )}
          <Grid item xs={6}>
            <Button
              variant={'outlined'}
              color={'primary'}
              disabled={isLoading}
              onClick={onReject}
              size={onMobile ? 'large' : 'medium'}
              fullWidth
            >
              {t('action.rejectOrderUpdate')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size={onMobile ? 'large' : 'medium'}
              disabled={isLoading}
              onClick={onConfirm}
              fullWidth
            >
              {t('action.confirmOrderUpdate')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          spacing={2}
        >
          {rfq?.traderName && (
            <ActionOpenChatOfRfq
              username={rfq?.traderName}
              rfq={rfq}
              onSuccess={() => {
                onClose();
              }}
            >
              {(props) => (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  size={onMobile ? 'large' : 'medium'}
                  startIcon={
                    <SvgIcon>
                      <ChatIcon />
                    </SvgIcon>
                  }
                  {...props}
                >
                  {t('action.openChat')}
                </Button>
              )}
            </ActionOpenChatOfRfq>
          )}
          <Button
            variant={'outlined'}
            color={'primary'}
            disabled={isLoading}
            onClick={onReject}
            size={onMobile ? 'large' : 'medium'}
          >
            {t('action.rejectOrderUpdate')}
          </Button>
          <Button
            variant="contained"
            size={onMobile ? 'large' : 'medium'}
            disabled={isLoading}
            onClick={onConfirm}
          >
            {t('action.confirmOrderUpdate')}
          </Button>
        </Stack>
      ),
    [isLoading, t, onMobile],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={0} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <SummaryLayout
              header={
                <Stack spacing={1}>
                  <TypographyWithLoading loading={isLoadingData}>
                    <RfqHeader rfq={data} />
                  </TypographyWithLoading>

                  {!onMobile && renderActions(data)}
                </Stack>
              }
              summary={
                <SummaryList>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={t('bond:field.isin')} />
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.isin ?? '-'}
                    </TypographyWithLoading>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.orderAccount')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnClientAccount rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {/* <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={`${t('field.size')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSize rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.price')}`} />
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnPrice rfq={data} />
                    </TypographyWithLoading>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.platformFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnPlatformFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.quoteExchangeRate !== 1 && (
                    <ListItem>
                      <ListItemText primary={t('field.quoteExchangeRate')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnQuoteExchangeRate rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary={t('field.tax')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <ColumnTaxResidency rfq={data} />
                          <Box>
                            <ColumnTaxRate rfq={data} />
                          </Box>
                        </Stack>
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementDate')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementDate rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={t('bond:field.tradingRestrictionType')}
                    />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.bond?.tradingRestrictionType || '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>

                  {onMobile ? (
                    <>
                      <ListItem>
                        <ListItemText primary={`${t('field.orderedAt')}`} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <ColumnRequestTime rfq={data} />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={`${t('field.quotedAt')}`} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <ColumnQuoteTime rfq={data} />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                    </>
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={`${t('field.orderedAt')} / ${t('field.quotedAt')}`}
                      />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnRequestTime rfq={data} /> /{' '}
                          <ColumnQuoteTime rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  {data?.orderNote && (
                    <ListItem>
                      <ListItemText primary={t('field.cancelReason')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          {data?.orderNote ?? '-'}
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                </SummaryList>
              }
            />
            {onMobile && data && (
              <>
                <Box height={132} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),

    [rfq, t, open, renderActions, onMobile],
  );
  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick })}
        <ModalLayout open={open} onClose={onClose}>
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}
      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionResponseOrderUpdate;
