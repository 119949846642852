import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ListItem,
  listItemClasses,
  ListItemText,
  listItemTextClasses,
  Stack,
  SvgIcon,
  Typography,
  typographyClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RfqStatus, useAddRFQ } from '../../../../services/brokers';
import ReactGA from 'react-ga4';
import { EventAction } from 'analytics/ga4';
import { ModalLayout, useToast } from 'ui/components';
import ActionFetchRfqDetail from '../../components/ActionFetchRfqDetail';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ChatIcon from 'ui/icons/chat.svg?react';
import ActionOpenChatOfRfq from '../../../../libs/ui/components/Chat/ActionOpenChatOfRfq';
import { RfqDetail } from '../../../../services/brokers';
import BaseActionDialog from 'ui/components/modal/BaseActionDialog';
import ColumnStatus from './ColumnStatus';
import ColumnSize from '../../components/ColumnSize';
import ColumnPrice from './ColumnPrice';
import ColumnDirection from '../../components/ColumnDirection';
import ColumnClientAccount from '../../components/ColumnClientAccount';
import ColumnQuoteTime from '../../components/ColumnQuoteTime';
import ColumnRequestTime from '../../components/ColumnRequestTime';
import ColumnSettlementDate from '../../components/ColumnSettlementDate';
import { useMeasure } from 'react-use';
import { formatApiErrorToast } from '../../../../services/apiUtils';
import ColumnAsset from '../../components/ColumnAsset';
import ColumnIsPartialFill from '../../components/ColumnIsPartialFill';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import ColumnTax from './ColumnTax';
import ColumnPlatformFee from './ColumnPlatformFee';
import ColumnSettlementFee from './ColumnSettlementFee';
import SummaryLayout from '../../components/SummaryLayout';
import RfqDialogLayout from '../../components/RfqDialogLayout';
import SummaryList from '../../components/SummaryList';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import InformationMark from 'ui/components/InformationMark';
import ColumnTaxResidency from '../../components/ColumnTaxResidency';
import ColumnTaxRate from './ColumnTaxRate';
import RfqHeader from './RfqHeader';
import ColumnQuoteExchangeRate from '../../components/ColumnQuoteExchangeRate';

const ActionOrderDetail: React.FC<{
  children: (props: { onClick: () => void; disabled?: boolean }) => JSX.Element;
  onSameOrderRequested?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  rfq: RfqDetail;
}> = ({
  children,
  onSameOrderRequested,
  onOpen,
  onClose: onCloseProp,
  rfq,
}) => {
  const { t } = useTranslation('rfq');
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const toast = useToast();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        direction: yup
          .string()
          .required()
          .label(t('field.direction').toLowerCase()),
        size: yup.number().required().label(t('field.size').toLowerCase()),
      }),
    [t],
  );
  const { handleSubmit } = useForm({
    defaultValues: {
      assetId: rfq?.assetId,
      assetType: 'BOND',
      direction: rfq?.direction,
      size: rfq?.initSize,
      isPartialFill: rfq?.isPartialFill,
    },
    resolver: yupResolver(validationSchema),
  });
  const handleClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    onMobile
      ? setTimeout(() => {
          onCloseProp?.();
        }, theme.transitions.duration.standard)
      : onCloseProp?.();
  };

  const { mutate: addRFQ, isLoading } = useAddRFQ({
    onSuccess(res) {
      ReactGA.event({
        action: EventAction.RequestSuccess,
        category: 'trade',
        label: 'action_request_for_quote_success',
      });
      toast.success(t('title.rfqCreated'), t('message.rfqCreated'));
      onSameOrderRequested?.();
      onClose();
    },
    onError(err) {
      const error = err?.response?.data;
      ReactGA.event({
        action: EventAction.RequestError,
        category: 'trade',
        label: 'action_request_for_quote_error',
      });

      toast.error(...formatApiErrorToast(t, error?.message));
    },
  });

  const onSubmit: SubmitHandler<{
    assetId: number;
    assetType: string;
    direction: string;
    size: number;
    isPartialFill: boolean;
  }> = async (data) => {
    addRFQ({
      assetId: data.assetId,
      assetType: data.assetType,
      direction: data.direction,
      size: data.size,
      isPartialFill: data.isPartialFill,
    });
  };

  const renderActions = useCallback(
    (data: RfqDetail) => (
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
      >
        {data?.traderName && (
          <ActionOpenChatOfRfq
            username={data?.traderName}
            rfq={data}
            onSuccess={() => {
              onClose();
            }}
          >
            {(props) => (
              <Button
                variant={'outlined'}
                color={'primary'}
                startIcon={
                  <SvgIcon>
                    <ChatIcon />
                  </SvgIcon>
                }
                size={onMobile ? 'large' : 'medium'}
                fullWidth={onMobile}
                {...props}
              >
                {t('action.openChat')}
              </Button>
            )}
          </ActionOpenChatOfRfq>
        )}
        {[
          RfqStatus.Expired,
          RfqStatus.OrderExecuted,
          RfqStatus.OrderCancelledByClient,
          RfqStatus.OrderCancelledByBlotter,
          RfqStatus.ClientRejected,
        ].includes(rfq?.status) && (
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            size={onMobile ? 'large' : 'medium'}
          >
            {t('action.requestOrderAgain')}
          </Button>
        )}
      </Stack>
    ),
    [isLoading, onMobile, t, rfq?.status],
  );

  const renderSummary = useCallback(
    () => (
      <ActionFetchRfqDetail rfqId={rfq?.id} view={0} enabled={open}>
        {({ data, isLoading: isLoadingData }) => (
          <>
            <SummaryLayout
              header={
                <Stack spacing={1}>
                  <TypographyWithLoading loading={isLoadingData}>
                    <RfqHeader rfq={data} />
                  </TypographyWithLoading>
                  {!onMobile && renderActions(data)}
                </Stack>
              }
              summary={
                <SummaryList>
                  {/* <ListItem>
                  <ListItemText primary={`${t('field.status')}`} />
                  <Box>
                    <TypographyWithLoading loading={isLoadingData}>
                      <ColumnStatus rfq={data} />
                    </TypographyWithLoading>
                  </Box>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={t('bond:field.isin')} />
                    <TypographyWithLoading loading={isLoadingData}>
                      {data?.bond?.isin ?? '-'}
                    </TypographyWithLoading>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.orderAccount')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnClientAccount rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {/* <ListItem>
                  <ListItemText
                    primary={`${t('bond:field.isin')} / ${t('bond:field.assetName')}`}
                  />
                  <TypographyWithLoading loading={isLoadingData}>
                    <ColumnAsset rfq={data} />
                  </TypographyWithLoading>
                </ListItem> */}
                  <ListItem>
                    <ListItemText primary={`${t('field.size')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSize rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`${t('field.price')}`} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnPrice rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.platformFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnPlatformFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementFee')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementFee rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  {data?.quoteExchangeRate !== 1 && (
                    <ListItem>
                      <ListItemText primary={t('field.quoteExchangeRate')} />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnQuoteExchangeRate rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemText primary={t('field.tax')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <ColumnTaxResidency rfq={data} />
                          <Box>
                            <ColumnTaxRate rfq={data} />
                          </Box>
                        </Stack>
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t('field.settlementDate')} />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        <ColumnSettlementDate rfq={data} />
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={t('bond:field.tradingRestrictionType')}
                    />
                    <Box>
                      <TypographyWithLoading loading={isLoadingData}>
                        {data?.bond?.tradingRestrictionType || '-'}
                      </TypographyWithLoading>
                    </Box>
                  </ListItem>

                  {onMobile ? (
                    <>
                      <ListItem>
                        <ListItemText primary={`${t('field.orderedAt')}`} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <ColumnRequestTime rfq={data} />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={`${t('field.quotedAt')}`} />
                        <Box>
                          <TypographyWithLoading loading={isLoadingData}>
                            <ColumnQuoteTime rfq={data} />
                          </TypographyWithLoading>
                        </Box>
                      </ListItem>
                    </>
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={`${t('field.orderedAt')} / ${t('field.quotedAt')}`}
                      />
                      <Box>
                        <TypographyWithLoading loading={isLoadingData}>
                          <ColumnRequestTime rfq={data} /> /{' '}
                          <ColumnQuoteTime rfq={data} />
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                  {data?.orderNote && (
                    <ListItem>
                      <ListItemText primary={t('field.cancelReason')} />
                      <Box>
                        <TypographyWithLoading
                          loading={isLoadingData}
                          whiteSpace={'pre-line'}
                        >
                          {data?.orderNote ?? '-'}
                        </TypographyWithLoading>
                      </Box>
                    </ListItem>
                  )}
                </SummaryList>
              }
            />
            {onMobile && data && (
              <>
                <Box height={132} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 16,
                    pl: 2,
                    pb: 2,
                    bgcolor: (theme) => theme.palette.background.default,
                  }}
                >
                  {renderActions(data)}
                </Box>
              </>
            )}
          </>
        )}
      </ActionFetchRfqDetail>
    ),

    [rfq, t, open, renderActions, onMobile],
  );
  // tabs
  const tabs = useMemo(
    () =>
      [
        {
          label: t('title.summary'),
          content: renderSummary(),
        },
        {
          label: t('title.tradeLog'),
          content: <div>Trade Log</div>,
        },
      ].filter(Boolean) as {
        label: string;
        content: React.ReactNode;
      }[],
    [t, renderSummary],
  );

  const renderContent = useCallback(
    () => <RfqDialogLayout tabs={tabs} />,
    [tabs],
  );

  useEffect(() => {
    if (open) {
      onOpen?.();
    }
  }, [open]);

  if (onMobile) {
    return (
      <>
        {children({ onClick: handleClick })}
        <ModalLayout open={open} onClose={onClose}>
          {renderContent()}
        </ModalLayout>
      </>
    );
  }

  return (
    <>
      {children({ onClick: handleClick })}

      <BaseActionDialog
        open={open}
        onClose={onClose}
        width={{
          xs: '90%',
          md: '80%',
          lg: 600,
        }}
      >
        {renderContent()}
      </BaseActionDialog>
    </>
  );
};

export default ActionOrderDetail;
