import { Message } from 'webstomp-client';

export const EVENT_KEY = '/user/rfq';

type RfqCreatedEvent = {
  id: number;
  username: string;
  assetType: string;
  assetId: number;
  assetIdentifier: string;
  assetName: string;
  currency: string;
  size: number;
  createdAt: string;
  updatedAt: string;
  status: number;
};

type RfqExpiredEvent = {
  expiredRfqId: number;
};

type RfqQuotedEvent = {
  createdAt: number;
  fi: string;
  price: number;
  rfq: {
    assetId: number;
    createdAt: number;
    currency: string;
    direction: string;
    identifier: string;
    rfqId: number;
    size: number;
    status: string;
    updatedAt: number;
    user: string;
  };
  status: string;
  rfqQuoteId: number;
  trader: string;
};

type RfqExecutedEvent = {
  executedOrderId: number;
  executedSize: number;
  executedPrice: number;
};

type NewMessageBody =
  | RfqCreatedEvent
  | RfqExpiredEvent
  | RfqQuotedEvent
  | RfqExecutedEvent;

export const eventHandler = (message: Message) => {
  const data = JSON.parse(message.body) as NewMessageBody;
  console.log(EVENT_KEY, data);

  return data;
};
