export const CurrencyCode = [
  { id: 'USD', name: 'USD', countryCode: 'us' }, // t('currency:USD.fullName')
  { id: 'EUR', name: 'EUR', countryCode: 'eu' }, // t('currency:EUR.fullName')
  { id: 'GBP', name: 'GBP', countryCode: 'gb' }, // t('currency:GBP.fullName')
  { id: 'AUD', name: 'AUD', countryCode: 'au' }, // t('currency:AUD.fullName')
  { id: 'SGD', name: 'SGD', countryCode: 'sg' }, // t('currency:SGD.fullName')
  { id: 'HKD', name: 'HKD', countryCode: 'hk' }, // t('currency:HKD.fullName')
  { id: 'CHF', name: 'CHF', countryCode: 'ch' }, // t('currency:CHF.fullName')
  { id: 'CNY', name: 'CNY', countryCode: 'cn' }, // t('currency:CNY.fullName')
  { id: 'TWD', name: 'TWD', countryCode: 'tw' }, // t('currency:TWD.fullName')
  { id: 'CAD', name: 'CAD', countryCode: 'ca' }, // t('currency:CAD.fullName')
  { id: 'IDR', name: 'IDR', countryCode: 'id' }, // t('currency:IDR.fullName')
  { id: 'JPY', name: 'JPY', countryCode: 'jp' }, // t('currency:JPY.fullName')
  { id: 'NZD', name: 'NZD', countryCode: 'nz' }, // t('currency:NZD.fullName')
  { id: 'MYR', name: 'MYR', countryCode: 'my' }, // t('currency:MYR.fullName')
  { id: 'ZAR', name: 'ZAR', countryCode: 'za' }, // t('currency:ZAR.fullName')
  { id: 'MXN', name: 'MXN', countryCode: 'mx' }, // t('currency:MXN.fullName')
  { id: 'SEK', name: 'SEK', countryCode: 'se' }, // t('currency:SEK.fullName')
  { id: 'NOK', name: 'NOK', countryCode: 'no' }, // t('currency:NOK.fullName')
  { id: 'RUB', name: 'RUB', countryCode: 'ru' }, // t('currency:RUB.fullName')
  { id: 'TRY', name: 'TRY', countryCode: 'tr' }, // t('currency:TRY.fullName')
  { id: 'BRL', name: 'BRL', countryCode: 'br' }, // t('currency:BRL.fullName')
  { id: 'INR', name: 'INR', countryCode: 'in' }, // t('currency:INR.fullName')
  { id: 'KRW', name: 'KRW', countryCode: 'kr' }, // t('currency:KRW.fullName')
  { id: 'CNH', name: 'CNH', countryCode: 'cn' }, // t('currency:CNH.fullName')
  { id: 'ARS', name: 'ARS', countryCode: 'ar' }, // t('currency:ARS.fullName')
  { id: 'ILS', name: 'ILS', countryCode: 'il' }, // t('currency:ILS.fullName')
  { id: 'HUF', name: 'HUF', countryCode: 'hu' }, // t('currency:HUF.fullName')
  { id: 'CZK', name: 'CZK', countryCode: 'cz' }, // t('currency:CZK.fullName')
  { id: 'PHP', name: 'PHP', countryCode: 'ph' }, // t('currency:PHP.fullName')
  { id: 'AED', name: 'AED', countryCode: 'ae' }, // t('currency:AED.fullName')
  { id: 'DOP', name: 'DOP', countryCode: 'do' }, // t('currency:DOP.fullName')
  { id: 'KZT', name: 'KZT', countryCode: 'kz' }, // t('currency:KZT.fullName')
  { id: 'MOP', name: 'MOP', countryCode: 'mo' }, // t('currency:MOP.fullName')
  { id: 'PEN', name: 'PEN', countryCode: 'pe' }, // t('currency:PEN.fullName')
  { id: 'PLN', name: 'PLN', countryCode: 'pl' }, // t('currency:PLN.fullName')
  { id: 'ISK', name: 'ISK', countryCode: 'is' }, // t('currency:ISK.fullName')
  { id: 'UZS', name: 'UZS', countryCode: 'uz' }, // t('currency:UZS.fullName')
  { id: 'CRC', name: 'CRC', countryCode: 'cr' }, // t('currency:CRC.fullName')
  { id: 'DKK', name: 'DKK', countryCode: 'dk' }, // t('currency:DKK.fullName')
  { id: 'NGN', name: 'NGN', countryCode: 'ng' }, // t('currency:NGN.fullName')
  { id: 'THB', name: 'THB', countryCode: 'th' }, // t('currency:THB.fullName')
  { id: 'UAH', name: 'UAH', countryCode: 'ua' }, // t('currency:UAH.fullName')
];
