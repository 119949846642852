import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, ApiKey } from './config';

interface GetKycResponse {
  id: number;
  personalDetails: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    gender: number;
    nationality: string;
    isUsCitizen: boolean;
    birthday: string;
    maritalStatus: number;
    residentialAddressCountry: string;
    residentialAddressPostalCode: string;
    residentialAddressCity: string;
    residentialAddressStreet1: string;
    residentialAddressStreet2: string;
    mailingAddressCountry: string;
    mailingAddressPostalCode: string;
    mailingAddressCity: string;
    mailingAddressStreet1: string;
    mailingAddressStreet2: string;
    isComplete: boolean;
  };
  employment: {
    employmentStatus: number;
    occupation: string;
    yearsOfService: number;
    employer: string;
    employerAddressCountry: string;
    employerAddressPostalCode: string;
    employerAddressCity: string;
    employerAddressStreet1: string;
    employerAddressStreet2: string;
    annualIncome: string;
    totalWealth: string;
    wealthSourceSalaryPension: boolean;
    wealthSourceInheritance: boolean;
    wealthSourceTradingProfitIncome: boolean;
    wealthSourceAllowanceSpouseIncome: boolean;
    wealthSourceOthers: boolean;
    wealthSourceOthersText: string;
    isComplete: boolean;
  };
  accreditation: {
    levelOfEducation: string;
    knowledgeDebtSecurities: boolean;
    knowledgeFunds: boolean;
    knowledgeEquities: boolean;
    knowledgeStructuredProducts: boolean;
    knowledgeOverTheCounter: boolean;
    knowledgeInvestmentLinkedInsurance: boolean;
    knowledgeCommoditiesSpot: boolean;
    knowledgeVirtualAssets: boolean;
    experienceDebtSecurities: boolean;
    experienceFunds: boolean;
    experienceEquities: boolean;
    experienceStructuredProducts: boolean;
    experienceOverTheCounter: boolean;
    experienceInvestmentLinkedInsurance: boolean;
    experienceCommoditiesSpot: boolean;
    experienceVirtualAssets: boolean;
    isPep: boolean;
    isAmstelRelated: boolean;
    isAmstelDirector: boolean;
    isAccreditedInvestor: boolean;
    accreditedInvestorDocumentFileId: string;
    isIncomeOverSgd300k: boolean;
    isPersonalAssetSgd300k: boolean;
    isAssetSgd1m: boolean;
    incomeDocumentFileId: string;
    assetDocumentFileId: string;
    isComplete: boolean;
  };
  verication: {
    idDocumentType: string;
    idDocumentFrontFileId: string;
    idDocumentBackFileId: string;
    addressDocumentType: string;
    addressDocumentFileId: string;
    w8BenFileId: string;
    isComplete: boolean;
  };
  interview: {
    timeSlot1: string;
    timeSlot2: string;
    timeSlot3: string;
    isComplete: boolean;
  };
  isSubmitted: boolean;
  isInterviewed: boolean;
  isPassed: boolean;
  isWaiting: boolean;
}

export const useKyc =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      AxiosResponse<GetKycResponse>,
      AxiosError,
      GetKycResponse
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'getKyc'],
      queryFn: () => axiosClient.get(ApiConfig.kyc).then((res) => res.data),
      ...options,
    });

type KycUploadParams = {
  file: File;
  purpose: string;
};

type KycUploadResponse = {
  id: number;
  [key: string]: any;
};

const objectToFormData = (target: any) => {
  const formData = new FormData();
  Object.keys(target).forEach((key: string) => {
    const value = target[key];
    formData.append(key, value);
  });
  return formData;
};

export const useUploadBrokerStatement =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<KycUploadResponse>,
      AxiosError,
      KycUploadParams,
      (string | KycUploadParams)[]
    >,
  ) =>
    useMutation({
      mutationFn: ({ file, purpose }: KycUploadParams) => {
        return axiosClient.post(ApiConfig.kycUpload, {
          body: objectToFormData({ file, purpose }),
        });
      },
      ...options,
    });

interface FeedbackParams {
  caseId: string;
  token: string;
  result: string;
  reason?: string;
}

export const useFeedback =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<GetKycResponse>,
      AxiosError,
      FeedbackParams
    >,
  ) => {
    return useMutation<
      AxiosResponse<GetKycResponse>,
      AxiosError,
      FeedbackParams
    >(
      (params: FeedbackParams) => axiosClient.post(ApiConfig.feedback, params),
      {
        ...options,
      },
    );
  };

export const useJoinWaitList =
  (axiosClient: AxiosInstance) =>
  (options?: UseMutationOptions<AxiosResponse, AxiosError, void>) => {
    const queryClient = new QueryClient();

    return useMutation<AxiosResponse<GetKycResponse>, AxiosError, void>(
      () => axiosClient.post(ApiConfig.joinWaitList),
      {
        ...options,
        onSuccess: (...params) => {
          queryClient.invalidateQueries([ApiKey, 'getKyc']);
          options?.onSuccess?.(...params);
        },
      },
    );
  };
