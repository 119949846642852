import React from 'react';
import {
  Box,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { RfqDetail } from '../../../../services/brokers';
import OpenNewIcon from 'ui/icons/open-new.svg?react';
import { useTranslation } from 'react-i18next';
import ColumnDirection from '../../components/ColumnDirection';
import TypographyWithLoading from '../../components/TypographyWithLoading';
import ColumnNetQuote from '../../components/ColumnNetQuote';
import ColumnSize from '../../components/ColumnSize';
import { formatBondName } from 'ui/utils';
import ColumnStatus from './ColumnStatus';
import ColumnPriceCalculation from '../../components/ColumnPriceCalculation';
import InformationMark from 'ui/components/InformationMark';

const RfqHeader: React.FC<{
  rfq: RfqDetail;
}> = ({ rfq }) => {
  const { t } = useTranslation('rfq');

  return (
    <>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {rfq?.id && (
          <Typography
            variant="body2"
            color="text.secondary"
            textTransform={'uppercase'}
          >
            {t('message.orderId', {
              id: rfq?.id,
            })}
          </Typography>
        )}
        <ColumnStatus rfq={rfq} />
      </Stack>

      <Stack direction={'row'} spacing={1} alignItems={'flex-start'}>
        <ColumnDirection rfq={rfq} variant="chip" />
        <Stack>
          <Typography fontSize={18}>{formatBondName(rfq?.bond)}</Typography>
          <Typography variant="body2" color={'textSecondary'}>
            {rfq?.bond?.issuerName}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={1}
        alignItems={{
          xs: 'flex-start',
          sm: 'center',
        }}
        flexWrap={'wrap'}
        useFlexGap={true}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={1}
          alignItems={{
            xs: 'flex-start',
            sm: 'center',
          }}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Typography textTransform={'uppercase'} color={'text.secondary'}>
              {t('field.netQuote')}
            </Typography>
            <InformationMark
              title={<ColumnPriceCalculation rfq={rfq} />}
              width={16}
              placement="bottom"
            />
          </Stack>
          <Box>
            <ColumnNetQuote rfq={rfq} />
          </Box>
        </Stack>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={1}
          alignItems={{
            xs: 'flex-start',
            sm: 'center',
          }}
        >
          <Typography textTransform={'uppercase'} color={'text.secondary'}>
            {t('field.size')}
          </Typography>
          <Box>
            <ColumnSize rfq={rfq} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default RfqHeader;
