import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Paper,
  Slide,
  Snackbar,
  Stack,
  useTheme,
} from '@mui/material';
import { get, isUndefined } from 'lodash';
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useCountDown } from 'ui/hooks';

type AlertItem = {
  title: string;
  message?: string;
  severity?: AlertColor;
  duration?: number | null;
  onClick?: () => void;
  'data-testid'?: string;
};

const ANIMATION_DURATION = 300;

export const AlertNotificationContext = createContext<{
  alerts: AlertItem[];
  addAlert: (toast: {
    title: string;
    message?: string;
    options?: {
      severity?: AlertColor;
      duration?: number | null;
      onClick?: () => void;
    };
  }) => void;
  removeAlert: (key: string) => void;
}>({
  alerts: [],
  addAlert: () => {
    return;
  },
  removeAlert: () => {
    return;
  },
});

const maxAlert = 3;

const getKey = (item: AlertItem, index: number) => {
  return item.title + item.message;
};

export const AlertNotificationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alerts, setAlerts] = useState<AlertItem[]>([]);
  const containerRef = useRef(null);

  const addAlert = ({
    title,
    message,
    options,
  }: {
    title: string;
    message?: string;
    options?: {
      severity?: AlertColor;
      duration?: number | null;
      onClick?: () => void;
    };
  }) => {
    setAlerts((prev) => [
      ...prev,
      {
        title,
        message,
        severity: options?.severity || 'info',
        duration: isUndefined(options?.duration) ? 5 : options?.duration,
        onClick: options?.onClick,
        'data-testid':
          get(options, 'data-testid') ||
          `alert-item-${options?.severity || 'info'}`,
      },
    ]);
  };

  const removeAlert = (key: string) => {
    setAlerts((prev) =>
      prev.filter((item, index) => {
        return getKey(item, index) !== key;
      }),
    );
  };

  useEffect(() => {
    if (alerts.length > 0) {
      setAlertOpen(true);
    } else {
      if (alertOpen) {
        setTimeout(() => {
          setAlertOpen(false);
        }, ANIMATION_DURATION);
      }
    }
  }, [alerts]);

  return (
    <AlertNotificationContext.Provider
      value={{ alerts, addAlert, removeAlert }}
    >
      {children}
      {alertOpen && alerts.length > 0 && (
        <Box
          ref={containerRef}
          sx={{
            position: 'absolute',
            top: (theme) => theme.mixins.toolbar.minHeight,
            left: 0,
            right: 0,
            zIndex: (theme) => theme.zIndex.snackbar - 1,
            overflow: 'hidden',
          }}
        >
          <Slide
            in={alertOpen}
            direction="down"
            container={containerRef.current}
          >
            <Box>
              <AlertNotification
                itemKey={getKey(alerts[0], 0)}
                item={alerts[0]}
              />
            </Box>
          </Slide>
        </Box>
      )}
    </AlertNotificationContext.Provider>
  );
};

const AlertNotification = forwardRef<
  HTMLDivElement,
  { item: AlertItem; itemKey: string }
>(({ item, itemKey }, ref) => {
  const { removeAlert } = useContext(AlertNotificationContext);
  const { time, start, stop } = useCountDown(item.duration || 0);

  useEffect(() => {
    if (item.duration) {
      start();
    }
  }, []);

  useEffect(() => {
    if (time === 0) {
      removeAlert(itemKey);
    }
  }, [time]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    removeAlert(itemKey);
  };

  return (
    <Alert
      variant="filled"
      severity={item?.severity || 'info'}
      sx={{
        borderRadius: 0,
      }}
      onMouseEnter={() => {
        stop();
      }}
      onClose={handleClose}
    >
      <AlertTitle>{item.title}</AlertTitle>
    </Alert>
  );
});

export const useAlert = () => {
  const { addAlert } = useContext(AlertNotificationContext);

  const addCommonAlert = ({
    title,
    message,
    options,
  }: {
    title: string;
    message?: string;
    options?: {
      severity?: AlertColor;
      duration?: number | null;
      onClick?: () => void;
    };
  }) => {
    addAlert({
      title,
      message,
      options,
    });
  };

  const addInfoAlert = (
    title: string,
    message?: string,
    options?: Omit<AlertItem, 'title' | 'message' | 'severity'>,
  ) => {
    addAlert({
      title,
      message,
      options: {
        severity: 'info',
        ...options,
      },
    });
  };

  const addSuccessAlert = (
    title: string,
    message?: string,
    options?: Omit<AlertItem, 'title' | 'message' | 'severity'>,
  ) => {
    addAlert({
      title,
      message,
      options: {
        severity: 'success',
        ...options,
      },
    });
  };

  const addErrorAlert = (
    title: string,
    message?: string,
    options?: Omit<AlertItem, 'title' | 'message' | 'severity'>,
  ) => {
    addAlert({
      title,
      message,
      options: {
        severity: 'error',
        ...options,
      },
    });
  };

  return {
    info: addInfoAlert,
    message: addCommonAlert,
    success: addSuccessAlert,
    error: addErrorAlert,
  };
};
