import { FC, useEffect } from 'react';
import ActionSearchBond from './ActionSearchBond';

export enum ChatCommand {
  SEARCH = 'search',
}

const TriggerOnMounted = ({ onMounted }: { onMounted: () => void }) => {
  useEffect(() => {
    onMounted();
  }, []);

  return null;
};

const ChatCommandHandler: FC<{
  chatId: number | string;
  command?: string;
  onOperated?: () => void;
}> = ({ chatId, command, onOperated }) => {
  switch (command) {
    case ChatCommand.SEARCH:
      return (
        <ActionSearchBond
          chatId={chatId}
          onSuccess={onOperated}
          onCanceled={onOperated}
        >
          {({ onClick }) => <TriggerOnMounted onMounted={onClick} />}
        </ActionSearchBond>
      );
    default:
      return null;
  }
};

export default ChatCommandHandler;
