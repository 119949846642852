import {
  Box,
  ButtonBase,
  ButtonProps,
  Paper,
  colors,
  paperClasses,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import AidanIcon from 'ui/icons/aidan/aidan_v2.svg?react';

const AidanFace: FC<{
  color?: string;
  backgroundColor?: string;
  size?: number;
  faceSize?: number;
  elevation?: number;
  border?: string;
}> = ({
  backgroundColor,
  size = 36,
  faceSize = 30,
  elevation = 3,
  color,
  border,
}) => {
  return (
    <Box
      sx={{
        width: size,
        aspectRatio: '1/1',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: (theme) => color ?? theme.palette.primary.contrastText,
        backgroundColor: (theme) =>
          backgroundColor ?? theme.palette.primary.main,

        border: border ?? 'none',
      }}
    >
      <AidanIcon width={faceSize} />
    </Box>
  );
};

export default AidanFace;
