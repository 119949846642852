import { storageHasItem } from '../../core/storage';
import { StorageKey } from '../../utils/storage-key';
import { Container, Paper, Typography } from '@mui/material';
import { redirect } from 'react-router-dom';
import { MAIN_PAGE_PATH } from './types';

const routeBackAfterLoginLoader = async ({ request }: { request: Request }) => {
  const searchParams = new URL(request.url).searchParams;

  const relativeUrl = request.url
    .replace(import.meta.env.VITE_APP_CLIENT_URL, '')
    .replace(`?${searchParams.toString()}`, '');

  if (!storageHasItem(StorageKey.token)) {
    return redirect(
      '/login' +
        `?redirect-url=${relativeUrl}${
          searchParams.toString() ? `&${searchParams.toString()}` : ''
        }`,
    );
  }

  const res = await fetch(request);

  return res;
};

export default routeBackAfterLoginLoader;
