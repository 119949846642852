import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// import SearchGlobalInput from "./search-global/components";
import { useTranslation } from 'react-i18next';
// import BreathingLight from '../common/breathing-light';

export const AuctionLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [bannerHide, setBannerHide] = useState(false);
  const [hasAuction, setHasAuction] = useState(false);

  return (
    <div>
      {/* {hasAuction && (
        <BreathingLight linkWithToken={true} bannerHide={bannerHide} />
      )} */}
      {children}
    </div>
  );
};

export default AuctionLayout;
