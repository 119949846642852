import { FC } from 'react';
import {
  AppBar,
  Box,
  Chip,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  appBarClasses,
  useTheme,
} from '@mui/material';
import AidanFace from './AidanFace';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AidanMail from 'ui/icons/aidan/aidan-mail.svg?react';
import ActionSendInsight from './ActionSendInsight';

const AidanAppBar: FC<{
  onBack?: () => void;
}> = ({ onBack }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        [`&.${appBarClasses.root}`]: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <Toolbar disableGutters>
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={3}
          py={2.5}
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            {onBack && (
              <IconButton
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
                size="small"
                onClick={onBack}
              >
                <ArrowBackIosNewIcon fontSize="inherit" />
              </IconButton>
            )}
            <AidanFace
              backgroundColor="#fff"
              color={theme.palette.primary.main}
              elevation={0}
            />
            <Stack spacing={0.5}>
              <Typography
                color={'primary.contrastText'}
                fontSize={18}
                fontWeight={'bold'}
              >
                Aidan
              </Typography>
              <Stack
                direction={'row'}
                spacing={0.5}
                fontSize={10}
                fontWeight={'bold'}
              >
                <Box
                  sx={{
                    px: '6px',
                    py: '4px',
                    borderRadius: '6px',
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.primary.main,
                  }}
                >
                  Beta
                </Box>
                <Box
                  sx={{
                    px: '6px',
                    py: '4px',
                    borderRadius: '6px',
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.primary.main,
                  }}
                >
                  AI
                </Box>
                <Box
                  sx={{
                    px: '6px',
                    py: '4px',
                    borderRadius: '6px',
                    color: theme.palette.primary.contrastText,
                  }}
                >
                  Bot
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <ActionSendInsight>
            {({ onClick }) => (
              <IconButton onClick={onClick}>
                <AidanMail />
              </IconButton>
            )}
          </ActionSendInsight>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AidanAppBar;
