import { Theme, FormLabelClasses, FormLabelProps } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<FormLabelProps> = {
  className: 'font-bold uppercase',
};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof FormLabelClasses,
    'MuiFormLabel',
    Omit<Theme, 'components'>
  >
> = {};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};

export default componentOverrides;
