import {
  Box,
  SvgIcon,
  Tooltip,
  TooltipProps,
  alpha,
  lighten,
} from '@mui/material';
import QuestionCircleIcon from 'ui/icons/question_circle.svg?react';

const InformationMark: React.FC<
  Omit<TooltipProps, 'children' | 'slots'> & {
    slots?: TooltipProps['slots'] & {
      icon?: React.ReactNode | JSX.Element;
    };
    width?: number;
  }
> = ({ slots, width, ...props }) => {
  return (
    <Tooltip
      arrow
      placement="bottom-start"
      componentsProps={{
        tooltip: {
          sx: {
            px: 2,
            py: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? alpha(lighten(theme.palette.background.paper, 0.05), 0.95)
                : theme.palette.background.paper,
            maxWidth: 'none',
          },
        },
        arrow: {
          sx: {
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? alpha(lighten(theme.palette.background.paper, 0.05), 0.95)
                : theme.palette.background.paper,
          },
        },
      }}
      {...props}
    >
      <Box
        color={'text.secondary'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {slots?.icon || <QuestionCircleIcon width={width ?? 16} />}
      </Box>
    </Tooltip>
  );
};

export default InformationMark;
