import { Box, Card, CardProps, IconButton, Modal, Slide } from '@mui/material';
import { BoxProps } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const ModalLayout: React.FC<{
  open?: boolean;
  height?: BoxProps['height'];
  children: React.ReactNode;
  onClose?: () => void;
  slotProps?: {
    card?: Partial<CardProps>;
  };
}> = ({ open = true, height = '90vh', children, onClose, slotProps }) => {
  return (
    <Modal open={open} disableAutoFocus>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
          }}
          height={height}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Card
            elevation={0}
            {...slotProps?.card}
            sx={{
              height: '100%',
              position: 'relative',
              borderTopRightRadius: '24px',
              borderTopLeftRadius: '24px',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: (theme) => theme.palette.background.default,
              ...slotProps?.card?.sx,
            }}
          >
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
              }}
            >
              <Box flex={1}>{children}</Box>
            </Box>
            {onClose && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                }}
              >
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                  sx={{
                    color: 'text.secondary',
                    p: 1,
                    fontSize: 24,
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
            )}
          </Card>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ModalLayout;
