import * as React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import {
  Avatar,
  Box,
  BoxProps,
  Divider,
  Link,
  linkClasses,
  Stack,
  useTheme,
} from '@mui/material';
import { Message, MessageType } from './type';
import { formatInitialism } from 'ui/utils';

export const ChatMessageContainer: React.FC<
  BoxProps & {
    align?: 'left' | 'right' | 'none';
  }
> = ({ children, sx, align = 'none', ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        boxShadow:
          theme.palette.mode === 'dark' ? 'none' : '2px 2px 15px 0px #0000001A',
        border: (theme) =>
          theme.palette.mode === 'dark'
            ? `1px solid ${theme.palette.divider}`
            : '1px solid #00000024',
        px: '16px',
        py: '12px',
        borderRadius: '10px',
        fontSize: '14px',

        backgroundColor:
          align === 'left' || align === 'none'
            ? theme.palette.background.paper
            : theme.palette.primary.main,
        color:
          align === 'left' || align === 'none'
            ? theme.palette.text.primary
            : theme.palette.common.white,
        borderBottomLeftRadius:
          align === 'none' ? '10px' : align === 'left' ? '0px' : '10px',
        borderBottomRightRadius:
          align === 'none' ? '10px' : align === 'left' ? '10px' : '0px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const TextMessageWithLink: React.FC<
  {
    message: Message;
  } & TypographyProps
> = ({ message, ...props }) => {
  const theme = useTheme();

  const linkRegex = /https?:\/\/[^\s]+/g;

  const parts = message.message.split(linkRegex);
  const links = message.message.match(linkRegex);

  return (
    <Typography
      {...props}
      sx={{
        whiteSpace: 'pre-wrap',
        ...props.sx,
      }}
    >
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {links?.[index] && (
            <Link
              href={links[index]}
              target={'_blank'}
              sx={{
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                wordBreak: 'break-all',
              }}
            >
              {links[index]}
            </Link>
          )}
        </React.Fragment>
      ))}
    </Typography>
  );
};

const SentTextMessage: React.FC<
  BoxProps & {
    message: Message;
  }
> = ({ message, sx, ...boxProps }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        boxShadow:
          theme.palette.mode === 'dark' ? 'none' : '2px 2px 15px 0px #0000001A',
        border: (theme) =>
          theme.palette.mode === 'dark'
            ? `1px solid ${theme.palette.divider}`
            : '1px solid #00000024',
        px: '16px',
        py: '12px',
        borderRadius: '10px',
        fontSize: '14px',

        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '0px',
        ...sx,
      }}
      {...boxProps}
    >
      <TextMessageWithLink
        message={message}
        sx={{
          [`& .${linkClasses.root}`]: {
            color: theme.palette.common.white,
          },
        }}
      />
    </Box>
  );
};

const ReceivedTextMessage: React.FC<
  BoxProps & {
    message: Message & { showAvatar: boolean };
  }
> = ({ message, sx, ...boxProps }) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} spacing={2}>
      {message.showAvatar ? (
        <Avatar
          sx={{
            width: 30,
            height: 30,
            bgcolor: '#BBDEFB',
            color: (theme) => theme.palette.background.paper,
            fontSize: 12,
          }}
        >
          {formatInitialism(message.sender)}
        </Avatar>
      ) : (
        <Box>
          <Box sx={{ width: 30 }} />
        </Box>
      )}
      <Box
        sx={{
          px: '16px',
          py: '12px',
          borderRadius: '10px',
          fontSize: '14px',

          backgroundColor:
            theme.palette.mode === 'dark'
              ? '#393939'
              : theme.palette.background.paper,

          boxShadow:
            theme.palette.mode === 'dark'
              ? 'none'
              : '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 3px 0px rgba(0,0,0,0.12)',
          color: theme.palette.text.primary,
          ...sx,
        }}
        {...boxProps}
      >
        <TextMessageWithLink message={message} />
      </Box>
    </Stack>
  );
};

const ChatOperationMessage: React.FC<{
  message: Message;
}> = ({ message }) => {
  return (
    <Box px={2} color={'text.secondary'} fontSize={12}>
      <Divider>{message.message}</Divider>
    </Box>
  );
};

const ChatMessage: React.FC<{
  message: Message & { showAvatar: boolean };
  align?: 'left' | 'right' | 'none';
}> = ({ message, align = 'none' }) => {
  switch (message.messageType) {
    case MessageType.TEXT: {
      return align === 'left' ? (
        <Stack direction={'row'} justifyContent={'flex-start'} pr={10}>
          <ReceivedTextMessage message={message} />
        </Stack>
      ) : (
        <Stack direction={'row'} justifyContent={'flex-end'} pl={10}>
          <SentTextMessage message={message} />
        </Stack>
      );
    }

    case MessageType.OPERATION: {
      return <ChatOperationMessage message={message} />;
    }

    default:
      return null;
  }
};

export default ChatMessage;
