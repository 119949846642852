import {
  Stack,
  Typography,
  Box,
  TextField,
  Popover,
  SvgIcon,
  IconButton,
} from '@mui/material';
import { isNumber } from 'lodash';
import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { textFieldNumberInputProps, thousandSeparated } from 'ui/utils';
import NumericFormatThousandSeparate from './NumericFormatThousandSeparate';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ArrowChevronDownIcon from 'ui/icons/chevron-down.svg?react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
  label?: string;
  value?: [number, number] | null;
  onChange?: (value: [number, number] | null) => void;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
}

const FilterRangeInput = forwardRef<HTMLDivElement, Props>(
  (
    { label, value, onChange, min = 0, max = 9999, step = 1, unit }: Props,
    ref,
  ) => {
    const { t } = useTranslation(['validation']);
    const defaultRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    const { control, handleSubmit, setValue, formState, trigger } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          from: yup
            .number()
            .nullable()
            .min(min, t('custom.rangeShouldBetween', { min, max }))
            .max(max, t('custom.rangeShouldBetween', { min, max })),
          to: yup
            .number()
            .nullable()
            .min(min, t('custom.rangeShouldBetween', { min, max }))
            .max(max, t('custom.rangeShouldBetween', { min, max }))
            .test(
              'lessThan',
              t('custom.rangeFromShouldLowerThanTo'),
              (value, context) => {
                if (!value || !context.parent.from) return true;

                return value > context.parent.from;
              },
            ),
        }),
      ),
      mode: 'onChange',
    });
    const errors = formState.errors;
    const onClose = () => {
      setAnchorEl(null);
      handleSubmit((data) => {
        isNumber(data.from) &&
          isNumber(data.to) &&
          onChange?.([data.from, data.to]);
      })();
    };

    return (
      <>
        <TextField
          ref={ref || defaultRef}
          label={label}
          value={
            isNumber(value?.[0]) || isNumber(value?.[1])
              ? `${thousandSeparated(value?.[0]) ?? ''} - ${thousandSeparated(value?.[1]) ?? ''} ${unit ?? ''}`
              : ''
          }
          onClick={(event) => {
            event.preventDefault();
            setAnchorEl(event.currentTarget);
          }}
          InputProps={{
            endAdornment: !value ? (
              <SvgIcon>
                <ArrowChevronDownIcon width={24} />
              </SvgIcon>
            ) : (
              <IconButton
                onClick={() => {
                  setValue('from', null);
                  setValue('to', null);
                  onChange?.(null);
                }}
                sx={{
                  color: 'text.secondary',
                  mr: 1,
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            ),
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          elevation={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <Box p={2}>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              spacing={1}
            >
              <Controller
                name="from"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('common:from')}
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(Number(event.target.value));
                      trigger('to');
                    }}
                    InputProps={{
                      inputComponent: NumericFormatThousandSeparate as any,
                    }}
                    {...textFieldNumberInputProps}
                  />
                )}
              />
              <Controller
                name="to"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('common:to')}
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(Number(event.target.value));
                    }}
                    InputProps={{
                      inputComponent: NumericFormatThousandSeparate as any,
                    }}
                    {...textFieldNumberInputProps}
                  />
                )}
              />
            </Stack>
            {errors && (
              <Typography color="error">
                {errors.from?.message || errors.to?.message || ''}
              </Typography>
            )}
          </Box>
        </Popover>
      </>
    );
  },
);

export default FilterRangeInput;
