import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

type Translator = ReturnType<typeof useTranslation>['t'];

export function setYupLocale(t: Translator) {
  setLocale({
    mixed: {
      required: ({ label }) => t('validation:mixed.required', { label }),
      notType: ({ label, type }) =>
        t('validation:mixed.notType', {
          label,
          type: t(`validation:type.${type}`),
        }),
    },
    string: {
      email: ({ label }) => t('validation:string.email', { label }),
      min: ({ label, min }) => t('validation:string.min', { label, min }),
      max: ({ label, max }) => t('validation:string.max', { label, max }),
    },
    array: {
      min: ({ label, min }) => t('validation:array.min', { label, min }),
      max: ({ label, max }) => t('validation:array.max', { label, max }),
    },
    number: {
      min: ({ label, min }) => t('validation:number.min', { label, min }),
      max: ({ label, max }) => t('validation:number.max', { label, max }),
      lessThan: ({ label, less }) =>
        t('validation:number.less', { label, less }),
      moreThan: ({ label, more }) =>
        t('validation:number.more', { label, more }),
    },
    date: {
      min: ({ label, min }) => t('validation:date.min', { label, min }),
      max: ({ label, max }) => t('validation:date.max', { label, max }),
    },
  });
}

export default function useYupLocalization() {
  const { t } = useTranslation('validation');

  useEffect(() => {
    setYupLocale(t);
  }, [t]);
}
