import { Container, Typography } from '@mui/material';

export default function ErrorPage() {
  return (
    <div className="bg-white dark:bg-background">
      <Container
        className="h-auto min-h-screen md:h-screen py-4 flex flex-col justify-center"
        maxWidth="sm"
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full sm:w-[480px] relative text-center">
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>
              Website Under Maintenance
            </Typography>
            <Typography>
              We're currently undergoing scheduled maintenance. We apologize for
              the inconvenience and appreciate your patience. Our website will
              be back online shortly to serve you better.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
}
