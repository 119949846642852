import { Params, redirect } from 'react-router-dom';
import { MAIN_PAGE_PATH } from './types';
import { apiAuthClient } from '../../services/apiClient';
import { ApiConfig } from '../../services/bonds';

const loader = async ({ params }: { params: Params<string> }) => {
  const isin = params.isin;

  const data = await apiAuthClient().get(ApiConfig.index, {
    params: {
      page: 1,
      size: 1,
      isinList: isin,
    },
  });

  if (data?.data?.content?.length > 0) {
    return redirect(`/app/bonds/bond-detail/${data?.data?.content[0].id}`);
  }

  return redirect(MAIN_PAGE_PATH);
};

export default loader;
