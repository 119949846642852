import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum RfqDirection {
  Buy = 1,
  Sell = 2,
}

export const useRfqDirectionOptions = () => {
  const { t } = useTranslation('rfq');
  const options = useMemo(
    () => [
      {
        value: RfqDirection.Buy,
        label: t('options.direction.buy'),
      },
      {
        value: RfqDirection.Sell,
        label: t('options.direction.sell'),
      },
    ],
    [t],
  );
  return options;
};

// **RFQ Status**
// - 0 - Waiting for quote: after placing RFQ
// - 1 - Quoted: any fi has quoted
// - 2 - Order in progress on end user side (Order claimed: trader processing on blotter side)
// - 3 - Order executed
// - 4 - Cancelled by client
// - 5 - Cancelled by blotter
// - 6 - Expired
// - 7 - Order updated
// - 8 - Client rejected

export enum RfqStatus {
  WaitingQuote = 0,
  Quoted = 1,
  OrderPlaced = 2,
  OrderExecuted = 3,
  OrderCancelledByClient = 4,
  OrderCancelledByBlotter = 5,
  Expired = 6,
  OrderUpdated = 7,
  ClientRejected = 8,
}

// **RFQ Quote Status**
// PENDING(0),
// QUOTED(1),
// NOT_QUOTED(4),
// ACCEPTED(2),
// CANCELLED(3),
// EXPIRED(5);

export enum RfqQuoteStatus {
  Pending = 0,
  Quoted = 1,
  Accepted = 2,
  Cancelled = 3,
  NotQuoted = 4,
  Expired = 5,
}

export const useOrdersRfqStatusOptions = (count?: {
  waitingForQuote: number | null;
  orderPlaced: number | null;
  quoteIsReady: number | null;
  orderUpdated: number | null;
  orderExecuted: number | null;
  orderCancelledByBlotter: number | null;
}) => {
  const { t } = useTranslation('rfq');

  const options = useMemo(
    () => [
      {
        value: [RfqStatus.WaitingQuote],
        label: t('options.orderStatus.waitingQuote'),
        count: 0,
      },
      {
        value: [RfqStatus.Quoted],
        label: t('options.orderStatus.quoteReady'),
        count: count?.quoteIsReady ?? 0,
      },
      {
        value: [RfqStatus.OrderPlaced, RfqStatus.OrderUpdated],
        label: t('options.orderStatus.orderInProcess'),
        count: count?.orderUpdated ?? 0,
      },
      {
        value: [
          RfqStatus.OrderExecuted,
          RfqStatus.OrderCancelledByBlotter,
          RfqStatus.OrderCancelledByClient,
          RfqStatus.Expired,
          RfqStatus.ClientRejected,
        ],
        label: t('options.orderStatus.orderHistory'),
        count:
          (count?.orderExecuted ?? 0) + (count?.orderCancelledByBlotter ?? 0),
      },
    ],
    [count, t],
  );
  return options;
};

export const useOrderRfqSubStatusOptions = () => {
  const { t } = useTranslation('rfq');

  const options = useMemo(
    () => [
      {
        value: RfqStatus.WaitingQuote,
        label: t('options.orderStatus.waitingQuote'),
      },
      {
        value: RfqStatus.Quoted,
        label: t('options.orderStatus.quoteReady'),
      },
      {
        value: RfqStatus.OrderPlaced,
        label: t('options.orderStatus.orderPlaced'),
      },
      {
        value: RfqStatus.OrderUpdated,
        label: t('options.orderStatus.orderUpdated'),
      },
      {
        value: RfqStatus.OrderExecuted,
        label: t('options.orderStatus.orderExecuted'),
      },
      {
        value: RfqStatus.OrderCancelledByBlotter,
        label: t('options.orderStatus.orderCancelledByBlotter'),
      },
      {
        value: RfqStatus.OrderCancelledByClient,
        label: t('options.orderStatus.orderCancelled'),
      },
      {
        value: RfqStatus.Expired,
        label: t('options.orderStatus.expired'),
      },
      {
        value: RfqStatus.ClientRejected,
        label: t('options.orderStatus.rejected'),
      },
    ],
    [t],
  );
  return options;
};

export const useBlottersRfqStatusOptions = (count?: {
  waitingForQuote: number | null;
  orderPlaced: number | null;
  quoteIsReady: number | null;
  orderExecuted: number | null;
  orderRejected: number | null;
}) => {
  const { t } = useTranslation('rfq');

  const options = useMemo(
    () => [
      {
        value: [RfqStatus.WaitingQuote],
        label: t('options.orderStatus.waitingQuote'),
        count: count?.waitingForQuote ?? 0,
      },
      {
        value: [RfqStatus.Quoted],
        label: t('options.orderStatus.quoted'),
        count: 0,
      },
      {
        value: [RfqStatus.OrderPlaced, RfqStatus.OrderUpdated],
        label: t('options.orderStatus.orderPlaced'),
        count: count?.orderPlaced ?? 0,
      },
      {
        value: [
          RfqStatus.OrderExecuted,
          RfqStatus.OrderCancelledByBlotter,
          RfqStatus.OrderCancelledByClient,
          RfqStatus.ClientRejected,
          RfqStatus.Expired,
        ],
        label: t('options.orderStatus.orderHistory'),
        count: count?.orderRejected ?? 0,
      },
    ],
    [count, t],
  );
  return options;
};

export const useBlotterRfqSubStatusOptions = () => {
  const { t } = useTranslation('rfq');

  const options = useMemo(
    () => [
      {
        value: RfqStatus.WaitingQuote,
        label: t('options.orderStatus.waitingQuote'),
      },
      {
        value: RfqStatus.Quoted,
        label: t('options.orderStatus.quoted'),
      },
      {
        value: RfqStatus.OrderPlaced,
        label: t('options.orderStatus.orderPlaced'),
      },
      {
        value: RfqStatus.OrderUpdated,
        label: t('options.orderStatus.orderUpdatedWatingForConfirm'),
      },
      {
        value: RfqStatus.OrderExecuted,
        label: t('options.orderStatus.orderExecuted'),
      },
      {
        value: RfqStatus.OrderCancelledByBlotter,
        label: t('options.orderStatus.orderCancelledByBlotter'),
      },
      {
        value: RfqStatus.OrderCancelledByClient,
        label: t('options.orderStatus.orderCancelled'),
      },
      {
        value: RfqStatus.Expired,
        label: t('options.orderStatus.expired'),
      },
      {
        value: RfqStatus.ClientRejected,
        label: t('options.orderStatus.clientRejected'),
      },
    ],
    [t],
  );
  return options;
};
