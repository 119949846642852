import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import usePermissions from '../../libs/hooks/usePermissions';

type Props = {
  fallbackRoute?: string;
  requiredPermission: string;
};

export default function ProtectedRoute({
  fallbackRoute = '/app/notfound',
  requiredPermission,
}: Props) {
  const permissions = usePermissions();

  const permitted = useMemo(() => {
    if (!requiredPermission) return false;

    return permissions.includes(requiredPermission);
  }, []);

  if (!permitted) {
    return <Navigate to={fallbackRoute} replace />;
  }
  return <Outlet />;
}
