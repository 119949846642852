import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ApiConfig, ApiKey } from './config';

interface LoginParam {
  username: string;
  password: string;
  recaptcha?: string;
  target?: 'cms';
}

interface SignInResponse {
  token: string;
  type: string;
  id: number;
  username: string;
  email: string;
  fullName: string;
  roles: string[];
  membership: string;
}

export const useSingIn =
  (axiosClient: AxiosInstance) =>
  (
    options = {} as Omit<
      UseMutationOptions<
        AxiosResponse<SignInResponse>,
        AxiosError<{
          error: {
            message: string;
          };
        }>,
        LoginParam,
        unknown[]
      >,
      'mutationFn'
    >,
  ) =>
    useMutation({
      mutationFn: (params) => axiosClient.post(ApiConfig.signIn, params),
      ...options,
    });

export const useSingInWithGoogle =
  (axiosClient: AxiosInstance) =>
  (
    options = {} as Omit<
      UseMutationOptions<
        AxiosResponse<SignInResponse>,
        unknown,
        {
          code: string;
          redirectUri?: string;
        },
        unknown[]
      >,
      'mutationFn'
    >,
  ) =>
    useMutation({
      mutationFn: ({ code, redirectUri = '' }) =>
        axiosClient.post(ApiConfig.singInWithGoogle, { code, redirectUri }),
      ...options,
    });

export const useSingInWithLinkedin =
  (axiosClient: AxiosInstance) =>
  (
    options = {} as Omit<
      UseMutationOptions<
        AxiosResponse<SignInResponse>,
        unknown,
        {
          code: string;
          redirectUri?: string;
        },
        unknown[]
      >,
      'mutationFn'
    >,
  ) =>
    useMutation({
      mutationFn: ({ code, redirectUri = '' }) =>
        axiosClient.post(ApiConfig.singInWithLinkedin, {
          code,
          redirectUri: redirectUri,
        }),
      ...options,
    });

export const useSignOut =
  (axiosClient: AxiosInstance) =>
  (
    options = {} as Omit<
      UseMutationOptions<unknown, unknown, void, unknown[]>,
      'mutationFn'
    >,
  ) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: () => axiosClient.post(ApiConfig.singOut),
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([ApiKey, 'checkLogin']);
        options.onSuccess?.(...params);
      },
    });
  };
interface SignUpParam {
  email: string;
  // firstName: string;
  // lastName: string;
  // phoneNumber: string;
  password: string;
  // confirmPassword: string;
  recaptcha: string;
}

export const useSignUp =
  (axiosClient: AxiosInstance) =>
  (
    options?: Omit<
      UseMutationOptions<
        AxiosResponse<unknown>,
        AxiosError<{
          error: {
            message: string;
          };
        }>,
        SignUpParam,
        unknown[]
      >,
      'mutationFn'
    >,
  ) =>
    useMutation({
      mutationFn: (params) => axiosClient.post(ApiConfig.signUp, params),
      ...options,
    });

export const checkLoginQuery = (
  axiosClient: AxiosInstance,
  options?: UseQueryOptions<
    AxiosResponse<unknown>,
    AxiosError,
    unknown,
    string[]
  >,
) =>
  ({
    queryKey: [ApiKey, 'checkLogin'],
    queryFn: () =>
      axiosClient.get(ApiConfig.checkLogin).then((res) => res.data),
    ...options,
  }) as UseQueryOptions<AxiosResponse<unknown>, AxiosError, unknown, string[]>;

export const useCheckLogin =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      AxiosResponse<unknown>,
      AxiosError,
      unknown,
      string[]
    >,
  ) =>
    useQuery(checkLoginQuery(axiosClient, options));

export const useForgetPassword =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      { username: string; recaptcha: string },
      unknown
    >,
  ) =>
    useMutation({
      mutationKey: [ApiKey, 'forgetPasswordUser'],
      mutationFn: (params) =>
        axiosClient
          .post(ApiConfig.forgetPasswordUser, params)
          .then((res) => res.data),
      ...options,
    });

export const useResetPassword =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      {
        token: string;
        newPassword: string;
      },
      unknown
    >,
  ) =>
    useMutation({
      mutationKey: [ApiKey, 'resetPassword'],
      mutationFn: (params) =>
        axiosClient
          .post(ApiConfig.resetPassword, params)
          .then((res) => res.data),
      ...options,
    });

export const useActivateUser =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      { token: string },
      unknown
    >,
  ) =>
    useMutation({
      mutationKey: [ApiKey, 'activateUser'],
      mutationFn: (params) =>
        axiosClient
          .post(ApiConfig.activateUser(params.token))
          .then((res) => res.data),
      ...options,
    });

interface GetPhoneNumberVerifyCodeParam {
  email: string;
  phoneNumber: string;
}

export const useGetPhoneNumberVerifyCode =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError<{
        message: string;
      }>,
      GetPhoneNumberVerifyCodeParam,
      unknown
    >,
  ) =>
    useMutation({
      mutationKey: [ApiKey, 'getPhoneNumberVerifyCode'],
      mutationFn: (params) =>
        axiosClient
          .post(ApiConfig.getPhoneNumberVerifyCode, params)
          .then((res) => res.data),
      ...options,
    });

export interface UserInfo {
  username: string;
  email: string;
  phoneNumber?: string;
  countryCallingCode?: number;
  firstname: string;
  lastname: string;
  company: string;
  title: string;
  linkedin?: string;
  isEmailOn: boolean;
  isPlatformOn: boolean;
  traderFi?: string | null;
}

export const useUserInfo =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseQueryOptions<
      AxiosResponse<UserInfo>,
      AxiosError,
      UserInfo,
      string[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'userInfo'],
      queryFn: () =>
        axiosClient.get(ApiConfig.userInfo).then((res) => res.data),
      ...options,
    });

const queryClient = new QueryClient();
export const fetchUserInfo = (axiosClient: AxiosInstance) => {
  return () =>
    queryClient.fetchQuery({
      queryKey: [ApiKey, 'userInfo'],
      queryFn: () =>
        axiosClient.get(ApiConfig.userInfo).then((res) => res.data as UserInfo),
    });
};
export interface UpdateUserInfoParam {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  title: string;
  linkedin: string;
  countryCallingCode: string;
  phoneNumber: string;
  isEmailOn: boolean;
  isPlatformOn: boolean;
}

export const useUpdateUserInfo =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError<{
        message: string;
      }>,
      Partial<UpdateUserInfoParam>,
      unknown
    >,
  ) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationKey: [ApiKey, 'update'],
      mutationFn: (params: Partial<UpdateUserInfoParam>) =>
        axiosClient.patch(ApiConfig.update, params).then((res) => res.data),
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([ApiKey, 'userInfo']);
        options?.onSuccess?.(...params);
      },
    });
  };

interface UpdateUserPhoneNumberParam {
  email: string;
  phoneNumber: string;
  verify: string;
}

export const useUpdateUserPhoneNumber =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError<{
        error: {
          message: string;
        };
      }>,
      Partial<UpdateUserPhoneNumberParam>,
      unknown
    >,
  ) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationKey: [ApiKey, 'updatePhoneNumber'],
      mutationFn: (params: Partial<UpdateUserPhoneNumberParam>) =>
        axiosClient
          .patch(ApiConfig.updatePhoneNumber, params)
          .then((res) => res.data),
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([ApiKey, 'userInfo']);
        options?.onSuccess?.(...params);
      },
    });
  };

interface UserListItem {
  id: number;
  lastName: string;
  firstName: string;
  username: string;
  company: string | null;
  title: string | null;
  linkedin: string | null;
}
interface UserListResponse {
  content: UserListItem[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  numberOfElements: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  number: number;
  size: number;
  first: boolean;
  empty: boolean;
}

interface UserListParam {
  keyword?: string;
  page?: number;
  size?: number;
  sortBy?: string;
  order?: 'asc' | 'desc';
}

export const useUserList =
  (axiosClient: AxiosInstance) =>
  (
    params: UserListParam,
    options?: UseQueryOptions<
      AxiosResponse<UserListResponse>,
      AxiosError,
      UserListResponse,
      (string | UserListParam)[]
    >,
  ) =>
    useQuery({
      queryKey: [ApiKey, 'list', params],
      queryFn: () =>
        axiosClient.get(ApiConfig.list, { params }).then((res) => res.data),
      ...options,
    });

interface ResendVerificationLinkParam {
  username: string;
}

export const useResendVerificationLink =
  (axiosClient: AxiosInstance) =>
  (
    options?: UseMutationOptions<
      AxiosResponse<unknown>,
      AxiosError,
      ResendVerificationLinkParam,
      unknown
    >,
  ) =>
    useMutation({
      mutationKey: [ApiKey, 'resendVerificationLink'],
      mutationFn: (params) =>
        axiosClient
          .post(ApiConfig.resendVerificationLink, params)
          .then((res) => res.data),
      ...options,
    });
