/* eslint-disable react-hooks/rules-of-hooks */
import { apiBaseClient } from '../services/apiClient';
import {
  useIndex as useIndexService,
  useIndexInfinite as useIndexInfiniteService,
  useCreate as useCreateService,
  useUpdate as useUpdateService,
  useMessages as useMessagesService,
  useMessagesInfinite as useMessagesInfiniteService,
  useSendMessage as useSendMessageService,
  useReadMessage as useReadMessageService,
  useSummary as useSummaryService,
  useGetSessionByUsername as useGetSessionByUsernameService,
  MessageItem,
} from 'services/chat';

export type { MessageItem };

export const useIndex = useIndexService(apiBaseClient());
export const useIndexInfinite = useIndexInfiniteService(apiBaseClient());
export const useCreate = useCreateService(apiBaseClient());
export const useUpdate = useUpdateService(apiBaseClient());
export const useMessages = useMessagesService(apiBaseClient());
export const useMessagesInfinite = useMessagesInfiniteService(apiBaseClient());
export const useSendMessage = useSendMessageService(apiBaseClient());
export const useReadMessage = useReadMessageService(apiBaseClient());
export const useSummary = useSummaryService(apiBaseClient());
export const useGetSessionByUsername =
  useGetSessionByUsernameService(apiBaseClient());
