import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  ButtonBase,
  buttonBaseClasses,
  InputBase,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import AidanFace from './AidanFace';
import AidanAppBar, { AidanAppBarSpacer } from './AidanAppBar';
import ChatMessage, { ChatMessageContainer } from './ChatMessage';
import {
  AidanChatResponse,
  useAidanChat,
} from '../../../../services/aidan/aidan';
import { isNil, isString } from 'lodash';
import { useInterval, useLocalStorage, useMeasure } from 'react-use';
import { StorageKey } from '../../../../utils/storage-key';
import { useTranslation } from 'react-i18next';
import { usePermittedV2 } from '../../../../libs/hooks/usePermissions';
import Markdown from 'react-markdown';

const useAidanSuggestions = () => {
  const { t } = useTranslation('aidan');

  const [permittedAidan1_3] = usePermittedV2(
    'aidan.summaryOfPortfolioAndWatchlist',
  );
  const [permittedAidan4] = usePermittedV2('aidan.summaryOfInvestment');

  const options = useMemo(() => {
    return [
      ...(permittedAidan1_3
        ? [
            // t('options.suggestion.updateOfPortfolio'),
            // t('options.suggestion.updateOfPortfolioAndWatchlist'),
            // t('options.suggestion.importantAlertsOnWatchlist'),
            // 'What is the top 3 most quoted bonds today?',
            'What is the highest traded bond today?',
            'Which bonds are leading in performance this month?',
          ]
        : []),
      // ...(permittedAidan4 ? [t('options.suggestion.investmentSummary')] : []),
    ];
  }, [t]);

  return options;
};

const ChatInformationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : '',
}));

const SuggestionMessage: FC<{
  onClickSuggestion?: (message: string) => void;
}> = ({ onClickSuggestion }) => {
  const { t } = useTranslation('aidan');
  const [selected, setSelected] = useState<number | null>(null);

  const suggestions = useAidanSuggestions();

  return (
    <Stack spacing={1} alignItems={'flex-start'} mt={1} width={'100%'}>
      <ChatInformationTypography variant="body1" fontWeight={'bold'}>
        {t('message.startInteracting')}
      </ChatInformationTypography>
      {suggestions.map((message, index) => (
        <ButtonBase
          key={index}
          sx={{
            display: 'inline-block',
            borderRadius: '10px',
            opacity: !isNil(selected) ? 0.5 : 1,
            textWrap: 'wrap',
            [`&.${buttonBaseClasses.root}`]: {
              textAlign: 'left',
            },
          }}
          onClick={() => {
            setSelected(index);
            onClickSuggestion?.(message);
          }}
          disabled={!isNil(selected)}
        >
          <ChatMessage align="left">{message}</ChatMessage>
        </ButtonBase>
      ))}
    </Stack>
  );
};

const AnswerMessage: FC<AidanChatResponse> = ({
  title,
  description,
  items,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation('aidan');
  const currentLang = i18n.resolvedLanguage;
  const isCurrentLocaleZh = useMemo(() => {
    return currentLang && Boolean(/^zh-*/.test(currentLang));
  }, [t]);

  // const formatLinkWithParams = (
  //   link: string,
  //   params: {
  //     [key: string]: string;
  //   },
  // ) => {
  //   // params format [[token]]
  //   const paramsFormat = /\[\[.*?\]\]/g;

  //   const paramsInLink = link.match(paramsFormat);

  //   if (paramsInLink) {
  //     paramsInLink.forEach((param) => {
  //       const key = param.replace('[[', '').replace(']]', '');
  //       link = link.replace(param, params[key]);
  //     });
  //   }

  //   return link;
  // };
  // const token = useLocalStorage(StorageKey.token)[0] as string | undefined;

  const reply = items?.[0]?.text || '';

  const markDollarsColorInMarkdown = (text: string) => {
    // match numbers with thousands separators and decimal points
    const numberFormat = /-?\$(\d{1,3}(,\d{3})*(\.\d+)?)/g;
    return text.replace(numberFormat, (match) => {
      return `**${match}**`;
    });
  };

  return (
    <ChatMessage align={'left'}>
      <ChatMessageContainer align="left">
        {/* {title && (
          <Typography fontSize={'inherit'} fontWeight={'bold'} gutterBottom>
            {title}
          </Typography>
        )}
        {description && (
          <Typography fontSize={'inherit'} gutterBottom>
            {description}
          </Typography>
        )}

        <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
          {items &&
            items.map((item, index) => (
              <ListItem
                key={index}
                sx={{ display: 'list-item', px: 0, py: 0.5 }}
              >
                {item.link ? (
                  <Link
                    href={formatLinkWithParams(item.link, {
                      token: token || '',
                    })}
                  >
                    {item.text}
                  </Link>
                ) : (
                  <Typography fontSize={'inherit'}>{item.text}</Typography>
                )}
              </ListItem>
            ))}
        </List> */}
        <Markdown
          components={{
            // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
            strong(props) {
              const { node, style, children, ...rest } = props;

              // check if title is minus number
              if (
                children &&
                isString(children) &&
                children.match(/-\$(\d{1,3}(,\d{3})*(\.\d+)?)/)
              ) {
                return (
                  <strong
                    style={{
                      ...style,
                      color: isCurrentLocaleZh
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                    {...rest}
                    children={children}
                  />
                );
              } else if (
                children &&
                isString(children) &&
                children.match(/\$(\d{1,3}(,\d{3})*(\.\d+)?)/)
              ) {
                return (
                  <strong
                    style={{
                      ...style,
                      color: isCurrentLocaleZh
                        ? theme.palette.error.main
                        : theme.palette.success.main,
                    }}
                    {...rest}
                    children={children}
                  />
                );
              }

              return <strong {...rest}>{children}</strong>;
            },
          }}
        >
          {markDollarsColorInMarkdown(reply)}
        </Markdown>
      </ChatMessageContainer>
    </ChatMessage>
  );
};

const AidanBody: FC<{
  onBack?: () => void;
}> = ({ onBack }) => {
  const { t } = useTranslation('aidan');
  const chatroomBottomRef = useRef<HTMLDivElement>(null);
  const [chatRoomBarRef, { height }] = useMeasure<HTMLDivElement>();

  const [permittedAidan1_3, loadingPermissionAidan1_3] = usePermittedV2(
    'aidan.summaryOfPortfolioAndWatchlist',
  );
  const [permittedAidan4, loadingPermissionAidan4] = usePermittedV2(
    'aidan.summaryOfInvestment',
  );

  const { mutate: ask, isLoading: isThinking } = useAidanChat({
    onSuccess: async (data) => {
      for (const response of data) {
        handleAidanSendMessage(<AnswerMessage {...response} />);
      }

      setTimeout(() => {
        handleAidanSendMessage(
          <SuggestionMessage onClickSuggestion={onClickSuggestion} />,
        );
      }, 1000);
    },
    cacheTime: 0,
  });

  const [messages, setMessages] = useState<
    {
      message: React.ReactNode;
      from: 'aidan' | 'user';
    }[]
  >([]);

  const handleSendMessage = (message: string) => {
    setMessages((prev) => [...prev, { message, from: 'user' }]);
    ask({
      q: message,
      level: 1,
    });
  };

  const handleAidanSendMessage = (message: React.ReactNode) => {
    setMessages((prev) => [...prev, { message, from: 'aidan' }]);
  };
  const onClickSuggestion = (message: string) => {
    handleSendMessage(message);
  };

  useEffect(() => {
    if (
      messages.length === 0 &&
      !loadingPermissionAidan1_3 &&
      !loadingPermissionAidan4
    ) {
      setMessages((prev) => [
        {
          message: <SuggestionMessage onClickSuggestion={onClickSuggestion} />,
          from: 'aidan',
        },
      ]);
    }
  }, [messages, loadingPermissionAidan1_3, loadingPermissionAidan4]);

  useEffect(() => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [messages]);

  useEffect(() => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior: 'instant' });
    }, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      chatroomBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [messages]);

  return (
    <Stack id="aidanChat" height={'100%'} justifyContent={'flex-end'}>
      <AidanAppBar
        ref={chatRoomBarRef}
        onBack={() => {
          setMessages([]);
          onBack?.();
        }}
      />
      <Stack
        height={`calc(100% - ${height}px)`}
        overflow={'hidden'}
        justifyContent={'flex-end'}
      >
        <Box
          flex={1}
          position={'relative'}
          sx={{
            overflowY: 'auto',
            // hide scrollbar
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            overscrollBehavior: 'contain',
          }}
          onScroll={(e) => e.stopPropagation()}
        >
          <Stack flex={1} spacing={2} p={2} justifyContent={'flex-end'}>
            {messages.map((message, index) => (
              <ChatMessage
                key={index}
                align={message.from === 'aidan' ? 'left' : 'right'}
              >
                {message.message}
              </ChatMessage>
            ))}
            {isThinking && (
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <AidanFace elevation={0} />
                <Typography color={'text.secondary'} fontSize={16}>
                  {t('message.thinking')} {<ThinkingDots />}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Box ref={chatroomBottomRef} height={0} width={'100%'} />
        </Box>
        {/* Spacer for chat input */}
        <Box>
          <Box height={64} />
        </Box>
      </Stack>
      <InputBase
        placeholder={t('placeholder.askAidan')}
        fullWidth
        sx={{
          position: 'absolute',
          bottom: 0,
          p: 2,
          height: 64,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#232525' : '#fff',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (e.currentTarget.value.trim() === '') {
              return;
            }

            handleSendMessage(e.currentTarget.value);
            e.currentTarget.value = '';
          }
        }}
      />
    </Stack>
  );
};

const ThinkingDots: FC = () => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev === 3 ? 0 : prev + 1));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <span>{Array.from({ length: dots }).map(() => '.')}</span>;
};

export default AidanBody;
