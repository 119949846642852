import { Theme, CardHeaderProps, CardHeaderClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

export const defaultProps: Partial<CardHeaderProps> = {};

export const styleOverrides: Partial<
  OverridesStyleRules<
    keyof CardHeaderClasses,
    'MuiCardHeader',
    Omit<Theme, 'components'>
  >
> = {
  root: ({ theme }) => ({
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  }),
};

const componentOverrides = {
  defaultProps,
  styleOverrides,
};
export default componentOverrides;
