import { useNavigate } from 'react-router-dom';
import { storageClear } from '../../core/storage';
import { FC } from 'react';
import { useSignOut } from '../../services/users';
import { QueryClient } from '@tanstack/react-query';

const ActionSignOut: FC<{
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { mutate: signOut } = useSignOut({
    onSuccess: () => {
      storageClear();
      const queryClient = new QueryClient();
      queryClient.clear();
      navigate('/login');
    },
  });

  return children({ onClick: () => signOut() });
};

export default ActionSignOut;
