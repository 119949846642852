import { isNumber } from 'lodash';
import { numericFormatter } from 'react-number-format';
import { RfqDetail } from 'services/broker';

const ColumnSettlementFee: React.FC<{
  rfq: RfqDetail | undefined;
}> = ({ rfq }) => {
  const fee = rfq?.settlementFee ?? rfq?.bestSettlementFee;
  if (isNumber(fee)) {
    return `USD ${numericFormatter(String(fee), {
      thousandSeparator: ',',
      decimalScale: 2,
      fixedDecimalScale: true,
    })}`;
  }

  return '-';
};

export default ColumnSettlementFee;
