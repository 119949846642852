import { MessageItem } from 'services/chat';

export type Message = MessageItem;

export enum MessageType {
  TEXT = 'TEXT',
  OPERATION = 'OPERATION',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  FILE = 'FILE',
  SYSTEM = 'SYSTEM',
}

export enum AnnouncementType {
  BOND = 'BOND',
  RFQ = 'RFQ',
}

export type Announcement = {
  title: string;
  announcementType: AnnouncementType; // BOND | RFQ
  parameters: {
    [key: string]: string | number | boolean | null;
  };
};

export type ChatListItem = {
  id: string | number;
  title: string;
  lastMessage?: string;
  lastMessageTime?: string;
  unreadNumber: number;
  isPinned: boolean;
  avatar: React.ReactNode;
  announcement?: Announcement;
};

export type ChatActionHandle = {
  openChatOn: (element: HTMLElement) => void;
};
